import { Fragment, useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { axiosPrograms } from '../../../../../config/axios'
import InputCertificate from './InputCertificate'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'

interface ModalCertificateSelectProps {
    dataCertificate: any
    setModalCertificate: React.Dispatch<React.SetStateAction<boolean>>
    currentCourseId: string
    setPreview: React.Dispatch<React.SetStateAction<never[]>>
}

const ModalCertificateSelect = ({
    dataCertificate,
    setModalCertificate,
    currentCourseId,
    setPreview,
}: ModalCertificateSelectProps) => {
    const { state: stateApariencia } = useContext(AparienciaContext)

    console.log(stateApariencia.currentIdEntity)

    const [imageCertificates, setImageCertificates] = useState<any>([])

    const [loading, setLoading] = useState(false)

    const token = JSON.parse(localStorage.getItem('user')!).token

    console.log(imageCertificates)

    // const onChangeImage = (e: any) => {

    //     if (e !== undefined) {

    //         const image = e.target.files[0]

    //         if (image !== undefined) {

    //             const typeFile = image.type
    //             if (typeFile === "image/png" || typeFile === "image/jpg" || typeFile === "image/jpeg" || typeFile === "image/JPG") {

    //                 console.log(e)
    //                 // console.log(image)
    //                 // setImageName(image.name)

    //                 let array: any = []
    //                 // let array2: any = []

    //                 array.push(image)

    //                 array.forEach((file : any) => {

    //                     file.type_file_back = e.target.name

    //                     // if (imageCertificates.length > 0) {

    //                     //     console.log('entras aqui? first')

    //                     //     imageCertificates.map((data: any, index: number) => {

    //                     //         console.log(data.name)
    //                     //         console.log(e.target.name)

    //                     //         if (e.target.name === data.name) {

    //                     //             console.log('hablaaaaaa')
    //                     //             array2.splice(index, 1)
    //                     //             array2.push(file)

    //                     //         } else {

    //                     //             console.log(array)
    //                     //             array2.push(file)

    //                     //         }
    //                     //     })

    //                     //     setImageCertificates(array2)

    //                     // } else {

    //                     //     console.log('entras aqui? second')

    //                     //     setImageCertificates([
    //                     //         ...imageCertificates,
    //                     //         file
    //                     //     ])

    //                     //     array2.push(file)

    //                     // }

    //                         // if (data.name === action.payload._id) {
    //                         //             return action.payload;
    //                         //         } else {
    //                         //             return data;
    //                         //         }

    //                     setImageCertificates([
    //                         ...imageCertificates,
    //                         file
    //                     ])

    //                 });

    //             }

    //         }

    //     } else {

    //     }

    // }

    const handleSubmitSelectCertificate = (e: any) => {
        e.preventDefault()

        setLoading(true)

        const datan: FormData = new FormData()

        datan.append('fname', 'setCertificateForCourse')
        datan.append('course', currentCourseId)
        datan.append('certificate', dataCertificate._id)

        let array2: any[] = []
        imageCertificates.map((res: any) =>
            array2.push({
                name_file: res.name,
                type_file_back: res.type_file_back,
            }),
        )

        datan.append('body', JSON.stringify(array2))

        imageCertificates.map((res: any) => datan.append('files', res))

        axiosPrograms({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: datan,
        })
            .then(({ data }) => {
                console.log('respuesta', data)

                axiosPrograms({
                    url: 'v5.0.1/api',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    data: {
                        fname: 'getCertificatesTemplates',
                        course: currentCourseId,
                        entity: stateApariencia.currentIdEntity,
                    },
                })
                    .then(({ data }) => {
                        console.log('certificados', data)

                        setPreview(data.certificates)

                        // Notification(type.success, "Certificado agregado correctamente")
                        toast.success('Certificado agregado correctamente')

                        setModalCertificate(false)

                        setLoading(false)
                    })
                    .catch((e) => {
                        console.log(e)

                        console.log(e.response)

                        setLoading(false)

                        // Notification(type.danger, "Ha ocurrido un error, intentalo de nuevo")
                        toast.error('Ha ocurrido un error, intentalo de nuevo')
                    })
            })
            .catch((e) => {
                console.log(e)

                console.log(e.response)

                setLoading(false)

                // Notification(type.danger, "Ha ocurrido un error, intentalo de nuevo")
                toast.error('Ha ocurrido un error, intentalo de nuevo')
            })
    }

    return (
        <div className="main_searchStudent">
            <div className="headSearchStudent">
                <i
                    className="fas fa-times"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setModalCertificate(false)}
                />
            </div>
            <div className="bodySearchStudent scroll">
                <div className="containerCertificateSelected">
                    <div className="imageCertificateSelected">
                        <img src={dataCertificate.preview} alt="imagenSeleccionada" />
                    </div>

                    <span className="subtitleCertificateSelected">
                        Para este certificado complete los siguientes campos{' '}
                    </span>

                    <form
                        className="formCertificateSelected"
                        onSubmit={handleSubmitSelectCertificate}
                    >
                        <div className="contentInput">
                            <div className="inputMapStyle">
                                {dataCertificate.resources.map(
                                    (res: any, key: number) => (
                                        <Fragment key={key}>
                                            <InputCertificate
                                                res={res}
                                                setImageCertificates={
                                                    setImageCertificates
                                                }
                                                imageCertificates={imageCertificates}
                                            />
                                        </Fragment>
                                    ),
                                )}
                            </div>
                        </div>

                        <div className="contentButtonSelectCertificate">
                            {loading ? (
                                <div className="containerSpinner">
                                    {' '}
                                    <SpinnerModal />{' '}
                                </div>
                            ) : (
                                <button type="submit" disabled={loading}>
                                    {' '}
                                    Subir{' '}
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ModalCertificateSelect
