import React, { useContext, useEffect, useState } from 'react'
import ContainerModal from '../../components/modals/ContainerModal'
import { getEntitySuperAdmin } from '../../context/entityContext/EntityAction'
import { EntityContext } from '../../context/entityContext/EntityProvider'
import CardEntity from './../../components/cards/CardEntity'
import { AddEntity } from './../../components/modals/entity/AddEntity'
import { typesEntity } from './../../context/types/typesEntity'
import Error from './../../components/general/error/Error'
import ProgressNS from './../../components/general/progress/ProgressNS'

// prueba
const EntidadesPage = () => {
    const token = JSON.parse(localStorage.getItem('user')!).token
    const { state, dispatch } = useContext(EntityContext)
    // console.log(state);
    const [showModalCreate, setShowModalCreate] = useState(false)

    const [loader, setLoader] = useState<boolean>(false)
    const [error, setError] = useState<number>()

    useEffect(() => {
        getEntitySuperAdmin(token)
            .then(({ data }) => {
                // console.log(data);

                dispatch({
                    type: typesEntity.getEntity,
                    payload: data.entities,
                })

                setLoader(true)
            })
            .catch((e) => {
                console.log(e.response)

                setLoader(true)

                setError(500)
            })
    }, [token, dispatch])

    return (
        <div className="container_EntidadesPage">
            <div className="headUserPag">
                <h3>Entidades</h3>
            </div>

            <div className="bodyUserPag">
                <div className="buttonAddContainer">
                    <div
                        className="designButtonAdd"
                        onClick={() => setShowModalCreate(true)}
                    >
                        Agregar
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>

                <div className="cardEntity_container">
                    <div className="cardEntity_header">
                        <h3> Organizaciones B2B </h3>
                    </div>
                    <div className="cardEntity_content">
                        {!loader ? (
                            <ProgressNS />
                        ) : error ? (
                            <Error error={error} />
                        ) : (
                            <div className="cardEntity_body">
                                {state.entities.length > 0
                                    ? state.entities.map((data: any, index: number) => (
                                          <CardEntity
                                              data={data}
                                              key={index}
                                          ></CardEntity>
                                      ))
                                    : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ContainerModal showModal={showModalCreate}>
                <AddEntity setShowModalCreate={setShowModalCreate} />
            </ContainerModal>
        </div>
    )
}

export default EntidadesPage
