import { IconLoader } from '..'
import './Button2.scss'

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    text?: string
    typeButton?: 'normal' | 'outline' | 'link'
    icon?: string
    isLoading?: boolean
}

export const Button2 = ({
    text,
    typeButton = 'normal',
    icon,
    isLoading,
    ...props
}: ButtonProps) => {
    return (
        <button
            className={`multipacha__button${typeButton === 'outline' ? '-outline' : ''}`}
            {...props}
        >
            {isLoading ? (
                <IconLoader />
            ) : (
                <>
                    {icon && <i className={icon} />}
                    {text}
                </>
            )}
        </button>
    )
}
