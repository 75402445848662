import { memo, useCallback } from 'react'
import './Button.scss'

type ButtonProps = {
    text?: string
    onClick?: () => void
    icon?: string
    icon2?: string
    type?: 'button' | 'submit' | 'reset'
}

export const Button = ({ text, onClick, icon, icon2, type }: ButtonProps) => {
    console.log('button render', icon)

    const handleOnClick = useCallback(() => {
        onClick && onClick()
    }, [onClick])

    // const memoOnClick = useMemo(() => onClick, [onClick])
    return (
        <button
            className={`multiedu__button ${icon || icon2 ? 'multiedu__button-icon' : ''}`}
            onClick={handleOnClick}
            type={type}
        >
            {icon && <i className={`fas fa-${icon}`} />}
            {icon2 && <i className={icon2} />}
            {text}
        </button>
    )
}

export default memo(Button)
