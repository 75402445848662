import { useContext, useEffect, useState } from 'react'
import ContainerModal from '../../components/modals/ContainerModal'
import { UserCounterContext } from '../../context/userCounter/UserCounterProvider'
import { EditUser } from '../../components/modals/continental/counter/users/EditUserCounter'
import { DeleteUser } from '../../components/modals/continental/counter/users/DeleteUserCounter'
import { ScheduledCoursesContext } from '../../context/sheduledCourses/ScheduledCoursesProvider'
import { AparienciaContext } from '../../context/aparienciaContext/AparienciaProvider'
import ContainerModalAllPage from './../../components/modals/ContainerModalAllPage'
import SearchStudentCounter from './../../components/modals/continental/admin/searchAndAddStudent/CrudStudentCounter'
import { getStudentCounter } from '../../context/userCounter/UserCounterAction'
import ProgressNS from './../../components/general/progress/ProgressNS'
import DataTable from 'datatable-multiapps'

const InvitedUserPage = () => {
    const token = JSON.parse(localStorage.getItem('user')!).token
    const { state, dispatch } = useContext(UserCounterContext)
    const { stateScheduled } = useContext(ScheduledCoursesContext)
    const { state: stateApariencia } = useContext(AparienciaContext)
    const [dataTable1, setdataTable1] = useState<any[]>([])

    const [pagination, setpagination] = useState('')

    const pag: any = pagination

    const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false)
    // const [studentsByCourse, setStudentsByCourse] = useState<any>([])
    const [loader, setloader] = useState(false)

    const [detailsUser, setDetailsUser] = useState<object>({})
    const { userCounter } = state

    // console.log('AQUI DATA', userCounter)

    const handleDelete = (data: any) => {
        // console.log(data);
        setDetailsUser(data)
        setShowModalDelete(true)
    }

    const handleEdit = (data: any) => {
        setDetailsUser(data)
        setShowModalEdit(true)
    }

    const options = [
        {
            label: 'Actualizar Alumno',
            onClick: handleEdit,
        },
        {
            label: 'Eliminar Alumno',
            onClick: handleDelete,
        },
    ]

    useEffect(() => {
        setdataTable1(userCounter)
    }, [userCounter, stateScheduled.scheduledCourses])

    const head = [
        {
            column_key: 'photo',
            column_name: 'Foto',
            column_type: 'image',
        },

        {
            column_key: 'name_student',
            column_name: 'Nombre',
            column_type: 'text',
        },

        {
            column_key: 'lastname',
            column_name: 'Apellidos',
            column_type: 'text',
        },

        {
            column_key: 'phone',
            column_name: 'Celular',
            column_type: 'text',
        },

        {
            column_key: 'dni',
            column_name: 'DNI',
            column_type: 'text',
        },

        {
            column_key: 'email',
            column_name: 'Email',
            column_type: 'text',
        },

        {
            column_key: 'address',
            column_name: 'Address',
            column_type: 'text',
        },
    ]

    useEffect(() => {
        const payload = {
            fname: 'getUsersByEntity',
            entity_id: stateApariencia.currentIdEntity,
            roles: ['estudiante'],
            page: 1,
            query: {
                field: 'name',
                search: '',
            },
            docs: 25,
            sortNameNumber: 1,
        }

        const getStudents = async () => {
            setloader(false)
            await getStudentCounter(token, payload, dispatch, setpagination)
            setloader(true)
        }

        getStudents()
    }, [dispatch, setpagination, stateApariencia.currentIdEntity, token])

    const getStudentsData = (e: any) => {
        // const { documents, query, page, filter } = e
        const { query, page, filter } = e

        // console.log(query)
        const payload = {
            fname: 'getUsersByEntity',
            entity_id: stateApariencia.currentIdEntity,
            roles: ['estudiante'],
            page: page,
            query: {
                field: 'name',
                search: query,
            },
            // docs: documents,
            docs: 25,
            sortNameNumber: filter === true ? -1 : 1,
        }

        getStudentCounter(token, payload, dispatch, setpagination)
    }

    const agregar = () => {
        setShowModalCreate(true)
    }

    return (
        <>
            <div className="main_UsuariosB2bPage">
                <div className="headUsuariosB2b">
                    <h3>Alumnos</h3>
                </div>
                <div className="bodyUsuariosB2b scroll">
                    <div className="cardEntity_container">
                        {loader ? (
                            <>
                                <div className="contBtn">
                                    <div className="addOption" onClick={agregar}>
                                        {' '}
                                        + Agregar{' '}
                                    </div>
                                </div>
                                {/* <div style={{display: 'flex', alignItems: 'center'}}> */}

                                <DataTable
                                    data={dataTable1}
                                    title="Listado de Alumnos"
                                    callback={getStudentsData}
                                    pagination={pag}
                                    options={options}
                                    headers={head}
                                    color={`var(--color-secondary)`}
                                />
                                {/* </div> */}
                            </>
                        ) : (
                            <ProgressNS />
                        )}
                    </div>
                    <ContainerModalAllPage showModal={showModalEdit}>
                        <EditUser
                            data={detailsUser}
                            setShowModalEdit={setShowModalEdit}
                            setpagination={setpagination}
                        />
                    </ContainerModalAllPage>

                    <ContainerModal showModal={showModalDelete}>
                        <DeleteUser
                            data={detailsUser}
                            setShowModalDelete={setShowModalDelete}
                            setpagination={setpagination}
                        />
                    </ContainerModal>

                    <ContainerModalAllPage showModal={showModalCreate}>
                        <SearchStudentCounter
                            setShowModalSearchStudent={setShowModalCreate}
                        />
                    </ContainerModalAllPage>
                </div>
            </div>
        </>
    )
}

export default InvitedUserPage
