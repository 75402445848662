import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

import UserHomePage from '../../pages/user/UserHomePage'
import UserPage from '../../pages/user/UserPage'
import EntityPage from './../../pages/user/EntityPage'

const UserRoutes = () => {
    const { path } = useRouteMatch()
    // console.log('path', path)

    return (
        <div>
            <Switch>
                <Route exact path={`${path}/home`} component={UserHomePage} />
                <Route exact path={`${path}/profile`} component={UserPage} />
                <Route exact path={`${path}/entity`} component={EntityPage} />
                <Redirect exact path={`/*`} to={`${path}/home`} />
            </Switch>
        </div>
    )
}

export default UserRoutes
