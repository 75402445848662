import { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { axiosUserV5 } from './../../config/axios'
import { types } from './../../context/types/types'
import { typeUi } from './../../context/types/typesui'
import { AuthContext } from '../../context/auth/AuthProvider'
import { AparienciaContext } from '../../context/aparienciaContext/AparienciaProvider'
import { userIsInEntities } from '../../context/entityContext/EntityAction'
import CardAccess from './CardAccess'

const LoginPage = () => {
    const history = useHistory()

    const { dispatch } = useContext(AuthContext)
    const { dispatchImg } = useContext(AparienciaContext)
    // const { dispatch: dispacthEvent } = useContext(AparienciaContext);
    // console.log(dispatchImg)

    const [user, setUser] = useState({
        username: '',
        password: '',
    })

    // const [userLogged, setUserLogged] = useState({})

    const { username, password } = user

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    // const [role, setRole] = useState('')

    const [msgError, setMsgError] = useState<any>({
        // fields: {},
        errors: {},
    })

    let data: any = {
        title: 'Iniciar sesión como:',
        name: 'Administrador',
        info: 'Para ingresar a la plataforma debe poner sus credenciales de acceso.',
    }

    const onChangeInputs = (e: any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        })
    }

    const handleValidation = () => {
        // let fields = msgError.fields;
        let errors = {
            email: '',
            password: '',
        }
        let formIsValid = true

        //Email
        if (!username) {
            formIsValid = false
            errors['email'] = 'El email es obligatorio'
        }

        if (!password) {
            formIsValid = false
            errors['password'] = 'La contraseña es obligatoria'
        }

        if (password.length < 6) {
            formIsValid = false
            errors['password'] = 'La contraseña tiene que tener mas de 6 carácteres'
        }

        setMsgError({ errors: errors })
        return formIsValid
    }

    const handleLogin = async (e: any) => {
        e.preventDefault()

        setLoading(true)

        if (handleValidation()) {
            axiosUserV5({
                url: 'signin',
                data: {
                    email: username,
                    password: password,
                },
            })
                .then((res) => {
                    const info = res.data
                    // console.log({info})

                    // console.log('primer', info)
                    if (info.user.rol === 'superadmin') {
                        setLoading(false)

                        dispatchImg({
                            type: typeUi.getTheme,
                            payload: {
                                colorSidebar: {
                                    bg_sidebar: '#0E3873',
                                    bg_buttons: '#0E3873',
                                    font_color: '#ffffff',
                                },
                                imgLogo:
                                    'https://res.cloudinary.com/khail/image/upload/v1623334250/Logo_vr_1_1_vvji7m.png',
                            },
                        })

                        dispatch({
                            type: types.login,
                            payload: {
                                username: info.user.email,
                                rol: info.user.rol,
                                token: info.token,
                                idUser: info.user._id,
                                dataEntity: {
                                    customize: {
                                        colors: {
                                            bg_sidebar: '#197BFF',
                                            bg_buttons: '#197BFF',
                                            font_color: '#ffffff',
                                        },
                                        logo: 'https://res.cloudinary.com/khail/image/upload/v1623334250/Logo_vr_1_1_vvji7m.png',
                                        portrait:
                                            'https://playtecacademy.s3.us-east-2.amazonaws.com/entities/Rectangle%203072.png',
                                    },
                                },
                                listEntity: [],
                            },
                        })
                    } else {
                        userIsInEntities(info.token, info.user._id).then(({ data }) => {
                            // console.log('QUE ENTIDADES ESTAS', data)

                            if (data.data.length > 0) {
                                const firstEntity = data.data[0]

                                console.log(firstEntity)
                                setLoading(false)
                                toast.dismiss()
                                toast.success(`Bienvenido ${username}`)

                                if (firstEntity.roles['coordinador']) {
                                    dispatch({
                                        type: types.changeRoles,
                                        payload: 'coordinador',
                                    })

                                    dispatchImg({
                                        type: typeUi.getTheme,
                                        payload: {
                                            colorSidebar: firstEntity.customize.colors,
                                            imgLogo: firstEntity.customize.logo,
                                        },
                                    })

                                    dispatchImg({
                                        type: typeUi.getId,
                                        payload: firstEntity._id,
                                    })

                                    dispatch({
                                        type: types.login,
                                        payload: {
                                            username: info.user.email,
                                            rol: info.user.rol,
                                            main_rol: 'coordinador',
                                            token: info.token,
                                            idUser: info.user._id,
                                            rolesByEntity: firstEntity.roles,
                                            dataEntity: firstEntity,
                                            listEntity: data.data,
                                            user: data.user,
                                        },
                                    })

                                    history.push('/dashboard/coordinator/courses')
                                }

                                if (firstEntity.roles['counter']) {
                                    dispatch({
                                        type: types.changeRoles,
                                        payload: 'counter',
                                    })

                                    dispatchImg({
                                        type: typeUi.getTheme,
                                        payload: {
                                            colorSidebar: firstEntity.customize.colors,
                                            imgLogo: firstEntity.customize.logo,
                                        },
                                    })

                                    dispatchImg({
                                        type: typeUi.getId,
                                        payload: firstEntity._id,
                                    })

                                    dispatch({
                                        type: types.login,
                                        payload: {
                                            username: info.user.email,
                                            rol: info.user.rol,
                                            main_rol: 'counter',
                                            token: info.token,
                                            idUser: info.user._id,
                                            rolesByEntity: firstEntity.roles,
                                            dataEntity: firstEntity,
                                            listEntity: data.data,
                                            user: data.user,
                                        },
                                    })

                                    history.push(
                                        '/dashboard/accountant/students/inscribed',
                                    )
                                }

                                if (firstEntity.roles['admin']) {
                                    dispatchImg({
                                        type: typeUi.getTheme,
                                        payload: {
                                            colorSidebar: firstEntity.customize.colors,
                                            imgLogo: firstEntity.customize.logo,
                                        },
                                    })

                                    dispatchImg({
                                        type: typeUi.getId,
                                        payload: firstEntity._id,
                                    })

                                    dispatch({
                                        type: types.changeRoles,
                                        payload: 'admin',
                                    })

                                    dispatch({
                                        type: types.login,
                                        payload: {
                                            username: info.user.email,
                                            rol: info.user.rol,
                                            main_rol: 'admin',
                                            token: info.token,
                                            idUser: info.user._id,
                                            rolesByEntity: firstEntity.roles,
                                            dataEntity: firstEntity,
                                            listEntity: data.data,
                                            user: data.user,
                                        },
                                    })

                                    // history.push('/dashboard/admin/home')
                                    history.push('/dashboard/admin/courses')
                                }

                                // if (!firstEntity.roles['admin b2b'] && !firstEntity.roles['counter'] && !firstEntity.roles['coordinador']) {
                                //     history.push('/dashboard/user/entity')
                                //     toast.error("No tienes acceso a esta entidad")

                                // }
                                // setLoading(false)

                                // toast.success(`Bienvenido ${username}`)
                            } else {
                                setLoading(false)

                                toast.error('No tienes acceso a esta entidad')
                            }
                        })
                    }
                })
                .catch((e) => {
                    setLoading(false)
                    console.log(e.response)
                    toast.error('Revisa tu usuario o contraseña e intenta de nuevo')
                })
        } else {
            setLoading(false)
        }
    }

    return (
        <div className="main_AccessPage">
            <CardAccess data={data}>
                <form onSubmit={handleLogin}>
                    <div className="formBody">
                        <label htmlFor="accessUser">
                            <input
                                id="accessUser"
                                type="email"
                                name="username"
                                placeholder="Ingrese su correo"
                                value={username}
                                onChange={onChangeInputs}
                                disabled={loading}
                                required
                            />
                        </label>
                        <span style={{ color: 'red' }}>{msgError.errors['email']}</span>
                        <span>
                            <Link to="/auth/recover-password">
                                ¿Olvidó su contraseña?
                            </Link>
                        </span>
                        <label htmlFor="accessPass">
                            <input
                                id="accessPass"
                                className="inpPass"
                                type={`${showPassword ? 'text' : 'password'}`}
                                placeholder="Contraseña"
                                name="password"
                                value={password}
                                onChange={onChangeInputs}
                                disabled={loading}
                                required
                            />
                            <i
                                className={`far fa-eye${showPassword ? '-slash' : ''}`}
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </label>
                        <span style={{ color: 'red' }}>
                            {msgError.errors['password']}
                        </span>
                    </div>
                    <div className="formFooter">
                        {loading ? (
                            <button style={{ backgroundColor: '#8CBDFF' }} disabled>
                                CARGANDO...
                            </button>
                        ) : (
                            <button>INICIAR SESIÓN</button>
                        )}
                    </div>
                </form>
            </CardAccess>
        </div>
        //  <div className="floating_button">
        //                     <i className="fas fa-headphones"></i>
        //  </div>
    )
}

export default LoginPage
