import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { toast } from 'react-toastify'

import { EVALUATIONS_URL } from '../../config/utils'
import type {
    Alternative,
    APIAddEditPracticeResponse,
    APIGetPracticesResponse,
    APIGetQuestionsResponse,
    EntityParam,
    PaginationOpts,
    // Question,
} from '../../interfaces'
import type { RootState } from '../store'

interface QualifiedPracticeId {
    qualified_practice?: string
}

interface GetQualifiedPracticesParams extends EntityParam {
    parent_course: string
}

interface AddEditQualifiedPracticeParams extends EntityParam, QualifiedPracticeId {
    fname: string
    course?: string
    title?: string
    weight?: number
}

interface GetQuestionsParams extends EntityParam, PaginationOpts {
    filter: string
}

interface DeleteQuestionParams extends EntityParam {
    question_id: string
}

interface AddEditQuestionParams extends EntityParam {
    fname: string
    question_id?: string
    keywords: string[]
    nivel: string
    question: string
    title: string
    type: string
    alternatives: Alternative[]
}

export const evaluationsApi = createApi({
    reducerPath: 'evalautionsAPi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${EVALUATIONS_URL}v5.0.1/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token
            if (token) headers.set('authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['Practices', 'Questions'],
    endpoints: (builder) => ({
        getQualifiedPractices: builder.query<
            APIGetPracticesResponse,
            GetQualifiedPracticesParams
        >({
            query: ({ entity, parent_course }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getQualifiedPractices',
                    parent_course,
                    entity,
                },
            }),
            providesTags: (result) =>
                result?.qualifiedPractices
                    ? [
                          ...result.qualifiedPractices.map(({ _id }) => ({
                              type: 'Practices' as const,
                              id: _id,
                          })),
                          { type: 'Practices', id: 'LIST' },
                      ]
                    : [{ type: 'Practices', id: 'LIST' }],
        }),
        addEditQualifiedPractice: builder.mutation<
            APIAddEditPracticeResponse,
            AddEditQualifiedPracticeParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    ...params,
                },
            }),
            invalidatesTags: [{ type: 'Practices', id: 'LIST' }],
            onQueryStarted: async (params, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        `La práctica ha sido ${
                            params.fname === 'createQualifiedPractice'
                                ? 'creada'
                                : 'actualizada'
                        } con éxito`,
                    )
                } catch (error) {
                    console.log(error)
                    toast.error(
                        `Error al ${
                            params.fname === 'createQualifiedPractice'
                                ? 'crear'
                                : 'actualizar'
                        } la práctica`,
                    )
                }
            },
        }),
        deleteQualifiedPractice: builder.mutation<{}, QualifiedPracticeId>({
            query: ({ qualified_practice }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteQualifiedPractice',
                    qualified_practice,
                },
            }),
            invalidatesTags: (_, __, { qualified_practice }) => [
                { type: 'Practices', id: qualified_practice },
            ],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Práctica eliminada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar la práctica')
                }
            },
        }),
        getQuestions: builder.query<APIGetQuestionsResponse, GetQuestionsParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getQuestions',
                    ...params,
                },
            }),
            // transformResponse: (response: APIGetQuestionsResponse) =>
            //     response.questions.map((question) => question),
            providesTags: (response) =>
                response
                    ? [
                          ...response.questions.map(({ _id }) => ({
                              type: 'Questions' as const,
                              id: _id,
                          })),
                          { type: 'Questions', id: 'LIST' },
                      ]
                    : [{ type: 'Questions', id: 'LIST' }],
        }),
        addEditQuestion: builder.mutation<{}, AddEditQuestionParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    ...params,
                },
            }),
            invalidatesTags: [{ type: 'Questions', id: 'LIST' }],
            onQueryStarted: async (params, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        `La pregunta ha sido ${
                            params.fname === 'createQuestion' ? 'creada' : 'actualizada'
                        } con éxito`,
                    )
                } catch (error) {
                    console.log(error)
                    toast.error(
                        `Error al ${
                            params.fname === 'createQuestion' ? 'crear' : 'actualizar'
                        } la pregunta`,
                    )
                }
            },
        }),
        deleteQuestion: builder.mutation<{}, DeleteQuestionParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteQuestion',
                    ...params,
                },
            }),
            invalidatesTags: (_, __, { question_id }) => [
                { type: 'Questions', id: question_id },
            ],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Pregunta eliminada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar la pregunta')
                }
            },
        }),
    }),
})

export const {
    useGetQualifiedPracticesQuery,
    useAddEditQualifiedPracticeMutation,
    useDeleteQualifiedPracticeMutation,
    useGetQuestionsQuery,
    useAddEditQuestionMutation,
    useDeleteQuestionMutation,
} = evaluationsApi
