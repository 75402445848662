import { axiosUser, axiosUserV5 } from './../../config/axios'

export const createUsers = async (obj: any, token: string) => {
    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const getUsers = async (token: string) => {
    return axiosUser({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getPreUsers',
            service: 'users',
        },
    })
}

export const updateUsers = async (obj: any, token: string) => {
    return axiosUser({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            ...obj,
        },
    })
}

export const deleteUsers = async (obj: any, token: string) => {
    return axiosUser({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            ...obj,
        },
    })
}

export const createAdmins = async (obj: any, token: string) => {
    return axiosUser({
        url: 'signup',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}
