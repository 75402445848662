import React, { ElementType, useState } from 'react'
import './styles.sass'

interface Props {
    columns: any
    data: any
    Butoon?: ElementType
    filterElement?: any
    Placeholder?: ElementType
}

const TableComponent = ({ columns, data, Butoon, filterElement, Placeholder }: Props) => {
    const [filterOn, setfilterOn] = useState(false)
    const [inputSearch, setinputSearch] = useState('')
    const [state, setState] = useState({
        pageSize: 5, // <- 2 items will be shown on single page
        pageIndex: 0, // 0 is a default page to show
    })
    const handlePrevPageClick = () => {
        setState({
            ...state,
            pageIndex: state.pageIndex > 0 ? state.pageIndex - 1 : 0,
        })
    }

    const handleNextPageClick = () => {
        setState({
            ...state,
            pageIndex:
                state.pageIndex < Math.floor(data.length / state.pageSize)
                    ? state.pageIndex + 1
                    : state.pageIndex,
        })
    }
    const onChangeSearch = async (e: any) => {
        e.persist()
        await setinputSearch(e.target.value)
        filterElement(e.target.value)
    }
    return (
        <>
            <div className="Header_table">
                {filterOn ? (
                    <>
                        <input
                            type="text"
                            className="input_search"
                            value={inputSearch}
                            onChange={onChangeSearch}
                        />
                        <i
                            className="fas fa-times"
                            onClick={() => setfilterOn(false)}
                        ></i>
                    </>
                ) : (
                    <button className="button_search " onClick={() => setfilterOn(true)}>
                        {' '}
                        <i className="fas fa-search"></i> Buscar
                    </button>
                )}
                {Butoon ? <Butoon /> : null}
            </div>
            <table>
                <thead>
                    <tr>
                        {columns.map((col: any, key: number) =>
                            col.icon ? (
                                <col.cellheader key={key}></col.cellheader>
                            ) : (
                                <th key={key}>{col.title}</th>
                            ),
                        )}
                    </tr>
                    <tr className="tr_vacio"></tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data
                            .slice(
                                state.pageIndex * state.pageSize,
                                state.pageIndex * state.pageSize + state.pageSize,
                            )
                            .map((dat: any, key: number) => (
                                <tr key={key}>
                                    {columns.map((col: any, key: number) =>
                                        col.button ? (
                                            <td key={key}>
                                                <col.cell data={dat}></col.cell>
                                            </td>
                                        ) : (
                                            <td key={key}>{dat[col.tagname]}</td>
                                        ),
                                    )}
                                </tr>
                            ))
                    ) : Placeholder ? (
                        <tr>
                            <td colSpan={4}>
                                <Placeholder />
                            </td>{' '}
                        </tr>
                    ) : null}
                </tbody>
            </table>
            <div className="pagination_table">
                <div className="itemstopage">
                    Filas por Pagina:
                    <select
                        className="selectbox-source"
                        id="number"
                        value={state.pageSize}
                        onChange={(e: any) =>
                            setState({ ...state, pageSize: Number(e.target.value) })
                        }
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                <div className="button" onClick={handlePrevPageClick}>
                    <i className="fas fa-angle-left"></i>
                </div>
                {state.pageIndex + 1}
                <div className="button" onClick={handleNextPageClick}>
                    <i className="fas fa-angle-right"></i>
                </div>
            </div>
        </>
    )
}

export default TableComponent
