import { useDeleteQualifiedPracticeMutation } from '../../../../../store/api'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface DeleteQualifiedPracticeProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    practiceId: string
}

export const DeleteQualifiedPractice = ({
    setShowModal,
    practiceId,
}: DeleteQualifiedPracticeProps) => {
    const [deletePractice, { isLoading }] = useDeleteQualifiedPracticeMutation()

    const handleDeletePractice = () => {
        deletePractice({
            qualified_practice: practiceId,
        }).then(() => setShowModal(false))
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal(false)} />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar la nota?</p>
                <div className="containerSpinner_center">
                    <button
                        type="submit"
                        onClick={() => setShowModal(false)}
                        disabled={isLoading}
                    >
                        Cancelar
                    </button>
                    {isLoading ? (
                        <div className="containerSpinner">
                            <SpinnerModal />
                        </div>
                    ) : (
                        <button className="saveButton" onClick={handleDeletePractice}>
                            Aceptar
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
