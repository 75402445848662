import { useState, useContext, useEffect } from 'react'

import { axiosPrograms } from '../../config/axios'
import SpinnerCertificate from './../../components/general/spinner/SpinnerCertificate'
import ContainerModalAllPage from '../../components/modals/ContainerModalAllPage'
import ModalCertificateSelect from '../../components/modals/continental/admin/certificated/ModalCertificateSelect'
import { AparienciaContext } from '../../context/aparienciaContext/AparienciaProvider'

interface CertificateViewProps {
    currentCourseId: string
    setShowViewCertificate: React.Dispatch<React.SetStateAction<boolean>>
}

const CertificateView = ({
    currentCourseId,
    setShowViewCertificate,
}: CertificateViewProps) => {
    const [loader, setLoader] = useState<boolean>(true)

    const { state: stateApariencia } = useContext(AparienciaContext)
    console.log(stateApariencia.currentIdEntity)

    const token = JSON.parse(localStorage.getItem('user')!).token

    const [preview, setPreview] = useState([])

    const [dataCertificate, setDataCertificate] = useState<any>({})

    const [modalCertificate, setModalCertificate] = useState(false)

    useEffect(() => {
        axiosPrograms({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getCertificatesTemplates',
                course: currentCourseId,
                entity: stateApariencia.currentIdEntity,
            },
        })
            .then(({ data }) => {
                console.log('certificados', data)

                setPreview(data.certificates)
                setLoader(false)
            })
            .catch((e) => {
                console.log(e)

                console.log(e.response)
                setLoader(false)
            })
    }, [token, stateApariencia.currentIdEntity, currentCourseId])

    const handleGoPreview = (res: any) => {
        setDataCertificate(res)

        setModalCertificate(true)
    }

    return (
        <div className="container_certificate">
            <div className="content_certificate_body">
                <span
                    style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
                >
                    <i
                        className="fas fa-chevron-left"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowViewCertificate(false)}
                    />
                    <p>Listado de diplomados</p>
                </span>
                {!loader ? (
                    preview.length ? (
                        <span>Puede elegir un modelo de certificado</span>
                    ) : (
                        <span>No cuenta con certificados disponibles</span>
                    )
                ) : null}
                {/* <span>Puede elegir un modelo de certificado</span> */}

                <div className="listCertificate">
                    {!loader ? (
                        preview.length ? (
                            preview.map((res: any, key: number) => (
                                <div
                                    className={
                                        res.selected ? 'itemCerti active' : 'itemCerti'
                                    }
                                    onClick={() => handleGoPreview(res)}
                                    key={key}
                                >
                                    <img
                                        src={res.preview}
                                        alt={`certy${key}`}
                                        loading="lazy"
                                    />
                                    {res.selected ? (
                                        <i className="fas fa-check-circle"></i>
                                    ) : null}
                                </div>
                            ))
                        ) : null
                    ) : (
                        <div className="containerSpinner">
                            {' '}
                            <SpinnerCertificate />{' '}
                        </div>
                    )}
                    {/* {

                        preview.length ? preview.map((res: any, key: number) => (
                            <div className={res.selected ? "itemCerti active" : "itemCerti"} onClick={() => handleGoPreview(res)} key={key}>
                                <img src={res.preview} alt={`certy${key}`} loading='lazy' />
                                {res.selected ? <i className="fas fa-check-circle"></i> : null}
                            </div>
                        )) : <div className="containerSpinner"> <SpinnerCertificate /> </div>
                    } */}
                </div>
                <ContainerModalAllPage showModal={modalCertificate}>
                    <ModalCertificateSelect
                        dataCertificate={dataCertificate}
                        currentCourseId={currentCourseId}
                        setModalCertificate={setModalCertificate}
                        setPreview={setPreview}
                    />
                </ContainerModalAllPage>
            </div>
        </div>
    )
}

export default CertificateView
