import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { toast } from 'react-toastify'

import { ENTITIES_URL } from '../../config/utils'
import {
    APIAddCredentialResponse,
    APIGetCredentialsResponse,
    EntityParam,
    PaginationOpts,
    ApiSedesResponse,
    APIGetRolesByEntityResponse,
    EntityIdParam,
    Rol,
} from '../../interfaces'
import { RootState } from '../store'

interface GetSedesParams extends EntityParam, PaginationOpts {}

export interface CreateSedeParams {
    _id: string
    entity: string
    name: string
    country: string
    department: string
    province?: string
    address: string
}

interface UpdateSedeParams extends CreateSedeParams {
    campus: string
}

interface DeleteSedeParams {
    campus: string
}

interface CredentialsParams {
    entity: string
    descendant: boolean
    docs: number
    page: number
    query?: string
    type: 'payment_method' | 'videoconference'
}

interface CredentialsCreateParams {
    zoom_token_id: string
    type: 'payment_method' | 'videoconference'
    entity: string
    email: string
    token: string
    service: string
}

export const entitiesApi = createApi({
    reducerPath: 'entitiesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENTITIES_URL}v5.0.1/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token
            if (token) headers.set('authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['Sedes', 'Credentials', 'Roles'],
    endpoints: (builder) => ({
        getSedes: builder.query<ApiSedesResponse, GetSedesParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getCampus',
                    ...params,
                    // entity: '623222f3790e4900124c337e',
                    // page,
                    // query: {
                    //     field: 'name',
                    //     search: '',
                    // },
                    // sort: '-name',
                    // docs: 15,
                    // descendant: false,
                },
            }),
            providesTags: (result) =>
                result?.campus
                    ? [
                          ...result.campus.map(({ _id }) => ({
                              type: 'Sedes' as const,
                              id: _id,
                          })),
                          { type: 'Sedes', id: 'LIST' },
                      ]
                    : [{ type: 'Sedes', id: 'LIST' }],

            // onQueryStarted: (params, { getState, updateCachedData }) => {
            //     console.log('query started', params)
            //     console.log((getState() as RootState).auth.entity)
            // },
        }),
        getSede: builder.query<ApiSedesResponse, GetSedesParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getCampus',
                    ...params,
                },
            }),
        }),
        addSede: builder.mutation<ApiSedesResponse, Omit<CreateSedeParams, '_id'>>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'createCampus',
                    ...params,
                },
            }),
            // invalidatesTags: ['Sedes'],
            invalidatesTags: [{ type: 'Sedes', id: 'LIST' }],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    // const { data } = await queryFulfilled
                    // console.log({ data })
                    await queryFulfilled
                    toast.success('Sede creada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al crear sede')
                }
            },
        }),
        editSede: builder.mutation<
            ApiSedesResponse,
            Omit<UpdateSedeParams, '_id' | 'entity'>
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'updateCampus',
                    ...params,
                },
            }),
            // invalidatesTags: ['Sedes'],
            invalidatesTags: (_, __, { campus }) => [{ type: 'Sedes', id: campus }],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    // const { data } = await queryFulfilled
                    // console.log({ data })
                    await queryFulfilled
                    toast.success('Sede actualizada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al actualizar sede')
                }
            },
        }),
        deleteSede: builder.mutation<ApiSedesResponse, DeleteSedeParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteCampus',
                    ...params,
                },
            }),
            // invalidatesTags: ['Sedes'],
            invalidatesTags: (_, __, { campus }) => [{ type: 'Sedes', id: campus }],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    // const { data } = await queryFulfilled
                    // console.log({ data })
                    await queryFulfilled
                    toast.success('Sede eliminada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar sede')
                }
            },
        }),
        getCredentials: builder.query<APIGetCredentialsResponse, CredentialsParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getZoomTokens',
                    ...params,
                },
            }),
            // providesTags: (result) =>
            //     result?.zoomToken
            //         ? [
            //               ...result.zoomToken.map(({ _id }) => ({
            //                   type: 'Credentials' as const,
            //                   _id,
            //               })),
            //               'Credentials',
            //           ]
            //         : ['Credentials'],
            providesTags: (result) =>
                result?.zoomToken
                    ? [
                          ...result.zoomToken.map(
                              ({ _id }) =>
                                  ({
                                      type: 'Credentials',
                                      _id,
                                  } as const),
                          ),
                          { type: 'Credentials', id: 'LIST' },
                      ]
                    : [{ type: 'Credentials', id: 'LIST' }],
        }),
        addCredential: builder.mutation<
            APIAddCredentialResponse,
            Omit<CredentialsCreateParams, 'zoom_token_id'>
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'createZoomToken',
                    ...params,
                },
            }),
            // invalidatesTags: ['Credentials'],
            invalidatesTags: [{ type: 'Credentials', id: 'LIST' }],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Credencial creada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al crear credencial')
                }
            },
        }),
        editCredential: builder.mutation<
            APIAddCredentialResponse,
            Omit<CredentialsCreateParams, 'entity' | 'type'>
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'updateZoomToken',
                    ...params,
                },
            }),
            // invalidatesTags: ['Credentials'],
            invalidatesTags: (_, __, { zoom_token_id }) => [
                { type: 'Credentials', _id: zoom_token_id },
            ],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    // const { data } = await queryFulfilled
                    // console.log({ data })
                    await queryFulfilled
                    toast.success('Credencial actualizada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al actualizar credencial')
                }
            },
        }),
        deleteCredential: builder.mutation<APIAddCredentialResponse, string>({
            query: (_id) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteZoomToken',
                    zoom_token_id: _id,
                },
            }),
            //  invalidatesTags: ['Credentials'],
            invalidatesTags: (_, __, _id) => [{ type: 'Credentials', _id }],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Credencial eliminada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar credencial')
                }
            },
        }),
        getRolesByEntity: builder.query<Rol[], EntityIdParam>({
            query: ({ entity_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getRolesByEntity',
                    entity_id,
                },
            }),
            transformResponse: (response: APIGetRolesByEntityResponse) =>
                response.roles.map((rol) => rol),
            providesTags: (roles) =>
                roles
                    ? [
                          ...roles.map(
                              ({ _id }) =>
                                  ({
                                      type: 'Roles',
                                      _id,
                                  } as const),
                          ),
                          { type: 'Roles', id: 'LIST' },
                      ]
                    : [{ type: 'Roles', id: 'LIST' }],
        }),
        // addRole: builder.mutation<APIAddRoleResponse, Omit<RoleCreateParams, 'entity'>>({
        //     query: (params) => ({
        //         url: '',
        //         method: 'POST',
        //         body: {
        //             fname: 'createRole',
        //             ...params,
        //         },
        //     }),
        //     // invalidatesTags: ['Roles'],
        //     invalidatesTags: [{ type: 'Roles', id: 'LIST' }],
        //     onQueryStarted: async (_, { queryFulfilled }) => {
        //         try {
        //             await queryFulfilled
        //             toast.success('Rol creado con éxito')
        //         } catch (error) {
        //             console.log(error)
        //             toast.error('Error al crear rol')
        //         }
        //     },
        // }),
        // editRole: builder.mutation<APIAddRoleResponse, Omit<RoleCreateParams, 'entity' | 'type'>>({
        //     query: (params) => ({
        //         url: '',
        //         method: 'POST',
        //         body: {
        //             fname: 'updateRole',
        //             ...params,
        //         },
        //     }),
        //     // invalidatesTags: ['Roles'],
        //     invalidatesTags: (_, __, { role_id }) => [{ type: 'Roles', _id: role_id }],
        //     onQueryStarted: async (_, { queryFulfilled }) => {
        //         try {
        //             // const { data } = await queryFulfilled
        //             // console.log({ data })
        //             await queryFulfilled
        //             toast.success('Rol actualizado con éxito')
        //         } catch (error) {
        //             console.log(error)
        //             toast.error('Error al actualizar rol')
        //         }
        //     },
        // }),
    }),
})

export const {
    useGetSedesQuery,
    useGetSedeQuery,
    useAddSedeMutation,
    useEditSedeMutation,
    useDeleteSedeMutation,
    useGetCredentialsQuery,
    useAddCredentialMutation,
    useEditCredentialMutation,
    useDeleteCredentialMutation,
    useGetRolesByEntityQuery,
} = entitiesApi
