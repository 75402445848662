import { Path, UseFormRegister } from 'react-hook-form'
import './Input.scss'

interface InputProps<T extends object> {
    // value?: string
    // onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    // setValue?: (value: string) => void
    label: Path<T>
    register: UseFormRegister<T>
    required?: boolean
}

export const Input = <T extends object>({
    label,
    register,
    required = false,
}: InputProps<T>) => {
    return (
        <input
            className="multiedu__input"
            type="text"
            // value={value}
            // onChange={(e) => setValue && setValue(e.target.value)}
            placeholder="Buscar por nombre..."
            {...register(label, { required })}
        />
    )
}
