import { useCallback, useMemo, useState } from 'react'
import type { TableColumn } from 'react-data-table-component'

import type { Course, ModalOptions, QualifiedPractice } from '../../interfaces'
import { useAppSelector } from '../../hooks/useReduxHooks'
import { selectAuth, selectCourse } from '../../store/slices'
import { useGetQualifiedPracticesQuery } from '../../store/api'
import { Button, MyTable, TableHeader } from '../../components'
import ContainerModal from '../../components/modals/ContainerModal'
import {
    AddEditQualifiedPractice,
    DeleteQualifiedPractice,
} from '../../components/modals/continental/admin/practices'

interface PracticesPageProps {
    setShowView: React.Dispatch<React.SetStateAction<'module' | 'evaluation' | 'none'>>
    course: Course
}

const PracticesPage = ({ course, setShowView }: PracticesPageProps) => {
    const { currentCategory } = useAppSelector(selectCourse)
    const { entity } = useAppSelector(selectAuth)
    const [currentPractice, setCurrentPractice] = useState<QualifiedPractice>()
    const [showModalAddEditPractice, setShowModalAddEditPractice] =
        useState<ModalOptions>('hide')
    const [showModalDeletePractice, setShowModalDeletePractice] = useState<boolean>(false)

    const { practices, isLoading, isFetching, refetch } = useGetQualifiedPracticesQuery(
        {
            entity,
            parent_course: course._id,
        },
        {
            selectFromResult: ({ data, isLoading, isFetching }) => ({
                practices:
                    data?.qualifiedPractices.map((p) => ({
                        ...p,
                        porcent: `${p.porcent.slice(0, -1)} %`,
                    })) || [],
                isLoading,
                isFetching,
            }),
        },
    )

    const columns: TableColumn<QualifiedPractice>[] = useMemo(
        () => [
            {
                name: 'Nombre',
                selector: (row) => row.title,
            },
            {
                name: 'Peso',
                selector: (row) => row.weight,
            },
            {
                name: 'Porcentaje',
                selector: (row) => row.porcent,
            },
        ],
        [],
    )

    // const openAddModal = useCallback(() => setShowModalAddEditPractice('add'), [])

    const RowOptions = useCallback(
        () => (
            <div
                className="multiedu__table--row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '1rem',
                    padding: '1rem 1rem 1rem 6rem',
                }}
            >
                <Button
                    onClick={() => setShowModalAddEditPractice('edit')}
                    text="Editar nota"
                />
                <Button
                    onClick={() => setShowModalDeletePractice(true)}
                    text="Eliminar nota"
                />
            </div>
        ),
        [],
    )

    return (
        <div className="content_ModuleCourse">
            <div className="first_row_moduleCourse">
                <span>
                    <i
                        className="fas fa-chevron-left"
                        onClick={() => setShowView('none')}
                    />
                </span>
                <span style={{ color: '#CDD4DB' }}>{currentCategory?.name}</span>
                <span>/</span>
                <span>{course.name}</span>
            </div>
            <div className="second_row_moduleCourse">
                <div className="left_content">
                    {course.preview && course.preview.includes('https://') ? (
                        <img src={course.preview} alt="ImagItem" />
                    ) : (
                        <div>
                            <i
                                className="profileDef fas fa-user-circle"
                                style={{ fontSize: '150px' }}
                            ></i>
                        </div>
                    )}
                </div>
                <div className="right_content">
                    <p></p>
                    <p>{course.name}</p>
                    <p>{course.description}.</p>
                </div>
            </div>
            <div className="third_row_evaluations">
                <div>
                    <TableHeader
                        // title="Listado de prácticas"
                        title="Asignación de peso de las notas de cada módulo"
                        refetch={refetch}
                        // handleShowAddModal={openAddModal}
                        showFilter={false}
                    />
                    <MyTable
                        data={practices}
                        columns={columns}
                        isLoading={isLoading || isFetching}
                        activeItem={currentPractice}
                        setActiveItem={setCurrentPractice}
                        showPagination={false}
                        refetch={refetch}
                        expandableRowsComponent={RowOptions}
                    />
                </div>
            </div>

            <ContainerModal showModal={showModalAddEditPractice !== 'hide'}>
                <AddEditQualifiedPractice
                    showModal={showModalAddEditPractice}
                    setShowModal={setShowModalAddEditPractice}
                    practice={currentPractice}
                    setPractice={setCurrentPractice}
                    courseId={course._id}
                />
            </ContainerModal>

            <ContainerModal showModal={showModalDeletePractice}>
                <DeleteQualifiedPractice
                    practiceId={currentPractice?._id!}
                    setShowModal={setShowModalDeletePractice}
                />
            </ContainerModal>
        </div>
    )
}

export default PracticesPage
