import { useEffect, useState, useContext } from 'react'
import ContainerModalAllPage from './../../../components/modals/ContainerModalAllPage'
import { AparienciaContext } from '../../../context/aparienciaContext/AparienciaProvider'
import { ScheduledCoursesContext } from '../../../context/sheduledCourses/ScheduledCoursesProvider'
import { getUsersByScheduleds } from '../../../context/userCounter/UserCounterAction'
import DataTable from 'datatable-multiapps'
import {
    AddUserEnrollment,
    DeleteUserEnrollment,
} from './../../../components/modals/continental/counter/userEnrollment/CrudUserEnrollment'
import ContainerModal from '../../../components/modals/ContainerModal'
import ProgressNS from './../../../components/general/progress/ProgressNS'

export const RightStudentSide = ({ setviewTable, setdetailStudent }: any) => {
    const [pagination, setpagination] = useState('')

    const [loader, setloader] = useState(true)

    const pag: any = pagination

    const [showModalCreate, setShowModalCreate] = useState<boolean>(false)

    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)

    const [dataTable1, setDataTable1] = useState([])

    const { stateScheduled } = useContext(ScheduledCoursesContext)

    const { currentScheduledCourses } = stateScheduled

    const [userDetail, setuserDetail] = useState('')

    const { state: stateApariencia } = useContext(AparienciaContext)

    const token = JSON.parse(localStorage.getItem('user')!).token

    const deleteStudent = (e: any) => {
        // console.log(e)
        setuserDetail(e)
        setShowModalDelete(true)
    }

    const showDetailStudent = (e: any) => {
        // console.log(e)
        handleChangeView(e)
    }

    const handleChangeView = (data: any) => {
        // console.log(data)
        setviewTable(false)
        setdetailStudent({ detail: data })
    }

    const getStudentsData = (e: any) => {
        // console.log(e)

        const { documents, query, page, filter } = e

        const payload = {
            page: page,
            filter_by_name: query,
            docs: documents,
            descendant: filter,
            setpagination: setpagination,
            setDataTable1: setDataTable1,
            currentScheduled: currentScheduledCourses.id,
            token: token,
            entity_id: stateApariencia.currentIdEntity,
        }

        getUsersByScheduleds(payload)
    }

    const options = [
        {
            label: 'Eliminar Alumno',
            onClick: deleteStudent,
        },
        {
            label: 'Ver detalle',
            onClick: showDetailStudent,
        },
    ]

    const head = [
        {
            column_key: 'photo',
            column_name: 'Foto',
            column_type: 'image',
        },

        {
            column_key: 'name',
            column_name: 'Nombre',
            column_type: 'text',
        },

        {
            column_key: 'lastname',
            column_name: 'Apellidos',
            column_type: 'text',
        },

        {
            column_key: 'phone',
            column_name: 'Celular',
            column_type: 'text',
        },

        {
            column_key: 'dni',
            column_name: 'DNI',
            column_type: 'text',
        },

        {
            column_key: 'email',
            column_name: 'Email',
            column_type: 'text',
        },

        {
            column_key: 'address',
            column_name: 'Dirección',
            column_type: 'text',
        },
    ]

    const agregar = () => {
        setShowModalCreate(true)
    }

    useEffect(() => {
        if (currentScheduledCourses) {
            const payload = {
                page: 1,
                filter_by_name: '',
                docs: 15,
                descendant: false,
                setpagination: setpagination,
                setDataTable1: setDataTable1,
                currentScheduled: currentScheduledCourses.id,
                token: token,
                entity_id: stateApariencia.currentIdEntity,
            }

            const getUserScheduled = async () => {
                setloader(true)
                await getUsersByScheduleds(payload)
                setloader(false)
            }

            getUserScheduled()
        }
    }, [stateApariencia.currentIdEntity, token, currentScheduledCourses])

    return (
        <div
            className="scroll"
            style={{
                backgroundColor: '#fff',
                padding: '20px',
                justifyContent: 'center',
                height: '100%',
                display: loader ? 'flex' : 'block',
                alignItems: loader ? 'center' : 'start',
                overflowY: 'auto',
            }}
        >
            {loader ? (
                <div className="containerSpinner" style={{ alignItems: 'center' }}>
                    {' '}
                    <ProgressNS />{' '}
                </div>
            ) : (
                <>
                    <h1
                        style={{
                            marginTop: '0px',
                            color: `var(--color-secondary)`,
                            textTransform: 'capitalize',
                        }}
                    >
                        {currentScheduledCourses.name}
                    </h1>

                    <div className="contBtn">
                        <div className="addOption" onClick={agregar}>
                            {' '}
                            + Agregar{' '}
                        </div>
                    </div>

                    <DataTable
                        data={dataTable1}
                        title="Listado de Alumnos"
                        callback={getStudentsData}
                        pagination={pag}
                        options={options}
                        headers={head}
                        color={`var(--color-secondary)`}
                    />
                </>
            )}

            <ContainerModalAllPage showModal={showModalCreate}>
                <AddUserEnrollment
                    setShowModal={setShowModalCreate}
                    setDataTable1={setDataTable1}
                    setpagination={setpagination}
                />
            </ContainerModalAllPage>

            <ContainerModal showModal={showModalDelete}>
                <DeleteUserEnrollment
                    setShowModalDelete={setShowModalDelete}
                    detailsUser={userDetail}
                    setpagination={setpagination}
                    setDataTable1={setDataTable1}
                />
            </ContainerModal>
        </div>
    )
}
