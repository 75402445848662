export const typesPrograms = {
    createCategories: '[Categories] create categories',
    loadingCategories: '[Categories] loading categories',
    getCategories: '[Categories] get categories',
    deleteCategories: '[Categories] delete categories',
    updateCategories: '[Categories] update categories',
    currentCategory: '[Categories] current category',
    removeCategory: '[Categories] remove category',

    createPrograms: '[Programs] create programs',
    getPrograms: '[Programs] get programs',
    loadingModules: '[Categories] loading modules',
    getModules: '[Programs] get modules',
    currentModule: '[Programs] current module',
    removeModule: '[Programs] remove module',
    removeModules: '[Programs] remove modules',
    getFilesByEntity: '[Programs] get files by entity',
    removeFiles: '[Programs] remove files',
    deletePrograms: '[Programs] delete programs',
    updatePrograms: '[Programs] update programs',
}
