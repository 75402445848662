import { configureStore } from '@reduxjs/toolkit'

import { coursesApi, entitiesApi, evaluationsApi, materialsApi, usersApi } from './api'
import { authSlice, courseSlice } from './slices'

export const store = configureStore({
    reducer: {
        [coursesApi.reducerPath]: coursesApi.reducer,
        [entitiesApi.reducerPath]: entitiesApi.reducer,
        [evaluationsApi.reducerPath]: evaluationsApi.reducer,
        [materialsApi.reducerPath]: materialsApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        auth: authSlice.reducer,
        course: courseSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            coursesApi.middleware,
            entitiesApi.middleware,
            evaluationsApi.middleware,
            materialsApi.middleware,
            usersApi.middleware,
        ),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
