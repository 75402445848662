import { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { AuthContext } from '../../context/auth/AuthProvider'

interface Props {
    children?: React.ReactNode
}

const SidebarProfile = ({ children }: Props): JSX.Element => {
    const { user } = useContext(AuthContext)
    // console.log(user)
    const { pathname } = useLocation()
    const [state, setstate] = useState('')

    useEffect(() => {
        if (pathname === '/dashboard/admin/profile/settings') {
            setstate('configuración')
        } else if (pathname === '/dashboard/admin/profile/user') {
            setstate('editar perfil')
        } else if (pathname === '/dashboard/admin/profile/password') {
            setstate('contraseña')
        } else {
            setstate('')
        }
    }, [pathname])

    return (
        <div className="main_sideProfile">
            <div className="headSideProfile">
                {user.user.photo ? (
                    <img
                        src={user.user.photo}
                        alt="Sidebar icon"
                        style={{ objectFit: 'contain' }}
                    />
                ) : (
                    <i className="fas fa-user-circle" />
                )}
                <span>
                    {user.user.name} {user.user.lastname}
                </span>
                <span>|</span>
                <span>
                    <b>{state}</b>
                </span>
            </div>
            <div className="bodySideProfile">
                <ul className="optRouts">
                    <li>
                        <NavLink
                            to="/dashboard/admin/profile/user"
                            className="link_sidebar"
                            activeClassName="active"
                        >
                            Editar perfil
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/dashboard/admin/profile/password"
                            className="link_sidebar"
                            activeClassName="active"
                        >
                            Contraseña
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink
                            to="/dashboard/admin/profile/settings"
                            className="link_sidebar"
                            activeClassName="active"
                        >
                            Configuración
                        </NavLink>
                    </li> */}
                </ul>
                {children}
            </div>
        </div>
    )
}

export default SidebarProfile
