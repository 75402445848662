import { useState, useContext, useEffect } from 'react'

import Error from '../../components/general/error/Error'
import ProgressNS from './../../components/general/progress/ProgressNS'
// import CardEntity from './../../components/cards/CardEntity';
// import { EntityContext } from '../../context/entityContext/EntityProvider';
// import { getEntitySuperAdmin, userIsInEntities } from './../../context/entityContext/EntityAction';
// import { typesEntity } from './../../context/types/typesEntity';
import { AuthContext } from './../../context/auth/AuthProvider'

const EntityPage = () => {
    const [loader] = useState<boolean>(false)
    const [error] = useState<number>()

    // const [loader, setLoader] = useState<boolean>(false)
    // const [error, setError] = useState<number>()

    const { user } = useContext(AuthContext)

    // const { state, dispatch } = useContext(EntityContext)

    const token = JSON.parse(localStorage.getItem('user')!).token

    useEffect(() => {
        // getEntitySuperAdmin(token).then(({ data }) => {
        //     console.log(data);
        //     dispatch({
        //         type: typesEntity.getEntity,
        //         payload: data.entities
        //     })
        //     setLoader(true)
        // }).catch((e) => {
        //     console.log(e.response)
        //     setLoader(true)
        //     setError(500)
        // })
    }, [token, user.idUser])

    return (
        <div className="container_EntidadesPage">
            <div className="headUserPag">
                <h3>Entidades</h3>
            </div>

            <div className="bodyUserPag">
                <div className="cardEntity_container">
                    <div className="cardEntity_header">
                        <h3> Organizaciones B2B </h3>
                    </div>
                    <div className="cardEntity_content">
                        {loader ? (
                            <ProgressNS />
                        ) : error ? (
                            <Error error={error} />
                        ) : (
                            <div className="cardEntity_body">
                                {/* {
                                            state.entities.length > 0 ? state.entities.map((data: any, index: number) => (
                                                <Fragment key={index}>
                                                    <CardEntity data={data} userGeneral></CardEntity>
                                                </Fragment>
                                                
                                            )) : null
                                        }   */}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntityPage
