import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
// import HomeB2BPage from '../../pages/userB2B/HomeB2BPage'
// import UsuariosB2BPage from '../../pages/userB2B/UsuariosB2BPage'
// import CoursesB2BPage from '../../pages/userB2B/CoursesB2BPage'
import CoursesB2BPage2 from '../../pages/userB2B/CoursesB2BPage2'
import { Userb2bProvider } from '../../context/userb2b/Userb2bProvider'
import ProfileRoutes from '../publicRoutes/ProfileRoutes'
// import RepositoryPage from '../../pages/dashboard/RepositoryPage'
import RepositoryPage2 from '../../pages/dashboard/RepositoryPage2'
import { RepositoryProvider } from '../../context/userb2b/repository/RepositoryProvider'
// import CredentialsPage from '../../pages/userB2B/CredentialsPage'
// import { CardPage } from '../../pages/userB2B/CardPage'
import MyEntityPage from '../../pages/userB2B/MyEntityPage'
// import SedesPage from '../../pages/userB2B/SedesPage'
import { SedesPage2 } from '../../pages/userB2B/SedesPage2'
import CredentialsPage2 from '../../pages/userB2B/CredentialsPage2'
import UsuariosB2BPage2 from '../../pages/userB2B/UsuariosB2BPage2'
import ChatPage from '../../pages/dashboard/ChatPage'

const Userb2bRoutes = () => {
    const { path } = useRouteMatch()
    // const { user } = useContext(AuthContext)
    return (
        <Userb2bProvider>
            <RepositoryProvider>
                <Switch>
                    {/* <Route exact path={`${path}/home`} component={HomeB2BPage} /> */}
                    <Route exact path={`${path}/users`} component={UsuariosB2BPage2} />
                    <Route exact path={`${path}/courses`} component={CoursesB2BPage2} />
                    <Route exact path={`${path}/programs`} component={CoursesB2BPage2} />
                    <Route
                        exact
                        path={`${path}/repository`}
                        component={RepositoryPage2}
                    />
                    <Route
                        exact
                        path={`${path}/credentials`}
                        // component={CredentialsPage}
                        component={CredentialsPage2}
                    />
                    {/* <Route exact path={`${path}/cards`} component={CardPage} /> */}
                    <Route exact path={`${path}/my-entity`} component={MyEntityPage} />
                    {/* <Route exact path={`${path}/sedes`} component={SedesPage} /> */}
                    <Route exact path={`${path}/sedes`} component={SedesPage2} />
                    <Route path={`${path}/profile`} component={ProfileRoutes} />
                    <Route path={`${path}/chat`} component={ChatPage} />
                    <Redirect exact path={`/*`} to={`${path}/programs`} />
                </Switch>
            </RepositoryProvider>
        </Userb2bProvider>
    )
}

export default Userb2bRoutes
