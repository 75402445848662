import React, { useState } from 'react'

const SubItemInteractive = ({ data }: any) => {
    const [showOptionInteractive, setShowOptionInteractive] = useState<boolean>(false)

    return (
        <>
            <div className="titleOption">
                <div className="left_title">
                    {data.name}

                    {data.items && data.items.length > 0 ? (
                        <div
                            className="icon_dropdown"
                            onClick={() =>
                                setShowOptionInteractive(!showOptionInteractive)
                            }
                        >
                            <i className="fas fa-chevron-down"></i>
                        </div>
                    ) : null}
                </div>

                <div>{data.check}</div>
            </div>

            <div>
                {showOptionInteractive ? (
                    <ul className="sections">
                        {data.items
                            ? data.items.length > 0
                                ? data.items.map((data: any, key: number) => (
                                      <div className="section_item" key={key}>
                                          <div>{data.name}</div>

                                          {data.check()}
                                      </div>
                                  ))
                                : null
                            : null}
                    </ul>
                ) : null}
            </div>
        </>
    )
}

export default SubItemInteractive
