import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import { deleteFiles } from '../../../../../context/userb2b/repository/RepositoryAction'
import { useAppDispatch } from '../../../../../hooks/useReduxHooks'
import { MaterialByEntity } from '../../../../../interfaces'
import { materialsApi } from '../../../../../store/api'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface DeleteFileProps {
    setShowModalDelete: (value: boolean) => void
    material: MaterialByEntity
    setCurrentMaterial: (value: any) => void
}

export const DeleteFile = ({
    setShowModalDelete,
    material,
    setCurrentMaterial,
}: DeleteFileProps) => {
    const [loading, setLoading] = useState<any>(false)
    const { state: stateApariencia } = useContext(AparienciaContext)

    const dispatch = useAppDispatch()

    const deleteFileButton = () => {
        const token = JSON.parse(localStorage.getItem('user')!).token

        setLoading(true)

        deleteFiles(material._id, stateApariencia.currentIdEntity, token)
            .then((res: any) => {
                setShowModalDelete(false)

                toast.success('El archivo ha sido eliminado correctamente')

                dispatch(materialsApi.util.invalidateTags(['MaterialsByEntity']))

                setCurrentMaterial(undefined)

                setLoading(false)
            })
            .catch((e: any) => {
                console.log(e.response)
                if (e.response) {
                    const error = e.response.data.message
                    // Notification(type.danger, error)
                    toast.error(error)
                } else {
                    // Notification(type.danger, "Hubo un error, no se eliminó el archivo")
                    toast.error('Hubo un error, no se eliminó el archivo')
                }
                setLoading(false)
            })
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalDelete(false)}
                />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar el material de clase?</p>
                {loading ? (
                    <div className="containerSpinner_center">
                        <button onClick={() => setShowModalDelete(false)} disabled>
                            Cancelar
                        </button>
                        <div className="containerSpinner">
                            <SpinnerModal />
                        </div>
                    </div>
                ) : (
                    <div>
                        <button onClick={() => setShowModalDelete(false)}>
                            Cancelar
                        </button>
                        <button className="saveButton" onClick={deleteFileButton}>
                            Aceptar
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
