import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../context/auth/AuthProvider'
import { types } from '../../../context/types/types'

import { AparienciaContext } from '../../../context/aparienciaContext/AparienciaProvider'
import { typeUi } from '../../../context/types/typesui'

interface Props {
    rol: string
}

const DetailsUser = ({ rol }: Props): JSX.Element => {
    const { user, dispatch } = useContext(AuthContext)

    const { dispatchImg } = useContext(AparienciaContext)

    const [option, setOption] = useState<boolean>(false)

    // const [darkMode, setDarkMode] = useState<boolean>(false)

    const handleLogout = () => {
        dispatch({
            type: types.logout,
        })
        dispatchImg({
            type: typeUi.removeTheme,
        })
    }

    // const changeColor = () => {
    //     !darkMode ? themeColors() : resetColors()
    //     setDarkMode(!darkMode)
    // }

    // const themeColors = () => {
    //     // document.getElementById("sidebarPrimary")?.classList.add('active')
    //     let side = document.getElementById('sidebarPrimary')
    //     if (side) {
    //         side.style.backgroundColor = '#233044'
    //     }
    //     document.documentElement.style.setProperty('--color-dashboard', '#1B2635')
    //     document.documentElement.style.setProperty('--color-light', '#233044')
    //     document.documentElement.style.setProperty('--color-dark', '#ffffff')
    // }

    // const resetColors = () => {
    //     // document.getElementById("sidebarPrimary")?.classList.remove('active')
    //     let side = document.getElementById('sidebarPrimary')
    //     if (side) {
    //         side.removeAttribute('style')
    //     }
    //     document.documentElement.style.removeProperty('--color-dashboard')
    //     document.documentElement.style.removeProperty('--color-light')
    //     document.documentElement.style.removeProperty('--color-dark')
    // }

    return (
        <>
            <div className="main_DetailsUser">
                {/* <div className="contOptionUser">
                    <i className="fas fa-search" />
                </div> */}
                <div className="contOptionUserInfo">
                    {/* <i className="fas fa-bell" /> */}
                    <div
                        className="cntPrf"
                        onMouseEnter={() => setOption(true)}
                        onMouseLeave={() => setOption(false)}
                    >
                        {user.user && user.user.photo ? (
                            <img
                                className="Userprofile"
                                src={user.user.photo}
                                alt="imageUser"
                                style={{ objectFit: 'contain' }}
                            />
                        ) : (
                            <div className="Userprofile" />
                        )}
                        {rol === 'superadmin' ? (
                            <div className="nameDUser">{user.username}</div>
                        ) : user.user.name && user.user.lastname ? (
                            <div
                                className="nameDUser"
                                style={{ textTransform: 'capitalize' }}
                            >
                                {' '}
                                {user.user.name + ' ' + user.user.lastname}{' '}
                            </div>
                        ) : (
                            <div className="nameDUser">{user.user.username}</div>
                        )}
                        <i className="fas fa-caret-down" />
                        {option && (
                            <div className="nesSeption">
                                <div className="optSelectOption">
                                    {rol === 'admin b2b' || rol === 'admin' ? (
                                        <>
                                            <Link to="/dashboard/admin/profile/user">
                                                <div className="onlyOpt">
                                                    <i className="fas fa-user-circle" />
                                                    Mi perfil
                                                    <div></div>
                                                </div>
                                            </Link>

                                            {/* <Link to="/dashboard/admin/profile/settings">
                                                <div
                                                    className="onlyOpt"
                                                    onClick={() => setOption(!option)}
                                                >
                                                    <i className="fas fa-cog" />
                                                    Configuración de cuenta
                                                    <div></div>
                                                </div>
                                            </Link> */}
                                        </>
                                    ) : null}
                                    <Link to="">
                                        <div className="onlyOpt" onClick={handleLogout}>
                                            <i
                                                className="fas fa-door-open"
                                                style={{ fontSize: '20px' }}
                                            />
                                            <span>Cerrar sesión</span>
                                            <div></div>
                                        </div>
                                    </Link>
                                    {/* <div className="lineceparate" />
                                    <div className="contDarkMode">
                                        <div>Modo oscuro</div>
                                        <button
                                            className={`switch ${
                                                darkMode ? 'active' : ''
                                            }`}
                                            onClick={changeColor}
                                        >
                                            <span>
                                                <i className="fas fa-sun" />
                                            </span>
                                            <span>
                                                <i className="fas fa-moon" />
                                            </span>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        )}
                    </div>
                    {/* {
                        option &&
                        <div className="optSelectOption">
                            {rol === "admin b2b" || rol === "admin" ?
                                <>
                                    <Link to="/dashboard/admin/profile/user" >
                                        <div className="onlyOpt">
                                            <i className="far fa-user-circle" />

                                            Mi perfil

                                            <div></div>
                                        </div>
                                    </Link>

                                    <Link to="/dashboard/admin/profile/settings">
                                        <div className="onlyOpt" onClick={() => setOption(!option)}>
                                            <i className="fas fa-cog" />

                                            Configuración de cuenta

                                            <div></div>
                                        </div>
                                    </Link>

                                </> : null
                            }
                            <div className="onlyOpt" onClick={handleLogout}>
                                <i className="fas fa-door-open" />
                                <span>
                                    Cerrar sesión
                                </span>
                                <div></div>
                            </div>
                            <div className="lineceparate" />
                            <div className="contDarkMode">
                                <div>
                                    Modo oscuro
                                </div>
                                <button className={`switch ${darkMode ? 'active' : ''}`} onClick={changeColor}>
                                    <span><i className="fas fa-sun" /></span>
                                    <span><i className="fas fa-moon" /></span>
                                </button>
                            </div>
                        </div>
                    } */}
                </div>
            </div>
        </>
    )
}

export default DetailsUser
