import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { Course, ModalOptions } from '../../../../../interfaces'
import {
    useAddEditCourseChildrenMutation,
    // useAddEditQualifiedPracticeMutation,
} from '../../../../../store/api'
import { selectAuth, selectCourse } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface FormModule {
    id: string
    name: string
    price: string
}

interface AddEditModuleProps {
    showModal: ModalOptions
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
    module?: Course
    setModule: React.Dispatch<React.SetStateAction<Course | undefined>>
    course: Course
}

export const AddEditModule = ({
    showModal,
    setShowModal,
    module,
    setModule,
    course,
}: AddEditModuleProps) => {
    const { entity } = useAppSelector(selectAuth)
    const { currentCategory } = useAppSelector(selectCourse)
    const { register, handleSubmit, setValue } = useForm<FormModule>({
        defaultValues: {
            id: module ? module._id : '',
            name: showModal === 'edit' ? module?.name : '',
            price: showModal === 'edit' ? module?.price.course_price.toString() : '',
        },
    })

    const [addEditCourseChildrenMutation, { isLoading }] =
        useAddEditCourseChildrenMutation()

    // const [addEditPractice, { isLoading: isLoadingPractices }] =
    //     useAddEditQualifiedPracticeMutation()

    const onSubmit: SubmitHandler<FormModule> = (data) => {
        console.log({ data })
        console.log(course._id)
        if (data.name.trim() === '')
            return toast.warning('No puede editar un módulo con espacios en blanco')

        if (showModal === 'edit') {
            console.log('edit')
            console.log({ module })
            addEditCourseChildrenMutation({
                fname: 'updateCourse',
                category: currentCategory?._id,
                course_id: module?._id,
                name: data.name,
                price: JSON.stringify({
                    course_price: +data.price,
                    certificate: module?.price.certificate,
                    inscription: module?.price.inscription,
                }),
            }).then(() => {
                // await addEditPractice({
                //     entity,
                //     fname: 'updateQualifiedPractice',
                //     qualified_practice: data.id,
                //     title: data.name,
                // })
                setShowModal('hide')
            })
        } else {
            addEditCourseChildrenMutation({
                entity_id: entity,
                fname: 'createCourse',
                category_id: currentCategory?._id!,
                name: data.name,
                alias: 'modulo',
                type_of_course: 'children',
                price: JSON.stringify({
                    course_price: +data.price,
                    certificate: course.price.certificate,
                    inscription: course.price.inscription,
                }),
                tags: JSON.stringify(course.tags),
                parent_course_id: course._id,
                main_course: course._id,
            }).then(async () => {
                // await addEditPractice({
                //     entity,
                //     fname: 'createQualifiedPractice',
                //     course: course._id,
                //     title: data.name,
                // })
                setShowModal('hide')
                setModule(undefined)
            })
        }

        // const datanToBack = {
        //     fname: 'updateCourse',
        //     category: currentCategory?._id,
        //     course_id: dataModule._id,
        //     name: data.name,
        //     price: JSON.stringify({
        //         inscription: dataModule.price.inscription,
        //         certificate: dataModule.price.certificate,
        //         course_price: data.price,
        //     }),
        // }

        // editPrograms(datanToBack)
    }

    return (
        <div className="main_AddCategory">
            <div className="heaCategory">
                <h3>{showModal === 'edit' ? 'Editar' : 'Agregar'} Módulo</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal('hide')} />
            </div>
            <div className="bodyCategory">
                <form className="contFormEnt" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contFormEnt">
                        <div className="contInAddEnt">
                            <p>Título</p>
                            <input
                                type="text"
                                maxLength={50}
                                {...register('name')}
                                // name="name"
                                // value={data.name}
                                // onChange={onChangeInputs}
                                // disabled={loading}
                                // onKeyDown={onKeyDown}
                            />
                        </div>

                        <div className="contInAddEnt">
                            <p>Precio</p>
                            <input
                                maxLength={5}
                                pattern="[0-9]*"
                                required
                                placeholder="0"
                                disabled={
                                    !course.price.course_price &&
                                    !course.price.inscription
                                        ? true
                                        : isLoading
                                }
                                {...register('price', {
                                    onChange: (e) => {
                                        const re = /^[0-9.\b]+$/
                                        if (re.test(e.target.value)) {
                                            setValue('price', e.target.value)
                                        } else {
                                            setValue('price', '')
                                        }
                                    },
                                })}
                                // disabled={courseFree ? courseFree : loading}
                                // name="price"
                                // onChange={(e: ChangeEvent) => onChangeInputs(e, true)}
                                // value={data.price}
                                // onKeyDown={onKeyDown}
                            />
                        </div>
                    </div>

                    <div className="optsBtnAdd">
                        <button onClick={() => setShowModal('hide')} disabled={isLoading}>
                            Cancelar
                        </button>
                        {isLoading ? (
                            <div className="containerSpinner">
                                <SpinnerModal />
                            </div>
                        ) : (
                            <button type="submit" disabled={isLoading}>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
