import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { CustomUser } from '../../interfaces'
import { Button } from '../buttons'

import './Chat.scss'

interface FormMessage extends IMessage {}

interface IMessage {
    id: string
    user: string
    message: string
}

interface ChatProps {
    user: CustomUser
    setCurrentUser: (user: CustomUser | undefined) => void
}

export const Chat = ({ user, setCurrentUser }: ChatProps) => {
    const [messages, setMessages] = useState<IMessage[]>([])
    const [showOptions, setShowOptions] = useState<boolean>(false)
    const messageRef = useRef<HTMLDivElement>(null)

    const { register, handleSubmit, reset } = useForm<FormMessage>()

    const sendMessage: SubmitHandler<FormMessage> = ({ message }) => {
        if (message.trim().length <= 0) return
        setMessages([
            ...messages,
            {
                id: Math.random().toString(),
                user: 'user',
                message,
            },
        ])
        reset()
        messageRef.current && messageRef.current.scrollIntoView()
    }

    useEffect(() => {
        setMessages([
            {
                id: '001',
                user: '1',
                message: 'Hola',
            },
            {
                id: '002',
                user: '1',
                message:
                    'Minim pariatur mollit non quis commodo cillum. Culpa esse eiusmod laboris duis dolor excepteur velit sint excepteur. Eu dolor ut nulla ex excepteur incididunt proident adipisicing cupidatat id est et. Excepteur fugiat qui labore anim reprehenderit ea ut.',
            },
            {
                id: '003',
                user: 'user',
                message:
                    'Quis eiusmod laboris duis dolor excepteur velit sint excepteur. Eu dolor ut nulla ex excepteur incididunt proident adipisicing cupidatat id est et. Excepteur fugiat qui labore anim reprehenderit ea ut.',
            },
            {
                id: '004',
                user: '2',
                message: 'Quis eiusmod ',
            },
            {
                id: '005',
                user: '1',
                message:
                    'Quis eiusmod laboris duis dolor excepteur velit sint excepteur. Eu dolor ut nulla ex excepteur incididunt proident adipisicing cupidatat id est et. Excepteur fugiat qui labore anim reprehenderit ea ut.',
            },
            {
                id: '006',
                user: 'user',
                message: 'User message',
            },
        ])
    }, [])

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView()
        }
    }, [])

    return (
        <div className="chat-user scroll">
            <div className="chat-user__container">
                <div className="chat-user__header">
                    <Button
                        icon2="fas fa-arrow-left"
                        onClick={() => setCurrentUser(undefined)}
                    />
                    <span>
                        {user.name} {user.lastname}
                    </span>
                </div>
                <div className="chat-user__body scroll">
                    {messages.map((message) => (
                        <div
                            className={`chat-user__body-message${
                                message.user === 'user' ? '-personal' : ''
                            }`}
                            key={message.id}
                        >
                            <p>{message.message}</p>
                        </div>
                    ))}
                    <div ref={messageRef} />
                </div>
                <form className="chat-user__footer" onSubmit={handleSubmit(sendMessage)}>
                    {showOptions && (
                        <div className="chat-user__modal">
                            <button className="chat-user__modal-button">
                                <i className="fas fa-file" />
                            </button>
                            <button className="chat-user__modal-button">
                                <i className="fas fa-image" />
                            </button>
                        </div>
                    )}
                    <button
                        className="chat-user__button"
                        type="button"
                        onClick={() => setShowOptions(!showOptions)}
                    >
                        <i className="fas fa-paperclip" />
                    </button>
                    <input
                        {...register('message')}
                        className="chat-user__input"
                        type="text"
                        placeholder="Escribe un mensaje"
                        autoComplete="off"
                    />
                    <button className="chat-user__button" type="submit">
                        <i className="fas fa-paper-plane" />
                    </button>
                </form>
            </div>
        </div>
    )
}
