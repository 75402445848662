import { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { axiosUserV5 } from '../../../config/axios'
import { EntityContext } from './../../../context/entityContext/EntityProvider'
import { typesEntity } from './../../../context/types/typesEntity'
import { createUsers } from './../../../context/superAdminUser/UserActions'
import SpinnerModal from './../../general/spinner/SpinnerModal'
import { createEntitySuperAdmin } from '../../../context/entityContext/EntityAction'
// import Notification, { type } from '../../general/toast/Toast'

interface Props {
    setShowModalCreate: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddEntity = ({ setShowModalCreate }: Props): JSX.Element => {
    const token = JSON.parse(localStorage.getItem('user')!).token

    const [showOpt, setShowOpt] = useState<number>(1)
    const [varchar, setVarchar] = useState<any>(400)
    const [, setUserAdmin] = useState([])
    const [loadAdmin, setloadAdmin] = useState(false)
    const [loadEntity, setloadEntity] = useState(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const { dispatch } = useContext(EntityContext)

    const [dataEntity, setDataEntity] = useState({
        entity_name: '',
        entity_type: '',
        description: '',
        domain: '',
        bg_sidebar: '#CC337E',
        bg_buttons: '#CC337E',
        font_color: '#000000',
        admin: '',
    })

    const [dataAdmin, setDataAdmin] = useState<any>({
        id: '',
        username: '',
        password: '',
        name: '',
        lastname: '',
        dni: '',
        email: '',
        phone: '',
        birthdate: '',
        domain: '',
        rol: 'admin_entity',
        roles: ['admin b2b', 'admin b2c'],
        address: '',
    })

    const [, setdataValidate] = useState<any>({
        entity_name: '',
        description: '',
        domain: '',
        // lastname: '',
        // email: '',
        // address: '',
        // dni: '',
        // phone: '',
    })

    const changeDataAdmin = (e: any, number?: boolean): void => {
        const { name, value } = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setDataAdmin({
                    ...dataAdmin,
                    [name]: value,
                })
            }
        } else {
            setDataAdmin({
                ...dataAdmin,
                [name]: value,
            })
        }
    }

    const changeDataEntity = (e: any, number?: boolean): void => {
        const { name, value } = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setDataEntity({
                    ...dataEntity,
                    [name]: value,
                })
            }
        } else {
            setDataEntity({
                ...dataEntity,
                [name]: value,
            })
        }

        if (name === 'description') {
            const max_chart = 400

            setVarchar(max_chart - value.length)
        }
    }

    const [imgLogo, setImgLogo] = useState<any>({})
    const [preview, setPreview] = useState<string>('')
    const [previewPortrait, setPreviewPortrait] = useState<string>('')
    const [imgPort, setImgPort] = useState<any>({})

    const [loading, setLoading] = useState<boolean>(false)

    // const getEntityType = (e : any) => {
    //     const { value } = e.target;
    //     let valor = value;
    //     if (value === "") {
    //     valor = "";
    //     }
    //     setDataEntity({
    //         ...dataEntity,
    //         entity_type: valor,
    //     });
    // };

    // const getUserAdmin = (e : any) => {
    //     const { value } = e.target;
    //     let valor = value;
    //     if (value === "") {
    //     valor = "";
    //     }
    //     setDataEntity({
    //         ...dataEntity,
    //         admin: valor,
    //     });

    //     axiosUserV5({
    //         url: 'v5.0.1/api',
    //         headers: {
    //             authorization: `Bearer ${token}`,
    //         },
    //         data: {
    //             fname: "getUser",
    //             user_id: valor,
    //             service: 'users'
    //         },
    //     }).then(({data}) => {

    //         // console.log(data);

    //         setdataValidate({
    //             entity_name: dataEntity.entity_name,
    //             description: dataEntity.description,
    //             domain: dataEntity.domain
    //             // name: data.user.name,
    //             // lastname: data.user.lastname,
    //             // email: data.user.email,
    //             // address: data.user.address,
    //             // dni: data.user.dni,
    //             // phone: data.user.phone,
    //         })

    //     }).catch((e) => {

    //        console.log(e.response);

    //     })

    // };

    const changeImageLogo = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e
                setImgLogo(imgPhoto)
                setPreview(URL.createObjectURL(e))
                setLoading(!loading)
            }
        }
    }

    const changeImagePortrait = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e
                setImgPort(imgPhoto)
                setPreviewPortrait(URL.createObjectURL(e))
                setLoading(!loading)
            }
        }
    }

    const sendEntityData = (e: any) => {
        e.preventDefault()

        // console.log('Esta es data entidad', dataEntity)

        const datan: FormData = new FormData()
        datan.append('fname', 'createEntity')
        datan.append('service', 'users')
        datan.append('entity_name', dataEntity.entity_name)
        datan.append('domain', dataEntity.domain)
        datan.append('logo', imgLogo)
        datan.append('entity_type', dataEntity.entity_type)
        datan.append('description', dataEntity.description)
        datan.append('portrait', imgPort)
        datan.append('bg_sidebar', dataEntity.bg_sidebar)
        datan.append('bg_buttons', dataEntity.bg_buttons)
        datan.append('font_color', dataEntity.font_color)
        // datan.append("admin_id", dataEntity.admin)

        setloadEntity(true)

        createEntitySuperAdmin(datan, token)
            .then(({ data }) => {
                // console.log(data)

                dispatch({
                    type: typesEntity.createEntity,
                    payload: data.entity,
                })

                setloadEntity(false)
                setShowModalCreate(false)

                // Notification(type.success, "La entidad ha sido creada")
                toast.success('La entidad ha sido creada')
            })
            .catch((e) => {
                console.log(e.response)

                // Notification(type.danger, e.response ? `Error! ${e.response.data.message}` : "Ha ocurrido un error")
                toast.error(
                    e.response
                        ? `Error! ${e.response.data.message}`
                        : 'Ha ocurrido un error',
                )

                setloadEntity(false)
            })
    }

    const getUsersAdmin = useCallback(() => {
        axiosUserV5({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getAdminEntity',
                rol: 'admin_entity',
                // service: "users",
            },
        })
            .then(({ data }) => {
                // console.log(data)

                // const admins = data.users
                setUserAdmin(data.users)
            })
            .catch((e) => {
                console.log(e.response)
            })
    }, [token])

    useEffect(() => {
        getUsersAdmin()
    }, [getUsersAdmin])

    const sendDataAdmin = (e: any) => {
        e.preventDefault()
        // console.log('Formulario Admin')
        // console.log(dataAdmin)

        setloadAdmin(true)

        const data = {
            fname: 'createUser',
            name: dataAdmin.name,
            lastname: dataAdmin.lastname,
            dni: dataAdmin.dni,
            email: dataAdmin.email,
            phone: dataAdmin.phone,
            birthdate: dataAdmin.birthdate,
            address: dataAdmin.address,
            rol: dataAdmin.rol,
            roles: JSON.stringify(dataAdmin.roles),
            username: dataAdmin.username,
            password: dataAdmin.password,
        }

        createUsers(data, token)
            .then(({ data }) => {
                // console.log(data);

                setDataEntity({
                    ...dataEntity,
                    admin: data.user._id,
                })

                setloadAdmin(false)

                setdataValidate({
                    entity_name: dataEntity.entity_name,
                    name: data.user.name,
                    lastname: data.user.lastname,
                    email: data.user.email,
                    address: data.user.address,
                    dni: data.user.dni,
                    phone: data.user.phone,
                })

                getUsersAdmin()

                // Notification(type.success, 'El usuario ha sido creado correctamente')
                toast.success('El usuario ha sido creado correctamente')

                setDataAdmin({
                    id: '',
                    username: '',
                    password: '',
                    name: '',
                    lastname: '',
                    dni: '',
                    email: '',
                    phone: '',
                    birthdate: '',
                    domain: '',
                    address: '',
                    rol: 'admin_entity',
                    roles: ['admin b2b', 'admin b2c'],
                })

                setShowOpt(3)
            })
            .catch((e) => {
                console.log(e.response)

                if (e.response.status === 400 || e.response.status === 500) {
                    // Notification(type.danger, e.response.data.message)
                    toast.error(e.response.data.message)

                    setDataAdmin({
                        id: '',
                        username: '',
                        password: '',
                        name: '',
                        lastname: '',
                        dni: '',
                        email: '',
                        phone: '',
                        birthdate: '',
                        domain: '',
                        address: '',
                        rol: 'admin_entity',
                        roles: ['admin b2b', 'admin b2c'],
                    })

                    setloadAdmin(false)
                }
            })
    }

    return (
        <div className="main_AddEntity">
            <div className="heaEntity">
                <h3>Nueva entidad</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalCreate(false)}
                />
            </div>
            <div className="bodyEntity">
                <div className="barNav">
                    <div className="optNav">
                        <span className={`${showOpt === 1 ? 'select' : ''}`}>Paso 1</span>
                        <span
                            className={`${
                                showOpt === 2 || showOpt === 4 ? 'select' : ''
                            }`}
                        >
                            Paso 2
                        </span>
                        {/* <span className={`${showOpt === 3 ? "select" : ""}`} >Paso 3</span> */}
                    </div>
                </div>
                <form
                    className="contFormEnt"
                    onSubmit={(e) =>
                        showOpt === 2
                            ? sendEntityData(e)
                            : showOpt === 4 && sendDataAdmin(e)
                    }
                >
                    <div className="contFormEnt scrollBody">
                        {showOpt === 1 ? (
                            <>
                                <div className="contInAddEnt">
                                    <p className="title">
                                        <b>Configuración de la entidad</b>
                                    </p>
                                </div>
                                <div className="contInAddEnt image">
                                    <label htmlFor="imgPort" className="imgPort">
                                        {previewPortrait ? (
                                            <img
                                                src={previewPortrait}
                                                alt="portada"
                                                className="imgPort"
                                            ></img>
                                        ) : (
                                            <div className="imgPortada"></div>
                                        )}
                                    </label>

                                    <input
                                        id="imgPort"
                                        name="imgPort"
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept=".png, .jpg, .jpeg"
                                        onChange={(e: any) =>
                                            changeImagePortrait(e.target.files[0])
                                        }
                                    />

                                    <label
                                        className={
                                            preview ? `imgPerf active` : ` imgPerf`
                                        }
                                        htmlFor="imgLogo"
                                    >
                                        <div>
                                            <i className="fas fa-camera" />
                                            <div>Perfil</div>
                                        </div>
                                        <div>
                                            {preview ? (
                                                <img
                                                    src={preview}
                                                    alt="perfilPhoto"
                                                    className="imgPhotoEntity"
                                                />
                                            ) : null}
                                        </div>
                                    </label>

                                    <input
                                        id="imgLogo"
                                        name="imgLogo"
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept=".png, .jpg, .jpeg"
                                        onChange={(e: any) =>
                                            changeImageLogo(e.target.files[0])
                                        }
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Nombre de la entidad</p>
                                    <input
                                        type="text"
                                        onChange={(e) => changeDataEntity(e)}
                                        name="entity_name"
                                        value={dataEntity.entity_name}
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Descripción</p>
                                    <textarea
                                        onChange={(e) => changeDataEntity(e)}
                                        name="description"
                                        maxLength={400}
                                        value={dataEntity.description}
                                    />
                                    <p
                                        style={{
                                            textAlign: 'right',
                                            color: '#a29f9f',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {' '}
                                        {varchar} caracteres
                                    </p>
                                </div>

                                <div className="contInAddEnt">
                                    <p>Dominio de la entidad</p>
                                    <input
                                        type="text"
                                        onChange={(e) => changeDataEntity(e)}
                                        name="domain"
                                        value={dataEntity.domain}
                                    />
                                </div>

                                {/* <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <p>Tipo de entidad</p>
                                            <select onChange={(e)=> getEntityType(e)}>
                                                <option value="">Seleccione una opción</option>
                                                <option value="b2b">B2B</option>
                                                <option value="b2c">B2C</option>
                                            </select>
                                        </div>
                                    </div> */}
                            </>
                        ) : // : showOpt === 2 ?
                        //     <>
                        //         <div className="contInAddEnt">
                        //             <p className="title"><b>Configuración de administrador principal</b></p>
                        //         </div>
                        //         <div className="contInAddEnt">
                        //             <p>Establecer con administrador existente</p>
                        //             <select className="half" onChange={(e)=> getUserAdmin(e)} disabled>
                        //                 <option value="">Seleccione una opción</option>
                        //                 {
                        //                     userAdmin && userAdmin.map((user: any, index: number) => {
                        //                         return (
                        //                             <option value={user._id} key={index}> { user.name } </option>
                        //                         )
                        //                     })
                        //                 }
                        //             </select>
                        //         </div>
                        //         <div className="sepContAddEnt">
                        //             <div className="contInAddEnt">
                        //                 <p>Crear uno nuevo</p>
                        //                 <div onClick={()=>setShowOpt(4)} className="button_ent">Agregar admin</div>
                        //             </div>
                        //         </div>
                        //     </>
                        showOpt === 2 ? (
                            <>
                                <div className="contInAddEnt">
                                    <p>Nombre de la entidad </p>
                                    <input
                                        type="text"
                                        value={dataEntity.entity_name}
                                        disabled
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p> Descripción </p>
                                    <input
                                        type="text"
                                        value={dataEntity.description}
                                        disabled
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p> Dominio </p>
                                    <input
                                        type="text"
                                        value={dataEntity.domain}
                                        disabled
                                    />
                                </div>
                                {/* <div className="contInAddEnt">
                                            <p>Administrador</p>
                                            <input type="text" value={dataValidate.name} disabled />
                                        </div>
                                        <div className="contInAddEnt">
                                            <p>Correo electrónico</p>
                                                <input type="text" value={dataValidate.email} disabled />
                                        </div>
                                        <div className="contInAddEnt">
                                            <p>Dirección</p>
                                            <input type="text" value={dataValidate.address} disabled/>
                                        </div>
                                        <div className="contInAddEnt">
                                            <p>Nro de DNI/Pasaporte/Carnet de extranjería</p>
                                            <input type="text" maxLength={8} value={ dataValidate.dni } disabled/>
                                        </div>
                                        <div className="contInAddEnt">
                                            <p>Celular</p>
                                            <input type="text" maxLength={9} value={ dataValidate.phone } disabled/>
                                        </div> */}
                            </>
                        ) : (
                            showOpt === 4 && (
                                <>
                                    <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <p>Nombres</p>
                                            <input
                                                type="text"
                                                name="name"
                                                value={dataAdmin.name}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                        <div className="contInAddEnt">
                                            <p>Apellidos</p>
                                            <input
                                                type="text"
                                                name="lastname"
                                                value={dataAdmin.lastname}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="contInAddEnt">
                                        <p>Correo electrónico</p>
                                        <input
                                            type="email"
                                            name="email"
                                            value={dataAdmin.email}
                                            onChange={(e) => changeDataAdmin(e)}
                                        />
                                    </div>
                                    <div className="contInAddEnt">
                                        <p>Dirección</p>
                                        <input
                                            type="text"
                                            name="address"
                                            value={dataAdmin.address}
                                            onChange={(e) => changeDataAdmin(e)}
                                        />
                                    </div>
                                    <div className="contInAddEnt">
                                        <p>Nro de DNI/Pasaporte/Carnet de extranjería</p>
                                        <input
                                            type="text"
                                            name="dni"
                                            maxLength={8}
                                            value={dataAdmin.dni}
                                            onChange={(e) => changeDataAdmin(e, true)}
                                        />
                                    </div>
                                    <div className="contInAddEnt">
                                        <p>Celular</p>
                                        <input
                                            type="text"
                                            name="phone"
                                            maxLength={9}
                                            value={dataAdmin.phone}
                                            onChange={(e) => changeDataAdmin(e, true)}
                                        />
                                    </div>

                                    <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <p>Usuario</p>
                                            <input
                                                type="text"
                                                name="username"
                                                value={dataAdmin.username}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                        <div className="contInAddEnt">
                                            <p>Contraseña</p>
                                            <input
                                                type={`${
                                                    showPassword ? 'text' : 'password'
                                                }`}
                                                name="password"
                                                value={dataAdmin.password}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                            {!showPassword ? (
                                                <i
                                                    className="icon_password far fa-eye-slash"
                                                    onClick={() => setShowPassword(true)}
                                                ></i>
                                            ) : (
                                                <i
                                                    className="icon_password fas fa-eye"
                                                    onClick={() => setShowPassword(false)}
                                                ></i>
                                            )}
                                        </div>
                                    </div>

                                    <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <p>Fecha de nacimiento</p>
                                            <input
                                                type="date"
                                                name="birthdate"
                                                value={dataAdmin.birthdate}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <input
                                                type="hidden"
                                                name="rol"
                                                value={dataAdmin.rol}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="sepContAddEnt" >
                                                <div className="contInAddEnt">
                                                    <input type="hidden"  name="domain" value={dataEntity.entity_name} />
                                                </div>
                                            </div> */}
                                </>
                            )
                        )}
                    </div>
                    <div className="optsBtnAdd">
                        {showOpt === 1 ? (
                            <>
                                <div
                                    className="otherButtons"
                                    onClick={() => setShowModalCreate(false)}
                                >
                                    Cancelar
                                </div>
                                <div
                                    className="otherButtons"
                                    onClick={() => setShowOpt(2)}
                                >
                                    {' '}
                                    Siguiente{' '}
                                </div>
                            </>
                        ) : // :
                        // showOpt === 2 ?
                        //     <>
                        //         <div className="otherButtons" onClick={() => setShowOpt(1)} >Atras</div>
                        //         <div className="otherButtons" onClick={() => setShowOpt(3)} > Siguiente </div>
                        //     </>
                        showOpt === 2 ? (
                            <>
                                <div
                                    className="otherButtons"
                                    onClick={() => setShowOpt(1)}
                                >
                                    Volver
                                </div>
                                {/* <button type="submit" > Finalizar </button> */}
                                {loadEntity ? (
                                    <div className="containerSpinner">
                                        {' '}
                                        <SpinnerModal />{' '}
                                    </div>
                                ) : (
                                    <button type="submit"> Finalizar </button>
                                )}
                            </>
                        ) : (
                            showOpt === 4 && (
                                <>
                                    <div
                                        className="otherButtons"
                                        onClick={() => setShowOpt(2)}
                                    >
                                        {' '}
                                        Volver
                                    </div>
                                    {/* <button type="submit" > Agregar </button> */}
                                    {loadAdmin ? (
                                        <div className="containerSpinner">
                                            {' '}
                                            <SpinnerModal />{' '}
                                        </div>
                                    ) : (
                                        <button type="submit"> Agregar </button>
                                    )}
                                </>
                            )
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
