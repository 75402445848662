import { useContext } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import SidebarMenu from './../../components/container/SidebarMenu'
import { AuthContext } from './../../context/auth/AuthProvider'
import UserRoutes from './UserRoutes'
import Userb2bRoutes from './Userb2bRoutes'
import AccountantRoutes from './AccountantRoutes'
import CoordinatorRoutes from './CoordinatorRoutes'
import { EntityProvider } from '../../context/entityContext/EntityProvider'
import { UserProvider } from '../../context/superAdminUser/UserProvider'
import { UserCounterProvider } from '../../context/userCounter/UserCounterProvider'
import { CoursesUserb2bProvider } from '../../context/coursesUserb2b/CoursesUserb2bProvider'
import { SheduledCoursesProvider } from '../../context/sheduledCourses/ScheduledCoursesProvider'
import { ProgramsProvider } from '../../context/userb2b/programs/ProgramsProvider'

const AdminRoutes = () => {
    const { path } = useRouteMatch()
    const { user } = useContext(AuthContext)

    return (
        <UserProvider>
            <UserCounterProvider>
                <CoursesUserb2bProvider>
                    <ProgramsProvider>
                        <SheduledCoursesProvider>
                            <EntityProvider>
                                <SidebarMenu
                                    rol={user.main_rol}
                                    rolByEntity={user.rolesByEntity}
                                >
                                    <Switch>
                                        <Route
                                            path={`${path}/user`}
                                            component={UserRoutes}
                                        />
                                        <Route
                                            path={`${path}/admin`}
                                            component={Userb2bRoutes}
                                        />
                                        <Route
                                            path={`${path}/accountant`}
                                            component={AccountantRoutes}
                                        />
                                        <Route
                                            path={`${path}/coordinator`}
                                            component={CoordinatorRoutes}
                                        />
                                        <Redirect
                                            exact
                                            path={`/*`}
                                            to={
                                                user.main_rol === 'counter'
                                                    ? `${path}/accountant`
                                                    : user.main_rol === 'coordinador'
                                                    ? `${path}/coordinator`
                                                    : `${path}/admin`
                                            }
                                        />
                                    </Switch>
                                </SidebarMenu>
                            </EntityProvider>
                        </SheduledCoursesProvider>
                    </ProgramsProvider>
                </CoursesUserb2bProvider>
            </UserCounterProvider>
        </UserProvider>
    )
}

export default AdminRoutes
