import { toast } from 'react-toastify'

import { axiosCourses, axiosUser, axiosUserV5 } from './../../config/axios'
import { typesCounter } from './../types/typesCounter'
// import Notification, { type } from '../../components/general/toast/Toast';

export const getStudentCounter = async (
    token: string,
    obj: object,
    dispatch: any,
    setpagination?: any,
) => {
    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
        .then(({ data }) => {
            // console.log(data);

            let array: any[] = []
            data.users.map((dat: any) =>
                dat
                    ? array.push({
                          id: dat.user._id,
                          name_student: dat.user.name,
                          lastname: dat.user.lastname,
                          email: dat.user.email,
                          photo: dat.user.photo.includes('https://')
                              ? dat.user.photo
                              : 'https://livewellwithstacy.com/wp-content/uploads/2018/04/avtar.png',
                          phone: dat.user.phone,
                          address: dat.user.address,
                          dni: dat.user.dni,
                          roles: dat.roles ? dat.roles : [],
                      })
                    : null,
            )

            dispatch({
                type: typesCounter.getUserCounter,
                payload: array,
            })

            if (setpagination) {
                setpagination(data.pagination)
            }

            // console.log(array)
        })
        .catch((e: any) => {
            console.log(e)
            console.log(e.response)
        })
}

export const getStudentCounterCourse = async (
    token: string,
    entity_id: any,
    scheduled_id: any,
) => {
    console.log('getStudentCounterCourse')

    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getUsersByEntity',
            roles: ['estudiante'],
            entity_id: entity_id,
            scheduled: scheduled_id,
            page: 1,
            query: {
                field: 'name',
                search: '',
            },
            docs: 1500,
            descendant: false,
        },
    })
}

export const createUsersCounter = async (token: string, obj: any) => {
    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const updateStudentCounter = async (token: string, obj: any) => {
    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const deleteStudentCounter = async (
    token: string,
    id: string,
    idEntity: string,
) => {
    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'deleteUserByEntity',
            user_id: id,
            entity_id: idEntity,
        },
    })
}

export const getCoursesCounter = async (token: string) => {
    return axiosUser({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getCourses',
            domain: 'continental',
            service: 'users',
        },
    })
}

export const getStudentsByCourses = async (token: string) => {
    return axiosUser({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getStudentsByCourse',
            domain: 'continental',
            service: 'users',
        },
    })
}

export const getUsersByScheduleds = async (params: any) => {
    const {
        token,
        entity_id,
        currentScheduled,
        page,
        docs,
        setDataTable1,
        setpagination,
        filter_by_name,
        descendant,
    } = params

    return await axiosCourses({
        url: 'v5.0.1/api',

        headers: {
            authorization: `Bearer ${token}`,
        },

        data: {
            fname: 'getUsersByScheduled',
            scheduled: currentScheduled,
            entity: entity_id,
            filter_by_name,
            sortNameNumber: descendant === true ? -1 : 1,
            rol: 'estudiante',
            page: page,
            docs: docs,
            // query: query
        },
    })
        .then(({ data }) => {
            // console.log(data)

            let array: any[] = []

            data.users.map((dat: any) =>
                dat
                    ? array.push({
                          _id: dat.user._id,
                          photo: dat.user.photo.includes('https://')
                              ? dat.user.photo
                              : null,
                          name: dat.user.name,
                          lastname: dat.user.lastname,
                          phone: dat.user.phone,
                          dni: dat.user.dni,
                          email: dat.user.email,
                          course: dat.scheduled.course,
                          scheduled: dat.scheduled._id,
                          address: dat.user.address,
                      })
                    : null,
            )

            setpagination(data.pagination)

            setDataTable1(array)
        })
        .catch((e) => {
            console.log(e.response)

            // Notification(type.danger, "Ha ocurrido un error")
            toast.error('Ha ocurrido un error')
        })
}
