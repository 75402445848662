import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'

import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import type { CustomUser, ModalOptions, OptionType } from '../../../../../interfaces'
import {
    useAddEditUserByEntityMutation,
    useGetRolesByEntityQuery,
} from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

const animatedComponents = makeAnimated()

interface FormUser {
    user: string
    name: string
    lastname: string
    email: string
    phone: string
    dni: string
    pass: string
    rol: OptionType[] | undefined
    address: string
    description: string
    photo: FileList | string | undefined
}

interface AddEditUserProps {
    showModal: ModalOptions
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
    currentUser?: CustomUser
    setCurrentUser: React.Dispatch<React.SetStateAction<CustomUser | undefined>>
}

export const AddEditUser = ({
    showModal,
    setShowModal,
    currentUser,
    setCurrentUser,
}: AddEditUserProps) => {
    console.log({ currentUser })
    const { entity } = useAppSelector(selectAuth)

    // const [varchar, setVarchar] = useState<any>(400)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const { register, handleSubmit, control, watch, setValue } = useForm<FormUser>({
        defaultValues: {
            user: showModal === 'edit' ? currentUser?._id : '',
            name: showModal === 'edit' ? currentUser?.name : '',
            lastname: showModal === 'edit' ? currentUser?.lastname : '',
            email: showModal === 'edit' ? currentUser?.email : '',
            phone: showModal === 'edit' ? currentUser?.phone : '',
            dni: showModal === 'edit' ? currentUser?.dni : '',
            photo: showModal === 'edit' ? currentUser?.photo : undefined,
            pass: '',
            rol:
                showModal === 'edit'
                    ? currentUser?.roles.map((r) => ({
                          value: r._id,
                          label: r.rol,
                      }))
                    : undefined,
            address: '',
            description: '',
        },
    })

    const { roles, isLoading: isLoadingRoles } = useGetRolesByEntityQuery(
        {
            entity_id: entity,
        },
        {
            selectFromResult: ({ data, isLoading }) => ({
                roles:
                    (
                        data?.map((r) => ({
                            value: r._id,
                            label: r.rol,
                        })) || []
                    ).filter((r) => r.label !== 'estudiante') || [],
                isLoading,
            }),
        },
    )

    const [addEditUser, { isLoading }] = useAddEditUserByEntityMutation()

    const onSubmit: SubmitHandler<FormUser> = (data) => {
        console.log(data)
        if (showModal === 'add' && data.pass.length < 6)
            return toast.warning('La contraseña debe tener al menos 6 caracteres')
        if (!data.rol || !data.rol.length) return toast.warning('Seleccione un rol')

        let formData = new FormData()
        if (typeof data.photo === 'object') {
            if (!data.photo.length) {
                return toast.warning('Debe agregar una foto de perfil')
            } else {
                formData.append('photo', (data.photo as FileList)[0])
            }
        }
        if (showModal === 'edit') {
            formData.append('fname', 'updateUser')
            formData.append('user', data.user)
        } else {
            formData.append('fname', 'createUser')
            formData.append('password', data.pass)
        }
        formData.append('entity', entity)
        formData.append('name', data.name)
        formData.append('lastname', data.lastname)
        formData.append('email', data.email)
        formData.append('phone', data.phone)
        formData.append('dni', data.dni)
        formData.append('description', data.description)
        formData.append('roles', JSON.stringify(data.rol.map((r) => r.value)))
        formData.append('address', data.address)
        addEditUser(formData)
            .then(() => {
                setShowModal('hide')
                setCurrentUser(undefined)
            })
            .catch(console.log)
    }

    const closeModal = () => setShowModal('hide')

    return (
        <div className="main_AddUser">
            <div className="headAddUser">
                <h3>{showModal === 'edit' ? 'Editar' : 'Crear'} usuario</h3>
                <i className="fas fa-times-circle" onClick={closeModal} />
            </div>
            <form className="bodyAddUser" onSubmit={handleSubmit(onSubmit)}>
                <div className="contPhotoUser">
                    <div className="contImgUser">
                        <label
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            {watch('photo') && watch('photo.length') ? (
                                typeof watch('photo') === 'object' ? (
                                    <img
                                        src={URL.createObjectURL(
                                            (watch('photo') as FileList)[0],
                                        )}
                                        alt={currentUser?.name}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null
                                            currentTarget.src =
                                                'https://via.placeholder.com/30'
                                        }}
                                        className="imgDeforView"
                                    />
                                ) : (
                                    <img
                                        src={watch('photo') as string}
                                        alt={currentUser?.name}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null
                                            currentTarget.src =
                                                'https://via.placeholder.com/30'
                                        }}
                                        className="imgDeforView"
                                    />
                                )
                            ) : (
                                <div className="imgDeforView">
                                    <i className="fas fa-camera" />
                                </div>
                            )}
                            Establecer foto de perfil
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                {...register('photo', {
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        if (e.target.files) {
                                            const typeFile = e.target.files[0].type
                                            if (
                                                typeFile === 'image/png' ||
                                                typeFile === 'image/jpg' ||
                                                typeFile === 'image/jpeg'
                                            ) {
                                                setValue('photo', {
                                                    ...(e.target.files as FileList),
                                                    length: e.target.files.length,
                                                })
                                            } else {
                                                setValue('photo', undefined)
                                                toast.warning(
                                                    'Formato de imagen no válido',
                                                )
                                            }
                                        }
                                    },
                                })}
                            />
                        </label>
                    </div>
                </div>
                <div className="contFormCour">
                    <div className="contFormCour scrollBody scroll">
                        <div className="sepContAddUser">
                            <div className="contInAddUser">
                                <p>
                                    <b>Nombre *</b>
                                </p>
                                <input
                                    type="text"
                                    required
                                    disabled={isLoading}
                                    {...register('name')}
                                />
                            </div>
                            <div className="contInAddUser">
                                <p>
                                    <b>Apellidos *</b>
                                </p>
                                <input
                                    type="text"
                                    required
                                    disabled={isLoading}
                                    {...register('lastname')}
                                />
                            </div>
                        </div>
                        <div className="contInAddUser">
                            <p>
                                <b>Correo electrónico *</b>
                            </p>
                            <input
                                type="email"
                                required
                                disabled={isLoading}
                                {...register('email')}
                            />
                        </div>
                        <div className="contInAddUser">
                            <p>
                                <b>Dirección</b>
                            </p>
                            <input
                                type="text"
                                disabled={isLoading}
                                {...register('address')}
                            />
                        </div>
                        <div className="sepContAddUser">
                            <div className="contInAddUser">
                                <p>
                                    <b>Celular</b>
                                </p>
                                <input
                                    type="text"
                                    maxLength={9}
                                    disabled={isLoading}
                                    {...register('phone')}
                                />
                            </div>
                            <div className="contInAddUser">
                                <p>
                                    <b>DNI</b>
                                </p>
                                <input
                                    type="text"
                                    maxLength={8}
                                    disabled={isLoading}
                                    {...register('dni')}
                                />
                            </div>
                        </div>
                        <div className="sepContAddUser">
                            <div className="contInAddUser">
                                <p>
                                    <b>Seleccione rol de usuario *</b>
                                </p>
                                <Controller
                                    name="rol"
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field: { value, ...rest } }) => (
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            className="selectbox"
                                            maxHeight={250}
                                            options={roles}
                                            placeholder={'Selecione rol...'}
                                            value={watch('rol')}
                                            isMulti
                                            maxMenuHeight={120}
                                            isLoading={isLoadingRoles}
                                            loadingMessage={() => 'Cargando...'}
                                            noOptionsMessage={() =>
                                                'No hay docentes disponibles'
                                            }
                                            {...rest}
                                        />
                                    )}
                                />
                            </div>
                            {showModal === 'add' && (
                                <div className="contInAddUser">
                                    <p>
                                        <b>Contraseña *</b>
                                    </p>
                                    <input
                                        type={`${showPassword ? 'text' : 'password'}`}
                                        required
                                        disabled={isLoading}
                                        maxLength={30}
                                        {...register('pass')}
                                    />
                                    <i
                                        className={`far fa-eye${
                                            showPassword ? '-slash' : ''
                                        }`}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="contInAddUser">
                            <p>
                                <b>Descripción del usuario</b>
                            </p>
                            <textarea
                                style={{ height: '63px' }}
                                maxLength={400}
                                disabled={isLoading}
                                {...register('description')}
                            />
                            <p
                                style={{
                                    textAlign: 'right',
                                    color: '#a29f9f',
                                    fontWeight: 600,
                                }}
                            >
                                {/* {varchar} caracteres */}
                            </p>
                        </div>
                    </div>
                    <div className="optsBtnAdd">
                        <button onClick={closeModal} disabled={isLoading}>
                            Cancelar
                        </button>
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? <SpinnerModal /> : 'Guardar'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
