import { useContext, useState } from 'react'
import { typesPrograms } from '../../context/types/typesPrograms'
import { ProgramsContext } from '../../context/userb2b/programs/ProgramsProvider'

import { Course, ModalOptions } from '../../interfaces'

interface ItemModuleCourse2Props {
    module: Course
    setCurrentModule: React.Dispatch<React.SetStateAction<Course | undefined>>
    setShowAddEditModule: React.Dispatch<React.SetStateAction<ModalOptions>>
    setShowDeleteModule: React.Dispatch<React.SetStateAction<boolean>>
}

const ItemModuleCourse2 = ({
    module,
    setCurrentModule,
    setShowAddEditModule,
    setShowDeleteModule,
}: ItemModuleCourse2Props) => {
    const [showOptions, setShowOptions] = useState<boolean>(false)

    const showModalEditModule = () => setShowAddEditModule('edit')
    const showDeleteModule = () => setShowDeleteModule(true)
    const { dispatch } = useContext(ProgramsContext)

    const handleCurrentModule = () => {
        setCurrentModule(module)
        const obj = {
            _id: module._id,
            name: module.name,
            price: module.price.course_price,
        }

        dispatch({
            type: typesPrograms.currentModule,
            payload: obj,
        })
    }

    return (
        <div className="circle_module">
            <div
                onClick={() => handleCurrentModule()}
                style={{ cursor: 'pointer', width: '100%' }}
            >
                <i className="fas fa-folder-open" />
                {/* <span style={{marginLeft: '10px'}}>Módulo {count + 1}</span>  */}
                <span style={{ marginLeft: '10px' }}>{module.name}</span>
            </div>

            <div
                className="menu_circle_module"
                onClick={() => {
                    setShowOptions(!showOptions)
                    !showOptions && handleCurrentModule()
                }}
            >
                <i className="fas fa-caret-down" style={{ cursor: 'pointer' }}></i>
                {showOptions && (
                    <div className="optSelectOption">
                        {
                            <>
                                <div className="onlyOpt" onClick={showModalEditModule}>
                                    <div>
                                        <i className="far fa-edit" /> Editar
                                    </div>
                                </div>

                                <div className="onlyOpt" onClick={showDeleteModule}>
                                    <div>
                                        <i className="far fa-trash-alt" /> Eliminar
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                )}
            </div>
        </div>
    )
}

export default ItemModuleCourse2
