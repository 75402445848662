import React from 'react'

export default function TypeBoolean({
    questiondata,
    setQuestionData,
    updateData,
    data,
    index,
}: any): JSX.Element {
    const { data_boolean } = questiondata

    const onSetAnswer = (id: string) => {
        const alternatives = data_boolean.map((opt: any) => {
            if (opt._id === id) {
                opt.correct = true
                return opt
            } else {
                opt.correct = false
                return opt
            }
        })

        setQuestionData({
            ...questiondata,
            data_boolean: alternatives,
        })
        if (updateData) {
            updateData({ ...data, alternatives: alternatives }, index)
        }
    }

    return (
        <div className="alternatives_content">
            {data_boolean.map((dt: any, key: number) => {
                const { _id, value, correct } = dt

                return (
                    <div key={key} className="alternatives_item">
                        <div>
                            <h4>{value}</h4>
                        </div>
                        <div className="choose" onClick={() => onSetAnswer(_id)}>
                            <input
                                id={_id}
                                type="radio"
                                // name="group_name"
                                checked={correct}
                                required
                            />
                            <span>El enunciado es {value}</span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
