import { useCallback, useRef, useState, useEffect } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import NewQuestion from '../newquestion/NewQuestion'
import ContainerModal from '../../../../../components/modals/ContainerModal'
import {
    DeleteDragQuestionModal,
    EditDragQuestionModal,
} from './modals/CrudDragQuestionModal'
import { handleOnInput } from '../../../../../utils'

import './itemDragableQuestion.scss'

export const ItemDragableQuestion = ({
    tablePosition,
    qst,
    updateData,
    setQuestions,
    idEvaluation,
    setTypeOption,
    deleteQuestion,
    typeOption,
    handleItem,
    handlePoints,
}: any) => {
    // console.log(qst)
    const [profile, setProfile] = useState(false)
    const [editModal, seteditModal] = useState(false)
    const [deleteModal, setdeleteModal] = useState(false)
    const [showModal, setShowModal] = useState<boolean>(false)

    const profileRef = useRef<any>(null)

    const { title, nivel, type, question, alternatives, questionbank, points } = qst

    const destroy = useCallback(
        (e) => {
            if (e.target !== profileRef.current && profile) {
                setProfile(false)
            }
        },
        [profile],
    )

    useEffect(() => {
        window.onclick = function (e: any) {
            destroy(e)
        }
    }, [destroy])

    return (
        <div>
            <Draggable draggableId={tablePosition.toString()} index={tablePosition}>
                {(provided) =>
                    questionbank ? (
                        <div
                            className="view_question border"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <div
                                className="container_question"
                                style={{ position: 'relative' }}
                            >
                                <div className="half">
                                    <label>Nivel de Dificultad:</label>
                                    <label>
                                        {nivel === 'easy'
                                            ? 'Fácil'
                                            : qst.nivel === 'medium'
                                            ? 'Normal'
                                            : 'Difícil'}
                                    </label>
                                </div>
                                <div className="half">
                                    <label>Puntos:</label>
                                    <input
                                        className="input_points"
                                        type="number"
                                        name="points"
                                        min="0"
                                        max="20"
                                        value={points}
                                        onInput={(e) => handleOnInput(e, 21)}
                                        onChange={(e) => handlePoints(e, qst._id)}
                                    />
                                </div>
                                <div className="half">
                                    <label>Tipo de Pregunta:</label>
                                    <label>
                                        {type === 'unique'
                                            ? 'Opción única'
                                            : type === 'multiple'
                                            ? 'Selección múltiple'
                                            : type === 'boolean'
                                            ? 'Verdadero o Falso'
                                            : type === 'short'
                                            ? 'Pregunta corta'
                                            : type === 'large'
                                            ? 'Pregunta larga'
                                            : 'Pregunta con archivos'}
                                    </label>
                                </div>
                                {typeOption === 'update' ? (
                                    <>
                                        <i
                                            className="fas fa-ellipsis-v"
                                            style={{
                                                position: 'absolute',
                                                right: '-5px',
                                                top: '-5px',
                                                padding: '5px',
                                            }}
                                            onClick={() => setProfile(!profile)}
                                            ref={profileRef}
                                        ></i>

                                        <div
                                            className={`optSelectOption ${
                                                profile ? 'active' : ''
                                            }`}
                                        >
                                            <div
                                                className="onlyOpt"
                                                onClick={() => setShowModal(true)}
                                            >
                                                Editar
                                            </div>
                                            <div
                                                className="onlyOpt"
                                                onClick={() => setdeleteModal(true)}
                                            >
                                                Eliminar
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <i
                                        className="fas fa-times-circle"
                                        style={{
                                            position: 'absolute',
                                            right: -5,
                                            top: 0,
                                            cursor: 'pointer',
                                            fontSize: '25px',
                                        }}
                                        onClick={() => deleteQuestion(tablePosition)}
                                    />
                                )}
                            </div>
                            <div className="container_question">
                                <label>Pregunta:</label>
                                <label className="question">{title}</label>
                            </div>
                            {question ? (
                                <div className="container_question">
                                    <label>Descripción:</label>
                                    <div
                                        className="question_view"
                                        dangerouslySetInnerHTML={{ __html: question }}
                                    ></div>
                                </div>
                            ) : null}
                            <div className="container_question">
                                <label>Alternativas:</label>
                                <div className="alternatives_view">
                                    <ul>
                                        {type === 'unique' ||
                                        type === 'multiple' ||
                                        type === 'boolean'
                                            ? alternatives?.map(
                                                  (alter: any, key: number) => (
                                                      <li key={key}>
                                                          <i
                                                              className={
                                                                  alter.correct === true
                                                                      ? 'fas fa-check-square'
                                                                      : 'far fa-square'
                                                              }
                                                          ></i>
                                                          {alter.value}
                                                      </li>
                                                  ),
                                              )
                                            : type === 'short'
                                            ? 'Los alumnos responderán la pregunta de manera consisa (80 palabras).'
                                            : type === 'large'
                                            ? 'Los alumnos responderán la pregunta de forma libre y explayada.'
                                            : 'Los alumnos responderán la pregunta adjuntando un archivo'}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="itemQuestion"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <NewQuestion
                                data={qst}
                                updateData={updateData}
                                index={tablePosition}
                                deleteQuestion={deleteQuestion}
                            />
                        </div>
                    )
                }
            </Draggable>
            <ContainerModal showModal={deleteModal}>
                <DeleteDragQuestionModal
                    data={qst}
                    setShowModalDelete={setdeleteModal}
                    handleDelete={() => handleItem(qst._id, false)}
                    // setQuestions={setQuestions}
                    // idEvaluation={idEvaluation}
                />
            </ContainerModal>

            <ContainerModal showModal={editModal}>
                <EditDragQuestionModal
                    data={qst}
                    setShowModalEdit={seteditModal}
                    editModal={editModal}
                    setQuestions={setQuestions}
                    idEvaluation={idEvaluation}
                    setTypeOption={setTypeOption}
                />
            </ContainerModal>
            <ContainerModal showModal={showModal}>
                <div className="main_Delete">
                    <div className="headModal">
                        <h3>Aviso</h3>
                        <i
                            className="fas fa-times-circle"
                            onClick={() => setShowModal(false)}
                        />
                    </div>
                    <div className="bodyModals">
                        <p>
                            La pregunta seleccionada se actualizará en el banco de
                            preguntas. ¿Está seguro de actualizarla?
                        </p>
                        <div>
                            <button onClick={() => setShowModal(false)}>Cancelar</button>
                            <button
                                className="saveButton"
                                onClick={() => {
                                    seteditModal(true)
                                    setShowModal(false)
                                }}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </ContainerModal>
        </div>
    )
}
