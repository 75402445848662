import { Control, useFieldArray, UseFormRegister, useWatch } from 'react-hook-form'

import { FormQuestion } from '../modalsQuestion/QuestionAddEdit'

let renderCount = 0

interface TypeUniqueProps {
    control: Control<FormQuestion, any> | undefined
    register: UseFormRegister<FormQuestion>
    isLoading: boolean
}

export const TypeUnique = ({ register, control, isLoading }: TypeUniqueProps) => {
    renderCount++
    console.log({ renderCount })

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'alternatives',
    })

    const alternatives = useWatch({
        control,
        name: 'alternatives',
    })

    const addNewAlternative = () => append({ correct: false, value: '' })

    const onSetAnswer = (index: number) => () => {
        const newFields = alternatives.map((alternative, idx) => ({
            ...alternative,
            correct: idx === index ? true : false,
        }))
        replace(newFields)
    }

    const onRemoveAlternative = (index: number) => () => remove(index)

    return (
        <div className="alternatives_content">
            {fields.length ? (
                fields.map((field, index) => (
                    <div key={field.id} className="alternatives_item">
                        <div>
                            <input
                                className="input_question"
                                placeholder="Escribe aquí..."
                                autoFocus={true}
                                disabled={isLoading}
                                required
                                {...register(`alternatives.${index}.value` as const)}
                            />
                            <button
                                type="button"
                                onClick={onRemoveAlternative(index)}
                                disabled={isLoading}
                            >
                                <i className="fa fa-trash" />
                            </button>
                        </div>
                        <div>
                            <input
                                id={field.id}
                                type="radio"
                                onChange={onSetAnswer(index)}
                                name="correct"
                                checked={field.correct}
                                required
                                disabled={isLoading}
                            />
                            <label
                                htmlFor={field.id}
                                style={{
                                    fontWeight: 'normal',
                                    margin: 0,
                                    cursor: 'pointer',
                                }}
                            >
                                Marcar como respuesta correcta
                            </label>
                        </div>
                    </div>
                ))
            ) : (
                <div className="alternatives_message">
                    <h4>Aún no hay alternativas</h4>
                </div>
            )}

            <div className="alternatives_buttons">
                <button type="button" onClick={addNewAlternative}>
                    <span className="fas fa-plus"></span> Agregar Alternativa
                </button>
            </div>
        </div>
    )
}
