import { useEffect, useState, useContext } from 'react'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { axiosCourses } from '../../../../../../config/axios'
import { AparienciaContext } from '../../../../../../context/aparienciaContext/AparienciaProvider'
import { ScheduledCoursesContext } from '../../../../../../context/sheduledCourses/ScheduledCoursesProvider'
import { getUsersByScheduleds } from '../../../../../../context/userCounter/UserCounterAction'
import SpinnerModal from './../../../../../general/spinner/SpinnerModal'
// import Notification, { type } from '../../../../../general/toast/Toast';

type ChangeEvent =
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>

type target = {
    name: string
    value: string
}

const DetailEntollment = ({ data, setShowModal, setDataTable1, setpagination }: any) => {
    const { state: stateApariencia } = useContext(AparienciaContext)

    const token = JSON.parse(localStorage.getItem('user')!).token

    const { stateScheduled, dispatch } = useContext(ScheduledCoursesContext)

    const { currentScheduledCourses } = stateScheduled

    const [moduleScheduled, setScheduledModule] = useState([])

    const animatedComponents = makeAnimated()

    const [dat, setData] = useState({
        price: '',
        selectedOption: 'total',
    })

    const [arrayIds, setArrayId] = useState<any>([])

    const [arrayTotalId, setArrayTotalId] = useState([])

    const [loadingTotal, setLoadingTotal] = useState(false)
    const [loadingPart, setLoadingPart] = useState(false)

    // console.log(arrayIds)

    // console.log(arrayTotalId)

    // console.log('que llega qui', currentScheduledCourses)

    const getSelectModule = (e: any) => {
        let array: any = []

        // console.log(e);

        e?.map(
            (prueba: any) =>
                // console.log(prueba)

                array.push(prueba.value),
            // suma = prueba.price + suma
        )

        setArrayId(array)
    }

    const changeData = (e: ChangeEvent, number?: boolean): void => {
        const { name, value }: target = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setData({
                    ...data,
                    [name]: value,
                })
            }
        } else {
            setData({
                ...data,
                [name]: value,
            })
        }
    }

    const handleTotalEnrollment = (e: any) => {
        e.preventDefault()

        setLoadingTotal(true)

        // return console.log('array que envio', arrayTotalId)
        // return console.log(JSON.stringify(arrayTotalId))

        axiosCourses({
            url: 'v5.0.1/api',

            headers: {
                authorization: `Bearer ${token}`,
            },

            data: {
                fname: 'assignUserToScheduled',
                rol: ['estudiante'],
                scheduleds: JSON.stringify(arrayTotalId),
                entity: stateApariencia.currentIdEntity,
                user: data._id,
                type: 'web',
            },
        })
            .then(async ({ data }) => {
                // console.log(data)

                // Notification(type.success, data.message)
                toast.success(data.message)

                const payload = {
                    page: 1,
                    query: {
                        field: 'email',
                        search: '',
                    },
                    docs: 15,
                    descendant: false,
                    setpagination: setpagination,
                    setDataTable1: setDataTable1,
                    currentScheduled: currentScheduledCourses.id,
                    token: token,
                    entity_id: stateApariencia.currentIdEntity,
                }

                await getUsersByScheduleds(payload)

                setShowModal(false)

                setLoadingTotal(false)
            })
            .catch((e) => {
                console.log(e.response)

                // Notification(type.danger, e.response.data.message)
                toast.error(e.response.data.message)

                setLoadingTotal(false)
            })
    }

    const handleModuleEnrollment = (e: any) => {
        e.preventDefault()

        setLoadingPart(true)

        arrayIds.push(currentScheduledCourses.id)

        // console.log('array que envio modulo', arrayIds)

        // return console.log(JSON.stringify(arrayIds))

        axiosCourses({
            url: 'v5.0.1/api',

            headers: {
                authorization: `Bearer ${token}`,
            },

            data: {
                fname: 'assignUserToScheduled',
                rol: ['estudiante'],
                scheduleds: JSON.stringify(arrayIds),
                entity: stateApariencia.currentIdEntity,
                user: data._id,
                type: 'web',
            },
        })
            .then(async ({ data }) => {
                // console.log(data)
                // Notification(type.success, data.message)
                toast.success(data.message)

                const payload = {
                    page: 1,
                    query: {
                        field: 'email',
                        search: '',
                    },
                    docs: 15,
                    descendant: false,
                    setpagination: setpagination,
                    setDataTable1: setDataTable1,
                    currentScheduled: currentScheduledCourses.id,
                    token: token,
                    entity_id: stateApariencia.currentIdEntity,
                }

                await getUsersByScheduleds(payload)

                setShowModal(false)

                setLoadingPart(false)
            })
            .catch((e) => {
                // console.log(e.response)

                // Notification(type.danger, e.response.data.message)
                toast.error(e.response.data.message)

                setLoadingPart(false)
            })
    }

    useEffect(() => {
        axiosCourses({
            url: '/v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getScheduleds',
                entity_id: stateApariencia.currentIdEntity,
                type_of_scheduled: 'children',
                parent_scheduled: currentScheduledCourses.id,
            },
        })
            .then(({ data }) => {
                // console.log(data);

                let array: any = []

                let arrayTotalId: any = []

                data.scheduleds?.map((data: any) =>
                    array.push({
                        value: data._id,
                        label: data.name,
                        price: data.price,
                    }),
                )
                data.scheduleds?.map((data: any) => arrayTotalId.push(data._id))
                // console.log(arrayTotalId)
                arrayTotalId.push(currentScheduledCourses.id)

                setScheduledModule(array)

                setArrayTotalId(arrayTotalId)
            })
            .catch((e) => {
                console.log(e)
                console.log(e.response)
            })
    }, [dispatch, token, stateApariencia.currentIdEntity, currentScheduledCourses.id])

    return (
        <div className="detailEnrollment">
            <h3> Matricular alumno en Diplomado de ecografía obstetrica </h3>

            <p> Completa los siguientes campos para matricular alumno </p>

            <div className="content_detail_body">
                <div className="radio_options">
                    <div className="radio_option">
                        <input
                            type="radio"
                            id="total"
                            name="selectedOption"
                            value="total"
                            onChange={(e) => changeData(e)}
                            checked={dat.selectedOption === 'total'}
                        />
                        <label htmlFor="total"> Pago total </label>
                    </div>

                    <div className="radio_option">
                        <input
                            type="radio"
                            id="part"
                            name="selectedOption"
                            value="part"
                            onChange={(e) => changeData(e)}
                            checked={dat.selectedOption === 'part'}
                        />
                        <label htmlFor="part"> Pago por módulo </label>
                    </div>
                </div>

                <div className="input_groups">
                    {dat.selectedOption === 'total' ? (
                        <form onSubmit={handleTotalEnrollment}>
                            <div className="contInAddUser">
                                <p>
                                    <b>Programa</b>
                                </p>
                                <input
                                    type="text"
                                    name="price"
                                    value={currentScheduledCourses.name}
                                    onChange={(e: ChangeEvent) => changeData(e)}
                                    disabled
                                />
                            </div>

                            <div className="sepContAddUser">
                                <div className="contInAddUser">
                                    <p>
                                        <b>Precio de matricula</b>
                                    </p>
                                    <input
                                        type="text"
                                        name="firstlastname"
                                        value={currentScheduledCourses.price.inscription}
                                        onChange={(e: ChangeEvent) => changeData(e)}
                                        disabled
                                    />
                                </div>

                                <div className="contInAddUser">
                                    <p>
                                        <b>Precio por programa</b>
                                    </p>
                                    <input
                                        type="text"
                                        name="secondlastname"
                                        value={currentScheduledCourses.price.course_price}
                                        onChange={(e: ChangeEvent) => changeData(e)}
                                        disabled
                                    />
                                </div>
                            </div>
                            {loadingTotal ? (
                                <div
                                    style={{
                                        backgroundColor: 'var(--color-secondary)',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <SpinnerModal />
                                </div>
                            ) : (
                                <div className="container_button">
                                    <button className="design_button" type="submit">
                                        {' '}
                                        Matricular{' '}
                                    </button>
                                </div>
                            )}
                        </form>
                    ) : dat.selectedOption === 'part' ? (
                        <form onSubmit={handleModuleEnrollment}>
                            <div className="contInAddUser">
                                <p>
                                    <b>Seleccióne los módulos que desea comprar: </b>
                                </p>
                                <Select
                                    className="selectbox"
                                    options={moduleScheduled}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    onChange={getSelectModule}
                                />
                            </div>

                            <div className="contInAddUser">
                                <p>
                                    <b>Pago</b>
                                </p>
                                <input
                                    type="text"
                                    name="price"
                                    value={dat.price}
                                    onChange={(e: ChangeEvent) => changeData(e)}
                                    disabled
                                />
                            </div>

                            {loadingPart ? (
                                <div
                                    style={{
                                        backgroundColor: 'var(--color-secondary)',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <SpinnerModal />
                                </div>
                            ) : (
                                <div className="container_button">
                                    <button className="design_button" type="submit">
                                        {' '}
                                        Matricular{' '}
                                    </button>
                                </div>
                            )}
                        </form>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default DetailEntollment
