import { useState, useMemo, useEffect } from 'react'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'

import type { Course, ModalOptions, OptionType } from '../../../../../interfaces'
import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { useAddEditCourseMutation } from '../../../../../store/api'
import { selectAuth, selectCourse } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface FormCourse {
    id: string
    name: string
    description: string
    priceInscription: number | string
    priceCertificate: number | string
    priceEnrollment: number | string
    category_id: string
    category: string
    tags: OptionType[] | undefined
    targetAudiences: string
    alias: string
    preview: FileList | string | undefined
}

interface AddEditCourseProps {
    showModal: ModalOptions
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
    course?: Course
    setCourse: React.Dispatch<React.SetStateAction<Course | undefined>>
}

export const AddEditCourse = ({
    showModal,
    setShowModal,
    course,
    setCourse,
}: AddEditCourseProps) => {
    console.log({ course })
    const { entity } = useAppSelector(selectAuth)
    const { currentCategory } = useAppSelector(selectCourse)

    const tags = useMemo(
        () => [
            {
                value: 'web',
                label: 'Web',
            },
            {
                value: 'app',
                label: 'App',
            },
            {
                value: '3d',
                label: '3D',
            },
        ],
        [],
    )

    const [isFree, setIsFree] = useState<boolean>(
        course?.price.certificate === 0 && course.price.inscription === 0 ? true : false,
    )

    const { register, control, handleSubmit, watch, setValue } = useForm<FormCourse>({
        defaultValues: {
            id: course?._id,
            name: showModal === 'edit' ? course?.name : '',
            description: showModal === 'edit' ? course?.description : '',
            priceInscription: showModal === 'edit' ? course?.price.inscription : '',
            priceCertificate: showModal === 'edit' ? course?.price.certificate : '',
            // priceEnrollment: showModal === 'edit' ? course?.price.course_price : '',
            priceEnrollment: '',
            category: currentCategory?._id,
            tags:
                showModal === 'edit'
                    ? course?.tags.map((tag) => ({
                          value: tag,
                          label: tag,
                      }))
                    : undefined,
            targetAudiences: showModal === 'edit' ? course?.target_audiences : '',
            preview: showModal === 'edit' ? course?.preview : undefined,
        },
    })
    const [addEditCourse, { isLoading }] = useAddEditCourseMutation()

    const animatedComponents = makeAnimated()

    // const [varchar, setVarchar] = useState<any>(400)
    // const [varchar2, setVarchar2] = useState<any>(400)

    const changeValueSwitch = () => setIsFree(!isFree)

    const onSubmit: SubmitHandler<FormCourse> = (data) => {
        if (
            data.name.trim() === '' ||
            data.description.trim() === '' ||
            data.targetAudiences.trim() === ''
        )
            return toast.warning('No puede crear un curso con espacios en blanco')

        if (!data.tags) return toast.warning('Debe asignarle los tags al curso')

        const formData: FormData = new FormData()

        if (typeof data.preview === 'object') {
            if (!data.preview.length) {
                return toast.warning('Debe agregar la foto del curso')
            } else {
                formData.append('preview', (data.preview as FileList)[0])
            }
        }

        if (showModal === 'edit') {
            formData.append('fname', 'updateCourse')
            formData.append('course_id', data.id)
            formData.append('category', data.category)
        } else {
            formData.append('fname', 'createCourse')
            formData.append('entity_id', entity)
            formData.append('category_id', data.category)
        }
        formData.append('alias', 'programa')
        formData.append('name', data.name)
        formData.append('description', data.description)
        formData.append(
            'price',
            JSON.stringify(
                isFree
                    ? {
                          inscription: 0,
                          certificate: 0,
                          course_price: 0,
                      }
                    : {
                          inscription: data.priceInscription,
                          certificate: data.priceCertificate,
                          course_price: +data.priceInscription + +data.priceCertificate,
                      },
            ),
        )
        formData.append('tags', JSON.stringify(data.tags.map((tag) => tag.value)))
        formData.append('target_audiences', data.targetAudiences)

        console.log(Object.fromEntries(formData))
        addEditCourse(formData)
            .then(() => {
                setShowModal('hide')
                setCourse(undefined)
            })
            .catch(console.log)
    }

    // ? validate priceCertificate and priceInscription !== '' and convert to number
    const priceCertificate =
        watch('priceCertificate').toString().length && +watch('priceCertificate')

    const priceInscription =
        watch('priceInscription').toString().length && +watch('priceInscription')

    useEffect(() => {
        if (isFree) {
            setValue('priceCertificate', '')
            setValue('priceInscription', '')
            setValue('priceEnrollment', '')
        }
        // console.log(priceCertificate, priceInscription)
        if (priceCertificate >= 0 && priceInscription >= 0) {
            // console.log('sumar', priceCertificate + priceInscription)
            setValue('priceEnrollment', priceCertificate + priceInscription)
        }
    }, [isFree, setValue, priceCertificate, priceInscription])

    useEffect(() => {
        showModal === 'add' && setCourse(undefined)
        // return () => {
        //     // showModal === 'add' && setCourse(undefined)
        //     setCourse(undefined)
        // }
    }, [setCourse, showModal])

    return (
        <div className="main_AddCourse">
            <div className="headAddCour">
                <h3>{showModal === 'edit' ? 'Editar ' : 'Agregar '}Curso</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal('hide')} />
            </div>
            <div className="bodyAddCour">
                <form onSubmit={handleSubmit(onSubmit)} className="contFormCour">
                    <div className="sepScroll scroll">
                        <div className="contInAddCour">
                            <p>Nombre del curso *</p>
                            <input
                                type="text"
                                required
                                maxLength={80}
                                {...register('name')}
                            />
                        </div>

                        <div className="contInAddCour">
                            <p>Foto del curso *</p>
                            <label className="file_label">
                                <span className="first_part">Subir Archivo</span>
                                <input
                                    className="input_design"
                                    type="file"
                                    id="file"
                                    {...register('preview')}
                                />
                                {watch('preview') && watch('preview.length') ? (
                                    typeof watch('preview') === 'object' ? (
                                        <span className="second_part">
                                            {(watch('preview') as FileList)[0].name}
                                        </span>
                                    ) : (
                                        <span className="second_part">
                                            {watch('preview') as string}
                                        </span>
                                    )
                                ) : (
                                    <span className="second_part">
                                        No se ha seleccionado ningun archivo
                                    </span>
                                )}
                            </label>
                        </div>

                        <div className="contInAddCour">
                            <p>Categorías *</p>
                            <select
                                name="select"
                                className="design_input"
                                defaultValue={currentCategory?._id}
                                disabled
                            >
                                <option value={currentCategory?._id}>
                                    {currentCategory?.name}
                                </option>
                            </select>
                        </div>
                        <div className="contInAddCour">
                            <p>Descripción del curso *</p>
                            <textarea
                                maxLength={400}
                                required
                                {...register('description')}
                            />
                            {/* <p
                                style={{
                                    textAlign: 'right',
                                    color: '#a29f9f',
                                    fontWeight: 600,
                                }}>
                                {varchar} caracteres{' '}
                            </p> */}
                        </div>

                        <div className="contInAddCour">
                            <p> ¿A quién va dirigido? *</p>
                            <textarea
                                maxLength={400}
                                required
                                {...register('targetAudiences')}
                            />
                            {/* <p
                                style={{
                                    textAlign: 'right',
                                    color: '#a29f9f',
                                    fontWeight: 600,
                                }}>
                                {varchar2} caracteres
                            </p> */}
                        </div>

                        <div className="contInAddCour">
                            <p>Tags *</p>
                            <Controller
                                name="tags"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, ...rest } }) => (
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        className="selectbox"
                                        maxHeight={250}
                                        options={tags}
                                        placeholder={'Selecione tags...'}
                                        value={watch('tags')}
                                        isMulti
                                        {...rest}
                                    />
                                )}
                            />
                        </div>

                        <div className="contInAddCour">
                            <div className="half_content_file">
                                <p className="text_switch">Curso gratuito </p>
                                <div
                                    className={`switch ${isFree ? 'active' : ''}`}
                                    onClick={changeValueSwitch}
                                >
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>

                        <div className="sepContAddCour">
                            <div className="contInAddCour">
                                <p>Precio de Certificado *</p>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    required
                                    maxLength={5}
                                    placeholder="0"
                                    disabled={isFree}
                                    {...register('priceCertificate', {
                                        setValueAs: (value: string) => parseInt(value),
                                        onChange: (e) => {
                                            const re = /^[0-9.\b]+$/
                                            if (re.test(e.target.value)) {
                                                setValue(
                                                    'priceCertificate',
                                                    e.target.value,
                                                )
                                            } else {
                                                setValue('priceCertificate', '')
                                            }
                                        },
                                    })}
                                />
                            </div>

                            <div className="contInAddCour">
                                <p>Precio de matrícula *</p>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    required
                                    maxLength={5}
                                    placeholder="0"
                                    disabled={isFree}
                                    {...register('priceInscription', {
                                        onChange: (e) => {
                                            const re = /^[0-9.\b]+$/
                                            if (re.test(e.target.value)) {
                                                setValue(
                                                    'priceInscription',
                                                    e.target.value,
                                                )
                                            } else {
                                                setValue('priceInscription', '')
                                            }
                                        },
                                    })}
                                />
                            </div>
                        </div>

                        <div className="contInAddCour">
                            <p>Precio de SubTotal *</p>
                            <input
                                type="text"
                                placeholder="0"
                                disabled
                                {...register('priceEnrollment', {
                                    value:
                                        +watch('priceInscription') +
                                        +watch('priceCertificate'),
                                })}
                            />
                        </div>
                    </div>

                    <div className="optsBtnAdd">
                        <button
                            type="button"
                            onClick={() => setShowModal('hide')}
                            disabled={isLoading}
                        >
                            Cancelar
                        </button>
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? <SpinnerModal /> : 'Guardar'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
