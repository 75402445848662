import { useEffect, useReducer, createContext } from 'react'

import { authReducer } from './AuthReducer'
import { IState } from './AuthInterface'
import { useAppDispatch } from '../../hooks/useReduxHooks'
import { setAuthenticated, setScheduledActive, setToken } from '../../store/slices'

const init = () => {
    return JSON.parse(localStorage.getItem('user')!) || { logged: false }
    //return localStorage.getItem('user') || {logged: false}
}

const initialState: IState = {
    user: {},
    rolesByEntity: {},
}

export const AuthContext = createContext<IState | any>(initialState)

const AuthProvider = (props: any) => {
    const [user, dispatch] = useReducer(authReducer, {}, init)

    const dispatchRedux = useAppDispatch()

    useEffect(() => {
        user.logged
            ? localStorage.setItem('user', JSON.stringify(user))
            : localStorage.clear()
    }, [user])

    useEffect(() => {
        if (user.token) {
            dispatchRedux(setToken(user.token))
            dispatchRedux(setAuthenticated(true))
        }
    }, [user.token, dispatchRedux])

    useEffect(() => {
        console.log('init coordinator routes')
        // console.log(currentScheduled)
        const currentScheduled = localStorage.getItem('currentScheduled')
        if (currentScheduled)
            dispatchRedux(setScheduledActive(JSON.parse(currentScheduled)))
    }, [dispatchRedux])

    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
