import { createApi } from '@reduxjs/toolkit/query/react'
import { toast } from 'react-toastify'

// import { COURSE_URL } from '../../config/utils'
import type {
    APIGetScheduledsResponse,
    APIAddEditScheduledResponse,
    APIAddEditScheduledChildResponse,
    APIGetCategoriesResponse,
    APIGetCoursesResponse,
    EntityIdParam,
    APIAddEditCategoryResponse,
    APIGetItemsResponse,
    EntityParam,
} from '../../interfaces'
import { baseQueryWithReauth } from '../../utils/withAuth'
// import type { RootState } from '../store'

interface GetSchuledsParams {
    entity_id: string
    docs: number
    page: number
    filter_by_name?: string
    sort: string
    query?: {
        field?: string
        search?: string
    }
}

export interface CreateEditScheduledParams {
    fname: string
    scheduled_id?: string
    entity_id: string
    name: string
    course_id: string
    campus_id: string
    description: string
    start_date: Date
    end_date: Date
    coverpage: string
    teacher_ids: string[]
    teacher_ids_old?: string[]
    publish: boolean
}

interface DeleteScheduledParams {
    scheduled_id: string
}

interface GetScheduledsChildrenParams extends EntityIdParam {
    parent_scheduled: string
}

interface GetCoursesChildrenParam extends EntityIdParam {
    parent_course_id: string
}

export interface AddEditScheduledChildParams {
    fname: string
    scheduled_id?: string
    parent_scheduled: string
    main_scheduled: string
    entity_id: string
    course_id: string
    campus_id: string
    type_of_scheduled: string
    name: string
    description: string
    start_date: string
    end_date: string
}

interface AddEditCategoryParams {
    fname: string
    category_id?: string
    entity_id: string
    name: string
    description: string
}

interface DeleteCategoryParams extends EntityIdParam {
    category_id: string
}

interface GetCoursesByCategoryParam extends EntityIdParam {
    category_id: string
    docs: number
    page?: number
    query?: { field?: 'name'; search?: string }
    // type_of_course: "main"
}

interface DeleteCourseParams extends EntityIdParam {
    course_id: string
}

interface GetItemsParam extends EntityParam {
    course: string
}

interface AddEditDeleteItemParams extends EntityParam {
    item?: string
    fname: string
    title: string
    course: string
    main_course: string
}

interface DeleteItemParams extends EntityIdParam {
    item: string
}

interface AddEditCourseChildrenParams extends EntityIdParam {
    fname: string
    name: string
    category?: string
    course_id?: string
    price: string
    //
    alias?: string
    category_id?: string
    main_course?: string
    parent_course_id?: string
    tags?: string
    type_of_course?: string
}

export const coursesApi = createApi({
    reducerPath: 'coursesApi',
    // baseQuery: fetchBaseQuery({
    //     baseUrl: `${COURSE_URL}v5.0.1/api`,
    //     prepareHeaders: (headers, { getState }) => {
    //         const token = (getState() as RootState).auth.token
    //         if (token) headers.set('authorization', `Bearer ${token}`)
    //         return headers
    //     },
    // }),
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Scheduleds',
        'ScheduledsChildren',
        'Categories',
        'Courses',
        'CoursesChildren',
        'Items',
    ],
    endpoints: (builder) => ({
        getScheduleds: builder.query<APIGetScheduledsResponse, GetSchuledsParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getScheduleds',
                    type_of_scheduled: 'main',
                    ...params,
                },
            }),
            providesTags: (result) =>
                result?.scheduleds
                    ? [
                          ...result.scheduleds.map(({ _id }) => ({
                              type: 'Scheduleds' as const,
                              id: _id,
                          })),
                          { type: 'Scheduleds', id: 'LIST' },
                      ]
                    : [{ type: 'Scheduleds', id: 'LIST' }],
        }),
        getScheduledsChildren: builder.query<
            APIGetScheduledsResponse,
            GetScheduledsChildrenParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getScheduleds',
                    type_of_scheduled: 'children',
                    ...params,
                },
            }),
            providesTags: (result) =>
                result?.scheduleds
                    ? [
                          ...result.scheduleds.map(({ _id }) => ({
                              type: 'ScheduledsChildren' as const,
                              id: _id,
                          })),
                          { type: 'ScheduledsChildren', id: 'LIST_CHILDREN' },
                      ]
                    : [{ type: 'ScheduledsChildren', id: 'LIST_CHILDREN' }],
        }),
        addEditScheduled: builder.mutation<APIAddEditScheduledResponse, FormData>({
            query: (body) => ({
                url: '',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Scheduleds', id: 'LIST' }],
            // onQueryStarted: async (_, { queryFulfilled }) => {
            //     try {
            //         await queryFulfilled
            //         toast.success('Curso creado con éxito')
            //     } catch (error) {
            //         console.log(error)
            //         toast.error('Error al crear curso')
            //     }
            // },
        }),
        deleteScheduled: builder.mutation<{}, DeleteScheduledParams>({
            query: ({ scheduled_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteScheduled',
                    scheduled_id,
                },
            }),
            invalidatesTags: (_, __, { scheduled_id }) => [
                { type: 'Scheduleds', id: scheduled_id },
            ],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Curso eliminada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar curso')
                }
            },
        }),
        getCategories: builder.query<APIGetCategoriesResponse, EntityIdParam>({
            query: ({ entity_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getCategories',
                    entity_id,
                },
            }),
            providesTags: (result) =>
                result?.categories
                    ? [
                          ...result.categories.map(({ _id }) => ({
                              type: 'Categories' as const,
                              id: _id,
                          })),
                          { type: 'Categories', id: 'LIST' },
                      ]
                    : [{ type: 'Categories', id: 'LIST' }],
        }),
        addEditCategory: builder.mutation<
            APIAddEditCategoryResponse,
            AddEditCategoryParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    ...params,
                },
            }),
            invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
            onQueryStarted: async (params, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        `La categoría ha sido ${
                            params.fname === 'createCategory' ? 'creada' : 'actualizada'
                        } con éxito`,
                    )
                } catch (error) {
                    console.log(error)
                    toast.error(
                        `Error al ${
                            params.fname === 'createCategory' ? 'crear' : 'actualizar'
                        } la categoría`,
                    )
                }
            },
        }),
        deleteCategory: builder.mutation<{}, DeleteCategoryParams>({
            query: ({ category_id, entity_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteCategory',
                    entity_id,
                    category_id,
                },
            }),
            invalidatesTags: (_, __, { category_id }) => [
                { type: 'Categories', id: category_id },
            ],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Categoría eliminada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar categoría')
                }
            },
        }),
        getCourses: builder.query<APIGetCoursesResponse, EntityIdParam>({
            query: ({ entity_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getCourses',
                    entity_id,
                    type_of_course: 'main',
                    docs: 900,
                },
            }),
            providesTags: (result) =>
                result?.courses
                    ? [
                          ...result.courses.map(({ _id }) => ({
                              type: 'Courses' as const,
                              id: _id,
                          })),
                          { type: 'Courses', id: 'LIST' },
                      ]
                    : [{ type: 'Courses', id: 'LIST' }],
        }),
        addEditCourse: builder.mutation<{}, FormData>({
            query: (body) => ({
                url: '',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Courses', id: 'LIST' }],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Curso creado con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al crear curso')
                }
            },
        }),
        deleteCourse: builder.mutation<{}, DeleteCourseParams>({
            query: ({ course_id, entity_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteCourse',
                    entity_id,
                    course_id,
                },
            }),
            invalidatesTags: (_, __, { course_id }) => [
                { type: 'Courses', id: course_id },
            ],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Curso eliminado con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar curso')
                }
            },
        }),
        getCoursesChildren: builder.query<APIGetCoursesResponse, GetCoursesChildrenParam>(
            {
                query: (params) => ({
                    url: '',
                    method: 'POST',
                    body: {
                        fname: 'getCourses',
                        docs: 20,
                        ...params,
                    },
                }),
                providesTags: (result) =>
                    result?.courses
                        ? [
                              ...result.courses.map(({ _id }) => ({
                                  type: 'CoursesChildren' as const,
                                  id: _id,
                              })),
                              { type: 'CoursesChildren', id: 'LIST' },
                          ]
                        : [{ type: 'CoursesChildren', id: 'LIST' }],
            },
        ),
        addEditCourseChildren: builder.mutation<{}, AddEditCourseChildrenParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    ...params,
                },
            }),
            invalidatesTags: [{ type: 'CoursesChildren', id: 'LIST' }],
            onQueryStarted: async ({ fname }, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        fname === 'createCourse'
                            ? 'Módulo creado con éxito'
                            : 'Módulo actualizado con éxito',
                    )
                } catch (error) {
                    console.log(error)
                    toast.error('Error al crear módulo')
                }
            },
        }),
        deleteCourseChildren: builder.mutation<{}, DeleteCourseParams>({
            query: ({ course_id, entity_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteCourse',
                    entity_id,
                    course_id,
                },
            }),
            invalidatesTags: (_, __, { course_id }) => [
                { type: 'CoursesChildren', id: course_id },
            ],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Módulo eliminado con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar el módulo')
                }
            },
        }),
        getCoursesByCategory: builder.query<
            APIGetCoursesResponse,
            GetCoursesByCategoryParam
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getCourses',
                    type_of_course: 'main',
                    ...params,
                },
            }),
            providesTags: (result) =>
                result?.courses
                    ? [
                          ...result.courses.map(({ _id }) => ({
                              type: 'Courses' as const,
                              id: _id,
                          })),
                          { type: 'Courses', id: 'LIST' },
                      ]
                    : [{ type: 'Courses', id: 'LIST' }],
        }),
        addEditScheduledChild: builder.mutation<
            APIAddEditScheduledChildResponse,
            AddEditScheduledChildParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    ...params,
                },
            }),
            invalidatesTags: [{ type: 'ScheduledsChildren', id: 'LIST_CHILDREN' }],
            onQueryStarted: async (params, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        `El módulo ha sido ${
                            params.fname === 'createScheduled' ? 'creado' : 'actualizado'
                        } con éxito`,
                    )
                } catch (error) {
                    console.log(error)
                    toast.error(
                        `Error al ${
                            params.fname === 'createScheduled' ? 'crear' : 'actualizar'
                        } el módulo`,
                    )
                }
            },
        }),
        getItems: builder.query<APIGetItemsResponse, GetItemsParam>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getItems',
                    ...params,
                },
            }),
            providesTags: (result) =>
                result?.items
                    ? [
                          ...result.items.map(({ _id }) => ({
                              type: 'Items' as const,
                              id: _id,
                          })),
                          { type: 'Items', id: 'LIST' },
                      ]
                    : [{ type: 'Items', id: 'LIST' }],
        }),
        addEditItem: builder.mutation<{}, AddEditDeleteItemParams>({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    ...params,
                },
            }),
            invalidatesTags: [{ type: 'Items', id: 'LIST' }],
            onQueryStarted: async (params, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        `El temario ha sido ${
                            params.fname === 'createItem' ? 'creado' : 'actualizado'
                        } con éxito`,
                    )
                } catch (error) {
                    console.log(error)
                    toast.error(
                        `Error al ${
                            params.fname === 'createItem' ? 'crear' : 'actualizar'
                        } el temario`,
                    )
                }
            },
        }),
        deleteItem: builder.mutation<{}, DeleteItemParams>({
            query: ({ item, entity_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteItem',
                    entity_id,
                    item,
                },
            }),
            invalidatesTags: (_, __, { item }) => [{ type: 'Items', id: item }],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('El temario ha sido eliminado correctamente')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar el temario')
                }
            },
        }),
    }),
})

export const {
    useGetScheduledsQuery,
    useGetScheduledsChildrenQuery,
    useAddEditScheduledMutation,
    useAddEditScheduledChildMutation,
    useDeleteScheduledMutation,
    useGetCategoriesQuery,
    useAddEditCategoryMutation,
    useDeleteCategoryMutation,
    useGetCoursesQuery,
    useAddEditCourseMutation,
    useDeleteCourseMutation,
    useGetCoursesChildrenQuery,
    useAddEditCourseChildrenMutation,
    useDeleteCourseChildrenMutation,
    useGetCoursesByCategoryQuery,
    useGetItemsQuery,
    useAddEditItemMutation,
    useDeleteItemMutation,
} = coursesApi
