import { useCallback, useEffect, useMemo, useState } from 'react'
import type { TableColumn } from 'react-data-table-component'

import ContainerModal from '../../components/modals/ContainerModal'
import { DeleteCategory } from '../../components/modals/continental/admin/categories'
import { useAppSelector } from '../../hooks/useReduxHooks'
import { selectAuth, selectCourse } from '../../store/slices'
import { useGetCoursesByCategoryQuery } from '../../store/api'
import { Course, ModalOptions, Pagination } from '../../interfaces'
import { Button, MyTable, TableHeader } from '../../components'
import {
    AddEditCourse,
    DeleteCourse,
} from '../../components/modals/continental/admin/courses'
import PracticesPage from './PracticesPage'
import ModuleCourses2 from './ModuleCourses2'

interface ListCoursesPage2Props {
    showView: 'module' | 'evaluation' | 'none'
    setShowView: React.Dispatch<React.SetStateAction<'module' | 'evaluation' | 'none'>>
    // showViewCertificate: boolean
    setShowViewCertificate: React.Dispatch<React.SetStateAction<boolean>>
    setShowModalAddEditCategory: React.Dispatch<React.SetStateAction<ModalOptions>>
    // setDetailCourseCertificate: React.Dispatch<React.SetStateAction<Course>>
    setCurrentCourseId: React.Dispatch<React.SetStateAction<string>>
}

export const ListCoursesPage2 = ({
    showView,
    setShowView,
    // showViewCertificate,
    setShowViewCertificate,
    setShowModalAddEditCategory,
    setCurrentCourseId,
}: ListCoursesPage2Props) => {
    const { entity } = useAppSelector(selectAuth)
    const { currentCategory } = useAppSelector(selectCourse)
    const [showModalDeleteCourse, setShowModalDeleteCourse] = useState<boolean>(false)

    const [showModalAddEditCourse, setShowModalAddEditCourse] =
        useState<ModalOptions>('hide')
    const [pagination, setPagination] = useState<Pagination>({
        actualPage: 1,
        itemCount: 10,
        total: 0,
    })
    const [textQuery, setTextQuery] = useState<string>('')
    const [showModalDeleteCategory, setShowModalDeleteCategory] = useState<boolean>(false)
    const [currentCourse, setCurrentCourse] = useState<Course>()
    const [showOptions, setShowOptions] = useState<boolean>(false)

    const { data, isLoading, isFetching, refetch } = useGetCoursesByCategoryQuery({
        entity_id: entity,
        category_id: currentCategory?._id!,
        page: pagination.actualPage!,
        docs: pagination.itemCount!,
        query: {
            field: 'name',
            search: textQuery,
        },
    })

    const columns: TableColumn<Course>[] = useMemo(
        () => [
            {
                name: 'Imagen',
                center: true,
                cell: (row) => (
                    <img
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                        src={row.preview}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = 'https://via.placeholder.com/30'
                        }}
                        alt={row.name}
                        height="30px"
                        width="30px"
                    />
                ),
                // maxWidth: '550px',
            },
            {
                name: 'Título',
                selector: (row) => row.name,
                // wrap: true,
                // center: true,
                // maxWidth: '800px',
            },
            {
                name: 'Categoría',
                selector: (row) => row.category.name,
                wrap: true,
            },
            {
                name: 'Precio de matrícula',
                // wrap: true,
                selector: (row) => row.price.inscription,
                format: (row) => `S/. ${row.price.inscription}`,
                // wrap: true,
                // compact: true,
                // grow: 2,
            },
            {
                name: 'Precio de curso',
                selector: (row) => row.total_price || 0,
                format: (row) => `S/. ${row.total_price || 0}`,
                // grow: 2,
            },
            {
                name: 'Precio del certificado',
                selector: (row) => row.price.certificate,
                format: (row) => `S/. ${row.price.certificate}`,
                // grow: 2,
            },
            {
                name: 'Descripción',
                selector: (row) => row.description,
                // maxWidth: '1450px',
            },
        ],
        [],
    )

    const changeCertificateView = useCallback(() => {
        setShowViewCertificate(true)

        setCurrentCourseId(currentCourse?._id!)
    }, [setShowViewCertificate, setCurrentCourseId, currentCourse?._id])

    const openAddModal = useCallback(() => {
        setShowModalAddEditCourse('add')
    }, [])

    const RowOptions = useCallback(
        () => (
            <div
                className="multiedu__table--row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '1rem',
                    padding: '1rem 1rem 1rem 6rem',
                }}
            >
                <Button
                    onClick={() => setShowModalAddEditCourse('edit')}
                    text="Editar curso"
                />
                <Button
                    onClick={() => setShowModalDeleteCourse(true)}
                    text="Eliminar curso"
                />
                <Button
                    onClick={() => setShowView('evaluation')}
                    text="Configuración de promedios"
                />
                <Button onClick={() => setShowView('module')} text="Módulos" />
                <Button onClick={changeCertificateView} text="Certificados" />
            </div>
        ),
        [setShowView, changeCertificateView],
    )

    useEffect(() => {
        // console.log(data)
        // setSkip(false)
        if (data) {
            let { itemCount, ...newPagination } = data.pagination
            // console.log(newPagination)
            setPagination((c) => ({
                ...c,
                ...newPagination,
            }))
        }
    }, [data])

    useEffect(() => {
        setShowOptions(false)
        currentCategory?._id && setShowView('none')
    }, [currentCategory?._id, setShowView])

    if (showView === 'evaluation')
        return <PracticesPage setShowView={setShowView} course={currentCourse!} />

    if (showView === 'module')
        return <ModuleCourses2 setShowView={setShowView} course={currentCourse!} />

    return (
        <>
            <div className="title_right_courses">
                <div className="text_container_courses">
                    {currentCategory && (
                        <>
                            <span> {currentCategory.name} </span>

                            <div className="total_courses_text">
                                {currentCategory.description}
                            </div>
                        </>
                    )}
                </div>

                {currentCategory && (
                    <div className="menu_container_courses">
                        <i
                            className="fas fa-ellipsis-v"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShowOptions(!showOptions)}
                        />
                        <div className={`optSelectOption ${showOptions ? 'active' : ''}`}>
                            <div
                                className="onlyOpt"
                                onClick={() => setShowModalAddEditCategory('edit')}
                            >
                                Editar
                            </div>
                            {currentCategory._id !== '6233533f8c4570001fff55a3' && (
                                <div
                                    className="onlyOpt"
                                    onClick={() => setShowModalDeleteCategory(true)}
                                >
                                    Eliminar
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="body_right_courses">
                <div>
                    <TableHeader
                        title="Listado de cursos"
                        refetch={refetch}
                        handleShowAddModal={openAddModal}
                        setTextQuery={setTextQuery}
                        resetTable={currentCategory?._id}
                    />
                    <MyTable
                        data={data?.courses || []}
                        columns={columns}
                        isLoading={isLoading || isFetching}
                        activeItem={currentCourse}
                        setActiveItem={setCurrentCourse}
                        pagination={pagination}
                        setPagination={setPagination}
                        refetch={refetch}
                        expandableRowsComponent={RowOptions}
                        showPagination
                    />
                </div>
            </div>
            <ContainerModal showModal={showModalAddEditCourse !== 'hide'}>
                <AddEditCourse
                    showModal={showModalAddEditCourse}
                    setShowModal={setShowModalAddEditCourse}
                    course={currentCourse}
                    setCourse={setCurrentCourse}
                />
            </ContainerModal>
            <ContainerModal showModal={showModalDeleteCourse}>
                <DeleteCourse
                    courseId={currentCourse?._id!}
                    setShowModal={setShowModalDeleteCourse}
                />
            </ContainerModal>
            <ContainerModal showModal={showModalDeleteCategory}>
                <DeleteCategory
                    setShowModal={setShowModalDeleteCategory}
                    categoryId={currentCategory?._id!}
                />
            </ContainerModal>
        </>
    )
}
