import { IAction, IState } from './CoursesUserb2bInterface'
import { typesCoursesb2b } from './../types/typesCoursesb2b'

export const coursesUserb2bReducer = (state: IState, action: IAction): any => {
    switch (action.type) {
        case typesCoursesb2b.getCoursesb2b:
            return {
                ...state,
                coursesUserb2b: action.payload,
            }
        case typesCoursesb2b.createCoursesb2b:
            return {
                ...state,
                coursesUserb2b: [...state.coursesUserb2b, action.payload],
            }
        case typesCoursesb2b.updateCoursesb2b:
            return {
                ...state,
                coursesUserb2b: state.coursesUserb2b.map((data: any) => {
                    if (data._id === action.payload._id) {
                        return action.payload
                    } else {
                        return data
                    }
                }),
            }
        case typesCoursesb2b.deleteCoursesb2b:
            return {
                ...state,
                coursesUserb2b: state.coursesUserb2b.filter(
                    (data: any) => data._id !== action.payload,
                ),
            }
        default:
            return state
    }
}
