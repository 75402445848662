import { useDeleteCredentialMutation } from '../../../../../store/api'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

type DeleteCredentialProps = {
    setShowModalDelete: (value: boolean) => void
    idCredential: string
}
export const DeleteCredential = ({
    setShowModalDelete,
    idCredential,
}: DeleteCredentialProps) => {
    const [deleteCredential, { isLoading }] = useDeleteCredentialMutation()

    const deleteCategoryButton = () => {
        deleteCredential(idCredential).then(() => setShowModalDelete(false))
    }
    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalDelete(false)}
                />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar esta credencial?</p>
                {isLoading ? (
                    <div className="containerSpinner_center">
                        <button onClick={() => setShowModalDelete(false)} disabled>
                            Cancelar
                        </button>
                        <div className="containerSpinner">
                            <SpinnerModal />
                        </div>
                    </div>
                ) : (
                    <div>
                        <button onClick={() => setShowModalDelete(false)}>
                            Cancelar
                        </button>
                        <button className="saveButton" onClick={deleteCategoryButton}>
                            Aceptar
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
