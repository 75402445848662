import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { useDeleteUserByEntityMutation } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface DeleteUserProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    userId: string
}

export const DeleteUser = ({ setShowModal, userId }: DeleteUserProps) => {
    const { entity } = useAppSelector(selectAuth)

    const [deleteUser, { isLoading }] = useDeleteUserByEntityMutation()

    const closeModal = () => setShowModal(false)

    const handleDeleteUser = () => {
        deleteUser({
            user_id: userId,
            entity_id: entity,
        })
            .then(() => closeModal())
            .catch(console.log)
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i className="fas fa-times-circle" onClick={closeModal} />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar este usuario?</p>
                <div className="containerSpinner_center">
                    <button onClick={closeModal} disabled={isLoading}>
                        Cancelar
                    </button>
                    {isLoading ? (
                        <div className="containerSpinner">
                            <SpinnerModal />
                        </div>
                    ) : (
                        <button className="saveButton" onClick={handleDeleteUser}>
                            Aceptar
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
