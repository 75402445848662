import React from 'react'

export default function TypeMultiple({
    questiondata,
    setQuestionData,
    updateData,
    data,
    index,
}: any): JSX.Element {
    const { data_multi } = questiondata

    const addNewAlternative = () => {
        function randomId(): string {
            const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0]
            return uint32.toString(16)
        }
        const alternative = { _id: randomId(), correct: false, value: '' }
        setQuestionData({
            ...questiondata,
            data_multi: [...questiondata.data_multi, alternative],
        })
        if (updateData) {
            updateData(
                { ...data, alternatives: [...data.alternatives, alternative] },
                index,
            )
        }
    }

    const onRemoveAlternative = (id: string) => {
        setQuestionData({
            ...questiondata,
            data_multi: data_multi.filter((opt: any) => opt._id !== id),
        })
        if (updateData) {
            updateData(
                {
                    ...data,
                    alternatives: data.alternatives.filter((opt: any) => opt._id !== id),
                },
                index,
            )
        }
    }

    const onSetAlternative = (id: string, correct: any) => {
        const alternatives = data_multi.map((opt: any) => {
            if (opt._id === id) {
                opt.correct = !correct
                return opt
            } else {
                return opt
            }
        })

        setQuestionData({
            ...questiondata,
            data_multi: alternatives,
        })
        if (updateData) {
            updateData({ ...data, alternatives: alternatives }, index)
        }
    }

    const onChangeInputs = (id: string, e: any) => {
        const alternatives = data_multi.map((opt: any) => {
            if (opt._id === id) {
                opt.value = e.target.value
                return opt
            } else {
                return opt
            }
        })

        setQuestionData({
            ...questiondata,
            data_multi: alternatives,
        })
        if (updateData) {
            updateData({ ...data, alternatives: alternatives }, index)
        }
    }

    return (
        <div className="alternatives_content">
            {data_multi.length ? (
                data_multi.map((opt: any, key: number) => {
                    const { _id, correct, value } = opt

                    return (
                        <div key={key} className="alternatives_item">
                            <div>
                                <input
                                    className="input_question"
                                    placeholder="Escribe aqui..."
                                    autoFocus={true}
                                    onChange={(e) => onChangeInputs(_id, e)}
                                    value={value}
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => onRemoveAlternative(_id)}
                                >
                                    <span className="fa fa-trash"></span>
                                </button>
                            </div>
                            <div
                                className="choose"
                                onClick={() => onSetAlternative(_id, correct)}
                            >
                                <input id={_id} type="checkbox" checked={correct} />
                                <span>Marcar como opción correcta</span>
                            </div>
                        </div>
                    )
                })
            ) : (
                <div className="alternatives_message">
                    <h4>Aún no hay alternativas</h4>
                </div>
            )}

            <div className="alternatives_buttons">
                <button onClick={addNewAlternative}>
                    <span className="fas fa-plus"></span> Agregar Alternativa
                </button>
            </div>
        </div>
    )
}
