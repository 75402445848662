import React from 'react'
import './spinnerModal.sass'

const SpinnerModal = () => {
    return (
        <div className="lds-ringModal">
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
            <div className="four"></div>
        </div>
    )
}

export default SpinnerModal
