import { toast } from 'react-toastify'

import { axiosUserV5 } from '../../config/axios'

export const changePasswordService = async (password: string, token: string) => {
    let success: boolean = true

    await axiosUserV5({
        url: '/v5.0.1/api',
        method: 'post',
        data: {
            fname: 'resetPassword',
            password,
        },
        headers: { authorization: `Bearer ${token}` },
    })
        .then(({ data }) => {
            // console.log('DATAAAA!!!!: ', data)
        })
        .catch((e) => {
            success = false
            toast.error('Hubo un error inesperado')
        })

    return success
}

export const verifyTokenExpiration = async (token: string) => {
    let expired: boolean = false

    await axiosUserV5({
        url: '/v5.0.1/api',
        method: 'post',
        data: { fname: 'tokenIsValid' },
        headers: { authorization: `Bearer ${token}` },
    })
        .then(({ data }) => {
            // console.log('DATAAAA!!!!: ', data)
        })
        .catch((e) => {
            expired = true
            console.log('MIRALAAA: ', e, e.response)
            toast.error('Sesión expirada, ingrese nuevamente')
        })

    return expired
}
