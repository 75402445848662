import { typesScheduledCourses } from '../../context/types/typeScheduledCourses'
import { useContext, useEffect, useState } from 'react'
import { getScheduledCourses } from './../../context/sheduledCourses/ScheduledCoursesAction'
import { ScheduledCoursesContext } from '../../context/sheduledCourses/ScheduledCoursesProvider'
import { AparienciaContext } from './../../context/aparienciaContext/AparienciaProvider'
import { AuthContext } from '../../context/auth/AuthProvider'
import { RightStudentSide } from './itemInscribedPage/RightStudentSide'
import DetailStudent from './itemInscribedPage/DetailStudent'
import ReactTooltip from 'react-tooltip'

const InscribedUserPage = ({
    scheduledCourses,
    dispatch,
    categoryId,
    setCategoryId,
}: any) => {
    const { user }: any = useContext(AuthContext)

    const { stateScheduled, dispatch: dispatchScheduled } = useContext(
        ScheduledCoursesContext,
    )

    const { state: stateApariencia } = useContext(AparienciaContext)

    const [viewTable, setviewTable] = useState(true)

    const [detailStudent, setdetailStudent] = useState('')

    const { pagination } = stateScheduled

    useEffect(() => {
        const data = {
            entity_id: stateApariencia.currentIdEntity,
            docs: 20,
            page: 1,
            filter_by_name: '',
            sort: '',
            query: '',
            isEmpty: true,
        }
        getScheduledCourses(dispatchScheduled, data, user.token)
    }, [dispatchScheduled, stateApariencia.currentIdEntity, user.token])

    const handleOption = (res: any) => {
        setCategoryId(res.id)

        dispatch({
            type: typesScheduledCourses.currentScheduledCourses,
            payload: res,
        })

        setviewTable(true)
    }

    const showMore = () => {
        // console.log(pagination);

        const data = {
            entity_id: stateApariencia.currentIdEntity,
            docs: 20,
            page: pagination.nextPage,
            filter_by_name: '',
            sort: '',
            query: '',
            isEmpty: false,
        }
        getScheduledCourses(dispatchScheduled, data, user.token)
    }

    return (
        <>
            <div className="container_studentInscribedPage">
                <div className="headStudentInscribedPag">
                    <h3>Alumnos Inscritos</h3>
                </div>

                <div className="bodyStudentInscribedPag">
                    <div className="content_inscribedStudents">
                        <div className="courses_content_sidebar scroll">
                            <img src="/images/modules/Paper.png" alt="paper"></img>
                            <h3>Cursos, Diplomados</h3>

                            {scheduledCourses.length > 0 &&
                                scheduledCourses.map((res: any, index: number) => (
                                    <div
                                        className={
                                            categoryId === res.id
                                                ? `item_course active`
                                                : ' item_course '
                                        }
                                        key={res.id}
                                        onClick={() => handleOption(res)}
                                    >
                                        <div className="image_item">
                                            <div className="content_image_item">
                                                {index + 1}
                                            </div>
                                        </div>
                                        <div className="info_item">
                                            <div
                                                className="title_name"
                                                data-tip={res.name}
                                                data-for={res.name}
                                            >
                                                {res.name}
                                            </div>

                                            <div> {res.category_name} </div>
                                        </div>
                                        <div className="arrow_item">
                                            <i className="fas fa-chevron-right"></i>
                                        </div>
                                        <ReactTooltip id={res.name} />
                                    </div>
                                ))}
                            {pagination.hasNextPage && (
                                <div className="show-more">
                                    <span onClick={showMore}>Mostrar más</span>
                                </div>
                            )}
                        </div>

                        <div className="list_courses_students scroll">
                            {viewTable ? (
                                <RightStudentSide
                                    setviewTable={setviewTable}
                                    setdetailStudent={setdetailStudent}
                                />
                            ) : (
                                <DetailStudent
                                    detailStudent={detailStudent}
                                    setviewTable={setviewTable}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InscribedUserPage
