import { useCallback, useEffect, useMemo, useState } from 'react'
import type { TableColumn } from 'react-data-table-component'

import { MyTable, TableHeader, Button } from '../../components'

import ContainerModal from '../../components/modals/ContainerModal'
import { AddEditSede } from '../../components/modals/continental/admin/sedes/AddEditSede'
import { DeleteSede } from '../../components/modals/continental/admin/sedes/DeleteSede'
import { useAppSelector } from '../../hooks/useReduxHooks'

import type { Campus, ModalOptions, Pagination } from '../../interfaces'
import { useGetSedesQuery } from '../../store/api'
import { selectAuth } from '../../store/slices'

export const SedesPage2 = () => {
    const { entity } = useAppSelector(selectAuth)
    const [pagination, setPagination] = useState<Pagination>({
        actualPage: 1,
        itemCount: 10,
        total: 0,
    })
    const [skip, setSkip] = useState(true)
    const [textQuery, setTextQuery] = useState('')
    const [showModalAddEdit, setShowModalAddEdit] = useState<ModalOptions>('hide')
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)

    const [currentSede, setCurrentSede] = useState<Campus>()

    const columns: TableColumn<Campus>[] = useMemo(
        () => [
            // {
            //     name: 'Image',
            //     cell: (row) => (
            //         <img src={row.image} alt={row.name} height="50px" width="50px" />
            //     ),
            // },
            {
                name: 'Nombre',
                selector: (row) => row.name,
                sortable: true,
                // button: true,
            },
            {
                name: 'País',
                selector: (row) => row.country,
            },
            {
                name: 'Departamento',
                selector: (row) => row.department,
            },
            {
                name: 'Dirección',
                selector: (row) => row.address,
            },
        ],
        [],
    )

    const { data, isLoading, isFetching, refetch } = useGetSedesQuery(
        {
            entity: entity!,
            page: pagination.actualPage!,
            docs: pagination.itemCount!,
            query: {
                field: 'name',
                search: textQuery,
            },
        },
        {
            skip,
        },
    )

    const RowOptions = useCallback(
        () => (
            <div
                className="multiedu__table--row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                    padding: '1rem',
                }}
            >
                <Button
                    onClick={() => setShowModalAddEdit('edit')}
                    text="Actualizar sede"
                />
                <Button onClick={() => setShowModalDelete(true)} text="Eliminar sede" />
            </div>
        ),
        [],
    )

    const openAddModal = useCallback(() => setShowModalAddEdit('add'), [])

    useEffect(() => {
        // console.log(data)
        setSkip(false)
        if (data) {
            let { itemCount, ...newPagination } = data.pagination
            // console.log(newPagination)
            setPagination((c) => ({
                ...c,
                ...newPagination,
            }))
        }
    }, [data])

    return (
        <>
            <div className="container_RepositorioPage scroll">
                <div className="headUserPag">
                    <h3>Lista de Sedes</h3>
                </div>
                <div className="bodyUserPagCredential">
                    <div className="cardEntity_container">
                        <div className="cardEntity_body">
                            <TableHeader
                                title="Listado de sede"
                                refetch={refetch}
                                handleShowAddModal={openAddModal}
                                setTextQuery={setTextQuery}
                                // showFilter={false}
                            />
                            <MyTable
                                columns={columns}
                                data={data?.campus || []}
                                isLoading={isLoading || isFetching}
                                activeItem={currentSede}
                                setActiveItem={setCurrentSede}
                                pagination={pagination}
                                setPagination={setPagination}
                                refetch={refetch}
                                expandableRowsComponent={RowOptions}
                                showPagination
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ContainerModal showModal={showModalAddEdit !== 'hide'}>
                <AddEditSede
                    setShowModal={setShowModalAddEdit}
                    currentSede={currentSede}
                    showModal={showModalAddEdit}
                />
            </ContainerModal>
            <ContainerModal showModal={showModalDelete}>
                <DeleteSede
                    setShowModalDelete={setShowModalDelete}
                    idSede={currentSede?._id!}
                />
            </ContainerModal>
        </>
    )
}
