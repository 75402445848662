import { Control, useFieldArray } from 'react-hook-form'

import { FormQuestion } from '../modalsQuestion/QuestionAddEdit'

interface TypeBooleanProps {
    control: Control<FormQuestion, any> | undefined
    isLoading: boolean
}

export const TypeBoolean = ({ control, isLoading }: TypeBooleanProps) => {
    const { fields, replace } = useFieldArray({
        control,
        name: 'alternatives',
    })

    const onSetAnswer = (index: number) => () => {
        const newFields = fields.map((alternative, idx) => ({
            ...alternative,
            correct: idx === index ? true : false,
        }))
        replace(newFields)
    }

    return (
        <div className="alternatives_content">
            {fields.map((field, index) => (
                <div key={index} className="alternatives_item">
                    <div>
                        <h4>{field.value}</h4>
                    </div>
                    <div>
                        <input
                            id={field.id}
                            type="radio"
                            checked={field.correct}
                            onChange={onSetAnswer(index)}
                            required
                            disabled={isLoading}
                        />
                        <label
                            htmlFor={field.id}
                            style={{
                                fontWeight: 'normal',
                                margin: 0,
                                cursor: 'pointer',
                            }}
                        >
                            El enunciado es {field.value}
                        </label>
                    </div>
                </div>
            ))}
        </div>
    )
}
