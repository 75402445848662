import { Question } from '../../../../../interfaces'

interface QuestionDetailProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    currentQuestion: Question
}
export const QuestionDetail = ({
    setShowModal,
    currentQuestion,
}: QuestionDetailProps) => {
    return (
        <div className="main_ModalCoursesScheduled">
            <div className="headModalCoursesScheduled">
                <h3>Detalles de Pregunta</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal(false)} />
            </div>
            <div className="bodyModalCoursesScheduled">
                <div className="view_question">
                    <div className="container_question">
                        <div className="half">
                            <label>Nivel de Dificultad:</label>
                            <label>
                                {currentQuestion.nivel === 'easy'
                                    ? 'Fácil'
                                    : currentQuestion.nivel === 'medium'
                                    ? 'Normal'
                                    : 'Difícil'}
                            </label>
                        </div>
                        <div className="half">
                            <label>Tipo de Pregunta:</label>
                            <label>
                                {currentQuestion.type === 'unique'
                                    ? 'Opción única'
                                    : currentQuestion.type === 'multiple'
                                    ? 'Selección múltiple'
                                    : currentQuestion.type === 'boolean'
                                    ? 'Verdadero o Falso'
                                    : currentQuestion.type === 'short'
                                    ? 'Pregunta corta'
                                    : currentQuestion.type === 'large'
                                    ? 'Pregunta larga'
                                    : 'Pregunta con archivos'}
                            </label>
                        </div>
                    </div>
                    <div className="container_question">
                        <label>Pregunta:</label>
                        <label className="question">{currentQuestion.title}</label>
                    </div>
                    <div className="container_question">
                        <label>Descripción:</label>
                        <div
                            className="question_view"
                            dangerouslySetInnerHTML={{
                                __html: currentQuestion.question,
                            }}
                        ></div>
                    </div>
                    <div className="container_question">
                        <label>Alternativas:</label>
                        <div className="alternatives_view">
                            <ul>
                                {currentQuestion.alternatives?.map((alternative) => (
                                    <li key={alternative._id}>
                                        <i
                                            className={
                                                alternative.correct === true
                                                    ? 'fas fa-check-square'
                                                    : 'far fa-square'
                                            }
                                        />
                                        {alternative.value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
