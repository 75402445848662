import React, { createContext, useReducer } from 'react'
import { CancelTokenSource } from 'axios'

import { IState } from './RepositoryInterface'
import { repositoryReducer } from './RepositoryReducer'

const initialState: IState = {
    loading: false,
    files: [],
    uploadProgressMaterial: 0,
    cancelToken: {} as CancelTokenSource,
}

export const RepositoryContext = createContext<IState | any>(initialState)

export const RepositoryProvider = (props: any) => {
    const [state, dispatch] = useReducer<any>(repositoryReducer, initialState)

    return (
        <RepositoryContext.Provider value={{ state, dispatch }}>
            {props.children}
        </RepositoryContext.Provider>
    )
}
