import { typeUserb2b } from '../types/typeUserb2b'
import { axiosUserV5 } from './../../config/axios'

// Nuevo getUser
export const getUser = async (token: string, obj: object, cancelToken: any) => {
    return await axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
        cancelToken: cancelToken,
    })
}

// Fin Nuevo getUser

export const getUserb2b = async (
    token: string,
    obj: object,
    dispatch: any,
    setpagination?: any,
    cancelToken?: any,
) => {
    return await axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
        cancelToken: cancelToken,
    })
        .then(({ data }: any) => {
            let array: any[] = []
            console.log(data)
            data.users.map((dat: any) =>
                dat
                    ? array.push({
                          _id: dat.user._id,
                          name: dat.user.name,
                          lastname: dat.user.lastname,
                          email: dat.user.email,
                          photo: dat.user.photo.includes('https://')
                              ? dat.user.photo
                              : 'https://livewellwithstacy.com/wp-content/uploads/2018/04/avtar.png',
                          phone: dat.user.phone,
                          description: dat.user.description ? dat.user.description : '',
                          address: dat.user.address,
                          dni: dat.user.dni,
                          rol: dat.user.rol,
                          roles: dat.roles ? dat.roles : [],
                      })
                    : null,
            )
            dispatch({
                type: typeUserb2b.getUsersb2b,
                payload: array,
            })

            if (setpagination) {
                setpagination(data.pagination)
            }
        })
        .catch((e) => {
            console.log(e)
            console.log(e.response)
        })
}

export const createUserb2b = async (obj: any, token: string) => {
    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const updateUserb2b = async (obj: any, token: string) => {
    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const deleteUserb2b = async (id: string, token: string, idEntity: string) => {
    return axiosUserV5({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'deleteUserByEntity',
            user_id: id,
            entity_id: idEntity,
        },
    })
}
