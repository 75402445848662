import React, { useState } from 'react'
import SubItemPermissions from './SubItemPermissions'

const ItemPermissions = ({ data }: any) => {
    const [showOption, setShowOption] = useState<boolean>(false)

    return (
        <>
            <div className="titleOption">
                <div className="left_title">
                    {data.name}
                    <div
                        onClick={() => setShowOption(!showOption)}
                        className="icon_dropdown"
                    >
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>

                <div>{data.check}</div>
            </div>

            <div className="option_body">
                {showOption ? (
                    <>
                        <ul className="sections">
                            {data.detailPermission
                                ? data.detailPermission.map((res: any, key: number) => (
                                      <SubItemPermissions data={res} key={key} />
                                  ))
                                : data.options.map((dat: any, key: number) => (
                                      <div className="section_item" key={key}>
                                          <div>{dat.name}</div>

                                          {dat.check()}
                                      </div>
                                  ))}
                        </ul>
                    </>
                ) : null}
            </div>
        </>
    )
}

export default ItemPermissions
