import React, { useEffect, useState } from 'react'
import TableComponent from './../../../../../components/general/table/table'
interface Props {
    setshowSelectQuestion: any
    setarrayIds: any
    arrayIds: any
    data: any
    arraySelect: any
    setarraySelect: any
}
const QuestionsSelect = ({
    setshowSelectQuestion,
    arrayIds,
    setarrayIds,
    data,
    arraySelect,
    setarraySelect,
}: Props) => {
    const [dataQuestions, setdataQuestions] = useState<any[]>([])
    const [dataTable, setdataTable] = useState<any[]>([])
    const [dataSelect, setdataSelect] = useState<any>({})
    // const resultado = arraySelect.find((data: any) => data.title === 'pregunta opción única 12/10 - 2') ? true : false;
    // console.log(resultado)

    const columns = [
        {
            title: '',
            button: true,
            cell: (row: any) => (
                <div className="menu_select_table">
                    <i
                        className={
                            arraySelect.find((data: any) => data._id === row.data._id)
                                ? 'fas fa-check-square'
                                : 'far fa-square'
                        }
                        onClick={() => handleChange(row.data)}
                    ></i>
                </div>
            ),
        },
        {
            title: 'Pregunta',
            tagname: 'title',
            // cell: (row: any)=> <div dangerouslySetInnerHTML={{__html: row.data.question}}></div>
        },
        {
            title: 'tipo de Pregunta',
            button: true,
            cell: (row: any) => (
                <>
                    {' '}
                    {row.data.type === 'unique'
                        ? 'Respuesta Unica'
                        : row.data.type === 'multiple'
                        ? 'Respuesta Multiple'
                        : row.data.type === 'boolean'
                        ? 'Verdadero o Falso'
                        : row.data.type === 'short'
                        ? 'Respuesta Corta'
                        : row.data.type === 'large'
                        ? 'Respuesta Larga'
                        : 'Respuesta con Archivo'}{' '}
                </>
            ),
        },
        {
            title: 'Nivel de dificultad',
            button: true,
            cell: (row: any) => (
                <>
                    {' '}
                    {row.data.nivel === 'easy'
                        ? 'Facil'
                        : row.data.nivel === 'medium'
                        ? 'Normal'
                        : 'Dificil'}{' '}
                </>
            ),
        },
        // {
        //     title: '',
        //     button: true,
        //     cell: (row: any) => (
        //         <button
        //             className="btn_Page_Evaluations"
        //             onClick={() => openModal(row.data)}
        //         >
        //             Ver más
        //         </button>
        //     ),
        // },
    ]
    useEffect(() => {
        // console.log(arrayIds);
        setdataTable(data)
        setdataQuestions(data)
        let objeto: any = {}
        data.map((dat: any) => {
            var exis = arrayIds.includes(dat._id)
            if (exis) {
                return (objeto[dat._id] = true)
            } else {
                return (objeto[dat._id] = false)
            }
        })
        // console.log(objeto);

        setdataSelect(objeto)
    }, [data, arrayIds])

    // const openModal = (data: any) => {
    // setmodalshow(true)
    // setselectQuestion(data)
    // }
    const handleChange = (d: any) => {
        setdataSelect({ ...dataSelect, [d._id]: !dataSelect[d._id] })
        let array = arrayIds
        let arrayS = arraySelect
        var exis = array.includes(d._id)
        if (exis === true) {
            var index = array.indexOf(d._id)
            array.splice(index, 1)
        } else {
            array.push(d._id)
        }

        let question = arrayS.find((dat: any) => dat._id === d._id)
        if (question) {
            // console.log('existe');

            arrayS = arrayS.filter((dat: any) => dat._id !== d._id)
        } else {
            // console.log('no existe');
            // console.log(d);
            arrayS.push({ ...d, questionbank: true })
        }
        setarrayIds(array)
        // console.log(array);
        setarraySelect(arrayS)
        // console.log(arrayS)
    }

    const filterElement = (value: string) => {
        var search = dataQuestions.filter((item: any) => {
            if (item.title?.toLowerCase().includes(value)) {
                return item
            } else {
                return null
            }
        })
        setdataTable(search)
    }
    return (
        <div>
            <TableComponent
                Butoon={() => (
                    <button
                        className="btn_Page_Evaluations"
                        onClick={() => setshowSelectQuestion(false)}
                    >
                        Terminar
                    </button>
                )}
                filterElement={filterElement}
                columns={columns}
                data={dataTable}
            ></TableComponent>
        </div>
    )
}

export default QuestionsSelect
