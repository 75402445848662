import {
    IActionScheduledCourses,
    IStateScheduledCourses,
} from './ScheduledCoursesInterface'
import { typesScheduledCourses } from './../types/typeScheduledCourses'

export const scheduledCoursesReducer = (
    state: IStateScheduledCourses,
    action: IActionScheduledCourses,
): any => {
    switch (action.type) {
        case typesScheduledCourses.setSelectScheduled:
            // console.log(action.payload);

            return {
                ...state,
                selectScheduled: action.payload,
            }
        case typesScheduledCourses.getScheduledCourses:
            return {
                ...state,
                scheduledCourses: [...action.payload.array],
                pagination: action.payload.pagination,
            }
        case typesScheduledCourses.addMoreScheduledCourses:
            return {
                ...state,
                scheduledCourses: state.scheduledCourses.concat(action.payload.array),
                pagination: action.payload.pagination,
            }
        case typesScheduledCourses.createScheduledCourses:
            return {
                ...state,
                scheduledCourses: [...state.scheduledCourses, action.payload],
            }
        case typesScheduledCourses.editScheduledCourses:
            return {
                ...state,
                scheduledCourses: state.scheduledCourses.map((data: any) => {
                    if (data.id === action.payload.id) {
                        return {
                            ...data,
                            ...action.payload,
                        }
                    } else {
                        return data
                    }
                }),
            }
        case typesScheduledCourses.deletegetScheduledCourses:
            return {
                ...state,
                scheduledCourses: state.scheduledCourses.filter(
                    (data: any) => data.id !== action.payload,
                ),
            }

        case typesScheduledCourses.currentScheduledCourses:
            return {
                ...state,
                currentScheduledCourses: action.payload,
            }

        default:
            return state
    }
}
