interface Route {
    name: string
    path: string
    icon: string
}

interface ListRoutes {
    rol: string
    routes: Route[]
}

export const listRoutes: ListRoutes[] = [
    {
        rol: 'superadmin',
        routes: [
            // {
            //     name: 'Dashboard',
            //     path: '/superadmin/dashboard/home',
            //     icon: 'fas fa-chart-bar',
            // },
            {
                name: 'Tickets',
                path: '/superadmin/dashboard/tickets',
                icon: 'fas fa-ticket-alt',
            },
            {
                name: 'Entidades',
                path: '/superadmin/dashboard/entities',
                icon: 'fas fa-building',
            },
            {
                name: 'Usuarios',
                path: '/superadmin/dashboard/users',
                icon: 'fas fa-users',
            },
            {
                name: 'Métricas',
                path: '/superadmin/dashboard/metrics',
                icon: 'fas fa-chart-area',
            },
        ],
    },
    {
        rol: 'admin_entity',
        routes: [
            {
                name: 'Inicio',
                path: '/dashboard/user/home',
                icon: 'fas fa-home',
            },
            {
                name: 'Perfil',
                path: '/dashboard/user/profile',
                icon: 'fas fa-users',
            },
            {
                name: 'Entidades',
                path: '/dashboard/user/entity',
                icon: 'fas fa-building',
            },
        ],
    },
    {
        rol: 'user',
        routes: [
            {
                name: 'Inicio',
                path: '/dashboard/user/home',
                icon: 'fas fa-home',
            },
            {
                name: 'Perfil',
                path: '/dashboard/user/profile',
                icon: 'fas fa-users',
            },
            {
                name: 'Entidades',
                path: '/dashboard/user/entity',
                icon: 'fas fa-building',
            },
        ],
    },
    {
        rol: 'admin b2b',
        routes: [
            // {
            //     name: 'Dashboard',
            //     path: '/dashboard/admin/home',
            //     icon: 'fas fa-chart-bar',
            // },
            {
                name: 'Programas',
                path: '/dashboard/admin/courses',
                icon: 'fas fa-book',
            },
            {
                name: 'Usuarios',
                path: '/dashboard/admin/users',
                icon: 'fas fa-user-plus',
            },
            {
                name: 'Sedes',
                path: '/dashboard/admin/sedes',
                icon: 'fas fa-map-marked-alt',
            },
            {
                name: 'Repositorio',
                path: '/dashboard/admin/repository',
                icon: 'fas fa-folder',
            },
            {
                name: 'Credenciales',
                path: '/dashboard/admin/credentials',
                icon: 'fas fa-key',
            },
            // {
            //     name: 'Tarjetas',
            //     path: '/dashboard/admin/cards',
            //     icon: 'far fa-credit-card',
            // },
            {
                name: 'Mi Entidad',
                path: '/dashboard/admin/my-entity',
                icon: 'fas fa-building',
            },
        ],
    },
    {
        rol: 'admin',
        routes: [
            // {
            //     name: 'Dashboard',
            //     path: '/dashboard/admin/home',
            //     icon: 'fas fa-chart-bar',
            // },
            {
                name: 'Programas',
                path: '/dashboard/admin/courses',
                icon: 'fas fa-book',
            },
            {
                name: 'Usuarios',
                path: '/dashboard/admin/users',
                icon: 'fas fa-user-plus',
            },
            {
                name: 'Sedes',
                path: '/dashboard/admin/sedes',
                icon: 'fas fa-map-marked-alt',
            },
            {
                name: 'Repositorio',
                path: '/dashboard/admin/repository',
                icon: 'fas fa-folder',
            },
            {
                name: 'Credenciales',
                path: '/dashboard/admin/credentials',
                icon: 'fas fa-key',
            },
            // {
            //     name: 'Tarjetas',
            //     path: '/dashboard/admin/cards',
            //     icon: 'far fa-credit-card',
            // },
            {
                name: 'Mi Entidad',
                path: '/dashboard/admin/my-entity',
                icon: 'fas fa-building',
            },
            // {
            //     name: 'Chat',
            //     path: '/dashboard/admin/chat',
            //     icon: 'fas fa-comments',
            // },
        ],
    },
    {
        rol: 'coordinador',
        routes: [
            {
                name: 'Cursos',
                path: '/dashboard/coordinator/courses',
                icon: 'fas fa-book',
            },
            {
                name: 'Evaluaciones',
                path: '/dashboard/coordinator/evaluations',
                icon: 'fas fa-file-alt',
            },
        ],
    },
    {
        rol: 'counter',
        routes: [
            {
                name: 'Inscritos',
                path: '/dashboard/accountant/students/inscribed',
                icon: 'fas fa-user-check',
            },
            {
                name: 'Invitados',
                path: '/dashboard/accountant/students/invited',
                icon: 'fas fa-paper-plane',
            },
            // {
            //     name: 'Vouchers',
            //     path: '/dashboard/accountant/vouchers',
            //     icon: 'fas fa-receipt',
            // },
            // {
            //     name: 'Historial',
            //     path: '/dashboard/accountant/history',
            //     icon: 'fas fa-globe-americas',
            // },
        ],
    },
]
