import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import DetailStudent from '../../pages/counter/itemInscribedPage/DetailStudent'
import VouchersPage from '../../pages/counter/VouchersPage'
import InscribedUserPage from './../../pages/counter/InscribedUserPage'
import InvitedUserPage from './../../pages/counter/InvitedUserPage'
import { RightStudentSide } from './../../pages/counter/itemInscribedPage/RightStudentSide'
import { useEffect } from 'react'
import { useContext } from 'react'
import { ScheduledCoursesContext } from '../../context/sheduledCourses/ScheduledCoursesProvider'
import { typesScheduledCourses } from '../../context/types/typeScheduledCourses'
import { useState } from 'react'
import HistoryPage from '../../pages/counter/HistoryPage'

const AccountantRoutes = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/students/inscribed`} component={AccountComponent} />
            <Route exact path={`${path}/students/invited`} component={InvitedUserPage} />
            <Route exact path={`${path}/vouchers`} component={VouchersPage} />
            <Route exact path={`${path}/history`} component={HistoryPage} />
            {/* <Route exact path={`${path}/students/inscribed/:id`} component={DetailStudent} /> */}
            <Redirect exact path={`/*`} to={`${path}/students/inscribed`} />
        </Switch>
    )
}

export default AccountantRoutes

export const AccountComponent = () => {
    const { path } = useRouteMatch()

    const { stateScheduled, dispatch } = useContext(ScheduledCoursesContext)

    const { scheduledCourses } = stateScheduled

    const [categoryId, setCategoryId] = useState('')

    useEffect(() => {
        if (scheduledCourses.length > 0) {
            dispatch({
                type: typesScheduledCourses.currentScheduledCourses,
                payload: scheduledCourses[0],
            })
            setCategoryId(scheduledCourses[0].id)
        }
    }, [dispatch, scheduledCourses])

    return (
        <InscribedUserPage
            scheduledCourses={scheduledCourses}
            dispatch={dispatch}
            setCategoryId={setCategoryId}
            categoryId={categoryId}
        >
            <Switch>
                <Route exact path={`${path}`} component={RightStudentSide} />
                <Route exact path={`${path}/list/:id`} component={DetailStudent} />
                <Redirect exact path={`/*`} to={`${path}`} />
            </Switch>
        </InscribedUserPage>
    )
}
