import { createContext, useCallback, useContext, useEffect, useReducer } from 'react'
import { getCoursesUserb2b } from './CoursesUserb2bAction'
import { IState } from './CoursesUserb2bInterface'
import { coursesUserb2bReducer } from './CoursesUserb2bReducer'
import { typesCoursesb2b } from './../types/typesCoursesb2b'
import { AuthContext } from './../auth/AuthProvider'
import { useHistory } from 'react-router-dom'
import { AparienciaContext } from './../aparienciaContext/AparienciaProvider'
import { typeUi } from './../types/typesui'
import { types } from './../types/types'

const initialState: IState = {
    coursesUserb2b: [],
}

export const CoursesUserb2bContext = createContext<IState | any>(initialState)

export const CoursesUserb2bProvider = (props: any) => {
    const history = useHistory()

    const [state, dispatch] = useReducer<any>(coursesUserb2bReducer, initialState)

    const { user, dispatch: dispatchAuth } = useContext(AuthContext)

    const { state: stateApariencia, dispatchImg } = useContext(AparienciaContext)

    const getCoursesPlaytec = useCallback(
        (dispatch) => {
            getCoursesUserb2b(user.token, stateApariencia.currentIdEntity)
                .then(({ data }) => {
                    let array: any[] = []

                    data.courses.map((dat: any) =>
                        dat
                            ? dat.alias === 'programa' &&
                              array.push({
                                  id: dat._id,
                                  name_course: dat.name,
                                  price: dat.price,
                                  description: dat.description,
                                  category: dat.category.name,
                                  preview: dat.preview,
                              })
                            : null,
                    )

                    dispatch({
                        type: typesCoursesb2b.getCoursesb2b,
                        payload: array,
                    })
                })
                .catch((e) => {
                    console.log(e.response)

                    if (
                        e.response &&
                        e.response.status === 401 &&
                        e.response.statusText === 'Unauthorized'
                    ) {
                        dispatchAuth({
                            type: types.logout,
                        })
                        dispatchImg({
                            type: typeUi.removeTheme,
                        })
                    }
                })
        },
        [user.token, stateApariencia.currentIdEntity, dispatchImg, dispatchAuth],
    )

    useEffect(() => {
        if (props.isAuthenticated !== false) {
            // getCoursesPlaytec(dispatch)
        } else {
            history.push('./login')
        }
    }, [getCoursesPlaytec, props.isAuthenticated, history])

    return (
        <CoursesUserb2bContext.Provider value={{ state, dispatch }}>
            {props.children}
        </CoursesUserb2bContext.Provider>
    )
}
