// export const AUTH_URL = "https://auth-playtec-v4.herokuapp.com"

export const AUTH_URL = 'https://auth-playtec-v4.herokuapp.com'

export const USER_URL = 'https://auth-playtec-v4.herokuapp.com'

// export const USER_URL_V5 = "http://18.205.231.226:3000"

// export const USER_URL_V5 = "http://3.21.18.85:3000/"

export const USER_URL_V5 = 'https://users.multiedu.la/'

export const COURSE_URL = 'https://courses.multiedu.la/'

// export const COURSE_URL = "http://3.143.184.22:3000/"

// export const MATERIAL_URL = "https://materials.multiedu.la/"
// export const MATERIAL_URL = "http://3.12.183.203:3000/"
export const MATERIAL_URL = 'https://materials.multiedu.la/'

// export const MATERIAL_URL = "http://54.157.239.164:3000/"

export const PROGRAMS_URL = 'https://courses.multiedu.la'

// export const PROGRAMS_URL = "http://3.143.184.22:3000/"

// export const PROGRAMS_URL = "http://54.163.147.81:3000/ "

export const ENTITIES_URL = 'https://entities.multiedu.la/'

// export const ENTITIES_URL = "http://3.130.99.188:3000/"

export const STUDENT_URL = 'https://students.multiedu.la/'

// export const STUDENT_URL = "http://3.143.119.113:3000/"

export const EVALUATIONS_URL = 'https://evaluations.multiedu.la/'

// export const EVALUATIONS_URL = "http://18.116.220.211:3000/"

export const TINY_API_KEY = 'omih8rkbmia8kuopv2zdepk6eur13qgvarg2jgmayvfcfe7f'
