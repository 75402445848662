import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ProfilePage from '../../pages/userB2B/ProfilePage'
import SettingsPage from './../../pages/userB2B/SettingsPage'
import PasswordPage from './../../pages/userB2B/PasswordPage'
// import React from 'react'
import SidebarProfile from './../../components/container/SidebarProfile'

const ProfileRoutes = () => {
    const { path } = useRouteMatch()

    return (
        <SidebarProfile>
            <Switch>
                <Route exact path={`${path}/user`} component={ProfilePage} />
                <Route exact path={`${path}/password`} component={PasswordPage} />
                <Route exact path={`${path}/settings`} component={SettingsPage} />
                <Redirect exact path={`/*`} to={`${path}/user`} />
            </Switch>
        </SidebarProfile>
    )
}

export default ProfileRoutes
