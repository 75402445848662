// import React, { useContext, useState } from 'react'
import React, { useContext, useState } from 'react'
// import { UserContext } from '../../context/user/UserProvider';
// import FormUserB2B from '../forms/FormUserB2B';
import { createUsers } from './../../context/superAdminUser/UserActions'
import FormLinkInvitation from './../forms/FormLinkInvitation'
import { types } from './../../context/types/types'
import { UserContext } from '../../context/superAdminUser/UserProvider'

const ModalCreateUsers = ({ stateModal, closeModal }: any) => {
    const { dispatch, state } = useContext(UserContext)

    const { allUsers } = state

    const [loading, setLoading] = useState(false)

    const [modal, setModal] = useState(false)

    const onSumbitData = async (e: any, dataUser: any) => {
        e.preventDefault()
        const token = JSON.parse(localStorage.getItem('user')!).token
        setLoading(true)
        try {
            const finalData = {
                fname: 'preRegister',
                service: 'users',
                ...dataUser,
            }

            createUsers(finalData, token)
                .then(({ data }) => {
                    // console.log(data)
                    let completed: any = [...allUsers.completed]
                    let incomplete: any = [...allUsers.incomplete]
                    let inProcess: any = [...allUsers.inProcess]

                    if (data.user.register === 'incomplete') {
                        incomplete = [...incomplete, data.user]
                    } else if (data.user.register === 'inProcess') {
                        inProcess = [...inProcess, data.user]
                    } else {
                        completed = [...completed, data.user]
                    }

                    dispatch({
                        type: types.createUser,
                        payload: {
                            incomplete,
                            inProcess,
                            completed,
                        },
                    })
                })
                .catch((e) => {
                    console.log(e.response)
                })
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    if (stateModal === false) {
        // console.log(statseModal)
        return null
    }

    return (
        <div className="modalUsers">
            <div
                className="modal-content_user"
                style={modal ? { height: '85%' } : { height: '45%' }}
            >
                <div className="modal-header_user">
                    Crear Usuario
                    <i
                        className="fas fa-times close"
                        onClick={() => {
                            setModal(false)
                            closeModal()
                        }}
                    ></i>
                </div>
                <div className="modal-body_user">
                    <FormLinkInvitation
                        onSumbitData={onSumbitData}
                        loading={loading}
                        setModal={setModal}
                        closeModal={closeModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default ModalCreateUsers
