import React from 'react'
import CommingSoon from '../../components/global/comming/CommingSoon'

const MetricasPage = () => {
    return (
        <div>
            {/* MetricasPage             */}
            <CommingSoon></CommingSoon>
        </div>
    )
}

export default MetricasPage
