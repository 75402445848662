import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import SpinnerModal from './../../../../general/spinner/SpinnerModal'
import {
    getStudentCounter,
    updateStudentCounter,
} from '../../../../../context/userCounter/UserCounterAction'
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import { UserCounterContext } from '../../../../../context/userCounter/UserCounterProvider'
import './userCounter.sass'
// import Notification, { type } from "../../../../general/toast/Toast"

//--------------- Generales -------------

type ChangeEvent =
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>

type FormEvent = React.FormEvent<HTMLFormElement>

type target = {
    name: string
    value: string
}

// interface Data {
//     dni: string,
//     phone: string,
//     name: string,
//     firstlastname: string,
//     secondlastname: string,
//     address: string,
//     // interestCourse: string,
//     // curso: string,
//     // username: string,
//     // password: string,
//     // course_id: string
// }

//----------------------------------------

export const EditUser = ({ setShowModalEdit, data, setpagination }: any): JSX.Element => {
    // console.log('data user',data)

    const { address, dni, name_student, lastname, phone, photo, id, roles } = data

    const [loading, setLoading] = useState<boolean>(false)

    const { state: stateApariencia } = useContext(AparienciaContext)

    const { dispatch } = useContext(UserCounterContext)

    const [dat, setData] = useState<any>({
        dni: dni,
        phone: phone,
        name: name_student,
        lastname: lastname,
        address: address,
        rol: roles.map((res: any) => {
            return res.rol._id
        }),
    })

    const [imgPhoto, setPhoto] = useState<any>(photo)

    const [preview, setPreview] = useState<string>('')

    const changeImage = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e
                // console.log(imgPhoto)
                setPhoto(imgPhoto)
                setPreview(URL.createObjectURL(e))
            }
        }
    }

    const changeData = (e: ChangeEvent, number?: boolean): void => {
        const { name, value }: target = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setData({
                    ...dat,
                    [name]: value,
                })
            }
        } else {
            setData({
                ...dat,
                [name]: value,
            })
        }
    }

    const valueImage = (e: any) => {
        e.target.value = ''
    }

    const submitEdit = async (e: FormEvent) => {
        e.preventDefault()

        // console.log(dat.rol)

        const token = JSON.parse(localStorage.getItem('user')!).token

        // console.log(imgPhoto)
        const datan: FormData = new FormData()
        datan.append('fname', 'updateUser')
        datan.append('user', id)
        datan.append('name', dat.name)
        datan.append('lastname', dat.lastname)
        datan.append('email', data.email)
        datan.append('phone', dat.phone)
        datan.append('entity', stateApariencia.currentIdEntity)
        if (imgPhoto !== {}) {
            datan.append('photo', imgPhoto)
        }
        datan.append('dni', dat.dni)
        datan.append('address', dat.address)
        datan.append('roles', JSON.stringify(dat.rol))

        setLoading(true)

        await updateStudentCounter(token, datan)
            .then((data) => {
                // console.log(data)

                // Notification(type.success, "Se actualizó el usuario correctamente.")
                toast.success('Se actualizó el usuario correctamente.')

                const payload = {
                    fname: 'getUsersByEntity',
                    entity_id: stateApariencia.currentIdEntity,
                    roles: ['estudiante'],
                    page: 1,
                    query: {
                        field: 'name_student',
                        search: '',
                    },
                    sortNameNumber: 1,
                    docs: 25,
                    descendant: false,
                }

                getStudentCounter(token, payload, dispatch, setpagination)
            })

            .catch((err: any) => {
                const error = err.response.data.message
                // Notification(type.danger, error ? error : "Hubo un error, no se actualizó el usuario.")
                toast.error(error ? error : 'Hubo un error, no se actualizó el usuario.')
            })

        setLoading(false)
    }

    return (
        <div className="main_searchStudent">
            <div className="headSearchStudent">
                <i
                    className="fas fa-times"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowModalEdit(false)}
                />
            </div>
            <div className="bodySearchStudent scroll">
                <div className="contentSearch">
                    <div
                        className="back_button"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowModalEdit(false)}
                    >
                        <i className="fas fa-long-arrow-alt-left"></i>
                        <span>Regresar</span>
                    </div>

                    <h2>Editar Estudiante</h2>
                </div>

                <div className="containerBody">
                    <div className="content_add_user">
                        {/* <div className="text_container">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum ipsum, facilis rem corporis temporibus nulla veniam ducimus ullam quod non vitae nemo quibusdam voluptatum. Modi harum architecto saepe delectus at.</p>
                        </div> */}

                        <div className="bodyAddUser">
                            <div className="contPhotoUser">
                                <div className="contImgUser">
                                    <label
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '1rem',
                                        }}
                                    >
                                        {preview ? (
                                            <img
                                                src={preview}
                                                alt="ImagenDef"
                                                className="imgDeforView"
                                            />
                                        ) : photo && photo.includes('https://') ? (
                                            <img
                                                src={photo}
                                                alt="ImagenDef"
                                                className="imgDeforView"
                                            />
                                        ) : (
                                            <div className="imgDeforView">
                                                <i className="fas fa-camera" />
                                            </div>
                                        )}
                                        Establecer foto de perfil
                                        <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e: any) =>
                                                changeImage(e.target.files[0])
                                            }
                                            onClick={(e: any) => valueImage(e)}
                                        />
                                    </label>
                                </div>
                            </div>
                            <form className="contFormCour" onSubmit={submitEdit}>
                                <div className="contFormCour scrollBody scroll">
                                    <div className="contInAddUser">
                                        <p>
                                            <b>Nombres</b>
                                        </p>
                                        <input
                                            type="text"
                                            name="name"
                                            value={dat.name}
                                            onChange={(e: ChangeEvent) => changeData(e)}
                                            required
                                        />
                                    </div>

                                    <div className="contInAddUser">
                                        <p>
                                            <b>Apellidos</b>
                                        </p>
                                        <input
                                            type="text"
                                            name="lastname"
                                            value={dat.lastname}
                                            onChange={(e: ChangeEvent) => changeData(e)}
                                            required
                                        />
                                    </div>

                                    {/* <div className="sepContAddUser">
                                        <div className="contInAddUser">
                                            <p><b>Apellido Paterno</b></p>
                                            <input type="text" name="firstlastname" value={dat.firstlastname} onChange={(e: ChangeEvent) => changeData(e)} required />
                                        </div>

                                        <div className="contInAddUser">
                                            <p><b>Apellido Materno</b></p>
                                            <input type="text" name="secondlastname" value={dat.secondlastname} onChange={(e: ChangeEvent) => changeData(e)} required />
                                        </div>
                                    </div> */}

                                    <div className="contInAddUser">
                                        <p>
                                            <b>Dirección</b>
                                        </p>
                                        <input
                                            type="text"
                                            name="address"
                                            value={dat.address}
                                            onChange={(e: ChangeEvent) => changeData(e)}
                                        />
                                    </div>

                                    <div className="sepContAddUser">
                                        <div className="contInAddUser">
                                            <p>
                                                <b>Celular</b>
                                            </p>
                                            <input
                                                type="text"
                                                name="phone"
                                                maxLength={9}
                                                value={dat.phone}
                                                onChange={(e: ChangeEvent) =>
                                                    changeData(e, true)
                                                }
                                            />
                                        </div>

                                        <div className="contInAddUser">
                                            <p>
                                                <b>DNI</b>
                                            </p>
                                            <input
                                                type="text"
                                                name="dni"
                                                maxLength={8}
                                                value={dat.dni}
                                                onChange={(e: ChangeEvent) =>
                                                    changeData(e, true)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="optsBtnAdd">
                                    {loading ? (
                                        <div className="containerSpinner">
                                            {' '}
                                            <SpinnerModal />{' '}
                                        </div>
                                    ) : (
                                        <button type="submit" disabled={loading}>
                                            Guardar
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
