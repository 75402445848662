import { useState, useContext } from 'react'
import { toast } from 'react-toastify'

import { AparienciaContext } from '../../../../../../context/aparienciaContext/AparienciaProvider'
import { typesCounter } from '../../../../../../context/types/typesCounter'
import SpinnerModal from './../../../../../general/spinner/SpinnerModal'
import { UserCounterContext } from './../../../../../../context/userCounter/UserCounterProvider'
import { createUsersCounter } from '../../../../../../context/userCounter/UserCounterAction'
// import Notification, { type } from '../../../../../general/toast/Toast';

// type ChangeEvent = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>

// type FormEvent = React.FormEvent<HTMLFormElement>

interface Data {
    dni: string
    phone: string
    name: string
    firstlastname: string
    secondlastname: string
    email: string
    address: string
    // username: string,
    password: string
    course_of_interest: any
    rol: string
    price: string
    typeDoc: string
}

const FormAddUser = ({ setShowModalSearchStudent, emailStudent, roles }: any) => {
    const [data, setData] = useState<Data>({
        dni: '',
        phone: '',
        name: '',
        firstlastname: '',
        secondlastname: '',
        email: emailStudent,
        address: '',
        password: '',
        course_of_interest: '',
        rol: '',
        price: '',
        typeDoc: '',
    })

    // console.log(data.course_of_interest);

    const token = JSON.parse(localStorage.getItem('user')!).token

    const [imgPhoto, setPhoto] = useState<any>({})

    const [preview, setPreview] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)

    const { state: stateApariencia } = useContext(AparienciaContext)

    const { dispatch } = useContext(UserCounterContext)

    const [showPassword, setShowPassword] = useState<boolean>(false)

    const changeData = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        number?: boolean,
    ): void => {
        const { name, value } = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setData({
                    ...data,
                    [name]: value,
                })
            }
        } else {
            setData({
                ...data,
                [name]: value,
            })
        }
    }

    const changeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const typeFile = e.target.files[0].type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e.target.files[0]
                setPhoto(imgPhoto)
                setPreview(URL.createObjectURL(e.target.files[0]))
            }
        }
    }

    const createStudentCounter = (e: React.FormEvent<HTMLFormElement>) => {
        setLoading(true)
        e.preventDefault()

        const datan: FormData = new FormData()
        datan.append('fname', 'createUser')
        datan.append('dni', data.dni)
        datan.append('photo', imgPhoto)
        datan.append('phone', data.phone)
        datan.append('name', data.name)
        datan.append('lastname', data.firstlastname)
        datan.append('email', data.email)
        datan.append('address', data.address)
        datan.append('password', data.password)
        datan.append('entity', stateApariencia.currentIdEntity)
        datan.append('roles', JSON.stringify([data.rol]))
        datan.append('typeDoc', data.typeDoc)
        // datan.append("roles", JSON.stringify([roles]))
        // datan.append("course_of_interest", JSON.stringify(course_of_interest))

        createUsersCounter(token, datan)
            .then(({ data }) => {
                // console.log(data);

                const datita = {
                    id: data.user._id,
                    name_student: data.user.name,
                    lastname: data.user.lastname,
                    email: data.user.email,
                    photo: data.user.photo,
                    phone: data.user.phone,
                    address: data.user.address,
                    dni: data.user.dni,
                    schedule: data.schedule,
                    course_of_interest: data.user.course_of_interest,
                    username: data.user.username,
                    password: data.user.password,
                }

                dispatch({
                    type: typesCounter.createUserCounter,
                    payload: datita,
                })

                setShowModalSearchStudent(false)
                // Notification(type.success, `El usuario se ha creado correctamente.`)
                toast.success('El usuario se ha creado correctamente')

                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log(e.response)
                // Notification(type.danger, `Ha ocurrido un error, intentelo de nuevo`)
                toast.error('Ha ocurrido un error, intentelo de nuevo')
            })
    }

    const valueImage = (e: any) => (e.target.value = '')

    return (
        <div className="bodyAddUser">
            <div className="contPhotoUser">
                <div className="contImgUser">
                    <label
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {preview ? (
                            <img src={preview} alt="ImagenDef" className="imgDeforView" />
                        ) : (
                            <div className="imgDeforView">
                                <i className="fas fa-camera" />
                            </div>
                        )}
                        Establecer foto de perfil
                        <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => changeImage(e)}
                            onClick={(e) => valueImage(e)}
                        />
                    </label>
                </div>
            </div>
            <form className="contFormCour" onSubmit={createStudentCounter}>
                <div className="contFormCour scrollBody scroll">
                    <div className="contInAddUser">
                        <p>
                            <b>Rol</b>
                        </p>
                        <select
                            name="rol"
                            value={data.rol}
                            onChange={(e) => changeData(e)}
                            required
                        >
                            <option value="" disabled>
                                Seleccione una opción
                            </option>
                            {roles.map(
                                (r: any) =>
                                    r.rol === 'estudiante' && (
                                        <option value={r._id} key={r._id}>
                                            {r.rol}
                                        </option>
                                    ),
                            )}
                        </select>
                    </div>

                    <div className="contInAddUser">
                        <p>
                            <b>Nombres</b>
                        </p>
                        <input
                            type="text"
                            name="name"
                            value={data.name}
                            onChange={(e) => changeData(e)}
                            required
                        />
                    </div>

                    <div className="contInAddUser">
                        <p>
                            <b>Apellido Paterno</b>
                        </p>
                        <input
                            type="text"
                            name="firstlastname"
                            value={data.firstlastname}
                            onChange={(e) => changeData(e)}
                            required
                        />
                    </div>

                    <div className="contInAddUser">
                        <p>
                            <b>Apellido Materno</b>
                        </p>
                        <input
                            type="text"
                            name="secondlastname"
                            value={data.secondlastname}
                            onChange={(e) => changeData(e)}
                            required
                        />
                    </div>
                    <div className="contInAddUser">
                        <p>
                            <b>Tipo de documento</b>
                        </p>
                        <select
                            name="typeDoc"
                            value={data.typeDoc}
                            onChange={(e) => changeData(e)}
                            required
                        >
                            <option value="" disabled>
                                Seleccione una opción
                            </option>
                            <option value="dni">DOC.NAC.IDENTIDAD / CREDENCIAL</option>
                        </select>
                    </div>

                    <div className="contInAddUser">
                        <p>
                            <b>Dirección</b>
                        </p>
                        <input
                            type="text"
                            name="address"
                            value={data.address}
                            onChange={(e) => changeData(e)}
                        />
                    </div>

                    <div className="sepContAddUser">
                        <div className="contInAddUser">
                            <p>
                                <b>Celular</b>
                            </p>
                            <input
                                type="text"
                                name="phone"
                                maxLength={9}
                                value={data.phone}
                                onChange={(e) => changeData(e, true)}
                            />
                        </div>

                        <div className="contInAddUser">
                            <p>
                                <b>DNI</b>
                            </p>
                            <input
                                type="text"
                                name="dni"
                                maxLength={8}
                                value={data.dni}
                                onChange={(e) => changeData(e, true)}
                            />
                        </div>
                    </div>

                    <div className="contInAddUser">
                        <p>
                            <b>Correo electrónico</b>
                        </p>
                        <input
                            type="email"
                            name="email"
                            value={data.email}
                            onChange={(e) => changeData(e)}
                            required
                        />
                    </div>

                    <div className="contInAddUser">
                        <p>
                            <b>Contraseña</b>
                        </p>
                        <input
                            type={`${showPassword ? 'text' : 'password'}`}
                            name="password"
                            value={data.password}
                            onChange={(e) => changeData(e)}
                            required
                            maxLength={30}
                        />
                        <i
                            className={`far fa-eye${showPassword ? '-slash' : ''}`}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                </div>

                <div className="optsBtnAdd">
                    {loading ? (
                        <div className="containerSpinner">
                            {' '}
                            <SpinnerModal />{' '}
                        </div>
                    ) : (
                        <button type="submit">Guardar</button>
                    )}
                </div>
            </form>
        </div>
    )
}

export default FormAddUser
