import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import CardAccess from './CardAccess'
import { axiosUserV5 } from './../../config/axios'

const RecoverPasswordPage = (): JSX.Element => {
    const data = {
        title: 'Recuperar contraseña',
        info: 'Para recuperar su contraseña, indroduzca el correo con que se registró.',
    }

    const token = JSON.parse(localStorage.getItem('user')!)?.token

    // const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState('')

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)

        axiosUserV5({
            url: 'auth/recover-password',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'recoverPassword',
                email: email,
            },
        })
            .then((data) => {
                // console.log(data)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    return (
        <div className="main_AccessPage">
            <CardAccess data={data}>
                <form onSubmit={onSubmit}>
                    <div className="formBody">
                        <label htmlFor="accessUser">
                            <input
                                id="accessUser"
                                type="email"
                                placeholder="Ingrese su correo"
                                value={email}
                                disabled={loading}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </label>
                        <div className="info">
                            Le enviaremos un mensaje a su correo con las intrucciones para
                            continuar con este proceso.
                        </div>
                    </div>
                    <div className="formFooter">
                        <button type="submit" disabled={loading}>
                            {loading ? 'ENVIANDO...' : 'ENVIAR'}
                        </button>
                        <div className="contChangeOpt">
                            <span>¿Se acordó su contraseña?</span>
                            <span>
                                <Link to="/login">Iniciar sesión</Link>
                            </span>
                        </div>
                    </div>
                </form>
            </CardAccess>
        </div>
    )
}

export default RecoverPasswordPage
