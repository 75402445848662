import React, { useContext } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import HomePage from './../pages/dashboard/HomePage'
import TicketsPage from './../pages/dashboard/TicketsPage'
import EntidadesPage from './../pages/dashboard/EntidadesPage'
import UsuariosPage from './../pages/dashboard/UsuariosPage'
import MetricasPage from './../pages/dashboard/MetricasPage'
import SidebarMenu from './../components/container/SidebarMenu'
import { UserProvider } from '../context/superAdminUser/UserProvider'
import { EntityProvider } from '../context/entityContext/EntityProvider'
import { AuthContext } from '../context/auth/AuthProvider'
import PermissionsPage from './../pages/dashboard/PermissionsPage'
// import RepositoryPage from './../pages/dashboard/RepositoryPage';
import RepositoryPage2 from './../pages/dashboard/RepositoryPage2'

const DashboardRoutes = () => {
    const { path } = useRouteMatch()
    const { user } = useContext(AuthContext)
    return (
        <UserProvider>
            <EntityProvider>
                <SidebarMenu rol={user.rol}>
                    <Switch>
                        <Route
                            exact
                            path={`${path}/dashboard/home`}
                            component={HomePage}
                        />
                        <Route
                            exact
                            path={`${path}/dashboard/tickets`}
                            component={TicketsPage}
                        />
                        <Route
                            exact
                            path={`${path}/dashboard/entities`}
                            component={EntidadesPage}
                        />
                        <Route
                            exact
                            path={`${path}/dashboard/permissions/:_id`}
                            component={PermissionsPage}
                        />
                        <Route
                            exact
                            path={`${path}/dashboard/repository`}
                            component={RepositoryPage2}
                        />
                        <Route
                            exact
                            path={`${path}/dashboard/users`}
                            component={UsuariosPage}
                        />
                        <Route
                            exact
                            path={`${path}/dashboard/metrics`}
                            component={MetricasPage}
                        />
                        <Redirect exact path={`/*`} to={`${path}/dashboard/home`} />
                    </Switch>
                </SidebarMenu>
            </EntityProvider>
        </UserProvider>
    )
}

export default DashboardRoutes
