import React, { useState, useContext } from 'react'
import { toast } from 'react-toastify'

import { axiosUserV5 } from '../../config/axios'
import { AuthContext } from './../../context/auth/AuthProvider'
// import Notification, { type } from '../../components/general/toast/Toast';

const PasswordPage = () => {
    const token = JSON.parse(localStorage.getItem('user')!).token

    const { user } = useContext(AuthContext)

    const [fields, setField] = useState({
        password: '',
        confirmPassword: '',
    })
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showPassword2, setShowPassword2] = useState<boolean>(false)

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const { password, confirmPassword } = fields
        // perform all neccassary validations
        if (password !== confirmPassword) {
            // Notification(type.danger, "Las contraseñas no coinciden")
            toast.error('Las contraseñas no coinciden')
        } else {
            axiosUserV5({
                url: '/v5.0.1/api',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                data: {
                    fname: 'resetPassword',
                    user: user.idUser,
                    password: password,
                },
            })
                .then((res) => {
                    // console.log(res)

                    // Notification(type.success, res.data.message)
                    toast.success(res.data.message)
                })
                .catch((e) => {
                    console.log(e.response)
                })
        }
    }

    const onChangeInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
        setField({
            ...fields,
            [e.target.name]: e.target.value,
        })
    }

    return (
        <div className="main_settings">
            <form onSubmit={handleSubmit}>
                <div className="containerForm">
                    <label>
                        <span>Contraseña nueva</span>
                        <div className="password-input">
                            <input
                                type={`${showPassword ? 'text' : 'password'}`}
                                name="password"
                                value={fields.password}
                                onChange={onChangeInputs}
                            />
                            <div className="password-icon">
                                <i
                                    className={`far fa-eye${
                                        showPassword ? '-slash' : ''
                                    }`}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </div>
                        </div>
                    </label>

                    <label>
                        <span>Confirmar contraseña nueva</span>
                        <div className="password-input">
                            <input
                                type={`${showPassword2 ? 'text' : 'password'}`}
                                name="confirmPassword"
                                value={fields.confirmPassword}
                                onChange={onChangeInputs}
                            />
                            <div className="password-icon">
                                <i
                                    className={`far fa-eye${
                                        showPassword2 ? '-slash' : ''
                                    }`}
                                    onClick={() => setShowPassword2(!showPassword2)}
                                />
                            </div>
                        </div>
                    </label>

                    <div className="buttonContainerProfile">
                        <button className="buttonDesignProfile" type="submit">
                            Guardar Cambios
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PasswordPage
