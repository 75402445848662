import { useState, useContext } from 'react'
import { toast } from 'react-toastify'

import SpinnerModal from './../../../../general/spinner/SpinnerModal'
import { axiosStudent } from '../../../../../config/axios'
// import Notification, { type } from '../../../../general/toast/Toast';
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'

const RejectVoucher = ({
    setRejectModal,
    order,
    setVouchers,
    setShowModal,
    setpagination,
}: any) => {
    const [description, setDescription] = useState('')
    const [loading, setloading] = useState(false)

    const token = JSON.parse(localStorage.getItem('user')!).token

    const { state: stateApariencia } = useContext(AparienciaContext)

    const handleReject = (e: any) => {
        e.preventDefault()
        setloading(true)

        axiosStudent({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'rejectOrder',
                order_id: order._id,
                message: description,
            },
        })
            .then((data) => {
                // console.log(data)
                if (data.status === 200) {
                    axiosStudent({
                        url: 'v5.0.1/api',
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                        data: {
                            fname: 'getOrders',
                            entity_id: stateApariencia.currentIdEntity,
                            page: 1,
                            docs: 15,
                            filter_by_email: '',
                            sort: '-created_at',
                        },
                    })
                        .then(({ data: algo }) => {
                            console.log(algo)

                            let array: any[] = []

                            algo.orders.map((dat: any) =>
                                dat
                                    ? array.push({
                                          _id: dat._id,
                                          name: dat.user.name,
                                          lastname: dat.user.lastname,
                                          photo:
                                              dat.user.photo &&
                                              dat.user.photo.includes('https://')
                                                  ? dat.user.photo
                                                  : 'https://livewellwithstacy.com/wp-content/uploads/2018/04/avtar.png',
                                          email: dat.user.email,
                                          state: dat.state,
                                          total_price: dat.total_price,
                                          created_at: dat.created_at,
                                      })
                                    : null,
                            )

                            setVouchers(array)

                            setpagination(algo.pagination)

                            setRejectModal(false)

                            setShowModal(false)

                            // Notification(type.success, "La orden ha sido rechazada")
                            toast.success('La orden ha sido rechazada')
                        })
                        .catch((e) => {
                            console.log(e.response)
                        })
                }
            })
            .catch((e) => {
                console.log(e.response)

                // Notification(type.success, "Ha ocurrido un problema al rechazar orden")
                toast.error('Ha ocurrido un problema al rechazar orden')
            })
    }

    return (
        <div className="main_AddCategory">
            <div className="heaCategory">
                <h3>Rechazar y enviar comentario</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setRejectModal(false)}
                />
            </div>
            <div className="bodyCategory">
                <form className="contFormEnt" onSubmit={handleReject}>
                    <div className="contFormEnt">
                        <div className="contInAddEnt">
                            <p>Descripción</p>
                            <textarea
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                disabled={loading}
                            />
                        </div>
                    </div>

                    <div className="optsBtnAdd">
                        {
                            <>
                                <button onClick={() => setRejectModal(false)}>
                                    {' '}
                                    Cancelar
                                </button>
                                {loading ? (
                                    <div className="containerSpinner">
                                        {' '}
                                        <SpinnerModal />{' '}
                                    </div>
                                ) : (
                                    <button type="submit" disabled={loading}>
                                        {' '}
                                        Enviar{' '}
                                    </button>
                                )}
                            </>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RejectVoucher
