import { useState, useEffect, useCallback, useMemo } from 'react'
import type { TableColumn } from 'react-data-table-component'

import { Button, MyTable, TableHeader } from '../../components'
import ContainerModal from '../../components/modals/ContainerModal'
import { AddEditUser, DeleteUser } from '../../components/modals/continental/admin/users'
import { useAppSelector } from '../../hooks/useReduxHooks'
import type { CustomUser, ModalOptions, Pagination } from '../../interfaces'
import { useGetUsersByEntityQuery } from '../../store/api'
import { selectAuth } from '../../store/slices'

const UsuariosB2BPage2 = () => {
    const [showAddEditUser, setShowAddEditUser] = useState<ModalOptions>('hide')
    const [showDeleteUser, setShowDeleteUser] = useState<boolean>(false)
    const { entity } = useAppSelector(selectAuth)
    const [textQuery, setTextQuery] = useState<string>('')
    const [pagination, setPagination] = useState<Pagination>({
        actualPage: 1,
        itemCount: 25,
        total: 0,
    })

    const [currentUser, setCurrentUser] = useState<CustomUser>()

    const { data, isLoading, isFetching, refetch } = useGetUsersByEntityQuery({
        entity_id: entity,
        page: pagination.actualPage!,
        docs: pagination.itemCount!,
        query: {
            field: 'name',
            search: textQuery,
        },
    })

    const columns: TableColumn<CustomUser>[] = useMemo(
        () => [
            {
                name: 'Foto',
                center: true,
                cell: (row) => (
                    <img
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                        src={row.photo}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = 'https://via.placeholder.com/30'
                        }}
                        alt={row.name}
                        height="30px"
                        width="30px"
                    />
                ),
            },
            {
                name: 'Nombre',
                selector: (row) => row.name,
            },
            {
                name: 'Apellido',
                selector: (row) => row.lastname,
            },
            {
                name: 'Email',
                selector: (row) => row.email,
            },
            {
                name: 'Teléfono',
                selector: (row) => row.phone,
            },
            {
                name: 'DNI',
                selector: (row) => row.dni,
            },
            {
                name: 'Dirección',
                selector: (row) => row.address,
            },
        ],
        [],
    )

    const openAddModal = useCallback(() => {
        setCurrentUser(undefined)
        setShowAddEditUser('add')
    }, [])

    const RowOptions = useCallback(
        () => (
            <div
                className="multiedu__table--row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '1rem',
                    padding: '1rem 1rem 1rem 6rem',
                }}
            >
                <Button
                    onClick={() => setShowAddEditUser('edit')}
                    text="Editar usuario"
                />
                <Button onClick={() => setShowDeleteUser(true)} text="Eliminar usuario" />
            </div>
        ),
        [],
    )

    useEffect(() => {
        if (data) {
            let { itemCount, ...newPagination } = data.pagination
            setPagination((c) => ({
                ...c,
                ...newPagination,
            }))
        }
    }, [data])

    return (
        <>
            <div className="main_UsuariosB2bPage">
                <div className="headUsuariosB2b">
                    <h3>Usuarios</h3>
                </div>
                <div className="bodyUsuariosB2b scroll">
                    <div className="cardEntity_container">
                        <TableHeader
                            title="Listado de usuarios"
                            handleShowAddModal={openAddModal}
                            setTextQuery={setTextQuery}
                            refetch={refetch}
                        />
                        <MyTable
                            data={data?.users || []}
                            columns={columns}
                            isLoading={isLoading || isFetching}
                            pagination={pagination}
                            setPagination={setPagination}
                            activeItem={currentUser}
                            setActiveItem={setCurrentUser}
                            refetch={refetch}
                            expandableRowsComponent={RowOptions}
                            showPagination
                        />
                    </div>
                </div>
            </div>
            <ContainerModal showModal={showAddEditUser !== 'hide'}>
                <AddEditUser
                    showModal={showAddEditUser}
                    setShowModal={setShowAddEditUser}
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                />
            </ContainerModal>
            <ContainerModal showModal={showDeleteUser}>
                <DeleteUser setShowModal={setShowDeleteUser} userId={currentUser?._id!} />
            </ContainerModal>
        </>
    )
}

export default UsuariosB2BPage2
