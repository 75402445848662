import { useCallback, useEffect, useContext, useState } from 'react'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

import { axiosEntities, axiosUserV5 } from '../../../../../config/axios'
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import { typesCounter } from '../../../../../context/types/typesCounter'
import { UserCounterContext } from '../../../../../context/userCounter/UserCounterProvider'
import ProgressNS from '../../../../general/progress/ProgressNS'
import SpinnerCertificate from '../../../../general/spinner/SpinnerCertificate'
// import Notification, { type } from '../../../../general/toast/Toast';
import FormAddUser from './items/FormAddUser'

interface PropsAdd {
    // createUser: (data: Data) => void
    setShowModalSearchStudent: React.Dispatch<React.SetStateAction<boolean>>
}

export interface IResponseGetRoles {
    message: string
    roles: Role[]
}

export interface Role {
    rol: string
    _id: string
}

const SearchStudentCounter = ({ setShowModalSearchStudent }: PropsAdd) => {
    const { state: stateApariencia } = useContext(AparienciaContext)
    const token = JSON.parse(localStorage.getItem('user')!).token
    const [search, setSearch] = useState('')
    const [exists, setExists] = useState(1)
    const [user, setUser] = useState<any>('')
    const [loading, setloading] = useState(false)
    const [loader, setloader] = useState(false)
    const { dispatch } = useContext(UserCounterContext)
    const [roles, setRoles] = useState<Role[]>([])

    const getRoles = useCallback(async () => {
        console.log('get roles')
        await axiosEntities({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getRolesByEntity',
                entity_id: stateApariencia.currentIdEntity,
            },
        }).then(({ data }: AxiosResponse<IResponseGetRoles>) => {
            console.log(data)
            setRoles(data.roles)
        })
    }, [token, stateApariencia.currentIdEntity])

    const searchStudents = () => {
        setloader(true)
        axiosEntities({
            url: 'v5.0.1/api',
            method: 'POST',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'searchUserByEntity',
                entity: stateApariencia.currentIdEntity,
                email: search,
            },
        })
            .then(({ data }) => {
                // console.log(data)

                if (!data.userExists && !data.userExistsInEntity) {
                    setExists(2)
                }

                if (data.userExists && !data.userExistsInEntity) {
                    setExists(5)
                    setUser(data.user)
                }

                if (data.userExists && data.userExistsInEntity) {
                    setExists(4)

                    setUser(data.user)
                }

                setloader(false)
            })
            .catch((err) => {
                setloader(false)
                console.log(err.response)
            })
    }

    const handleAddUser = (id: any) => {
        setloading(true)

        axiosUserV5({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'assignUserToEntity',
                entity: stateApariencia.currentIdEntity,
                user: id,
                roles: JSON.stringify(['estudiante']),
            },
        })
            .then(({ data }) => {
                // console.log(data)

                const datita = {
                    id: data.user._id,
                    name_student: data.user.name,
                    lastname: data.user.lastname,
                    email: data.user.email,
                    photo: data.user.photo,
                    phone: data.user.phone,
                    address: data.user.address,
                    dni: data.user.dni,
                    schedule: data.schedule,
                    course_of_interest: data.user.course_of_interest,
                    username: data.user.username,
                    password: data.user.password,
                }

                dispatch({
                    type: typesCounter.createUserCounter,
                    payload: datita,
                })

                // Notification(type.success, `El usuario ha sido asignado correctamente.`)
                toast.success('El usuario ha sido asignado correctamente')
                setShowModalSearchStudent(false)
                setloading(false)
            })
            .catch((e) => {
                console.log(e.response)
                // Notification(type.danger, `Ha ocurrido un error`)
                toast.error('Ha ocurrido un error')
                setloading(false)
            })
    }

    const onKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
            searchStudents()
        }
    }

    useEffect(() => {
        getRoles()
    }, [getRoles])

    return (
        <div className="main_searchStudent">
            <div className="headSearchStudent">
                <i
                    className="fas fa-times"
                    onClick={() => setShowModalSearchStudent(false)}
                />
            </div>
            <div className="bodySearchStudent scroll">
                {exists === 3 ? (
                    <>
                        <div className="contentSearch">
                            <div className="back_button" onClick={() => setExists(2)}>
                                <i className="fas fa-long-arrow-alt-left"></i>
                                <span>Regresar</span>
                            </div>

                            <h2>Agregar Estudiante</h2>
                        </div>

                        <div className="containerBody">
                            <div className="content_add_user">
                                {/* <div className="text_container">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum ipsum, facilis rem corporis temporibus nulla veniam ducimus ullam quod non vitae nemo quibusdam voluptatum. Modi harum architecto saepe delectus at.</p>
                                </div> */}

                                <FormAddUser
                                    setShowModalSearchStudent={setShowModalSearchStudent}
                                    emailStudent={search}
                                    roles={roles}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="contentSearch">
                            <h2>Agregar Estudiante</h2>
                            <div className="search_container">
                                <input
                                    type="text"
                                    placeholder="Buscar"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={onKeyDown}
                                />
                                <i className="fas fa-search" onClick={searchStudents}></i>
                            </div>
                        </div>

                        <div className="containerBody">
                            {loader ? (
                                <div className="contentBody">
                                    <ProgressNS />
                                    {/* <div className="response_body">
                                                    <h5>  </h5>
                                            </div> */}
                                </div>
                            ) : exists === 2 ? (
                                <div className="contentBody">
                                    <div className="response_body">
                                        <h5>
                                            No se encontró usuario, ¿desea agregarlo
                                            manualmente?
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() => setExists(3)}
                                        >
                                            {' '}
                                            <i className="fas fa-plus-circle"></i> Agregar
                                            nuevo{' '}
                                        </button>
                                    </div>
                                </div>
                            ) : exists === 4 ? (
                                <div className="contentBody">
                                    <div className="response_body">
                                        <h5>
                                            {' '}
                                            Usuario {user.email} ya existe en la entidad{' '}
                                        </h5>
                                        {/* <button type="button" onClick={() => setExists(3)} > <i className="fas fa-plus-circle"></i>  </button> */}
                                    </div>
                                </div>
                            ) : exists === 5 ? (
                                <div className="info_user">
                                    <img
                                        src={user.photo}
                                        className="img_photo"
                                        alt="phot"
                                    />
                                    <div className="data_info">
                                        <div className="data_info_name">
                                            <p> {user.name + user.lastname} </p>
                                            <p> {user.email} </p>
                                        </div>
                                        <div className="assing_name">
                                            {loading ? (
                                                <SpinnerCertificate></SpinnerCertificate>
                                            ) : (
                                                <div
                                                    className="button_center"
                                                    onClick={() =>
                                                        handleAddUser(user._id)
                                                    }
                                                >
                                                    Agregar
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="contentBody">
                                    <div className="response_body">
                                        <h5>
                                            Busca el usuario ingresando su correo, para
                                            saber si ya esta registrado en la entidad
                                        </h5>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default SearchStudentCounter
