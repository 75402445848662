// import { createApi } from '@reduxjs/toolkit/dist/query/react'
import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { COURSE_URL } from '../config/utils'
import type { RootState } from '../store'
import { logout } from '../store/slices'
// import { tokenReceived, loggedOut } from './authSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: `${COURSE_URL}v5.0.1/api`,
    method: 'POST',
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token
        if (token) headers.set('authorization', `Bearer ${token}`)
        return headers
    },
})
export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    // result.error && result.error.status === 401
    if (result.error) {
        // try to get a new token
        // const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
        // if (refreshResult.data) {
        //     // store the new token
        //     api.dispatch(tokenReceived(refreshResult.data))
        //     // retry the initial query
        //     result = await baseQuery(args, api, extraOptions)
        // } else {
        //     api.dispatch(loggedOut())
        // }
        console.log((result.error as any).originalStatus)
        console.log('error')
        if ((result.error as any).originalStatus === 401) {
            api.dispatch(logout())
            localStorage.clear()
        }
    }
    return result
}

// export const baseApi = createApi({
//     reducerPath: 'baseApi',
//     baseQuery: baseQueryWithReauth,
//     endpoints: () => ({}),
// })
