import { useState } from 'react'
import { toast } from 'react-toastify'

import { Credential } from '../../../../../interfaces'
import { useEditCredentialMutation } from '../../../../../store/api'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'
import ContainerModal from '../../../ContainerModal'

type EditCredentialProps = {
    setShowModalEdit: React.Dispatch<React.SetStateAction<boolean>>
    currentCredential: Credential
}

export const EditCredential = ({
    setShowModalEdit,
    currentCredential,
}: EditCredentialProps) => {
    const { service, email, token, _id, type } = currentCredential

    const [data, setData] = useState({
        email: email,
        token: token,
        service: service,
    })
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

    const [editCredential, { isLoading }] = useEditCredentialMutation()

    const onChangeInputs = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    ) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    }

    const handleEditCredential = () => {
        editCredential({
            zoom_token_id: _id,
            ...data,
        }).then(() => {
            setShowConfirmModal(false)
            setShowModalEdit(false)
        })
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (data.token.trim() === '') {
            return toast.warning('No puede editar una credencial con espacios en blanco')
        }

        setShowConfirmModal(true)
    }

    return (
        <>
            <div className="main_AddCategory">
                <div className="heaCategory">
                    <h3>Actualizar credenciales</h3>
                    <i
                        className="fas fa-times-circle"
                        onClick={() => setShowModalEdit(false)}
                    />
                </div>
                <div className="bodyCategory">
                    <form className="contFormEnt" onSubmit={handleSubmit}>
                        <div className="contFormEnt">
                            <div className="contInAddEnt">
                                <p>Tipo de credencial</p>
                                <select
                                    name="service"
                                    className="design_input"
                                    onChange={onChangeInputs}
                                    disabled={isLoading}
                                    required
                                >
                                    <option value={data.service}> {data.service} </option>
                                    {type === 'payment_method' ? (
                                        <>
                                            <option value="mercadopago">
                                                {' '}
                                                Mercadopago{' '}
                                            </option>
                                            <option value="niubiz"> Niubiz </option>
                                            <option value="izipay"> iziPay </option>
                                        </>
                                    ) : (
                                        <>
                                            <option value="zoom"> Zoom </option>
                                            <option value="meet"> Meet </option>
                                        </>
                                    )}
                                </select>
                            </div>

                            <div className="contInAddEnt">
                                <p>Correo de zoom para crear las reuniones</p>
                                <input
                                    type="text"
                                    name="email"
                                    value={data.email}
                                    onChange={onChangeInputs}
                                    required
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="contInAddEnt">
                                <p> Token </p>
                                <textarea
                                    name="token"
                                    value={data.token}
                                    onChange={onChangeInputs}
                                    required
                                    disabled={isLoading}
                                />
                            </div>
                        </div>

                        <div className="optsBtnAdd">
                            <button type="button" onClick={() => setShowModalEdit(false)}>
                                Cancelar
                            </button>
                            {isLoading ? (
                                <div className="containerSpinner">
                                    <SpinnerModal />
                                </div>
                            ) : (
                                <button type="submit" disabled={isLoading}>
                                    Guardar
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
            <ConfirmModal
                showModal={showConfirmModal}
                setShowModal={setShowConfirmModal}
                isLoading={isLoading}
                handleFunction={handleEditCredential}
            />
        </>
    )
}

interface ConfirmModalProps {
    showModal: boolean
    setShowModal: (value: boolean) => void
    handleFunction: () => void
    isLoading: boolean
}

const ConfirmModal = ({
    showModal,
    setShowModal,
    handleFunction,
    isLoading,
}: ConfirmModalProps) => {
    return (
        <ContainerModal showModal={showModal}>
            <div className="main_Delete">
                <div className="headModal">
                    <h3>Aviso</h3>
                    <i
                        className="fas fa-times-circle"
                        onClick={() => setShowModal(false)}
                    />
                </div>
                <div className="bodyModals">
                    <p>
                        ¿Confirma que desea realizar la modificación? <br /> Recuerde que
                        es un cambio fundamental.
                    </p>
                    {isLoading ? (
                        <div className="containerSpinner_center">
                            <button onClick={() => setShowModal(false)} disabled>
                                Cancelar
                            </button>
                            <div className="containerSpinner">
                                <SpinnerModal />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <button onClick={() => setShowModal(false)}>Cancelar</button>
                            <button className="saveButton" onClick={handleFunction}>
                                Aceptar
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </ContainerModal>
    )
}
