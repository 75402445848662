import { IAction } from './UserCounterInterface'
import { IState } from './UserCounterInterface'
import { typesCounter } from './../types/typesCounter'

export const userCounterReducer = (state: IState, action: IAction): any => {
    switch (action.type) {
        case typesCounter.getUserCounter:
            return {
                ...state,
                userCounter: action.payload,
            }

        case typesCounter.createUserCounter:
            return {
                ...state,
                userCounter: [...state.userCounter, action.payload],
            }

        case typesCounter.deleteUserCounter:
            return {
                ...state,
                userCounter: state.userCounter.filter(
                    (usCounter: any) => usCounter.id !== action.payload,
                ),
            }

        case typesCounter.updateUserCounter:
            return {
                ...state,
                userCounter: state.userCounter.map((usCounter: any) => {
                    if (usCounter.id === action.payload.id) {
                        return action.payload
                    } else {
                        return usCounter
                    }
                }),
            }

        case typesCounter.getCoursesCounter:
            return {
                ...state,
                coursesUserCounter: action.payload,
            }

        default:
            return state
    }
}
