import { createContext, useReducer } from 'react'

import { IState } from './Userb2bInterface'
import { Userb2bReducer } from './Userb2bReducer'
// import { AuthContext } from './../auth/AuthProvider'
// import { getUserb2b } from './Userb2bAction'
// // import { typeUserb2b } from '../types/typeUserb2b'
// import { AparienciaContext } from './../aparienciaContext/AparienciaProvider';

const initialState: IState = {
    userb2b: [],
}

export const Userb2bContext = createContext<IState | any>(initialState)

export const Userb2bProvider = (props: any) => {
    // const { user } = useContext(AuthContext)
    // const { state: stateApariencia } = useContext(AparienciaContext)

    const [state, dispatch] = useReducer<any>(Userb2bReducer, initialState)

    // const getListUsers = useCallback((dispatch) => {
    //     getUserb2b(user.token, stateApariencia.currentIdEntity, dispatch)
    // }, [user.token, stateApariencia.currentIdEntity])

    // useEffect(() => {
    //     getListUsers(dispatch)
    // }, [getListUsers])

    return (
        <Userb2bContext.Provider value={{ state, dispatch }}>
            {props.children}
        </Userb2bContext.Provider>
    )
}
