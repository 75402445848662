import { useState } from 'react'
interface Props {
    title: string
    color: string
    typeUser: string
    userType: any
}

const ItemUser = ({ title, color, typeUser, userType }: Props): JSX.Element => {
    const [showOption, setShowOption] = useState<boolean>(false)

    return (
        <div
            className="container_ItemUser"
            style={{
                height: `${showOption ? `${userType.length * 66 + 48}px` : ''}`,
            }}
        >
            <div className="heatItenUsr" style={{ color: color }}>
                <div className="optInfoUser">
                    <div>
                        <i
                            className={`fas fa-chevron-circle-down ${
                                showOption ? 'active' : ''
                            }`}
                            onClick={() => setShowOption(!showOption)}
                        />
                    </div>
                    <div>
                        <i className="fas fa-grip-vertical" />
                    </div>
                    <div>{title}</div>
                </div>
                {!showOption ? (
                    <div className="nElement">{userType.length} elementos</div>
                ) : (
                    <div className="optOptUser other">
                        <div>Aplicado para</div>
                        <div>CV</div>
                        <div>Teléfono</div>
                        <div>DNI</div>
                        <div className="optMoreData">
                            <div>Correo electrónico</div>
                            <div>Link de perfil</div>
                        </div>
                    </div>
                )}
            </div>

            {userType.map((usr: any, i: number) => (
                <div className="heatItenUsr" style={{ color: color }} key={i}>
                    <div className="optInfoUser list">
                        <div className="franjList" style={{ backgroundColor: color }} />
                        <div className="contImgUser">
                            <img
                                src="https://i.imgur.com/zLCYdR9.jpg"
                                alt="photoPerfil"
                            />
                        </div>
                        <div>{usr.name}</div>
                    </div>
                    <div className="optOptUser other">
                        <div className="selectOptionUser">
                            optSelect
                            <i className="fas fa-chevron-down"></i>
                        </div>
                        <div className="buttonOptUser" style={{ color: color }}>
                            IR
                        </div>
                        <div>{usr.phone}</div>
                        <div>{usr.dni}</div>
                        <div className="optMoreData">
                            <div>{usr.email}</div>
                            <div>
                                https://developer.mozilla.org/es/docs/Web/CSS/:last-child
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* <div className="heatItenUsr" style={{ color: color }}>
                <div className="optInfoUser list">
                    <div className="franjList" style={{ backgroundColor: color }} />
                    <div className="contImgUser"><img src="https://i.imgur.com/zLCYdR9.jpg" alt="photoPerfil" /></div>
                    <div>Khail Mogollon</div>
                </div>
                <div className="optOptUser other">
                    <div>optSelect</div>
                    <div>IR</div>
                    <div>123456789</div>
                    <div>12345678</div>
                    <div className="optMoreData">
                        <div>khailmogollon@gmail.com</div>
                        <div>https://developer.mozilla.org/es/docs/Web/CSS/:last-child</div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default ItemUser
