import React from 'react'
import { useState, useContext } from 'react'
import { AparienciaContext } from '../../context/aparienciaContext/AparienciaProvider'
import { useEffect } from 'react'
import { axiosStudent } from '../../config/axios'
import ProgressNS from '../../components/general/progress/ProgressNS'
import DataTable from 'datatable-multiapps'
import ContainerModalAllPage from '../../components/modals/ContainerModalAllPage'
import DetailVoucher from '../../components/modals/continental/counter/voucher/DetailVoucher'

const HistoryPage = () => {
    // const [, setShowModalDelete] = useState<boolean>(false)
    const [loading, setloading] = useState(true)

    // console.log(showModalDelete)

    const [detailVoucherModal, setDetailVoucherModal] = useState<boolean>(false)

    const [order, setOrder] = useState<any>('')
    const [totalPrice, setTotalPrice] = useState('')
    const [imageVoucher, setImageVoucher] = useState('')

    const [vouchers, setVouchers] = useState<any>([])
    const [searchResults, setSearchResults] = useState<any[]>([])

    const { state: stateApariencia } = useContext(AparienciaContext)
    const token = JSON.parse(localStorage.getItem('user')!).token

    const [pagination, setpagination] = useState('')

    const pag: any = pagination

    useEffect(() => {
        setloading(false)

        axiosStudent({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getOrders',
                entity_id: stateApariencia.currentIdEntity,
                page: 1,
                docs: 15,
                filter_by_email: '',
                sort: '-created_at',
                state: ['rejected', 'canceled', 'success'],
            },
        })
            .then(({ data }) => {
                console.log(data)

                let array: any[] = []

                data.orders.map((dat: any) =>
                    dat
                        ? array.push({
                              _id: dat._id,
                              name: dat.user.name,
                              lastname: dat.user.lastname,
                              photo:
                                  dat.user.photo && dat.user.photo.includes('https://')
                                      ? dat.user.photo
                                      : 'https://livewellwithstacy.com/wp-content/uploads/2018/04/avtar.png',
                              email: dat.user.email,
                              state: dat.state,
                              total_price: dat.total_price,
                              created_at: dat.created_at,
                          })
                        : null,
                )

                setVouchers(array)

                setpagination(data.pagination)

                setloading(true)
            })
            .catch((e) => {
                console.log(e.response)

                setloading(true)
            })
    }, [stateApariencia.currentIdEntity, token])

    useEffect(() => {
        setSearchResults(vouchers)
    }, [vouchers])

    const dataVoucherAndGo = (data: any) => {
        axiosStudent({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getOrderDetail',
                order_id: data._id,
            },
        })
            .then(({ data }) => {
                console.log(data)

                setOrder(data.order)
                // setLoader(true)
                setTotalPrice(data.order.total_price)

                setImageVoucher(data.vouchers)

                setDetailVoucherModal(true)
            })
            .catch((e) => {
                console.log(e.response)
            })
    }

    const getVouchers = (e: any) => {
        const { documents, query, page, filter } = e

        console.log(query)

        axiosStudent({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getOrders',
                entity_id: stateApariencia.currentIdEntity,
                page: page,
                query: query,
                docs: documents,
                filter_by_email: query,
                sort: filter === true ? '-created_at' : 'created_at',
                state: ['rejected', 'canceled', 'success'],
            },
        })
            .then(({ data }) => {
                console.log('data filtrada', data)
                let array: any[] = []
                data.orders.map((dat: any) =>
                    dat
                        ? array.push({
                              _id: dat._id,
                              name: dat.user.name,
                              lastname: dat.user.lastname,
                              photo: dat.user.photo,
                              email: dat.user.email,
                              state: dat.pending,
                              total_price: dat.total_price,
                              created_at: dat.created_at,
                          })
                        : null,
                )
                setVouchers(array)

                setpagination(data.pagination)
            })
            .catch((e) => {
                console.log(e.response)
            })
    }

    const head = [
        {
            column_key: 'photo',
            column_name: 'Foto',
            column_type: 'image',
        },

        {
            column_key: 'name',
            column_name: 'Nombres',
            column_type: 'text',
        },
        {
            column_key: 'lastname',
            column_name: 'Apellidos',
            column_type: 'text',
        },

        {
            column_key: 'email',
            column_name: 'Email',
            column_type: 'text',
        },

        {
            column_key: 'state',
            column_name: 'Estado',
            column_type: 'text',
        },

        {
            column_key: 'total_price',
            column_name: 'Precio total',
            column_type: 'text',
        },

        {
            column_key: 'created_at',
            column_name: 'Fecha',
            column_type: 'text',
        },
    ]

    const options = [
        {
            label: 'Ver Voucher',
            onClick: dataVoucherAndGo,
        },
    ]

    return (
        <div className="main_UsuariosB2bPage">
            <div className="headUsuariosB2b">
                <h3>Historial</h3>
            </div>

            <div className="bodyUsuariosB2b scroll">
                <div className="cardEntity_container">
                    {!loading ? (
                        <ProgressNS />
                    ) : (
                        <>
                            <DataTable
                                data={searchResults}
                                title="Historial"
                                callback={getVouchers}
                                pagination={pag}
                                options={options}
                                headers={head}
                                color={`var(--color-secondary)`}
                            />
                        </>
                    )}
                </div>
                <ContainerModalAllPage showModal={detailVoucherModal}>
                    <DetailVoucher
                        setShowModal={setDetailVoucherModal}
                        order={order}
                        totalPrice={totalPrice}
                        imageVoucher={imageVoucher}
                        setVouchers={setVouchers}
                        setpagination={setpagination}
                        history={true}
                    />
                </ContainerModalAllPage>
            </div>
        </div>
    )
}

export default HistoryPage
