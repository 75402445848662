import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { ItemDragableQuestion } from './ItemDragableQuestion'

import './listquestions.sass'

const ListQuestions = ({
    questions,
    setQuestions,
    id,
    setTypeOption,
    deleteQuestion,
    typeOption,
}: any) => {
    const updateData = (question: any, index: number) => {
        let array: any[] = [...questions]
        array[index] = question
        setQuestions(array)
    }

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return
        const items = Array.from(questions)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        setQuestions(items)
    }

    const handleItem = (id: string, isNew = true) => {
        // console.log('handle item')
        // console.log(id)
        // questions.filter((q: any) => q._id !== id)
        if (isNew) {
            console.log('la pregunta es nueva')
            console.log(questions)
            setQuestions(
                questions.filter((_: any, index: number) => {
                    console.log(index.toString(), id)
                    return index.toString() !== id
                }),
            )
        } else {
            console.log('la pregunta no es nueva')
            setQuestions(questions.filter((q: any) => q._id !== id))
        }
    }

    const handlePoints = (e: React.ChangeEvent<HTMLInputElement>, questionId: string) => {
        let data2 = questions.map((q: any) => ({
            ...q,
            points: q._id === questionId ? +e.target.value : q.points,
        }))
        setQuestions(data2)
    }

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="data">
                {(provided) => (
                    <div
                        className="list_question scroll"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {questions.map((qst: any, key: number) => (
                            <ItemDragableQuestion
                                key={key}
                                tablePosition={key}
                                qst={qst}
                                idEvaluation={id}
                                updateData={updateData}
                                setQuestions={setQuestions}
                                questions={questions}
                                setTypeOption={setTypeOption}
                                deleteQuestion={deleteQuestion}
                                typeOption={typeOption}
                                handleItem={handleItem}
                                handlePoints={handlePoints}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default ListQuestions
