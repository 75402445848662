import { useState, useEffect, useCallback, useMemo } from 'react'
import { Media, TableColumn } from 'react-data-table-component'
import { useHistory } from 'react-router-dom'

import ContainerModal from '../../../components/modals/ContainerModal'
import { AddEditScheduled, DeleteScheduled } from '../../../components/modals/scheduleds'
import { Button, MyTable, TableHeader } from '../../../components'

import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHooks'
import { ModalOptions, Pagination, Scheduled } from '../../../interfaces'
import { useGetScheduledsQuery } from '../../../store/api'
import { selectAuth, setScheduledActive } from '../../../store/slices'

import './coursespage.sass'

const CoursesPage2 = () => {
    const { entity } = useAppSelector(selectAuth)
    const dispatch = useAppDispatch()
    const [pagination, setPagination] = useState<Pagination>({
        actualPage: 1,
        itemCount: 15,
        total: 0,
    })
    const [showModalAddEditScheduled, setShowModalAddEditScheduled] =
        useState<ModalOptions>('hide')
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const history = useHistory()
    const [textQuery, setTextQuery] = useState('')
    const [currentScheduled, setCurrentScheduled] = useState<Scheduled>()

    const { data, isLoading, isFetching, refetch } = useGetScheduledsQuery({
        entity_id: entity,
        page: pagination.actualPage!,
        docs: pagination.itemCount!,
        query: {
            field: 'name',
            search: textQuery,
        },
        sort: '-name',
    })

    const columns: TableColumn<Scheduled>[] = useMemo(
        () => [
            {
                name: 'Imagen',
                center: true,
                cell: (row) => (
                    <img
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                        src={row.coverpage}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = 'https://via.placeholder.com/30'
                        }}
                        alt={row.name}
                        height="30px"
                        width="30px"
                    />
                ),
            },
            {
                name: 'Título',
                selector: (row) => row.name,
            },
            {
                name: 'Categoría',
                selector: (row) => row.category.name,
            },
            {
                name: 'Descripción',
                selector: (row) => row.description,
                hide: Media.MD,
            },
            {
                name: 'Sede',
                selector: (row) => row.campus?.name || '',
            },
            {
                name: 'Inicio',
                selector: (row) => row.start_date.toString(),
            },
            {
                name: 'Fin',
                selector: (row) => row.end_date.toString(),
            },
        ],
        [],
    )

    const openAddModal = useCallback(() => setShowModalAddEditScheduled('add'), [])

    const goModules = useCallback(() => {
        currentScheduled && dispatch(setScheduledActive(currentScheduled))
        history.push('/dashboard/coordinator/modules/' + currentScheduled?._id)
    }, [history, currentScheduled, dispatch])

    const RowOptions = useCallback(
        () => (
            <div
                className="multiedu__table--row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '1rem',
                    padding: '1rem 1rem 1rem 6rem',
                }}
            >
                <Button
                    onClick={() => setShowModalAddEditScheduled('edit')}
                    text="Editar curso"
                />
                <Button onClick={() => setShowModalDelete(true)} text="Eliminar curso" />
                <Button onClick={goModules} text="Programar módulos" />
            </div>
        ),
        [goModules],
    )

    useEffect(() => {
        if (data) {
            let { itemCount, ...newPagination } = data.pagination
            setPagination((c) => ({
                ...c,
                ...newPagination,
            }))
        }
    }, [data])

    return (
        <div className="main_UsuariosB2bPage">
            <div className="headUsuariosB2b">
                <h3>Lista de Cursos Programados</h3>
            </div>
            <div className="bodyUsuariosB2b scroll">
                <div className="cardEntity_container">
                    <TableHeader
                        title="Cursos programados"
                        refetch={refetch}
                        handleShowAddModal={openAddModal}
                        setTextQuery={setTextQuery}
                    />
                    <MyTable
                        data={data?.scheduleds || []}
                        columns={columns}
                        isLoading={isLoading || isFetching}
                        activeItem={currentScheduled}
                        setActiveItem={setCurrentScheduled}
                        pagination={pagination}
                        setPagination={setPagination}
                        refetch={refetch}
                        expandableRowsComponent={RowOptions}
                        showPagination
                    />
                </div>
            </div>
            <ContainerModal showModal={showModalDelete}>
                <DeleteScheduled
                    setShowModal={setShowModalDelete}
                    scheduledId={currentScheduled?._id!}
                />
            </ContainerModal>
            <ContainerModal showModal={showModalAddEditScheduled !== 'hide'}>
                <AddEditScheduled
                    showModal={showModalAddEditScheduled}
                    setShowModal={setShowModalAddEditScheduled}
                    scheduled={currentScheduled}
                    setScheduled={setCurrentScheduled}
                />
            </ContainerModal>
        </div>
    )
}

export default CoursesPage2
