import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getPermisos, updatePermisos } from '../../context/entityContext/EntityAction'
import ItemPermissions from './itemPermissions/ItemPermissions'
// import Notification, { type } from '../../components/general/toast/Toast'

const PermissionsPage = () => {
    const token = JSON.parse(localStorage.getItem('user')!).token

    const { _id }: any = useParams()

    const [services, setservices] = useState({
        calendary: false,
        courses: false,
        discover: false,
        evaluations: false,
        forum: false,
        materials: false,
        slides: false,
        payment: false,
    })

    const [methods, setmethods] = useState({
        accessToSimulators: false,
        accessToVirtualReality: false,
        enableCourses: false,
        enableInteractiveCards: false,
        enableScrimba: false,
        enableVideos: false,
    })

    const [communication, setcommunication] = useState({
        chat: false,
        groupChat: false,
        notes: false,
    })

    const [empathy, setempathy] = useState({
        groups: false,
        list: false,
        random: false,
        timer: false,
        trivia: false,
    })

    const [footer, setfooter] = useState({
        audio: false,
        camera: false,
        form: false,
        sharescreen: false,
        slides: false,
        video: false,
    })

    const [meet, setmeet] = useState({
        ads: false,
        assists: false,
        evaluations: false,
        forums: false,
        groups: false,
        homeworks: false,
        lessons: false,
        parents: false,
        recordings: false,
        studentList: false,
    })

    const [zoom, setzoom] = useState({
        ads: false,
        assists: false,
        evaluations: false,
        forums: false,
        groups: false,
        homeworks: false,
        lessons: false,
        parents: false,
        recordings: false,
        studentList: false,
    })

    const [tools, settools] = useState({
        blocky: false,
        board: false,
        scratch: false,
    })

    const handleEachCheck = (e: any, tagname: any, value: any, state: any) => {
        state({
            ...tagname,
            [value]: e.target.checked,
        })
    }

    const handleAllCheck = (e: any, state: any, objeto: any) => {
        let newObj = {
            ...objeto,
        }

        for (let i in objeto) {
            newObj[i] = e.target.checked
        }

        state({
            ...newObj,
        })
    }

    const handleGeneralAllCheck = (e: any, state: any) => {
        // console.log(state);
        // console.log(objeto);

        state.map((sets: any) => {
            let newObj = {
                ...sets.objeto,
            }

            for (let i in sets.objeto) {
                newObj[i] = e.target.checked
            }

            sets.state({
                ...newObj,
            })
            return null
        })
    }

    const comprobateCheck = (tagname: any): any => {
        const value = JSON.stringify(tagname).includes('false')
        return !value
    }

    const data_permissions = [
        {
            name: 'Permisos a nivel de microservicios',
            tagname: 'services',
            check: (
                <input
                    type="checkbox"
                    checked={comprobateCheck(services)}
                    onChange={(e) => handleAllCheck(e, setservices, services)}
                />
            ),
            options: [
                {
                    name: 'Habilitar todos los  permisos en cursos  de academy Playtec',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={services.courses}
                            onChange={(e) =>
                                handleEachCheck(e, services, 'courses', setservices)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar todos los  permisos en descubre ',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={services.discover}
                            onChange={(e) =>
                                handleEachCheck(e, services, 'discover', setservices)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar permisos en calendario ',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={services.calendary}
                            onChange={(e) =>
                                handleEachCheck(e, services, 'calendary', setservices)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar permisos en material de clase ',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={services.materials}
                            onChange={(e) =>
                                handleEachCheck(e, services, 'materials', setservices)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar permisos en evaluaciones',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={services.evaluations}
                            onChange={(e) =>
                                handleEachCheck(e, services, 'evaluations', setservices)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar permisos en foros',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={services.forum}
                            onChange={(e) =>
                                handleEachCheck(e, services, 'forum', setservices)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar permisos en slides',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={services.slides}
                            onChange={(e) =>
                                handleEachCheck(e, services, 'slides', setservices)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar permisos en payment',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={services.payment}
                            onChange={(e) =>
                                handleEachCheck(e, services, 'payment', setservices)
                            }
                        />
                    ),
                    subPerm: [],
                },
            ],
        },
        {
            name: 'Permisos a nivel de funcionalidad',
            check: (
                <input
                    type="checkbox"
                    checked={comprobateCheck(methods)}
                    onChange={(e) => handleAllCheck(e, setmethods, methods)}
                />
            ),
            options: [
                {
                    name: 'Habilitar videos',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={methods.enableVideos}
                            onChange={(e) =>
                                handleEachCheck(e, methods, 'enableVideos', setmethods)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar cursos LMS',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={methods.enableCourses}
                            onChange={(e) =>
                                handleEachCheck(e, methods, 'enableCourses', setmethods)
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Accesos a los simuladores',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={methods.accessToSimulators}
                            onChange={(e) =>
                                handleEachCheck(
                                    e,
                                    methods,
                                    'accessToSimulators',
                                    setmethods,
                                )
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Accesso a realidad virtual y aumentada',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={methods.accessToVirtualReality}
                            onChange={(e) =>
                                handleEachCheck(
                                    e,
                                    methods,
                                    'accessToVirtualReality',
                                    setmethods,
                                )
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar permisos a fichas interactivas ',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={methods.enableInteractiveCards}
                            onChange={(e) =>
                                handleEachCheck(
                                    e,
                                    methods,
                                    'enableInteractiveCards',
                                    setmethods,
                                )
                            }
                        />
                    ),
                    subPerm: [],
                },
                {
                    name: 'Habilitar permisos a SCRIMBA',
                    check: () => (
                        <input
                            type="checkbox"
                            checked={methods.enableScrimba}
                            onChange={(e) =>
                                handleEachCheck(e, methods, 'enableScrimba', setmethods)
                            }
                        />
                    ),
                    subPerm: [],
                },
            ],
        },
        {
            name: 'Permisos a nivel de integración con terceros',
            check: (
                <input
                    type="checkbox"
                    onChange={(e) =>
                        handleGeneralAllCheck(e, [
                            {
                                state: setcommunication,
                                objeto: communication,
                            },
                            {
                                state: setempathy,
                                objeto: empathy,
                            },
                            {
                                state: settools,
                                objeto: tools,
                            },
                            {
                                state: setfooter,
                                objeto: footer,
                            },
                            {
                                state: setzoom,
                                objeto: zoom,
                            },
                            {
                                state: setmeet,
                                objeto: meet,
                            },
                        ])
                    }
                    checked={
                        comprobateCheck(communication) &&
                        comprobateCheck(empathy) &&
                        comprobateCheck(tools) &&
                        comprobateCheck(footer) &&
                        comprobateCheck(zoom) &&
                        comprobateCheck(meet)
                    }
                />
            ),
            detailPermission: [
                {
                    name: 'Zoom',
                    check: (
                        <input
                            type="checkbox"
                            checked={comprobateCheck(zoom)}
                            onChange={(e) => handleAllCheck(e, setzoom, zoom)}
                        />
                    ),
                    subPerm: [
                        {
                            name: 'Clases',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={zoom.lessons}
                                    onChange={(e) =>
                                        handleEachCheck(e, zoom, 'lessons', setzoom)
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Tareas',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={zoom.homeworks}
                                    onChange={(e) =>
                                        handleEachCheck(e, zoom, 'homeworks', setzoom)
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Lista de alumnos',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={zoom.studentList}
                                    onChange={(e) =>
                                        handleEachCheck(e, zoom, 'studentList', setzoom)
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Grupos',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={zoom.groups}
                                    onChange={(e) =>
                                        handleEachCheck(e, zoom, 'groups', setzoom)
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Foros',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={zoom.forums}
                                    onChange={(e) =>
                                        handleEachCheck(e, zoom, 'forums', setzoom)
                                    }
                                />
                            ),
                        },
                    ],
                },

                {
                    name: 'Meet',
                    check: (
                        <input
                            type="checkbox"
                            checked={comprobateCheck(meet)}
                            onChange={(e) => handleAllCheck(e, setmeet, meet)}
                        />
                    ),
                    subPerm: [
                        {
                            name: 'Clases',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={meet.lessons}
                                    onChange={(e) =>
                                        handleEachCheck(e, meet, 'lessons', setmeet)
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Tareas',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={meet.homeworks}
                                    onChange={(e) =>
                                        handleEachCheck(e, meet, 'homeworks', setmeet)
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Lista de alumnos',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={meet.studentList}
                                    onChange={(e) =>
                                        handleEachCheck(e, meet, 'studentList', setmeet)
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Grupos',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={meet.groups}
                                    onChange={(e) =>
                                        handleEachCheck(e, meet, 'groups', setmeet)
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Foros',
                            check: () => (
                                <input
                                    type="checkbox"
                                    checked={meet.forums}
                                    onChange={(e) =>
                                        handleEachCheck(e, meet, 'forums', setmeet)
                                    }
                                />
                            ),
                        },
                    ],
                },
                {
                    name: 'Interactive',
                    check: (
                        <input
                            type="checkbox"
                            onChange={(e) =>
                                handleGeneralAllCheck(e, [
                                    {
                                        state: setcommunication,
                                        objeto: communication,
                                    },
                                    {
                                        state: setempathy,
                                        objeto: empathy,
                                    },
                                    {
                                        state: settools,
                                        objeto: tools,
                                    },
                                    {
                                        state: setfooter,
                                        objeto: footer,
                                    },
                                ])
                            }
                            checked={
                                comprobateCheck(communication) &&
                                comprobateCheck(empathy) &&
                                comprobateCheck(tools) &&
                                comprobateCheck(footer)
                            }
                        />
                    ),
                    subPerm: [
                        {
                            name: 'Comunicación',
                            check: (
                                <input
                                    type="checkbox"
                                    onChange={(e) =>
                                        handleAllCheck(e, setcommunication, communication)
                                    }
                                    checked={comprobateCheck(communication)}
                                />
                            ),
                            items: [
                                {
                                    name: 'Chat',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={communication.chat}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    communication,
                                                    'chat',
                                                    setcommunication,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Chat grupal',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={communication.groupChat}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    communication,
                                                    'groupChat',
                                                    setcommunication,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Notas',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={communication.notes}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    communication,
                                                    'notes',
                                                    setcommunication,
                                                )
                                            }
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            name: 'Empatía',
                            check: (
                                <input
                                    type="checkbox"
                                    onChange={(e) =>
                                        handleAllCheck(e, setempathy, empathy)
                                    }
                                    checked={comprobateCheck(empathy)}
                                />
                            ),
                            items: [
                                {
                                    name: 'Azar',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={empathy.random}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    empathy,
                                                    'random',
                                                    setempathy,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Grupos',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={empathy.groups}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    empathy,
                                                    'groups',
                                                    setempathy,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Lista',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={empathy.list}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    empathy,
                                                    'list',
                                                    setempathy,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Timer',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={empathy.timer}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    empathy,
                                                    'timer',
                                                    setempathy,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Trivia ',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={empathy.trivia}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    empathy,
                                                    'trivia',
                                                    setempathy,
                                                )
                                            }
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            name: 'Tools',
                            check: (
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleAllCheck(e, settools, tools)}
                                    checked={comprobateCheck(tools)}
                                />
                            ),
                            items: [
                                {
                                    name: 'Blocky',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={tools.blocky}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    tools,
                                                    'blocky',
                                                    settools,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Pizarra',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={tools.board}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    tools,
                                                    'board',
                                                    settools,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Scratch',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={tools.scratch}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    tools,
                                                    'scratch',
                                                    settools,
                                                )
                                            }
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            name: 'Footer',
                            check: (
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleAllCheck(e, setfooter, footer)}
                                    checked={comprobateCheck(footer)}
                                />
                            ),
                            items: [
                                {
                                    name: 'Video',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={footer.video}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    footer,
                                                    'video',
                                                    setfooter,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Presentación',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={footer.form}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    footer,
                                                    'form',
                                                    setfooter,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Cámara',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={footer.camera}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    footer,
                                                    'camera',
                                                    setfooter,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Audio',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={footer.audio}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    footer,
                                                    'audio',
                                                    setfooter,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Compratir pantalla',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={footer.sharescreen}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    footer,
                                                    'sharescreen',
                                                    setfooter,
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    name: 'Slides',
                                    check: () => (
                                        <input
                                            type="checkbox"
                                            checked={footer.slides}
                                            onChange={(e) =>
                                                handleEachCheck(
                                                    e,
                                                    footer,
                                                    'slides',
                                                    setfooter,
                                                )
                                            }
                                        />
                                    ),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]

    useEffect(() => {
        getPermisos(token, _id)
            .then(({ data }) => {
                setservices(data.management.services)
                setmethods(data.management.methods)
                setmeet(data.management.externalServices.meet)
                setzoom(data.management.externalServices.zoom)
                setcommunication(data.management.interactive.communication)
                setempathy(data.management.interactive.empathy)
                setfooter(data.management.interactive.footer)
                settools(data.management.interactive.tools)
                // console.log(data);
            })
            .catch((e) => {
                console.log(e.response)
            })
    }, [token, _id])

    const [showTypePermission, setshowTypePermission] = useState('entity')

    const handleUpdatePermissions = () => {
        const dataPermission = {
            fname: 'updateManagement',
            service: 'users',
            entity_id: _id,
            externalServices: {
                zoom: zoom,
                meet: meet,
            },
            interactive: {
                communication: communication,
                empathy: empathy,
                footer: footer,
                tools: tools,
            },
            methods: methods,
            services: services,
        }

        // console.log(dataPermission);

        updatePermisos(token, dataPermission)
            .then((res) => {
                // console.log(res);

                // Notification(type.success, "Se actualizaron los permisos.")
                toast.success('Se actualizaron los permisos.')
            })
            .catch((e) => {
                console.log(e.response)

                // Notification(type.danger, "Se ha producido un error inesperado.")
                toast.error('Se ha producido un error inesperado.')
            })
    }

    return (
        <div className="container_PermisosPage">
            <div className="headUserPag">
                <h3>Permisos</h3>
                <div>
                    Agregar
                    <i className="fas fa-chevron-down"></i>
                </div>
            </div>
            <div className="bodyUserPag">
                <div className="cardPermisos_container">
                    <div className="cardPermisos_content">
                        <div className="cardPermisos_header">
                            <div className="headerContainer_Permisos">
                                <div
                                    className={`${
                                        showTypePermission === 'entity'
                                            ? 'itemHeader_active'
                                            : 'itemHeader'
                                    }`}
                                    onClick={() => setshowTypePermission('entity')}
                                >
                                    <h5> Permisos a nivel de entidades / Continental </h5>
                                </div>
                                <div
                                    className={`${
                                        showTypePermission === 'account'
                                            ? 'itemHeader_active'
                                            : 'itemHeader'
                                    }`}
                                    onClick={() => setshowTypePermission('account')}
                                >
                                    <h5>
                                        {' '}
                                        Permisos de usuarios a nivel de cuenta /
                                        Continental
                                    </h5>
                                </div>
                            </div>

                            <span>
                                Haga click en una o más casillas de verificación para
                                asignar privilegios a este rol.
                            </span>
                            <div className="search_box_permisos">
                                <div className="text_search_permisos">Buscar</div>
                                <div className="icons_search_permisos">
                                    <i className="fas fa-filter"></i>
                                    <i className="fas fa-search"></i>
                                </div>
                            </div>
                        </div>

                        {showTypePermission === 'entity' ? (
                            <div className="cardPermisos_body">
                                <div className="title_permissions_header">
                                    <div>Permisos</div>
                                    <div>Habilitar</div>
                                </div>

                                {data_permissions &&
                                    data_permissions.map((res, key) => (
                                        <ItemPermissions data={res} key={key} />
                                    ))}

                                <div className="container_button_permissions">
                                    <button
                                        className="button_design_permissions"
                                        onClick={handleUpdatePermissions}
                                    >
                                        {' '}
                                        Guardar Cambios{' '}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="cardPermisos_body">
                                {/* <div className="title_permissions_account_header">
                                        <div> Permisos </div>
                                        <div className="role_admins"> Admin B2B </div>
                                        <div className="role_admins"> Coordinador </div>
                                        <div className="role_admins"> Counter </div>
                                        <div className="role_admins"> Docente </div>
                                        <div className="role_admins"> Alumno </div>
                                    </div> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PermissionsPage
