import { types } from './../types/types'
import { IAction, IState } from './AuthInterface'

export const authReducer = (state: IState, action: IAction) => {
    switch (action.type) {
        case types.login:
            return {
                ...action.payload,
                logged: true,
            }

        case types.getRoles:
            return {
                ...state,
                rolesByEntity: action.payload,
            }

        case types.changeRoles:
            return {
                ...state,
                main_rol: action.payload,
            }

        case types.editUser:
            return {
                ...state,
                user: action.payload,
            }

        case types.editEntity:
            return {
                ...state,
                dataEntity: action.payload,
            }

        case types.logout:
            return {
                logged: false,
            }

        default:
            return state
    }
}
