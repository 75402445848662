import { useCallback, useEffect, useMemo, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { Button, MyTable, TableHeader } from '../../components'

import ContainerModal from '../../components/modals/ContainerModal'
import {
    AddFile,
    DeleteFile,
    EditFile,
} from '../../components/modals/continental/admin/files'
import { useAppSelector } from '../../hooks/useReduxHooks'
import { MaterialByEntity, Pagination } from '../../interfaces'
import { useGetMaterialsByEntityQuery } from '../../store/api'
import { selectAuth } from '../../store/slices'

const RepositoryPage2 = () => {
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false)
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)

    const [currentMaterial, setCurrentMaterial] = useState<MaterialByEntity>()

    const { entity } = useAppSelector(selectAuth)

    const [pagination, setPagination] = useState<Pagination>({
        actualPage: 1,
        itemCount: 15,
        total: 0,
    })
    const [textQuery, setTextQuery] = useState<string>('')

    const {
        data: dataMaterialsByEntity,
        isLoading,
        isFetching,
        refetch,
    } = useGetMaterialsByEntityQuery({
        entity_id: entity,
        date: false,
        page: pagination.actualPage,
        docs: pagination.itemCount,
        filter_by_title: textQuery,
    })

    // useEffect(() => {
    //     const payload = {
    //         token: token,
    //         entity_id: stateApariencia.currentIdEntity,
    //         dispatch: dispatch,
    //         setLoader: setLoader,
    //         setError: setError,
    //         page: 1,
    //         filter_by_title: '',
    //         docs: 15,
    //         descendant: -1,
    //         setpagination: setpagination,
    //     }

    //     getFiles(payload)
    // }, [dispatch, stateApariencia.currentIdEntity, token])

    const openAddModal = useCallback(() => setShowModalCreate(true), [])

    const columns: TableColumn<MaterialByEntity>[] = useMemo(
        () => [
            // {
            //     name: 'Image',
            //     cell: (row) => (
            //         <img src={row.image} alt={row.name} height="50px" width="50px" />
            //     ),
            // },
            {
                name: 'Título',
                selector: (row) => row.title,
                sortable: true,
                // button: true,
            },
            {
                name: 'Entidad',
                selector: (row) => row.entity.entity_name,
            },
            {
                name: 'Categoría',
                selector: (row) => row.category?.name || '',
            },
            {
                name: 'Fecha de creación',
                selector: (row) => row.date,
            },

            {
                name: 'Price',
                selector: (row) => row.price || 'Gratis',
            },
            {
                name: 'Creado por',
                selector: (row) => row.created_by.name,
            },
        ],
        [],
    )

    // const editMaterial = (e: any) => {
    //     // console.log(e)
    //     setCurrentFile(e)
    //     setShowModalEdit(true)
    // }

    // const deleteMaterial = (e: any) => {
    //     // console.log(e)
    //     setCurrentFile(e)
    //     setShowModalDelete(true)
    // }

    // const options = [
    //     {
    //         label: 'Actualizar Material',
    //         onClick: editMaterial,
    //     },
    //     {
    //         label: 'Eliminar Material',
    //         onClick: deleteMaterial,
    //     },
    // ]

    const RowOptions = useCallback(
        () => (
            <div className="multiedu__table--row">
                <Button
                    onClick={() => setShowModalEdit(true)}
                    text="Actualizar material"
                />
                <Button
                    onClick={() => setShowModalDelete(true)}
                    text="Eliminar material"
                />
            </div>
        ),
        [],
    )

    useEffect(() => {
        // console.log(data)
        if (dataMaterialsByEntity) {
            let { itemCount, ...newPagination } = dataMaterialsByEntity.pagination
            setPagination((c) => ({
                ...c,
                ...newPagination,
            }))
        }
    }, [dataMaterialsByEntity])

    return (
        <div className="container_RepositorioPage">
            <div className="headUserPag">
                <h3>Repositorio</h3>
            </div>

            <div className="bodyUserPag scroll">
                <div className="cardEntity_container">
                    <TableHeader
                        title="Listado de sede"
                        refetch={refetch}
                        handleShowAddModal={openAddModal}
                        setTextQuery={setTextQuery}
                        // showFilter={false}
                    />
                    <MyTable
                        columns={columns}
                        data={dataMaterialsByEntity?.materials || []}
                        isLoading={isLoading || isFetching}
                        activeItem={currentMaterial}
                        setActiveItem={setCurrentMaterial}
                        pagination={pagination}
                        setPagination={setPagination}
                        refetch={refetch}
                        expandableRowsComponent={RowOptions}
                        showPagination
                    />
                </div>
            </div>

            <ContainerModal showModal={showModalCreate}>
                <AddFile
                    setShowModalCreate={setShowModalCreate}
                    typeModalFile={'repository'}
                    // setCurrentMaterial={setCurrentMaterial}
                />
            </ContainerModal>

            <ContainerModal showModal={showModalEdit}>
                <EditFile
                    setShowModalEdit={setShowModalEdit}
                    material={currentMaterial!}
                    setCurrentMaterial={setCurrentMaterial}
                />
            </ContainerModal>

            <ContainerModal showModal={showModalDelete}>
                <DeleteFile
                    setShowModalDelete={setShowModalDelete}
                    material={currentMaterial!}
                    setCurrentMaterial={setCurrentMaterial}
                />
            </ContainerModal>
        </div>
    )
}

export default RepositoryPage2
