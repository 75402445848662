import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { useDeleteQuestionMutation } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'

interface QuestionDeleteProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    questionId: string
}

export const QuestionDelete = ({ setShowModal, questionId }: QuestionDeleteProps) => {
    const { entity } = useAppSelector(selectAuth)

    const [deleteQuestion, { isLoading }] = useDeleteQuestionMutation()

    const handleDeleteQuestion = () => {
        deleteQuestion({
            question_id: questionId,
            entity,
        })
            .then(() => {
                setShowModal(false)
            })
            .catch(console.log)
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i
                    className="fas fa-times-circle"
                    style={isLoading ? { pointerEvents: 'none' } : {}}
                    onClick={() => setShowModal(false)}
                />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar esta pregunta?</p>
                <div>
                    <button disabled={isLoading} onClick={() => setShowModal(false)}>
                        Cancelar
                    </button>
                    <button
                        disabled={isLoading}
                        className="saveButton"
                        onClick={handleDeleteQuestion}
                    >
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    )
}
