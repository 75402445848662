import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PublicRoute = ({
    isAuthenticated,
    component: Component,
    rol,
    ...rest
}: any) => {
    return (
        <Route
            {...rest}
            component={(props: any) =>
                !isAuthenticated ? (
                    <Component {...props} />
                ) : rol === 'superadmin' ? (
                    <Redirect to="/superadmin/dashboard/home" />
                ) : rol === 'admin_entity' || rol === 'user' ? (
                    <Redirect to="/dashboard" />
                ) : (
                    <Component {...props} />
                )
            }
        ></Route>
    )
}
