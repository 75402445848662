import { useContext, useState, useEffect } from 'react'
import { ColorExtractor } from 'react-color-extractor'
import { toast } from 'react-toastify'

import { AparienciaContext } from '../../context/aparienciaContext/AparienciaProvider'
import { typeUi } from './../../context/types/typesui'
import { changeThemeAxios } from './../../context/aparienciaContext/AparienciaAction'
import { updateEntitySuperAdmin } from '../../context/entityContext/EntityAction'
import { AuthContext } from './../../context/auth/AuthProvider'
import { types } from '../../context/types/types'
// import Notification, { type } from '../../components/general/toast/Toast'

const SettingsPage = (): JSX.Element => {
    const { state, dispatchImg } = useContext(AparienciaContext)

    const { state: stateApariencia } = useContext(AparienciaContext)

    const [preview, setPreview] = useState<string>('')

    const [previewPortrait, setPreviewPortrait] = useState<string>('')

    const [imgPort, setImgPort] = useState<any>('')

    const [imgLogo, setImgLogo] = useState<any>('')

    // console.log(imgPort)

    const [loading, setLoading] = useState<boolean>(false)

    const [view, setview] = useState('')

    const [colors, setColors] = useState<string[]>([])

    const { user, dispatch } = useContext(AuthContext)

    const [bgSidebar, setBgSidebar] = useState<string>(
        stateApariencia.theme.colorSidebar.bg_sidebar,
    )
    const [bgButtons, setBgButtons] = useState(
        stateApariencia.theme.colorSidebar.bg_buttons,
    )
    const [fontColor, setFontColor] = useState(
        stateApariencia.theme.colorSidebar.font_color,
    )

    const [loadEntity, setloadEntity] = useState(false)

    console.log(loadEntity)

    // console.log(bgSidebar)

    const token = JSON.parse(localStorage.getItem('user')!).token

    const [dataEntity, setDataEntity] = useState({
        entity_name: user.dataEntity.entity_name,
        entity_type: '',
        description: user.dataEntity.description,
        bg_sidebar: user.dataEntity.customize.colors.bg_sidebar,
        bg_buttons: user.dataEntity.customize.colors.bg_buttons,
        font_color: user.dataEntity.customize.colors.font_color,
        // primary_color: user.dataEntity.customize.colors.primary,
        // secondary_color: user.dataEntity.customize.colors.secondary,
        admin: user.dataEntity.admin,
    })

    const changeImagePortrait = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e
                setImgPort(imgPhoto)
                setPreviewPortrait(URL.createObjectURL(e))
                setLoading(!loading)
            }
        }
    }

    const changeImageLogo = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e
                setImgLogo(imgPhoto)
                setPreview(URL.createObjectURL(e))
                setLoading(!loading)
            }
        }
    }

    const changeDataEntity = (e: any, number?: boolean): void => {
        const { name, value } = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setDataEntity({
                    ...dataEntity,
                    [name]: value,
                })
            }
        } else {
            setDataEntity({
                ...dataEntity,
                [name]: value,
            })
        }
    }

    const changeImage = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e
                setImgLogo(imgPhoto)
                dispatchImg({
                    type: typeUi.setImgPreview,
                    payload: URL.createObjectURL(e),
                })
            }
        }
    }

    useEffect(() => {
        dispatchImg({
            type: typeUi.setImgReload,
        })
    }, [dispatchImg])

    const onClick = (e: any) => {
        e.target.value = ''
    }

    const changeColor = (color: string): void => {
        document.documentElement.style.setProperty('--color-primary', color)
        setBgSidebar(color)
    }

    const deleteImage = (): void => {
        dispatchImg({
            type: typeUi.changeImg,
            payload: '',
        })
        setImgLogo({})
    }

    const changeInputColor = (e: any) => {
        const colorSlc = e.target.value
        document.documentElement.style.setProperty('--color-primary', colorSlc)
        setBgSidebar(colorSlc)
    }

    const saveAparience = (): void => {
        let datan: FormData = new FormData()
        datan.append('fname', 'updateEntity')
        datan.append('entity_id', stateApariencia.currentIdEntity)
        datan.append('font_color', fontColor)
        datan.append('bg_sidebar', bgSidebar)
        datan.append('bg_buttons', bgButtons)
        datan.append('logo', imgLogo)

        changeThemeAxios(datan, token)
            .then(
                ({
                    data: {
                        entity: { customize },
                    },
                }) => {
                    // console.log(customize)

                    dispatchImg({
                        type: typeUi.getTheme,
                        payload: {
                            colorSidebar: customize.colors,
                            imgLogo: customize.logo,
                        },
                    })

                    // Notification(type.success, 'Apariencia actualizada correctamente.')
                    toast.success('Apariencia actualizada correctamente.')
                },
            )
            .catch((e) => {
                console.log(e.response)

                // Notification(type.danger, `Ha ocurrido un error inesperado.`)
                toast.error('Ha ocurrido un error inesperado.')
            })
    }

    const handleUpdateEntity = (e: any) => {
        // console.log(_id)
        // console.log(dataEntity.admin)
        e.preventDefault()

        setloadEntity(true)

        const datan: FormData = new FormData()
        datan.append('fname', 'updateEntity')
        datan.append('entity_name', dataEntity.entity_name)
        if (imgLogo !== '') {
            datan.append('logo', imgLogo)
        }

        if (imgPort !== '') {
            datan.append('portrait', imgPort)
        }
        // datan.append("entity_type", dataEntity.entity_type)
        datan.append('description', dataEntity.description)
        datan.append('bg_sidebar', dataEntity.bg_sidebar)
        datan.append('bg_buttons', dataEntity.bg_buttons)
        datan.append('font_color', dataEntity.font_color)
        // datan.append("admin_id", dataEntity.admin)
        datan.append('entity_id', stateApariencia.currentIdEntity)

        // console.log(datan)

        updateEntitySuperAdmin(datan, token)
            .then(({ data }) => {
                // console.log(data);

                dispatch({
                    type: types.editEntity,
                    payload: data.entity,
                })

                // Notification(type.success, "La entidad ha sido actualizada")
                toast.success('La entidad ha sido actualizada')

                setloadEntity(false)
            })
            .catch((e: any) => {
                console.log(e.response)

                // Notification(type.danger, "Ha ocurrido un error")
                toast.error('Ha ocurrido un error')

                setloadEntity(false)
            })
    }

    return (
        <div className="main_settings">
            {view === 'theme' ? (
                <div className="theme_container">
                    <div className="headInfo">
                        <span onClick={() => setview('settings')}>
                            {' '}
                            <i className="fas fa-chevron-left"></i> Tema{' '}
                        </span>
                        <p>Los colores se generan automaticamente de acuerdo al logo.</p>
                    </div>
                    <div className="bodyOptsApar">
                        <div className="imgColors">
                            <ColorExtractor
                                getColors={(colors: string[]) => setColors(colors)}
                                maxColors={4}
                            >
                                {state.theme.imgLogoPreview ? (
                                    <img
                                        src={state.theme.imgLogoPreview}
                                        alt="perfilPhoto"
                                    />
                                ) : state.theme.imgLogo ? (
                                    <img src={state.theme.imgLogo} alt="pdeplaytec" />
                                ) : (
                                    <img
                                        src="/images/login/playtec.svg"
                                        alt="pdeplaytec"
                                    />
                                )}
                            </ColorExtractor>
                            <div className="content_Colors">
                                {colors.map((color: string, key: number) => (
                                    <div
                                        className="circle"
                                        key={key}
                                        onClick={() => changeColor(color)}
                                    >
                                        <div
                                            className="semi-circulo"
                                            style={{ backgroundColor: color }}
                                        />
                                        <div className="semi-circulo invert" />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="inputContent">
                            <label className="designImgLabel" htmlFor="imageChoose">
                                <i className="far fa-image" />{' '}
                                <span>Seleccionar imagen</span>
                            </label>
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                id="imageChoose"
                                name="imageChoose"
                                className="inputFile"
                                onChange={(e: any) => changeImage(e.target.files[0])}
                                onClick={(e: any) => onClick(e)}
                            />
                            <div className="deleteImage" onClick={deleteImage}>
                                {' '}
                                <i className="fas fa-trash-alt"></i> Quitar imagen
                            </div>
                        </div>
                    </div>

                    <div className="design_advanced">
                        <h4>Configuraciones Avanzadas</h4>

                        <label>
                            {' '}
                            Color del menu:{' '}
                            <input
                                type="color"
                                value={bgSidebar}
                                onChange={(e) => changeInputColor(e)}
                            />{' '}
                        </label>

                        <label style={{ marginTop: '15px' }}>
                            {' '}
                            Color de botones:{' '}
                            <input
                                type="color"
                                value={bgButtons}
                                onChange={(e) => {
                                    setBgButtons(e.target.value)
                                    document.documentElement.style.setProperty(
                                        '--color-secondary',
                                        e.target.value,
                                    )
                                }}
                            />
                        </label>

                        <label>
                            {' '}
                            Color de letras del menu:{' '}
                            <input
                                type="color"
                                value={fontColor}
                                onChange={(e) => {
                                    setFontColor(e.target.value)
                                    document.documentElement.style.setProperty(
                                        '--color-font',
                                        e.target.value,
                                    )
                                }}
                            />
                        </label>
                    </div>

                    <div className="buttonContainerProfile">
                        <button className="buttonDesignProfile" onClick={saveAparience}>
                            Guardar Cambios
                        </button>
                    </div>
                </div>
            ) : view === 'fonts' ? (
                <div className="font_container">
                    <div className="headInfo">
                        <span onClick={() => setview('settings')}>
                            {' '}
                            <i className="fas fa-chevron-left"></i> Personalizar fuentes{' '}
                        </span>
                        <p>Escoja el tipo de fuente que mas le guste.</p>
                    </div>

                    <div className="design_advanced">
                        <div className="container_select">
                            <span>Primera fuente estandar</span>
                            <div className="content_select">
                                <label htmlFor="first_select">
                                    {' '}
                                    Seleccione una tipografía
                                </label>
                                <select
                                    id="first_select"
                                    name="select"
                                    className="design_input"
                                >
                                    <option value="" selected>
                                        Rubik
                                    </option>
                                    <option value="1.2">Roboto</option>
                                </select>
                            </div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Repellat sunt consequatur aliquam illum sequi amets
                            </p>
                        </div>

                        <div className="container_select">
                            <span>Segunda fuente estándar </span>
                            <div className="content_select">
                                <label htmlFor="second_select">
                                    {' '}
                                    Seleccione una tipografía
                                </label>
                                <select
                                    id="second_select"
                                    name="select"
                                    className="design_input"
                                >
                                    <option value="" selected>
                                        Rubik
                                    </option>
                                    <option value="1.2">Roboto</option>
                                </select>
                            </div>

                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Repellat sunt consequatur aliquam illum sequi amet ms
                            </p>
                        </div>
                    </div>

                    <div className="buttonContainerProfile">
                        <button className="buttonDesignProfile" onClick={saveAparience}>
                            Guardar Cambios
                        </button>
                    </div>
                </div>
            ) : (
                <div className="container_settings">
                    <span className="newstylespa">Entidad</span>
                    <form className="form_entitycontainer" onSubmit={handleUpdateEntity}>
                        <div className="contInAddEnt image">
                            <label htmlFor="imgPort" className="imgPort">
                                <div className="icon">
                                    <i className="far fa-edit" />
                                </div>
                                {previewPortrait ? (
                                    <img
                                        src={previewPortrait}
                                        alt="portada"
                                        className="imgPort"
                                    ></img>
                                ) : user.dataEntity.customize.portrait !== '' ? (
                                    <img
                                        src={user.dataEntity.customize.portrait}
                                        alt="portada"
                                        className="imgPort"
                                    ></img>
                                ) : (
                                    <div className="imgPort"></div>
                                )}
                            </label>

                            <input
                                id="imgPort"
                                name="imgPort"
                                type="file"
                                style={{ display: 'none' }}
                                accept=".png, .jpg, .jpeg"
                                onChange={(e: any) =>
                                    changeImagePortrait(e.target.files[0])
                                }
                            />

                            <label
                                className={
                                    user.dataEntity.customize.logo !== ''
                                        ? `imgPerf active`
                                        : ` imgPerf`
                                }
                                htmlFor="imgLogo"
                            >
                                <div>
                                    <i className="fas fa-camera" />
                                    <div>Perfil</div>
                                </div>
                                {/* <div className='iconPerf'>
                                        <i className='far fa-edit'/>
                                    </div> */}
                                <div>
                                    {preview ? (
                                        <img
                                            src={preview}
                                            alt="perfilPhoto"
                                            className="imgPhotoEntity"
                                        />
                                    ) : (
                                        <img
                                            src={user.dataEntity.customize.logo}
                                            alt="perfilPhoto"
                                            className="imgPhotoEntity"
                                        />
                                    )}
                                </div>
                            </label>

                            <input
                                id="imgLogo"
                                name="imgLogo"
                                type="file"
                                style={{ display: 'none' }}
                                accept=".png, .jpg, .jpeg"
                                onChange={(e: any) => changeImageLogo(e.target.files[0])}
                            />
                        </div>

                        <div className="contInAddEnt" style={{ padding: '13px 0' }}>
                            <p className="newstylespa">Nombre de la entidad</p>
                            <input
                                type="text"
                                onChange={(e) => changeDataEntity(e)}
                                name="entity_name"
                                value={dataEntity.entity_name}
                            />
                        </div>

                        <div className="contInAddEnt">
                            <p className="newstylespa">Descripción</p>
                            <textarea
                                onChange={(e) => changeDataEntity(e)}
                                name="description"
                                value={dataEntity.description}
                            />
                        </div>

                        <div className="buttonContainerProfile">
                            <button className="buttonDesignProfile" type="submit">
                                Guardar Cambios
                            </button>
                        </div>
                    </form>

                    <section>
                        <p style={{ fontWeight: 'bold' }}>Aspectos</p>

                        <div className="list_more_options">
                            <div
                                className="item_container"
                                onClick={() => setview('theme')}
                            >
                                <div className="item_content">
                                    <div className="first">
                                        <p> Tema </p>
                                        <p> Colores dashboard entidad </p>
                                    </div>
                                    <div className="last">
                                        <i className="fas fa-caret-right"></i>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="item_container"
                                onClick={() => setview('fonts')}
                            >
                                <div className="item_content">
                                    <div className="first">
                                        <p> Personalizar fuentes </p>
                                        <p> Colores dashboard entidad </p>
                                    </div>
                                    <div className="last">
                                        <i className="fas fa-caret-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </div>
    )
}

export default SettingsPage
