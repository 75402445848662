import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'

import AuthProvider from './context/auth/AuthProvider'
import { AparienciaProvider } from './context/aparienciaContext/AparienciaProvider'
import App from './routes/index'
import { store } from './store'

import './assets/styles/global.css'
import './assets/styles/index.sass'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-toastify/dist/ReactToastify.min.css'

const AdminApp = () => {
    return (
        <Provider store={store}>
            <AuthProvider>
                <AparienciaProvider>
                    <App />
                    <ToastContainer
                        theme="colored"
                        position="bottom-right"
                        autoClose={4000}
                    />
                </AparienciaProvider>
            </AuthProvider>
        </Provider>
    )
}

export default AdminApp
