import { useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useAppSelector } from '../../../../../hooks/useReduxHooks'

import { ModalOptions, QualifiedPractice } from '../../../../../interfaces'
import { useAddEditQualifiedPracticeMutation } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface FormPractice {
    id: string
    title: string
    weight: string
}

interface AddEditQualifiedPracticeProps {
    showModal: ModalOptions
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
    practice?: QualifiedPractice
    setPractice: React.Dispatch<React.SetStateAction<QualifiedPractice | undefined>>
    courseId: string
}

export const AddEditQualifiedPractice = ({
    showModal,
    setShowModal,
    practice,
    setPractice,
    courseId,
}: AddEditQualifiedPracticeProps) => {
    const { entity } = useAppSelector(selectAuth)
    const { register, handleSubmit } = useForm<FormPractice>({
        defaultValues: {
            id: practice ? practice._id : '',
            title: showModal === 'edit' ? practice?.title : '',
            weight: showModal === 'edit' ? practice?.weight.toString() : '',
        },
    })

    const [addEditPractice, { isLoading }] = useAddEditQualifiedPracticeMutation()

    const onSubmit: SubmitHandler<FormPractice> = (data) => {
        console.log({ data })
        if (showModal === 'edit') {
            addEditPractice({
                entity,
                fname: 'updateQualifiedPractice',
                qualified_practice: data.id,
                weight: +data.weight,
            }).then(() => setShowModal('hide'))
        } else {
            addEditPractice({
                entity,
                fname: 'createQualifiedPractice',
                course: courseId,
                weight: +data.weight,
                title: data.title,
            }).then(() => setShowModal('hide'))
        }
    }

    useEffect(() => {
        return () => setPractice(undefined)
    }, [setPractice])

    return (
        <div className="main_AddCategory">
            <div className="heaCategory">
                <h3>{showModal === 'edit' ? 'Editar' : 'Agregar'} Peso</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal('hide')} />
            </div>
            <div className="bodyCategory">
                <form className="contFormEnt" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contFormEnt">
                        <div className="contInAddEnt">
                            <p>Título</p>
                            <input
                                type="text"
                                maxLength={50}
                                placeholder="Nombre de la práctica"
                                disabled={showModal === 'edit'}
                                required
                                {...register('title')}
                            />
                        </div>

                        <div className="contInAddEnt">
                            <p>Peso</p>
                            <input
                                type="number"
                                min={0}
                                placeholder="0"
                                disabled={isLoading}
                                required
                                {...register('weight')}
                            />
                        </div>
                    </div>

                    <div className="optsBtnAdd">
                        <button onClick={() => setShowModal('hide')} disabled={isLoading}>
                            Cancelar
                        </button>

                        {isLoading ? (
                            <div className="containerSpinner">
                                <SpinnerModal />
                            </div>
                        ) : (
                            <button type="submit" disabled={isLoading}>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
