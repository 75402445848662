import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { useAddCredentialMutation } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

type AddCredentialProps = {
    setShowModalCreate: React.Dispatch<React.SetStateAction<boolean>>
    typeCredential: 'payment_method' | 'videoconference'
}

export const AddCredential = ({
    setShowModalCreate,
    typeCredential,
}: AddCredentialProps) => {
    const { entity } = useAppSelector(selectAuth)

    const nameCredential = useMemo(
        () =>
            typeCredential === 'payment_method'
                ? 'el método de pago'
                : 'la videoconferencia',
        [typeCredential],
    )

    const [data, setData] = useState({
        service: '',
        email: '',
        token: '',
    })

    const [addSede, { isLoading }] = useAddCredentialMutation()

    const onChangeInputs = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    ) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    }

    const handleAddCategory = (e: React.FormEvent) => {
        e.preventDefault()
        console.log({ data })
        console.log({ typeCredential })
        console.log({ entity })

        if (data.token.trim() === '') {
            return toast.warning('No puede crear una credencial con espacios en blanco')
        }

        addSede({
            ...data,
            entity: entity!,
            type: typeCredential,
        }).then(() => setShowModalCreate(false))
    }

    return (
        <div className="main_AddCategory">
            <div className="heaCategory">
                <h3>Crear Credenciales</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalCreate(false)}
                />
            </div>
            <div className="bodyCategory">
                <form className="contFormEnt" onSubmit={handleAddCategory}>
                    <div className="contFormEnt">
                        <div className="contInAddEnt">
                            <p>Tipo de credencial</p>
                            <select
                                name="service"
                                className="design_input"
                                onChange={onChangeInputs}
                                disabled={isLoading}
                                required
                            >
                                <option value=""> Seleccione una categoría ... </option>
                                {typeCredential === 'payment_method' ? (
                                    <>
                                        <option value="mercadopago"> Mercadopago </option>
                                        <option value="niubiz"> Niubiz </option>
                                        <option value="izipay"> iziPay </option>
                                    </>
                                ) : (
                                    <>
                                        <option value="zoom"> Zoom </option>
                                        <option value="meet"> Meet </option>
                                    </>
                                )}
                            </select>
                        </div>

                        <div className="contInAddEnt">
                            <p>Correo para crear {nameCredential}</p>
                            <input
                                type="email"
                                name="email"
                                value={data.email}
                                onChange={onChangeInputs}
                                required
                                disabled={isLoading}
                                maxLength={50}
                            />
                        </div>

                        <div className="contInAddEnt">
                            <p>Token</p>
                            <textarea
                                name="token"
                                value={data.token}
                                onChange={onChangeInputs}
                                required
                                disabled={isLoading}
                            />
                        </div>
                    </div>

                    <div className="optsBtnAdd">
                        {
                            <>
                                <button
                                    type="button"
                                    onClick={() => setShowModalCreate(false)}
                                >
                                    Cancelar
                                </button>
                                {isLoading ? (
                                    <div className="containerSpinner">
                                        <SpinnerModal />
                                    </div>
                                ) : (
                                    <button type="submit" disabled={isLoading}>
                                        Guardar
                                    </button>
                                )}
                            </>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
