import React, { createContext, useEffect, useReducer } from 'react'
import { IStateScheduledCourses } from './ScheduledCoursesInterface'
import { scheduledCoursesReducer } from './ScheduledCoursesReducer'

const initialState: IStateScheduledCourses = {
    scheduledCourses: [],
    currentScheduledCourses: null,
    pagination: {
        actualPage: 0,
        hasNextPage: false,
        hasPrevPage: false,
        itemCount: 0,
        nextPage: 0,
        success: true,
        total: 0,
        totalPages: 0,
    },
    selectScheduled: {},
}

export const ScheduledCoursesContext = createContext<IStateScheduledCourses | any>(
    initialState,
)

export const SheduledCoursesProvider = (props: any) => {
    const [stateScheduled, dispatch] = useReducer(
        scheduledCoursesReducer,
        initialState,
        () => {
            const localData = localStorage.getItem('stateScheduled')
            return localData ? JSON.parse(localData) : initialState
        },
    )
    useEffect(() => {
        localStorage.setItem('stateScheduled', JSON.stringify(stateScheduled))
    }, [stateScheduled])

    return (
        <ScheduledCoursesContext.Provider value={{ stateScheduled, dispatch }}>
            {props.children}
        </ScheduledCoursesContext.Provider>
    )
}
