import React, { createContext, useReducer } from 'react'
import { IState } from './EntityInterface'
import { entityReducer } from './EntityReducer'

const initialState: IState = {
    entities: [],
}

export const EntityContext = createContext<IState | any>(initialState)

export const EntityProvider = (props: any) => {
    const [state, dispatch] = useReducer<any>(entityReducer, initialState)

    return (
        <EntityContext.Provider value={{ state, dispatch }}>
            {props.children}
        </EntityContext.Provider>
    )
}
