export const types = {
    login: '[auth] login',
    logout: '[auth] logout',
    getRoles: '[auth] getRoles',
    changeRoles: '[auth] changeRoles',
    editUser: '[user] edit_user',
    editEntity: '[user] edit_entity',
    createUser: '[user] create_user',
    getUser: '[user] get_user',
}
