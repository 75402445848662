import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { Category, Scheduled } from '../../interfaces'
import type { RootState } from '../store'

interface CourseState {
    // courses: Course[]
    mainScheduled?: Scheduled
    currentCategory?: Category
}

const initialState: CourseState = {
    // courses: [],
    mainScheduled: undefined,
    currentCategory: undefined,
}

export const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        setScheduledActive: (state, action: PayloadAction<Scheduled>) => {
            console.log('schedule ACTIVEEE')
            state.mainScheduled = action.payload
            localStorage.setItem('currentScheduled', JSON.stringify(action.payload))
        },
        clearScheduleActive: (state) => {
            state.mainScheduled = undefined
            localStorage.removeItem('currentScheduled')
        },
        setCurrentCategory: (state, action: PayloadAction<Category>) => {
            state.currentCategory = action.payload
        },
        clearCurrentCategory: (state) => {
            state.currentCategory = undefined
        },
    },
})

export const {
    setScheduledActive,
    clearScheduleActive,
    setCurrentCategory,
    clearCurrentCategory,
} = courseSlice.actions

export const selectCourse = (state: RootState) => state.course
