import { useState } from 'react'
import { toast } from 'react-toastify'
// import Notification, {type} from '../../../../general/toast/Toast';

const InputCertificate = ({ res, setImageCertificates, imageCertificates }: any) => {
    const [imageName, setImageName] = useState('No se ha seleccionado ningun archivo')

    const onChangeImage = (e: any) => {
        if (e !== undefined) {
            const image = e.target.files[0]

            if (image !== undefined) {
                const typeFile = image.type
                if (
                    typeFile === 'image/png' ||
                    typeFile === 'image/jpg' ||
                    typeFile === 'image/jpeg' ||
                    typeFile === 'image/JPG'
                ) {
                    console.log(e)
                    // console.log(image)
                    setImageName(image.name)

                    let array: any = []
                    // let array2: any = []

                    array.push(image)

                    array.forEach((file: any) => {
                        file.type_file_back = e.target.name

                        // if (imageCertificates.length > 0) {

                        //     console.log('entras aqui? first')

                        //     imageCertificates.map((data: any, index: number) => {

                        //         console.log(data.name)
                        //         console.log(e.target.name)

                        //         if (e.target.name === data.name) {

                        //             console.log('hablaaaaaa')
                        //             array2.splice(index, 1)
                        //             array2.push(file)

                        //         } else {

                        //             console.log(array)
                        //             array2.push(file)

                        //         }
                        //     })

                        //     setImageCertificates(array2)

                        // } else {

                        //     console.log('entras aqui? second')

                        //     setImageCertificates([
                        //         ...imageCertificates,
                        //         file
                        //     ])

                        //     array2.push(file)

                        // }

                        // if (data.name === action.payload._id) {
                        //             return action.payload;
                        //         } else {
                        //             return data;
                        //         }

                        setImageCertificates([...imageCertificates, file])
                    })
                } else {
                    // Notification(type.warning, "Debe subir una imagen, no un archivo")
                    toast.warning('Debe subir una imagen, no un archivo')
                }
            }
        } else {
        }
    }

    return (
        <div>
            <p>Seleccione {res.name_front}</p>

            <label className="file_label">
                <span className="first_part">Subir Archivo</span>
                <input
                    type="file"
                    id={res.name}
                    name={res.name}
                    onChange={(e: any) => onChangeImage(e)}
                />
                <span className="second_part">{imageName} </span>
            </label>
        </div>
    )
}

export default InputCertificate
