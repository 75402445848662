import React, { useContext, useEffect, useState } from 'react'
import { getUsers } from '../../context/superAdminUser/UserActions'
import ModalCreateUsers from './../../components/modals/ModalCreateUsers'
import ItemUser from './itemUser/ItemUser'
import { types } from './../../context/types/types'
import { UserContext } from './../../context/superAdminUser/UserProvider'

const UsuariosPage = (): JSX.Element => {
    const [modalState, setModalState] = useState<boolean>(false)

    const { dispatch, state } = useContext(UserContext)

    const { allUsers } = state

    const showModal = () => {
        setModalState(true)
    }

    const closeModal = () => {
        setModalState(false)
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('user')!).token

        getUsers(token).then(({ data }) => {
            // console.log(data)

            // const user = [];

            // user.push(data.incomplete)
            // user.push(data.completed)
            // user.push(data.inprocess)

            // setCompleted(data.completed);
            // setIncompleted(data.incomplete);
            // setInProcess(data.inprocess)
            // console.log(user)

            let completed: any = []
            let incomplete: any = []
            let inProcess: any = []

            data.users.filter((user: any) => {
                if (user.register === 'completed') {
                    return (completed = [...completed, user])
                } else if (user.register === 'inProcess') {
                    return (inProcess = [...inProcess, user])
                } else {
                    return (incomplete = [...incomplete, user])
                }
            })

            dispatch({
                type: types.getUser,
                payload: {
                    incomplete: incomplete,
                    inProcess: inProcess,
                    completed: completed,
                },
            })
        })
    }, [dispatch])

    return (
        <>
            <div className="container_UsuariosPage">
                <div className="headUserPag">
                    <h3>Usuarios</h3>
                    <div onClick={showModal}>
                        Agregar
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div className="bodyUserPag">
                    <ItemUser
                        title="Usuarios invitados"
                        color={'#A34ECB'}
                        typeUser="incomplete"
                        userType={allUsers.incomplete}
                    />
                    <ItemUser
                        title="Usuarios en proceso"
                        color={'#F0A92E'}
                        typeUser="inprocess"
                        userType={allUsers.inProcess}
                    />
                    <ItemUser
                        title="Completado"
                        color={'#00A510'}
                        typeUser="completed"
                        userType={allUsers.completed}
                    />
                </div>
            </div>
            <ModalCreateUsers stateModal={modalState} closeModal={closeModal} />
        </>
    )
}

export default UsuariosPage
