import { useCallback } from 'react'

import { useDeleteSedeMutation } from '../../../../../store/api'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

type DeleteSedeProps = {
    setShowModalDelete: React.Dispatch<React.SetStateAction<boolean>>
    idSede: string
}

export const DeleteSede = ({ setShowModalDelete, idSede }: DeleteSedeProps) => {
    const [deleteSede, { isLoading }] = useDeleteSedeMutation()

    const closeModal = useCallback(() => {
        setShowModalDelete(false)
    }, [setShowModalDelete])

    const deleteSedeButton = () => {
        deleteSede({ campus: idSede }).then(() => closeModal())
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i className="fas fa-times-circle" onClick={closeModal} />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar esta sede?</p>
                {isLoading ? (
                    <div className="containerSpinner_center">
                        <button onClick={closeModal} disabled>
                            Cancelar
                        </button>
                        <div className="containerSpinner">
                            <SpinnerModal />
                        </div>
                    </div>
                ) : (
                    <div>
                        <button onClick={closeModal}>Cancelar</button>
                        <button className="saveButton" onClick={deleteSedeButton}>
                            Aceptar
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
