import React, { useState } from 'react'
import SubItemInteractive from './SubItemInteractive'

const SubItemPermissions = ({ data }: any) => {
    // console.log(data)
    const [showSubOption, setShowSubOption] = useState<boolean>(false)
    return (
        <>
            <div className="titleOption">
                <div className="left_title">
                    {data.name}
                    {data.subPerm.length > 0 ? (
                        <div
                            onClick={() => setShowSubOption(!showSubOption)}
                            className="icon_dropdown"
                        >
                            <i className="fas fa-chevron-down"></i>
                        </div>
                    ) : null}
                </div>

                <div>{data.check}</div>
            </div>

            <div>
                {showSubOption ? (
                    <ul className="sections">
                        {data.subPerm.length > 0
                            ? data.subPerm.map((dat: any, key: number) =>
                                  dat.items ? (
                                      <SubItemInteractive data={dat} key={key} />
                                  ) : (
                                      <div className="section_item" key={key}>
                                          <div>{dat.name}</div>

                                          {dat.check()}
                                      </div>
                                  ),
                              )
                            : null}
                    </ul>
                ) : null}
            </div>
        </>
    )
}

export default SubItemPermissions
