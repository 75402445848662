import React from 'react'
import './error.sass'

interface Props {
    error: number
}

const Error = ({ error }: Props): JSX.Element => {
    let message
    // ,
    //  photo: string = ''
    switch (error) {
        case 500:
            message = 'Internal server error.'
            // photo = ''
            break
        case 403:
            message = 'You do not have permission to view this resource.'
            // photo = ''
            break
        case 404:
            message = 'Sorry, we were unable to find that page.'
            // photo = ''
            break
        default:
            break
    }

    return (
        <div className="main_Error">
            <div className="contError">
                {/* <img src={photo} alt="Photo error page" /> */}
                <div className="contMessg">
                    <div>{error}</div>
                    <div>{message}</div>
                    <button onClick={() => window.location.reload()}>Reload page</button>
                </div>
            </div>
        </div>
    )
}

export default Error
