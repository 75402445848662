import './loader.scss'

export const Loader = () => <div className="custom-loader" />

export const MainLoader = () => (
    <div className="custom-loader__container">
        <Loader />
    </div>
)

export const SimpleLoader = () => (
    <div className="custom-loader__simple">
        <Loader />
    </div>
)

// export const IconLoader = () => <i className="fas fa-spinner custom-loader__icon" />
export const IconLoader = () => <i className="fa fa-spinner fa-pulse" />
