import { IState, IAction } from './ProgramsInterface'
import { typesPrograms } from './../../types/typesPrograms'
export const programsReducer = (state: IState, action: IAction): any => {
    switch (action.type) {
        case typesPrograms.loadingCategories:
            return {
                ...state,
                loadingCategories: action.payload,
            }
        case typesPrograms.getCategories:
            return {
                ...state,
                categories: action.payload,
            }

        case typesPrograms.createCategories:
            return {
                ...state,
                categories: [action.payload, ...state.categories],
            }

        case typesPrograms.currentCategory:
            return {
                ...state,
                currentCategory: action.payload,
            }

        case typesPrograms.removeCategory:
            return {
                ...state,
                currentCategory: null,
            }

        case typesPrograms.deleteCategories:
            return {
                ...state,
                categories: state.categories.filter(
                    (data: any) => data._id !== action.payload,
                ),
            }

        case typesPrograms.updateCategories:
            return {
                ...state,
                categories: state.categories.map((data: any) => {
                    if (data._id === action.payload._id) {
                        return action.payload
                    } else {
                        return data
                    }
                }),
            }

        case typesPrograms.getPrograms:
            return {
                ...state,
                courses: action.payload,
            }

        case typesPrograms.loadingModules:
            return {
                ...state,
                loadingModules: action.payload,
            }
        case typesPrograms.getModules:
            // console.log('cuanto llega aqui?', action.payload)
            return {
                ...state,
                modules: action.payload,
            }

        case typesPrograms.currentModule:
            return {
                ...state,
                currentModule: action.payload,
            }

        case typesPrograms.removeModule:
            // console.log('REMOVE MODULE')
            return {
                ...state,
                currentModule: action.payload,
            }

        case typesPrograms.removeModules:
            return {
                ...state,
                modules: action.payload,
            }

        case typesPrograms.removeFiles:
            // console.log('REMOVE FILES')
            return {
                ...state,
                filesByModule: action.payload,
            }

        case typesPrograms.getFilesByEntity:
            return {
                ...state,
                filesByModule: action.payload,
            }

        default:
            return state
    }
}
