import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useAppSelector } from '../../../../hooks/useReduxHooks'
import type { ModalOptions, Scheduled } from '../../../../interfaces'
import { useAddEditScheduledChildMutation } from '../../../../store/api'
import { selectAuth, selectCourse } from '../../../../store/slices'
import SpinnerModal from '../../../general/spinner/SpinnerModal'

interface FormScheduledChild {
    id: string
    name: string
    description: string
    start_date: string
    end_date: string
    course_id: string
}

interface AddEditScheduledChildProps {
    minDateStart: string
    minDateEnd: string
    maxDate: string
    showModal: ModalOptions
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
    activeScheduled: Scheduled
    setActiveScheduled: React.Dispatch<React.SetStateAction<Scheduled | undefined>>
    activeModuleId: string
    setMinDateEnd: React.Dispatch<React.SetStateAction<string>>
    setIsNew: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddEditScheduledChild = ({
    minDateStart,
    minDateEnd,
    maxDate,
    showModal,
    setShowModal,
    activeScheduled,
    setActiveScheduled,
    activeModuleId,
    setMinDateEnd,
    setIsNew,
}: AddEditScheduledChildProps) => {
    const { entity } = useAppSelector(selectAuth)
    const { mainScheduled } = useAppSelector(selectCourse)
    const { _idCourse } = useParams<{ _idCourse: string }>()
    const { register, handleSubmit, setValue, getValues } = useForm<FormScheduledChild>({
        defaultValues: {
            name: showModal === 'edit' ? activeScheduled.name : '',
            id: showModal === 'edit' ? activeScheduled._id : '',
            start_date: showModal === 'edit' ? activeScheduled.start_date.toString() : '',
            end_date: showModal === 'edit' ? activeScheduled.end_date.toString() : '',
            course_id: showModal === 'edit' ? activeScheduled.course._id : '',
            description: showModal === 'edit' ? activeScheduled.description : '',
        },
    })

    const [addEditScheduledChild, { isLoading }] = useAddEditScheduledChildMutation()

    const onSubmit: SubmitHandler<FormScheduledChild> = (data) => {
        // console.log({ data })
        console.log({ activeScheduled })
        addEditScheduledChild({
            scheduled_id: data.id,
            name: data.name,
            description: data.description,
            start_date: data.start_date,
            end_date: data.end_date,
            course_id: activeModuleId,
            campus_id: mainScheduled?.campus?._id || '',
            entity_id: entity,
            // fname: 'updateScheduled',
            fname: showModal === 'edit' ? 'updateScheduled' : 'createScheduled',
            main_scheduled: _idCourse,
            parent_scheduled: _idCourse,
            type_of_scheduled: 'children',
        })
            .unwrap()
            .then(({ scheduled }) => {
                // console.log({ scheduled })
                setActiveScheduled({
                    ...scheduled,
                    start_date: getValues('start_date'),
                    end_date: getValues('end_date'),
                })
                setIsNew(false)
                setShowModal('hide')
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="main_ModalCoursesScheduled" style={{ width: '400px' }}>
            <div className="headModalCoursesScheduled">
                <h3>{showModal === 'add' ? 'Programar' : 'Editar'} Módulo</h3>
            </div>
            <div className="bodyModalCoursesScheduled">
                <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'block' }}>
                    <div className="container_input_content_MUC">
                        <label>Título:</label>
                        <input
                            type="text"
                            className="input_design_content_MUC"
                            {...register('name')}
                        />
                    </div>
                    <div className="content_inputs_flex">
                        <div className="container_input_content_MUC">
                            <label>Fecha de inicio del curso</label>
                            <input
                                required
                                type="date"
                                min={minDateStart}
                                max={maxDate}
                                className="input_design_content_MUC"
                                {...register('start_date', {
                                    onChange: (e) => {
                                        setMinDateEnd(e.target.value)
                                        setValue('end_date', '')
                                    },
                                })}
                            />
                        </div>
                        <div className="container_input_content_MUC">
                            <label>Fecha de fin del curso</label>
                            <input
                                required
                                type="date"
                                min={minDateEnd}
                                max={maxDate}
                                className="input_design_content_MUC"
                                {...register('end_date')}
                            />
                        </div>
                    </div>
                    <div className="buttons_container">
                        <div
                            className="cancelButton"
                            style={isLoading ? { pointerEvents: 'none' } : {}}
                            onClick={() => setShowModal('hide')}
                        >
                            <span>CANCELAR</span>
                        </div>
                        {isLoading ? (
                            <div
                                className="containerSpinner"
                                style={{
                                    backgroundColor: 'var(--color-secondary)',
                                    color: '#fff',
                                    borderRadius: '10px',
                                    width: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <SpinnerModal />
                            </div>
                        ) : (
                            <button
                                disabled={isLoading}
                                type="submit"
                                className="saveButton"
                            >
                                GUARDAR
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
