import { axiosEntities } from './../../config/axios'

export const changeThemeAxios = async (obj: any, token: string) => {
    // console.log(obj)
    return axiosEntities({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

// export const getThemeAxios = async (token: string, obj : any) => {
//     return axiosAuth({
//         url: 'api',
//         headers: {
//             authorization: `Bearer ${token}`,
//         },
//         data: obj
//     })
// }

export const getEntityAndTheme = async (token: string, idEntity: string) => {
    return axiosEntities({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getEntity',
            service: 'users',
            entity_id: idEntity,
        },
    })
}

export const getRoles = async (token: string, idEntity: string, idUser: any) => {
    return axiosEntities({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getRoles',
            entity_id: idEntity,
            user_id: idUser,
        },
    })
}
