import { useCallback, useEffect, useMemo, useState } from 'react'
import type { TableColumn } from 'react-data-table-component'

import { Button, MyTable, TableHeader } from '../../components'
import ContainerModal from '../../components/modals/ContainerModal'
import {
    AddCredential,
    DeleteCredential,
    EditCredential,
} from '../../components/modals/continental/admin/credentials'
import { useAppSelector } from '../../hooks/useReduxHooks'

import { Credential, Pagination } from '../../interfaces'
import { useGetCredentialsQuery } from '../../store/api'
import { selectAuth } from '../../store/slices'

const CredentialsPage2 = () => {
    const [typeCredential, setTypeCredential] = useState<
        'payment_method' | 'videoconference'
    >('payment_method')

    const [showModalCreate, setShowModalCreate] = useState<boolean>(false)
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)

    const [pagination, setPagination] = useState<Pagination>({
        actualPage: 1,
        itemCount: 10,
        total: 0,
    })
    const { entity } = useAppSelector(selectAuth)
    const [skip, setSkip] = useState(true)
    const [activeCredential, setActiveCredential] = useState<Credential>()

    const { data, isLoading, isFetching, refetch } = useGetCredentialsQuery(
        {
            entity: entity!,
            page: pagination.actualPage!,
            docs: pagination.itemCount!,
            type: typeCredential,
            descendant: false,
        },
        {
            skip,
        },
    )

    const columns: TableColumn<Credential>[] = useMemo(
        () => [
            {
                name: 'Tipo de credencial',
                selector: (row) => row.type,
            },
            {
                name: 'Valor',
                selector: (row) => row.token,
                maxWidth: '250px',
            },
            {
                name: 'Email',
                selector: (row) => row.email,
            },
            {
                name: 'Fecha de creación',
                selector: (row) => row.date,
            },
        ],
        [],
    )

    const openAddModal = useCallback(() => setShowModalCreate(true), [])

    const RowOptions = useCallback(
        () => (
            <div
                className="multiedu__table--row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                    padding: '1rem',
                }}
            >
                <Button
                    onClick={() => setShowModalEdit(true)}
                    text="Actualizar credencial"
                />
                <Button
                    onClick={() => setShowModalDelete(true)}
                    text="Eliminar credencial"
                />
            </div>
        ),
        [],
    )

    useEffect(() => {
        setSkip(false)
        if (data) {
            let { itemCount, ...newPagination } = data.pagination
            setPagination((c) => ({
                ...c,
                ...newPagination,
            }))
        }
    }, [data])

    return (
        <div className="container_RepositorioPage scroll">
            <div className="headUserPag">
                <h3>Lista de Credenciales</h3>
            </div>

            <div className="bodyUserPagCredential">
                <div className="options_credentials ">
                    <div
                        className={
                            typeCredential === 'payment_method'
                                ? 'item_credentials active'
                                : 'item_credentials'
                        }
                        onClick={() => setTypeCredential('payment_method')}
                    >
                        <p>Métodos de pago</p>
                    </div>

                    <div
                        className={
                            typeCredential === 'videoconference'
                                ? 'item_credentials active'
                                : 'item_credentials'
                        }
                        onClick={() => setTypeCredential('videoconference')}
                    >
                        <p>Videoconferencias</p>
                    </div>
                </div>

                <div className="cardEntity_container">
                    <div className="cardEntity_body">
                        <TableHeader
                            title="Listado de credenciales"
                            refetch={refetch}
                            handleShowAddModal={openAddModal}
                            showFilter={false}
                        />
                        <MyTable
                            columns={columns}
                            data={data?.zoomToken || []}
                            isLoading={isLoading || isFetching}
                            activeItem={activeCredential}
                            setActiveItem={setActiveCredential}
                            pagination={pagination}
                            setPagination={setPagination}
                            refetch={refetch}
                            expandableRowsComponent={RowOptions}
                            showPagination
                        />
                    </div>
                </div>
            </div>

            <ContainerModal showModal={showModalCreate}>
                <AddCredential
                    typeCredential={typeCredential}
                    setShowModalCreate={setShowModalCreate}
                />
            </ContainerModal>

            <ContainerModal showModal={showModalEdit}>
                <EditCredential
                    setShowModalEdit={setShowModalEdit}
                    currentCredential={activeCredential!}
                />
            </ContainerModal>

            <ContainerModal showModal={showModalDelete}>
                <DeleteCredential
                    setShowModalDelete={setShowModalDelete}
                    idCredential={activeCredential?._id!}
                />
            </ContainerModal>
        </div>
    )
}

export default CredentialsPage2
