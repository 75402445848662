import { useState, useContext } from 'react'
import { toast } from 'react-toastify'

import { axiosCourses, axiosStudent } from '../../../../../config/axios'
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import SpinnerCertificate from './../../../../general/spinner/SpinnerCertificate'
// import Notification, { type } from '../../../../general/toast/Toast';

const GenerateCertificate = ({
    setShowModalCertificateView,
    user_data,
    setCertificate,
    courseScheduled,
    setSendEmail,
}: any) => {
    const { state: stateApariencia } = useContext(AparienciaContext)

    const [loading, setloading] = useState(false)

    const token = JSON.parse(localStorage.getItem('user')!).token

    const habilitarCertificado = () => {
        setloading(true)

        axiosStudent({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'generateCertificate_2',
                scheduled_id: courseScheduled,
                entity_id: stateApariencia.currentIdEntity,
                student_id: user_data._id,
            },
        })
            .then((data) => {
                // console.log(data)

                setloading(false)

                // Notification(type.success, "Se habilito el certificado correctamente")
                toast.success('Se habilito el certificado correctamente')

                axiosCourses({
                    url: '/v5.0.1/api',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    data: {
                        fname: 'getCertificateByUser',
                        entity: stateApariencia.currentIdEntity,
                        scheduled: courseScheduled,
                        user: user_data._id,
                    },
                })
                    .then(({ data }) => {
                        // console.log('CertificateUser', data);
                        setCertificate(data.certificate)
                        setShowModalCertificateView(false)
                    })
                    .catch((e) => {
                        console.log(e)
                        console.log(e.response)
                    })
            })
            .catch((e) => {
                console.log(e.response)
                setloading(false)
                // Notification(type.danger, "No tienes acceso a esta entidad")
                toast.error('No tienes acceso a esta entidad')
            })
    }

    return (
        <div className="main_searchStudent">
            <div className="headSearchStudent">
                <i
                    className="fas fa-times"
                    onClick={() => setShowModalCertificateView(false)}
                />
            </div>
            <div className="bodySearchStudent scroll">
                {
                    <>
                        <div className="contentSearch">
                            <h2>
                                Generar certificado para,{' '}
                                <span style={{ textTransform: 'capitalize' }}>
                                    {' '}
                                    {user_data.name + ' ' + user_data.lastname}{' '}
                                </span>{' '}
                            </h2>
                        </div>

                        <div className="containerBody">
                            <div className="content_certificate_body">
                                <p>
                                    Para poder generar certificado el alumno debe pagar
                                    por uno o todos nuestros certificados que están
                                    disponibles para este programa o el usted puede
                                    habilitarlo.{' '}
                                </p>
                            </div>

                            <div className="buttons_container">
                                {loading ? (
                                    <div className="containerSpinner">
                                        {' '}
                                        <SpinnerCertificate />{' '}
                                    </div>
                                ) : (
                                    <button onClick={habilitarCertificado}>
                                        {' '}
                                        Habilitar certificado{' '}
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default GenerateCertificate
