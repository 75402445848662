import { useCallback, useContext, useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { toast } from 'react-toastify'

import TypeUnique from './../types/TypeUnique'
import TypeMultiple from './../types/TypeMultitple'
import TypeBoolean from './../types/TypeBoolean'
import TypeShort from './../types/TypeShort'
import TypeLarge from './../types/TypeLarge'
import TypeFile from './../types/TypeFile'
import { AparienciaContext } from './../../../../../context/aparienciaContext/AparienciaProvider'
import { axiosEvaluations } from '../../../../../config/axios'
import { TINY_API_KEY } from '../../../../../config/utils'
// import Notification from '../../../../../components/general/toast/Toast';
// import { type as color } from '../../../../../components/general/toast/Toast';
import './styleModalQuestion.sass'
interface Props {
    dataedit: any
    token: string
    nameCourse: string
    edit: boolean
    closeModal: any
    setQuestions?: any
    idEvaluation?: string
    setTypeOption?: any
    newpeticionget?: () => void
}

const QuestionAdd = ({
    dataedit,
    token,
    nameCourse,
    edit,
    closeModal,
    setQuestions,
    idEvaluation,
    setTypeOption,
    newpeticionget,
}: Props) => {
    const [questiondata, setQuestionData]: any = useState({
        title: '',
        nivel: 'easy',
        type: 'unique',
        data_unique: [],
        data_multi: [],
        data_boolean: [
            { _id: '1', value: 'Verdadero', correct: false },
            { _id: '2', value: 'Falso', correct: false },
        ],
    })
    const { state: stateApariencia } = useContext(AparienciaContext)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [question, setquestion] = useState('')
    const { nivel, title, type } = questiondata

    const setCurrentQuestion = useCallback(
        (actual: any) => {
            function defineData(
                type_data: string,
                type_question: string,
                data_core: any,
            ) {
                setQuestionData({
                    ...questiondata,
                    title: data_core.title,
                    type: type_question,
                    nivel: data_core.nivel,
                    [type_data]: data_core.alternatives,
                })
                setquestion(data_core.question)
            }

            function defineDefault(data_core: any) {
                setQuestionData({
                    ...questiondata,
                    title: data_core.title,
                    type: data_core.type,
                    nivel: data_core.nivel,
                })
                setquestion(data_core.question)
            }

            switch (actual.type) {
                case 'unique':
                    defineData('data_unique', 'unique', actual)
                    break

                case 'multiple':
                    defineData('data_unique', 'multiple', actual)
                    break

                case 'boolean':
                    defineData('data_unique', 'boolean', actual)
                    break

                default:
                    defineDefault(actual)
                    break
            }
        },
        [setQuestionData, questiondata],
    )

    useEffect(() => {
        if (edit) setCurrentQuestion(dataedit)
        // console.log(dataedit);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit, dataedit])

    const onChangeInputs = (e: any) => {
        setQuestionData({
            ...questiondata,
            [e.target.name]: e.target.value,
        })
    }

    const onChangeOption = (e: any) => {
        setQuestionData({
            ...questiondata,
            type: e.target.value,
        })
    }

    function asignMessage(msg: string) {
        setMessage(msg)
        setTimeout(() => {
            setMessage('')
        }, 3000)
    }

    function validateUnique() {
        let msg = null
        let index = 0
        questiondata.data_unique.map((dt: any) => {
            if (!dt.value.length)
                return (msg =
                    'Hay una o mas alternativas en blanco. Completela o eliminela')
            if (dt.correct) return index++
            return null
        })

        if (msg) {
            asignMessage(msg)
            return true
        }

        if (!index) {
            asignMessage('Por favor, indique la alternativa correcta')
            return true
        }

        return false
    }

    function validateMultiple() {
        let msg = null
        let index = 0

        if (msg) {
            asignMessage(msg)
            return true
        }

        if (!index) {
            asignMessage('Por favor, indique almenos una alternativa correcta')
            return true
        }

        return false
    }

    function validateBoolean() {
        let msg = null
        let index = 0

        if (msg) {
            asignMessage(msg)
            return true
        }

        if (!index) {
            asignMessage('Por favor, indique si el enunciado es verdadero o falso')
            return true
        }

        return false
    }

    function sendArrayData(current_type: string) {
        switch (current_type) {
            case 'unique':
                return questiondata.data_unique

            case 'multiple':
                return questiondata.data_multi

            case 'boolean':
                return questiondata.data_boolean

            case 'short':
                return [{ value: 'Short Question', correct: true }]

            case 'large':
                return [{ value: 'Large Question', correct: true }]

            case 'file':
                return [{ value: 'File Question', correct: true }]

            default:
                break
        }
    }

    const onSaveQuestion = async (e: any) => {
        e.preventDefault()

        if (type === 'unique' && validateUnique()) return
        if (type === 'multiple' && validateMultiple()) return
        if (type === 'boolean' && validateBoolean()) return

        const data = {
            fname: 'createQuestion',
            entity: stateApariencia.currentIdEntity,
            type: type,
            title: title,
            nivel: nivel,
            keywords: [nameCourse],
            question: question,
            alternatives: sendArrayData(type),
        }
        petionpost(data, 'createQuestion')
        // setLoading(true);
        // setLoading(false);
    }

    const onUpdateQuestion = async (id: string, e: any) => {
        e.preventDefault()
        // console.log(id);

        if (type === 'unique' && validateUnique()) return
        if (type === 'multiple' && validateMultiple()) return
        if (type === 'boolean' && validateBoolean()) return

        const data = {
            fname: 'updateQuestion',
            entity: stateApariencia.currentIdEntity,
            question_id: id,
            title: title,
            type: type,
            nivel: nivel,
            keywords: [nameCourse],
            question: question,
            alternatives: sendArrayData(type),
        }
        petionpost(data, 'updateQuestion')
        // setLoading(true);
        // setLoading(false);
    }

    const getOptionsInOrder = (type_opt: string) => {
        let list = [
            {
                value: <option value="unique"> Opción única </option>,
                type: 'unique',
            },
            {
                value: <option value="multiple"> Selección múltiple </option>,
                type: 'multiple',
            },
            {
                value: <option value="boolean"> Verdadero o Falso </option>,
                type: 'boolean',
            },
            {
                value: <option value="short"> Pregunta corta </option>,
                type: 'short',
            },
            {
                value: <option value="large"> Pregunta larga </option>,
                type: 'large',
            },
            {
                value: <option value="file"> Pregunta con archivos </option>,
                type: 'file',
            },
        ]

        if (type_opt === 'none') return list.map((opt) => opt.value)

        const firstOption: any = list.find((opt) => opt.type === type_opt)
        list = list.filter((opt) => opt.type !== type_opt)
        list.unshift(firstOption)

        return list.map((opt) => opt.value)
    }

    const petionpost = (data: any, name?: string) => {
        // console.log(data);
        setLoading(true)
        axiosEvaluations({
            url: 'v5.0.1/api',
            method: 'POST',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(({ data }) => {
                // console.log(data);
                if (name) {
                    switch (name) {
                        case 'createQuestion':
                            // Notification(color.success, 'La pregunta ha sido creada correctamente.')
                            toast.success('La pregunta ha sido creada correctamente.')
                            break
                        case 'updateQuestion':
                            // Notification(color.success, 'La pregunta ha sido actualizada correctamente.')
                            toast.success(
                                'La pregunta ha sido actualizada correctamente.',
                            )
                            break
                        default:
                            break
                    }
                    if (newpeticionget) newpeticionget()
                    closeModal()
                }
                axiosEvaluations({
                    url: 'v5.0.1/api',
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    data: {
                        fname: 'getEvaluation',
                        evaluation_id: idEvaluation,
                    },
                })
                    .then(({ data }) => {
                        console.log(data, 'llegas aqui?')

                        let array: any[] = []

                        for (let { question, alternatives } of data.questions) {
                            array.push({ ...question, alternatives, questionbank: true })
                        }

                        setQuestions([...array])

                        setTypeOption('show')

                        closeModal()

                        // Notification(color.success, `Pregunta ${edit ? 'editada' : 'eliminada'} de la evaluación`)
                        toast.success(
                            `Pregunta ${edit ? 'editada' : 'eliminada'} de la evaluación`,
                        )
                        setLoading(false)
                    })
                    .catch((err) => {
                        console.log(err.response)
                        setLoading(false)
                    })
            })
            .catch((err) => {
                console.log(err.response)
                setLoading(false)
            })
    }

    return (
        <form
            onSubmit={
                edit ? (e: any) => onUpdateQuestion(dataedit._id, e) : onSaveQuestion
            }
            className="form_question"
        >
            <div className="container_options">
                <div className="select">
                    <label>Nivel de Dificultad:</label>
                    <select name="nivel" value={nivel} onChange={onChangeInputs}>
                        <option value="easy">Facil</option>
                        <option value="medium">Normal</option>
                        <option value="hard">Dificil</option>
                    </select>
                </div>
                <div className="select">
                    <label>Tipo de Pregunta:</label>
                    <select onChange={onChangeOption}>
                        {edit
                            ? getOptionsInOrder(dataedit.type)
                            : getOptionsInOrder('none')}
                    </select>
                </div>
            </div>
            <div className="container_options">
                <label>Titulo:</label>
                <input
                    className="input_question"
                    type="text"
                    name="title"
                    value={title}
                    onChange={onChangeInputs}
                />
            </div>
            <div className="container_options">
                <label>Pregunta:</label>
                <Editor
                    // initialValue={}
                    // cloudChannel='Default'
                    init={{
                        language: 'es',
                        language_url: '/langs/es.js',
                        directionality: 'ltr',
                        height: 300,
                        width: '100%',
                        menubar: true,
                        branding: false,
                        plugins: [
                            'advlist autolink lists link image',
                            'charmap print preview anchor help',
                            'searchreplace visualblocks code',
                            'insertdatetime media table paste wordcount',
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic | ' +
                            'alignleft aligncenter alignright |' +
                            'bullist numlist outdent indent | help',
                    }}
                    value={question}
                    // apiKey='otoxozb34a9nq19k4eaqydpvvgpwn9gwyrsharyfvbtstfyu'
                    apiKey={TINY_API_KEY}
                    onEditorChange={(content: string, editor: any) =>
                        setquestion(content)
                    }
                />
            </div>
            <div className="container_options">
                <label>Alternativas:</label>
                {type === 'unique' ? (
                    <TypeUnique
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                ) : type === 'multiple' ? (
                    <TypeMultiple
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                ) : type === 'boolean' ? (
                    <TypeBoolean
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                ) : type === 'short' ? (
                    <TypeShort
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                ) : type === 'large' ? (
                    <TypeLarge
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                ) : (
                    <TypeFile
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                )}
            </div>
            {edit ? (
                <div className="container_options button_options">
                    <button type="submit" disabled={loading}>
                        {' '}
                        Actualizar Pregunta{' '}
                    </button>
                </div>
            ) : (
                <div className="container_options button_options">
                    <button type="submit" disabled={loading}>
                        {' '}
                        Guardar Pregunta{' '}
                    </button>
                </div>
            )}
            <h4 className="menssage_modal_form">{message}</h4>
        </form>
    )
}

export default QuestionAdd
