import { useEffect, useMemo, useState } from 'react'

import { Chat, MyTable, TableHeader } from '../../components'
import { useAppSelector } from '../../hooks/useReduxHooks'
import type { CustomUser, Pagination, TableColumn } from '../../interfaces'
import { useGetUsersByEntityQuery } from '../../store/api'
import { selectAuth } from '../../store/slices'

const ChatPage = () => {
    const { entity } = useAppSelector(selectAuth)
    const [textQuery, setTextQuery] = useState<string>('')
    const [pagination, setPagination] = useState<Pagination>({
        actualPage: 1,
        itemCount: 25,
        total: 0,
    })

    const [currentUser, setCurrentUser] = useState<CustomUser>()

    const { data, isLoading, isFetching, refetch } = useGetUsersByEntityQuery({
        entity_id: entity,
        page: pagination.actualPage!,
        docs: pagination.itemCount!,
        query: {
            field: 'name',
            search: textQuery,
        },
    })

    const columns: TableColumn<CustomUser>[] = useMemo(
        () => [
            {
                cell: (row) =>
                    row.name.length > 10 && (
                        <span
                            style={{
                                // margin: '0.5rem',
                                // backgroundColor: 'red',
                                width: '50px',
                                height: '50px',
                                // borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                padding: '0.5rem',
                            }}
                        >
                            <span
                                style={{
                                    // width: '50px',
                                    // height: '50px',
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'var(--color-primary)',
                                    borderRadius: '50%',
                                    fontSize: '.8rem',
                                }}
                            >
                                {row.name.length > 17 ? '99+' : row.name.length}
                            </span>
                            {/* <i style={{ fontSize: '.5rem' }} className="fas fa-plus" /> */}
                            {/* +1 */}
                        </span>
                    ),
                center: true,
                grow: 0,

                // cell: (row) => (
                //     <div>
                //         <i className="fas fa-comment-dots" />
                //         +1
                //     </div>
                // ),
                // width: '60px',
            },
            {
                name: 'Foto',
                center: true,
                cell: (row) => (
                    <img
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                        src={row.photo}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = 'https://via.placeholder.com/30'
                        }}
                        alt={row.name}
                        height="30px"
                        width="30px"
                    />
                ),
                grow: 0,
            },
            {
                name: 'Nombre',
                selector: (row) => row.name,
                grow: 1,
            },
            {
                name: 'Apellido',
                selector: (row) => row.lastname,
                grow: 1,
            },
            {
                name: 'Email',
                selector: (row) => row.email,
                grow: 2,
            },
            {
                name: 'Teléfono',
                selector: (row) => row.phone,
            },
            {
                name: 'DNI',
                selector: (row) => row.dni,
                grow: 1,
            },
            {
                name: 'Dirección',
                selector: (row) => row.address,
            },
        ],
        [],
    )

    useEffect(() => {
        if (data) {
            let { itemCount, ...newPagination } = data.pagination
            setPagination((c) => ({
                ...c,
                ...newPagination,
            }))
        }
    }, [data])

    return (
        <>
            {currentUser ? (
                <Chat user={currentUser} setCurrentUser={setCurrentUser} />
            ) : (
                <div className="main_UsuariosB2bPage">
                    <div className="headUsuariosB2b">
                        <h3>Usuarios</h3>
                    </div>
                    <div className="bodyUsuariosB2b scroll">
                        <div className="cardEntity_container">
                            <TableHeader
                                title="Listado de usuarios"
                                setTextQuery={setTextQuery}
                                refetch={refetch}
                            />
                            <MyTable
                                data={data?.users || []}
                                columns={columns}
                                isLoading={isLoading || isFetching}
                                pagination={pagination}
                                setPagination={setPagination}
                                activeItem={currentUser}
                                setActiveItem={setCurrentUser}
                                refetch={refetch}
                                showPagination
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ChatPage
