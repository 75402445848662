import { IAction, IState } from './Userb2bInterface'
import { typeUserb2b } from '../types/typeUserb2b'

export const Userb2bReducer = (state: IState, action: IAction): any => {
    switch (action.type) {
        case typeUserb2b.getUsersb2b:
            return {
                ...state,
                userb2b: action.payload,
            }
        case typeUserb2b.createUsersb2b:
            return {
                ...state,
                userb2b: [...state.userb2b, action.payload],
            }
        case typeUserb2b.updateUsersb2b:
            return {
                ...state,
                userb2b: state.userb2b.map((data: any) => {
                    if (data._id === action.payload._id) {
                        return action.payload
                    } else {
                        return data
                    }
                }),
            }
        case typeUserb2b.deleteUsersb2b:
            return {
                ...state,
                userb2b: state.userb2b.filter((data: any) => data._id !== action.payload),
            }
        default:
            return state
    }
}
