import React, { useState } from 'react'

const FormUserB2B = ({ onSumbitData, loading, setModal, closeModal }: any) => {
    const [data, setData] = useState({
        name: '',
        lastname: '',
        dni: '',
        photo: '',
        email: '',
        // password: '',
        genre: '',
        phone: '',
        birthdate: '',
        place_of_birth: '',
        cv_url: '',
        address: '',
        linkedin_url: '',
        rol: '',
    })

    const {
        email,
        name,
        lastname,
        photo,
        genre,
        phone,
        birthdate,
        place_of_birth,
        dni,
        cv_url,
        address,
        linkedin_url,
        rol,
    } = data

    const [changeBtn, setChangeBtn] = useState<boolean>(false)

    //console.log(changeBtn)

    const getRol = (e: any) => {
        const { value } = e.target
        let valor = value
        if (value === '') {
            valor = ''
        }
        setData({
            ...data,
            rol: valor,
        })
    }

    const getGenre = (e: any) => {
        const { value } = e.target
        let valor = value
        if (value === '') {
            valor = ''
        }
        setData({
            ...data,
            genre: valor,
        })
    }

    const onChangeInputs = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    }

    const dataUser = {
        email: email,
        name: name,
        lastname: lastname,
        phone: phone,
        birthdate: birthdate,
        place_of_birth: place_of_birth,
        dni: dni,
        cv_url: cv_url,
        address: address,
        linkedin_url: linkedin_url,
        rol: rol,
        photo: photo,
        // password: password,
        genre: genre,
    }

    return (
        <>
            <div className="container_form_create_user">
                <form onSubmit={(e) => onSumbitData(e, dataUser)}>
                    <div className="infoDetailsCrU">
                        <div className={`firstForm ${changeBtn ? 'active' : ''}`}>
                            <div className="container_input_form">
                                <label htmlFor="photo" className="designFormInput">
                                    {' '}
                                    <i className="fas fa-camera"></i>{' '}
                                </label>
                                <span>Foto de perfil</span>
                                <input
                                    type="file"
                                    name="photo"
                                    id="photo"
                                    className="hideInputForm"
                                />
                            </div>

                            <div className="container_row">
                                <div className="container_input_left">
                                    <div className="text_form_create_user">
                                        <label>Nombres</label>
                                    </div>
                                    <input
                                        className="design_input"
                                        placeholder="Nombres"
                                        name="name"
                                        value={name}
                                        onChange={onChangeInputs}
                                    />
                                </div>

                                <div className="container_input_right">
                                    <div className="text_form_create_user">
                                        <label> Apellidos </label>
                                    </div>
                                    <input
                                        className="design_input"
                                        placeholder="Apellidos"
                                        name="lastname"
                                        value={lastname}
                                        onChange={onChangeInputs}
                                    />
                                </div>
                            </div>

                            <div className="container_input">
                                <div className="text_form_create_user">
                                    <label>Correo Electronico</label>
                                </div>
                                <input
                                    className="design_input"
                                    placeholder="Correo Electronico"
                                    name="email"
                                    value={email}
                                    onChange={onChangeInputs}
                                />
                            </div>

                            <div className="container_row">
                                <div className="container_input_left">
                                    <div>
                                        <div className="text_form_create_user">
                                            <label> N° de celular </label>
                                        </div>
                                        <input
                                            className="design_input"
                                            placeholder="N° de celular"
                                            name="phone"
                                            value={phone}
                                            onChange={onChangeInputs}
                                        />
                                    </div>
                                </div>

                                <div className="container_input_right">
                                    <div>
                                        <div className="text_form_create_user">
                                            <label> Fecha de nacimiento </label>
                                        </div>
                                        <input
                                            className="design_input"
                                            placeholder="Fecha de nacimiento"
                                            name="birthdate"
                                            value={birthdate}
                                            onChange={onChangeInputs}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="container_input">
                                <div>
                                    <div className="text_form_create_user">
                                        <label>
                                            Nro de DNI/ Pasaporte/ CarnetExtranjería
                                        </label>
                                    </div>
                                    <input
                                        className="design_input"
                                        placeholder="DNI"
                                        name="dni"
                                        value={dni}
                                        onChange={onChangeInputs}
                                    />
                                </div>
                            </div>

                            <div className="container_row">
                                <div className="container_input_left">
                                    <div>
                                        <div className="text_form_create_user">
                                            <label>Lugar de nacimiento </label>
                                        </div>
                                        <input
                                            className="design_input"
                                            placeholder="Lugar de nacimiento"
                                            name="place_of_birth"
                                            value={place_of_birth}
                                            onChange={onChangeInputs}
                                        />
                                    </div>
                                </div>

                                <div className="container_input_right">
                                    <div>
                                        <div className="text_form_create_user">
                                            <label> Dirección </label>
                                        </div>
                                        <input
                                            className="design_input"
                                            placeholder="Dirección"
                                            name="address"
                                            value={address}
                                            onChange={onChangeInputs}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`secondForm ${changeBtn ? 'active' : ''}`}>
                            <div className="container_input">
                                <div>
                                    <div className="text_form_create_user">
                                        <label>
                                            Adjuntar link del perfil de linkedin
                                        </label>
                                    </div>
                                    <input
                                        className="design_input"
                                        placeholder="Adjuntar link del perfil de linkedin"
                                        name="linkedin_url"
                                        value={linkedin_url}
                                        onChange={onChangeInputs}
                                    />
                                </div>
                            </div>

                            <div className="container_row">
                                <div className="container_input_left">
                                    <div>
                                        <div className="text_form_create_user">
                                            <label>Asignar Rol </label>
                                        </div>
                                        <select
                                            name="select"
                                            className="design_input"
                                            onChange={(e) => getRol(e)}
                                        >
                                            <option value="" selected>
                                                Asignar Rol
                                            </option>
                                            <option value="1.2">AdminUser</option>
                                            <option value="15" selected>
                                                Coordinador
                                            </option>
                                            <option value="20" selected>
                                                Counter
                                            </option>
                                        </select>
                                        {/* <input className="selectOptionUser" placeholder='Asignar Rol' name='rol' value={rol} onChange={onChangeInputs} /> */}
                                    </div>
                                </div>

                                <div className="container_input_right">
                                    <div>
                                        <div className="text_form_create_user">
                                            <label>Genero</label>
                                        </div>
                                        <select
                                            name="select"
                                            className="design_input"
                                            onChange={(e) => getGenre(e)}
                                        >
                                            <option value="" selected>
                                                Genero
                                            </option>
                                            <option value="Masculino">Masculino</option>
                                            <option value="Femenino" selected>
                                                Femenino
                                            </option>
                                        </select>
                                        {/* <input className="design_input" placeholder='Genero' name='genre' value={genre} onChange={onChangeInputs} /> */}
                                    </div>
                                </div>
                            </div>

                            <div className="container_input">
                                <div className="text_form_create_user">
                                    <label>Adjuntar CV </label>
                                </div>
                                <label htmlFor="photo" className="designFormUpload">
                                    {' '}
                                    Subir archivo{' '}
                                </label>
                                <input
                                    className="cv_input"
                                    type="file"
                                    name="cv_url"
                                    id="cv_url"
                                    value={cv_url}
                                    onChange={onChangeInputs}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="btnDetCrU">
                        <div className="container_pagination">
                            <div
                                className={`content_left ${!changeBtn ? 'active' : ''}`}
                                onClick={() => setChangeBtn(false)}
                            />
                            <div
                                className={`content_right ${changeBtn ? 'active' : ''}`}
                                onClick={() => setChangeBtn(true)}
                            />
                        </div>

                        <div className="container_button_userB2B">
                            <div
                                className="container_cancel_button"
                                onClick={() => {
                                    setModal(false)
                                    closeModal()
                                }}
                            >
                                Cancelar
                            </div>

                            {changeBtn ? (
                                <div className="container_next_button">
                                    <button type="submit">Finalizar</button>
                                </div>
                            ) : (
                                <div
                                    className="container_next_button"
                                    onClick={() => setChangeBtn(true)}
                                >
                                    <div className="divButton">Siguiente</div>
                                </div>
                            )}
                            {/* <div className="container_next_button">
                                {
                                    changeBtn ? <button type="submit">Finalizar</button> : <button onClick={() => setChangeBtn(true)} type="button">Siguiente</button>
                                }
                            </div> */}
                        </div>
                    </div>
                </form>
            </div>

            {/* <div> Contraseña </div>
                <input placeholder='Contraseña' name='password' value={password} onChange={onChangeInputs} /> */}

            {/* <div className="container_button_userB2B">
                    <div className="container_cancel_button">
                        Cancelar
                    </div>
                    <div className="container_next_button">
                        <button type="submit" >Finalizar</button>
                    </div>
                </div> */}
        </>
    )
}

export default FormUserB2B
