import { useState, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'

import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import { ScheduledCoursesContext } from '../../../../../context/sheduledCourses/ScheduledCoursesProvider'
import {
    getStudentCounterCourse,
    getUsersByScheduleds,
} from '../../../../../context/userCounter/UserCounterAction'
import CardUser from './items/CardUser'
import SpinnerModal from './../../../../general/spinner/SpinnerModal'
import { axiosCourses } from '../../../../../config/axios'
// import Notification, { type } from '../../../../general/toast/Toast';

export const AddUserEnrollment = ({
    setShowModal,
    setDataTable1,
    setpagination,
}: any) => {
    const [search, setSearch] = useState('')

    const [data, setData] = useState<any[]>([])

    const [dataSearch, setdataSearch] = useState<any[]>([])

    const { stateScheduled } = useContext(ScheduledCoursesContext)

    const { state: stateApariencia } = useContext(AparienciaContext)

    const { currentScheduledCourses } = stateScheduled

    const token = JSON.parse(localStorage.getItem('user')!).token

    useEffect(() => {
        if (currentScheduledCourses) {
            // console.log('Entras?')

            getStudentCounterCourse(
                token,
                stateApariencia.currentIdEntity,
                currentScheduledCourses.id,
            )
                .then(({ data }) => {
                    // console.log(data)

                    setData(data.users)
                })
                .catch((e) => {
                    console.log(e.response)

                    // Notification(type.danger, e.response.data.message)
                    toast.error(e.response.data.message)
                })
        }
    }, [currentScheduledCourses, stateApariencia.currentIdEntity, token])

    useEffect(() => {
        if (data.length > 0) {
            setdataSearch(data)
        }
    }, [data])

    const filterElement = (e: any) => {
        const valor = e.target.value.toLowerCase()

        setSearch(e.target.value)

        var search = data.filter((item: any) => {
            if (
                item.user.name?.toLowerCase().includes(valor) ||
                item.user.email?.toLowerCase().includes(valor)
            ) {
                return item
            } else {
                return null
            }
        })

        // console.log(search)

        setdataSearch(search)
    }

    return (
        <div className="main_searchStudent">
            <div className="headSearchStudent">
                <i className="fas fa-times" onClick={() => setShowModal(false)} />
            </div>
            <div className="bodySearchStudent scroll">
                <div className="contentSearch">
                    <div className="head_content_user_enrollment">
                        <h2>Matricular Estudiante</h2>

                        <div className="search_container_enrollment">
                            <input
                                type="text"
                                placeholder="Buscar"
                                value={search}
                                onChange={filterElement}
                            />
                            <i className="fas fa-search"></i>
                        </div>
                    </div>

                    <div className="body_content_user_enrollment scroll">
                        {dataSearch.length > 0 &&
                            dataSearch.map((res, index: number) =>
                                !res.is_enrolled ? (
                                    <CardUser
                                        data={res.user}
                                        key={index}
                                        setDataTable1={setDataTable1}
                                        setShowModal={setShowModal}
                                        setpagination={setpagination}
                                    />
                                ) : null,
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const DeleteUserEnrollment = ({
    detailsUser,
    setShowModalDelete,
    setpagination,
    setDataTable1,
}: any): JSX.Element => {
    const { _id } = detailsUser

    // console.log(_id)

    const [loading, setLoading] = useState<any>(false)
    const { state: stateApariencia } = useContext(AparienciaContext)
    const { stateScheduled } = useContext(ScheduledCoursesContext)
    const { currentScheduledCourses } = stateScheduled

    const deleteCourse = () => {
        const token = JSON.parse(localStorage.getItem('user')!).token

        setLoading(true)

        // console.log(currentScheduledCourses.id)
        // console.log(stateApariencia.currentIdEntity)
        // console.log(_id)

        axiosCourses({
            url: 'v5.0.1/api',
            method: 'POST',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                entity: stateApariencia.currentIdEntity,
                fname: 'deallocateUserFromScheduled',
                scheduled: currentScheduledCourses.id,
                user: _id,
            },
        })
            .then(async ({ data }) => {
                // console.log(data);

                setLoading(false)

                const payload = {
                    page: 1,
                    query: {
                        field: 'email',
                        search: '',
                    },
                    docs: 15,
                    descendant: false,
                    setpagination: setpagination,
                    setDataTable1: setDataTable1,
                    currentScheduled: currentScheduledCourses.id,
                    token: token,
                    entity_id: stateApariencia.currentIdEntity,
                }

                await getUsersByScheduleds(payload)

                // Notification(type.success, "Se ha eliminado al alumno del curso programado")
                toast.success('Se ha eliminado al alumno del curso programado')

                setShowModalDelete(false)
            })
            .catch((err) => {
                console.log(err)
                console.log(err.response)
                setLoading(false)
                // Notification(type.danger, "Ha ocurrido un inconveniente, inténtalo más tarde")
                toast.error('Ha ocurrido un inconveniente, inténtalo más tarde')
            })
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalDelete(false)}
                />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar este usuario del curso programado?</p>
                {loading ? (
                    <div className="containerSpinner_center">
                        <button onClick={() => setShowModalDelete(false)} disabled>
                            Cancelar
                        </button>
                        <div className="containerSpinner">
                            <SpinnerModal />
                        </div>
                    </div>
                ) : (
                    <div>
                        <button onClick={() => setShowModalDelete(false)}>
                            Cancelar
                        </button>
                        <button className="saveButton" onClick={deleteCourse}>
                            Aceptar
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
