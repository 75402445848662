import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHooks'
import { useDeleteCategoryMutation } from '../../../../../store/api'
import { clearCurrentCategory, selectAuth } from '../../../../../store/slices'

import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface DeleteCategoryProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    categoryId: string
}

export const DeleteCategory = ({ setShowModal, categoryId }: DeleteCategoryProps) => {
    const { entity } = useAppSelector(selectAuth)
    const dispatch = useAppDispatch()

    const [deleteCategory, { isLoading }] = useDeleteCategoryMutation()

    const handleDeleteCategory = () => {
        deleteCategory({
            category_id: categoryId,
            entity_id: entity,
        })
            .then(() => {
                setShowModal(false)
                dispatch(clearCurrentCategory())
            })
            .catch(console.log)
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal(false)} />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar la categoría?</p>
                {isLoading ? (
                    <div className="containerSpinner_center">
                        <button onClick={() => setShowModal(false)} disabled>
                            Cancelar
                        </button>
                        <div className="containerSpinner">
                            <SpinnerModal />
                        </div>
                    </div>
                ) : (
                    <div>
                        <button onClick={() => setShowModal(false)}>Cancelar</button>
                        <button className="saveButton" onClick={handleDeleteCategory}>
                            Aceptar
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
