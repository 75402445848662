export const handleOnInput = (
    e: React.FormEvent<HTMLInputElement>,
    maxValue: number,
    maxLength = 2,
) => {
    // console.log(e.currentcurrentTarget.value)
    let inputValues: number = 1
    let inputString = e.currentTarget.value
    if (!!e.currentTarget.value) inputValues = parseInt(e.currentTarget.value)

    // !!e.currentTarget.value -> false => valores permitidos
    // !!e.currentTarget.value && (Math.abs(inputValues) > 0) && Math.abs(inputValues) < 11

    !!e.currentTarget.value &&
    Math.abs(inputValues) > 0 &&
    Math.abs(inputValues) < maxValue &&
    inputString.length <= maxLength
        ? (e.currentTarget.value = Math.abs(inputValues).toString().slice(0, maxLength))
        : (e.currentTarget.value = '')
}
