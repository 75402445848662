import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import EvaluationsPage2 from '../../pages/coordinator/evaluations/EvaluationsPage2'
import EvaluationXCourse from '../../pages/coordinator/evaluations/evaluationXCourse/EvaluationXCourse'
import CreateEvaluationsPage from '../../pages/coordinator/evaluations/createEvaluation/CreateEvaluationsPage'
import QuestionAll2 from '../../pages/coordinator/evaluations/questions/QuestionAll2'
import CoursesPage2 from '../../pages/coordinator/courses/CoursesPage2'
import ModulePage2 from '../../pages/coordinator/courses/Modules/ModulePage2'
// import QuestionAll from '../../pages/coordinator/evaluations/questions/QuestionAll'
// import ModulePage from './../../pages/coordinator/courses/Modules/ModulePage'
// import CoursesPage from './../../pages/coordinator/courses/CoursesPage'

import {
    useGetTeachersByEntityQuery,
    useGetCoursesQuery,
    useGetCategoriesQuery,
} from '../../store/api'
import { useAppSelector } from '../../hooks/useReduxHooks'
import { selectAuth } from '../../store/slices'

const CoordinatorRoutes = () => {
    const { path } = useRouteMatch()

    // const [pagination, setpagination] = useState('')
    const { entity } = useAppSelector(selectAuth)

    useGetCategoriesQuery({ entity_id: entity })

    useGetCoursesQuery({ entity_id: entity })

    useGetTeachersByEntityQuery({ entity_id: entity })

    return (
        <Switch>
            {/* <Route exact path={`${path}/courses`} component={CoursesPage} /> */}
            <Route exact path={`${path}/courses`} component={CoursesPage2} />
            <Route exact path={`${path}/modules/:_idCourse`} component={ModulePage2} />
            <Route
                exact
                path={`${path}/evaluations`}
                component={EvaluationsPage2}
                // render={() => <EvaluationsPage />}
            />
            <Route
                exact
                path={`${path}/evaluations/:_idCourse/:_idModules`}
                component={EvaluationXCourse}
            />
            <Route
                exact
                path={`${path}/evaluations/:_idCourse/:_idModules/question_all`}
                component={QuestionAll2}
            />
            <Route
                exact
                path={`${path}/evaluations/:_idCourse/:_idModules/:action`}
                component={CreateEvaluationsPage}
            />
            <Route
                exact
                path={`${path}/evaluations/:_idCourse/:_idModules/:action/:_idEvaluation`}
                component={CreateEvaluationsPage}
            />
            <Redirect exact path={`/*`} to={`${path}/courses`} />
        </Switch>
    )
}

export default CoordinatorRoutes
