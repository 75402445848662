import { useContext, useMemo, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { listRoutes } from '../../routes/listroutes'
import DetailsUser from './../general/detailsUser/DetailsUser'

import { AparienciaContext } from '../../context/aparienciaContext/AparienciaProvider'
import { AuthContext } from './../../context/auth/AuthProvider'
import { types } from '../../context/types/types'
import { typeUi } from '../../context/types/typesui'
// import Notification, { type } from '../general/toast/Toast'

interface IOption {
    symbol: string
    name: string
    title: string
    path: string
    active: boolean
}

const options: IOption[] = [
    {
        symbol: 'A',
        name: 'admin',
        title: 'Admin',
        // path: '/dashboard/admin/home',
        path: '/dashboard/admin/courses',
        active: false,
    },
    {
        symbol: 'C',
        name: 'counter',
        title: 'Counter',
        path: '/dashboard/accountant/students/inscribed',
        active: false,
    },
    {
        symbol: 'Co',
        name: 'coordinador',
        title: 'Coordinador',
        path: '/dashboard/coordinator/courses',
        active: false,
    },
]

interface IRolByEntity {
    admin: boolean
    coordinador: boolean
    counter: boolean
}

interface SidebarMenuProps {
    children: React.ReactNode
    rol: string
    rolByEntity?: IRolByEntity
}

const SidebarMenu = ({ children, rol, rolByEntity }: SidebarMenuProps) => {
    // console.log(rol)
    // console.log(rolByEntity)
    const { state, dispatchImg } = useContext(AparienciaContext)

    const { user, dispatch } = useContext(AuthContext)

    const [newRol] = useState<string>(rol)

    const [showOption, setShowOption] = useState<boolean>(false)

    const [showEntity, setShowEntity] = useState<boolean>(false)

    const [showSide, setShowSide] = useState<boolean>(false)

    const roles = useMemo(
        () =>
            rolByEntity
                ? rolByEntity.admin // ? If is admin show all options
                    ? options.map((opt) => ({
                          ...opt,
                          active: true,
                      }))
                    : Object.entries(rolByEntity) // ? If is not admin show only the options that the user has
                          .map(([key, value]) => {
                              // console.log(key, value)
                              let opt =
                                  options.find(
                                      (option) => option.name === key.toLowerCase(),
                                  ) || ({} as IOption)
                              if (value && opt) {
                                  return {
                                      ...opt,
                                      active: true,
                                  }
                              }
                              return opt
                          })
                          .filter((r) => Object.keys(r).length !== 0 && r.active) // ? Filter the options that are not undefined and active
                : [],
        [rolByEntity],
    )
    // console.log(roles)

    const history = useHistory()

    const handleChangeEntity = (res: any) => {
        if (res.roles['coordinador']) {
            dispatch({
                type: types.changeRoles,
                payload: 'coordinador',
            })

            dispatchImg({
                type: typeUi.getTheme,
                payload: {
                    colorSidebar: res.customize.colors,
                    imgLogo: res.customize.logo,
                },
            })

            dispatchImg({
                type: typeUi.getId,
                payload: res._id,
            })

            dispatch({
                type: types.login,
                payload: {
                    username: user.user.email,
                    rol: user.user.rol,
                    main_rol: 'coordinador',
                    token: user.token,
                    idUser: user.user._id,
                    rolesByEntity: res.roles,
                    dataEntity: res,
                    listEntity: user.listEntity,
                    user: user.user,
                },
            })

            history.push('/dashboard/coordinator/courses')
        }

        if (res.roles['counter']) {
            dispatch({
                type: types.changeRoles,
                payload: 'counter',
            })

            dispatchImg({
                type: typeUi.getTheme,
                payload: {
                    colorSidebar: res.customize.colors,
                    imgLogo: res.customize.logo,
                },
            })

            dispatchImg({
                type: typeUi.getId,
                payload: res._id,
            })

            dispatch({
                type: types.login,
                payload: {
                    username: user.user.email,
                    rol: user.user.rol,
                    main_rol: 'counter',
                    token: user.token,
                    idUser: user.user._id,
                    rolesByEntity: res.roles,
                    dataEntity: res,
                    listEntity: user.listEntity,
                    user: user.user,
                },
            })

            history.push('/dashboard/accountant/students/inscribed')
        }

        if (res.roles['admin b2b']) {
            dispatch({
                type: types.changeRoles,
                payload: 'admin b2b',
            })

            dispatchImg({
                type: typeUi.getTheme,
                payload: {
                    colorSidebar: res.customize.colors,
                    imgLogo: res.customize.logo,
                },
            })

            dispatchImg({
                type: typeUi.getId,
                payload: res._id,
            })

            dispatch({
                type: types.login,
                payload: {
                    username: user.user.email,
                    rol: user.user.rol,
                    main_rol: 'admin b2b',
                    token: user.token,
                    idUser: user.user._id,
                    rolesByEntity: res.roles,
                    dataEntity: res,
                    listEntity: user.listEntity,
                    user: user.user,
                },
            })

            // history.push('/dashboard/admin/home')
            history.push('dashboard/admin/courses')
        }

        if (res.roles['admin']) {
            dispatch({
                type: types.changeRoles,
                payload: 'admin',
            })

            dispatchImg({
                type: typeUi.getTheme,
                payload: {
                    colorSidebar: res.customize.colors,
                    imgLogo: res.customize.logo,
                },
            })

            dispatchImg({
                type: typeUi.getId,
                payload: res._id,
            })

            dispatch({
                type: types.login,
                payload: {
                    username: user.user.email,
                    rol: user.user.rol,
                    main_rol: 'admin',
                    token: user.token,
                    idUser: user.user._id,
                    rolesByEntity: res.roles,
                    dataEntity: res,
                    listEntity: user.listEntity,
                    user: user.user,
                },
            })

            // history.push('/dashboard/admin/home')
            history.push('dashboard/admin/courses')
        }

        if (
            !res.roles['admin b2b'] &&
            !res.roles['counter'] &&
            !res.roles['coordinador']
        ) {
            history.push('/dashboard/user/entity')
            // Notification(type.danger, "No tienes acceso a esta entidad")
            toast.error('No tienes acceso a esta entidad')
        }
    }

    return (
        <div className="container_dashboard">
            <div className="hambImg">
                <i className="fas fa-bars" onClick={() => setShowSide(true)} />
                {state.theme.imgLogoPreview ? (
                    <img src={state.theme.imgLogoPreview} alt="perfilPhoto" />
                ) : user.dataEntity.customize.logo ? (
                    <img src={user.dataEntity.customize.logo} alt="empLogo" />
                ) : (
                    <img src="/images/login/playtec.svg" alt="pdeplaytec" />
                )}
            </div>
            <div
                className={`sidebar_container${showSide ? ' show' : ''}`}
                id="sidebarPrimary"
            >
                <div className="closeSide">
                    <i className="fas fa-times" onClick={() => setShowSide(false)} />
                </div>
                <div
                    className={`logo_sidebar ${
                        user.dataEntity.domain
                            ? user.dataEntity.domain === 'cuidate-web'
                                ? ''
                                : 'tras'
                            : ''
                    }`}
                >
                    <div className="logo_sidebar_content">
                        {state.theme.imgLogoPreview ? (
                            <img src={state.theme.imgLogoPreview} alt="perfilPhoto" />
                        ) : user.dataEntity.customize.logo ? (
                            <img src={user.dataEntity.customize.logo} alt="empLogo" />
                        ) : (
                            <img src="/images/login/playtec.svg" alt="pdeplaytec" />
                        )}
                    </div>

                    {user.listEntity.length > 1 ? (
                        <div
                            className="menuOptionsEntity"
                            onClick={() => setShowEntity(!showEntity)}
                        >
                            <div className="arrow_choose">
                                <i className="fas fa-chevron-up"></i>
                                <i className="fas fa-chevron-down"></i>
                            </div>
                        </div>
                    ) : null}

                    <div
                        className="option_role_menu"
                        style={{
                            display: `${showEntity ? 'block' : 'none'}`,
                            zIndex: 3,
                            borderRadius: '0 0 20px 20px',
                        }}
                    >
                        {user.listEntity.map((res: any, key: number) => (
                            <div
                                className="container_role"
                                onClick={() => handleChangeEntity(res)}
                                key={key}
                            >
                                <div className="logo_role">
                                    <img
                                        className="imgPhoto"
                                        src={res.customize.logo}
                                        alt="ImagItem"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div
                    className="container_choose_role"
                    onClick={() =>
                        rolByEntity !== null ? setShowOption(!showOption) : null
                    }
                >
                    <div className="content_choose">
                        <div className="image_text_choose">
                            <div className="image_role">
                                {/* <span>{rol === "counter" ? 'C' : rol === "coordinador" ? 'CO' : 'A'}</span> */}
                                <span>
                                    {rol === 'admin'
                                        ? 'A'
                                        : rol === 'counter'
                                        ? 'C'
                                        : rol === 'coordinador'
                                        ? 'CO'
                                        : null}
                                </span>
                            </div>
                            <div className="text_role">
                                {/* <span> {rol === 'admin b2b' ? 'admin' : rol} </span> */}
                                <span> {rol} </span>
                            </div>
                        </div>
                        <div className="arrow_choose">
                            <i className="fas fa-chevron-up"></i>
                            <i className="fas fa-chevron-down"></i>
                        </div>
                    </div>

                    {roles.length > 0 && (
                        <div
                            className="option_role_menu"
                            style={{ display: `${showOption ? 'block' : 'none'}` }}
                        >
                            {roles.map((rol) => (
                                <SidebarMenuOption key={rol.name} option={rol} />
                            ))}
                            {/* <div
                            onClick={() => {
                                dispatch({
                                    type: types.changeRoles,
                                    // payload: 'admin b2b'
                                    payload: 'admin',
                                })
                                history.push('/dashboard/admin/home')
                            }}
                            className="container_role"
                            style={{
                                display: `${
                                    rolByEntity && rolByEntity['admin'] && rol === 'admin'
                                        ? 'flex'
                                        : 'none'
                                }`,
                            }}>
                            <div className="image_role">
                                <span>A</span>
                            </div>
                            <div className="text_role">
                                <span>Admin</span>
                            </div>
                        </div> */}

                            {/* <div
                            onClick={() => {
                                dispatch({
                                    type: types.changeRoles,
                                    payload: 'counter',
                                })
                                history.push('/dashboard/accountant/students/inscribed')
                            }}
                            className="container_role"
                            style={{
                                display: `${
                                    rolByEntity &&
                                    rolByEntity['counter'] &&
                                    (rol !== 'counter') === false
                                        ? 'none'
                                        : 'flex'
                                }`,
                            }}>
                            <div className="image_role">
                                <span>C</span>
                            </div>
                            <div className="text_role">
                                <span>Counter</span>
                            </div>
                        </div>

                        <div
                            onClick={() => {
                                dispatch({
                                    type: types.changeRoles,
                                    payload: 'coordinador',
                                })

                                history.push('/dashboard/coordinator/courses')
                            }}
                            className="container_role"
                            style={{
                                display: `${
                                    rolByEntity &&
                                    rolByEntity['coordinador'] &&
                                    (rol !== 'coordinador') === false
                                        ? 'none'
                                        : 'flex'
                                }`,
                            }}>
                            <div className="image_role">
                                <span>CO</span>
                            </div>
                            <div className="text_role">
                                <span>Coordinador</span>
                            </div>
                        </div> */}
                        </div>
                    )}
                </div>
                <div className="container_nav">
                    <ul className="list_nav">
                        {/* {
                            rolByEntity !== {} ? ListRoutes.data.map((data) =>
                                data.rol === newRol &&
                                data.routes.map(({ name, icon, path }, key: number) =>
                                    <li className="list_nav_item" key={key}>
                                        <NavLink to={path} className="link_sidebar" activeClassName="active">
                                            <i className={icon} /><span>{name}</span>
                                        </NavLink>
                                    </li>
                                )
                            ) :  ListRoutes.data.map((data) =>
                                data.rol === newRol &&
                                data.routes.map(({ name, icon, path }, key: number) =>
                                    <li className="list_nav_item" key={key}>
                                        <NavLink to={path} className="link_sidebar" activeClassName="active">
                                            <i className={icon} /><span>{name}</span>
                                        </NavLink>
                                    </li>
                                )
                            )
                        } */}
                        {listRoutes.map(
                            (data) =>
                                data.rol === newRol &&
                                data.routes.map(({ name, icon, path }, key: number) => (
                                    <li className="list_nav_item" key={key}>
                                        <NavLink
                                            to={path}
                                            className="link_sidebar"
                                            activeClassName="active"
                                        >
                                            <i className={icon} />
                                            <span>{name}</span>
                                        </NavLink>
                                    </li>
                                )),
                        )}
                    </ul>
                </div>
            </div>

            <div className="content_dashboard">
                <DetailsUser rol={rol}></DetailsUser>
                {children}
            </div>
        </div>
    )
}

export default SidebarMenu

interface SidebarMenuOptionProps {
    option: IOption
}

export const SidebarMenuOption = ({
    option: { name, path, title, symbol },
}: SidebarMenuOptionProps) => {
    const { dispatch } = useContext(AuthContext)
    const history = useHistory()
    return (
        <div
            onClick={() => {
                dispatch({
                    type: types.changeRoles,
                    // payload: 'admin b2b'
                    payload: name,
                })
                history.push(path)
            }}
            style={{ display: 'flex' }}
            className="container_role"
        >
            <div className="image_role">
                <span>{symbol}</span>
            </div>
            <div className="text_role">
                <span>{title}</span>
            </div>
        </div>
    )
}
