import React, { FormEvent, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import Spinner from './../../components/general/spinner/Spinner'
import CardAccess from './CardAccess'
import { Link } from 'react-router-dom'
import { verifyTokenExpiration } from '../../context/auth/AuthAction'
import { changePasswordService } from './../../context/auth/AuthAction'

const ResetPasswordPage = () => {
    const { token }: any = useParams()

    // console.log(token)

    let data: any = { title: 'Cambiar contraseña' }

    const [user, setUser] = useState({
        password: '',
        verfpass: '',
    })

    const { password, verfpass } = user

    const [validtoken, setValidToken] = useState('loading')

    const [diffpass, setDiff] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [lookpass, setLookPass] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(false)

    const onChangePass = (e: any) => {
        if (e.target.name === 'verfpass' && password === e.target.value) setDiff(false)
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        })
    }

    const validateToken = useCallback(async () => {
        const expired = await verifyTokenExpiration(token)
        if (expired) return setValidToken('invalid')
        return setValidToken('valid')
    }, [token])

    useEffect(() => {
        validateToken()
    }, [validateToken])

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        if (password !== verfpass) return setDiff(true)
        const success = await changePasswordService(password, token)
        if (success) setStatus(true)
        setLoading(false)
    }

    return (
        <div className="main_AccessPage">
            {validtoken === 'loading' ? (
                <Spinner />
            ) : validtoken === 'invalid' ? (
                <div>
                    Lo sentimos, el tiempo brindado para cambiar su contraseña ha vencido.
                    Por favor, solicite nuevamente un cambio de contraseña
                </div>
            ) : (
                <CardAccess data={data}>
                    <form onSubmit={onSubmit}>
                        <div className="formBody">
                            {!status ? (
                                <>
                                    <label htmlFor="accessPass">
                                        <input
                                            id="accessPass"
                                            className="inpPass"
                                            type={`${lookpass ? 'text' : 'password'}`}
                                            placeholder="Ingrese contraseña"
                                            onChange={onChangePass}
                                            disabled={loading}
                                            name="password"
                                            value={password}
                                            required
                                        />
                                        <i
                                            className={`far fa-eye${
                                                lookpass ? '-slash' : ''
                                            }`}
                                            onClick={() => setLookPass(!lookpass)}
                                        />
                                    </label>
                                    <label htmlFor="accessPassR">
                                        {diffpass ? (
                                            <div className="msgDetail">
                                                Las contraseñas no coinciden.
                                            </div>
                                        ) : null}
                                        <input
                                            id="accessPassR"
                                            className={`inpPass ${
                                                diffpass ? 'incorrect' : ''
                                            }`}
                                            type={`${lookpass ? 'text' : 'password'}`}
                                            placeholder="Repita contraseña"
                                            onChange={onChangePass}
                                            disabled={loading}
                                            name="verfpass"
                                            value={verfpass}
                                            required
                                        />
                                        <i
                                            className={`far fa-eye${
                                                lookpass ? '-slash' : ''
                                            }`}
                                            onClick={() => setLookPass(!lookpass)}
                                        />
                                    </label>
                                </>
                            ) : (
                                <>
                                    <div className="info">
                                        Su contraseña ha sido restaurada con éxito.
                                    </div>
                                    <div className="info">
                                        Ya puede ingresar a la plataforma con su
                                        respectiva cuenta restablecida.
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="formFooter">
                            {status ? (
                                <Link to="/login">
                                    {' '}
                                    <button>INICIAR SESIÓN</button>
                                </Link>
                            ) : (
                                <button type="submit" disabled={loading}>
                                    {loading ? 'CARGANDO...' : 'RESTABLECER'}
                                </button>
                            )}
                            <div className="contChangeOpt">
                                <span>¿Ya tienes una cuenta?</span>
                                <span>
                                    <Link to="/login">Iniciar sesión </Link>
                                </span>
                            </div>
                        </div>
                    </form>
                </CardAccess>
            )}
        </div>
    )
}

export default ResetPasswordPage
