import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { EntityContext } from '../../../context/entityContext/EntityProvider'
import { typesEntity } from '../../../context/types/typesEntity'
import { deleteEntity } from './../../../context/entityContext/EntityAction'
import SpinnerModal from './../../general/spinner/SpinnerModal'
// import Notification, { type } from '../../general/toast/Toast'

export const DeleteEntityModal = ({
    idEntity,
    setShowConfirmDelete,
}: any): JSX.Element => {
    const token = JSON.parse(localStorage.getItem('user')!).token

    const [loading, setLoading] = useState(false)

    const { dispatch } = useContext(EntityContext)

    const deleteEntityButton = () => {
        setLoading(true)
        deleteEntity(idEntity, token)
            .then((res) => {
                setLoading(false)

                // console.log(res);

                dispatch({
                    type: typesEntity.deleteEntity,
                    payload: idEntity,
                })

                setShowConfirmDelete(false)

                // Notification(type.success, "La entidad ha sido eliminada")
                toast.success('La entidad ha sido eliminada')
            })
            .catch((e) => {
                console.log(e.response)

                // Notification(type.danger, "Se ha producido un error.")
                toast.error('Se ha producido un error.')

                setLoading(false)
            })
    }

    return (
        <div className="main_DeleteEntity">
            <i
                className="fas fa-times-circle iconDel"
                onClick={() => setShowConfirmDelete(false)}
            />
            <div className="bodyDeletEntity">
                <i className="fas fa-info-circle" />
                <h3>Eliminar Entidad</h3>
                <p>¿Está seguro de eliminar esta entidad?</p>
                <div className="containerSpinner_center">
                    {loading ? (
                        <div className="containerSpinner">
                            {' '}
                            <SpinnerModal />{' '}
                        </div>
                    ) : (
                        <button onClick={deleteEntityButton}>Eliminar</button>
                    )}
                </div>
            </div>
        </div>
    )
}
