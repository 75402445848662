import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import { typesRepository } from '../../../../../context/types/typesRepository'
import { ProgramsContext } from '../../../../../context/userb2b/programs/ProgramsProvider'
import { createFiles } from '../../../../../context/userb2b/repository/RepositoryAction'
import { RepositoryContext } from '../../../../../context/userb2b/repository/RepositoryProvider'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHooks'
import { materialsApi, useGetCategoriesQuery } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface AddFileProps {
    setShowModalCreate: any
    typeModalFile: string
    dataCourse?: any
    setpagination?: any
    // setCurrentMaterial?: any
}

const isEmpty = (obj: Object): boolean => Object.keys(obj).length === 0

export const AddFile = ({
    setShowModalCreate,
    typeModalFile,
    dataCourse,
}: // setCurrentMaterial,
AddFileProps) => {
    const [typeFile, setTypeFile] = useState<string>('')
    const [url, setUrl] = useState<string>('')
    const [namefile, setNameFile] = useState<string>('')

    const [changewitch, setChangeSwitch] = useState(false)
    const { state: stateApariencia } = useContext(AparienciaContext)
    const [imageSelect, setImageSelect] = useState<any>('')
    const [imageName, setImageName] = useState<any>('')
    const [loading, setLoading] = useState<any>(false)
    const { state } = useContext(ProgramsContext)
    const [varchar, setVarchar] = useState<any>(400)

    const { entity } = useAppSelector(selectAuth)
    const dispatchRedux = useAppDispatch()

    const { data: dataCategories } = useGetCategoriesQuery({ entity_id: entity })

    const newEntityName: string = JSON.parse(localStorage.getItem('user')!).dataEntity
        .entity_name

    const {
        state: { uploadProgressMaterial, cancelToken },
        dispatch,
    } = useContext(RepositoryContext)

    const [data, setData] = useState({
        title: '',
        category: '',
        description: '',
        price: '',
        state: 'private',
        tags: '',
    })

    const onChangeInputs = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        number?: boolean,
    ): void => {
        const { name, value } = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setData({
                    ...data,
                    [name]: value,
                })
            }
        } else {
            setData({
                ...data,
                [name]: value,
            })
        }

        if (e.target.name === 'description') {
            const max_chart = 400

            setVarchar(max_chart - e.target.value.length)
        }
    }

    const changeValueSwitch = () => {
        setChangeSwitch(!changewitch)

        if (data.state === 'private') {
            setData({
                ...data,
                state: 'public',
            })
        } else {
            setData({
                ...data,
                state: 'private',
            })
        }
    }

    const changeImage = (e: any) => {
        // console.log(e);

        if (e !== undefined) {
            const imgPhoto: object = e
            // console.log(imgPhoto)
            setImageSelect(imgPhoto)
            setImageName(e.name)
        } else {
            setImageSelect('')

            setImageName('')
        }
    }

    const sendFile = (e: any) => {
        setLoading(true)

        const token = JSON.parse(localStorage.getItem('user')!).token

        e.preventDefault()

        // console.log(stateApariencia.currentIdEntity)

        if (data.description.trim() === '' || data.title.trim() === '') {
            setLoading(false)
            // return Notification(type.warning, "No puede crear un archivo con espacios en blanco");
            return toast.warning('No puede crear un archivo con espacios en blanco')
        }

        if (typeFile === 'URL') {
            if (url.trim() === '' || namefile.trim() === '') {
                setLoading(false)
                // return Notification(type.warning, "No puede crear un archivo con espacios en blanco");
                return toast.warning('No puede crear un archivo con espacios en blanco')
            }
        }

        if (typeFile === 'Archivo') {
            if (imageSelect === '') {
                setLoading(false)
                // return Notification(type.warning, "No puede crear un material sin algún material");
                return toast.warning('No puede crear un material sin algún material')
            }
        }

        const datan: FormData = new FormData()
        datan.append('fname', 'addMaterials')

        if (typeFile === 'Archivo') {
            datan.append('files', imageSelect)
        } else if ('URL') {
            datan.append('name_file', namefile)
            datan.append('url', url)
            datan.append('type', 'url')
        }

        datan.append('price', data.price)

        if (typeModalFile === 'module') {
            datan.append('course_id', state.currentModule._id)
            datan.append('category_id', dataCourse.category._id)
        }
        if (typeModalFile === 'repository') {
            datan.append('category_id', data.category)
        }
        datan.append('description', data.description)
        datan.append('title', data.title)
        datan.append('state', data.state)
        datan.append('entity_id', stateApariencia.currentIdEntity)
        // console.log(datan)

        createFiles(datan, token, dispatch)
            .then((res) => {
                console.log(res)

                dispatch({
                    type: typesRepository.clearUploadProgressMaterial,
                })

                toast.success('El archivo ha sido subido correctamente')

                // ? Invalidated cache
                typeModalFile === 'module'
                    ? dispatchRedux(
                          materialsApi.util.invalidateTags(['MaterialsByCourse']),
                      )
                    : dispatchRedux(
                          materialsApi.util.invalidateTags(['MaterialsByEntity']),
                      )

                // setCurrentMaterial(undefined)

                setLoading(false)
                setShowModalCreate(false)
            })
            .catch((e) => {
                console.log(e.response)
                const error = e.response?.data?.message
                // Notification(type.danger, error ? error : "Hubo un error, no se subieron los archivos.")
                toast.error(error ? error : 'Hubo un error, no se subieron los archivos.')

                setLoading(false)
            })
    }

    const hadleCancelCreate = () => {
        setShowModalCreate(false)
        dispatch({
            type: typesRepository.clearUploadProgressMaterial,
        })
        if (!isEmpty(cancelToken)) cancelToken.cancel('Operation canceled by the user.')
    }

    return (
        <div className="main_AddFiles">
            <div className="headFiles">
                <h3>Subir material de clases</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalCreate(false)}
                />
            </div>
            <div className="bodyFiles">
                <form className="contFormFile" onSubmit={sendFile}>
                    <div className="sepScroll scroll">
                        <div className="contInAddFile">
                            <p className="text_option_disabled">Entidad</p>
                            <input
                                type="text"
                                name="title"
                                value={newEntityName}
                                style={{ textTransform: 'capitalize' }}
                                disabled
                            />
                            {/* <select name="select" className="design_input" disabled style={{ textTransform: 'capitalize' }}>
                                <option value="" selected > {entityName} </option>
                            </select> */}
                        </div>

                        <div className="contInAddFile">
                            <p>Categorías</p>
                            {typeModalFile === 'module' ? (
                                <select name="select" className="design_input" disabled>
                                    <option value={dataCourse.category._id} selected>
                                        {dataCourse.category.name}
                                    </option>
                                </select>
                            ) : (
                                // <Select
                                //     required
                                //     className="selectbox"
                                //     options={tags}
                                //     closeMenuOnSelect={false}
                                //     components={animatedComponents}
                                //     isMulti
                                //     onChange={getSelectTags}
                                //     placeholder={'Selecione categoría...'}
                                //     noOptionsMessage={() => 'Sin categorías'}
                                // />
                                <select
                                    name="category"
                                    className="design_input"
                                    onChange={(e: any) => onChangeInputs(e)}
                                    required
                                >
                                    <option value="" style={{ display: 'none' }}>
                                        Seleccione una categoría ... *
                                    </option>
                                    {dataCategories &&
                                        dataCategories.categories.length &&
                                        dataCategories.categories.map(({ _id, name }) => (
                                            <option key={_id} value={_id}>
                                                {name}
                                            </option>
                                        ))}
                                </select>
                            )}
                        </div>
                        {/* <button onClick={() => console.log(data)}>asd</button> */}

                        {typeModalFile === 'module' ? (
                            <div className="contInAddFile">
                                <p>Curso *</p>
                                <select
                                    name="select"
                                    className="design_input"
                                    disabled
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    <option value={dataCourse._id} selected>
                                        {dataCourse.name}
                                    </option>
                                </select>
                            </div>
                        ) : null}

                        <div className="contInAddFile">
                            <p>Título *</p>
                            <input
                                type="text"
                                name="title"
                                onChange={onChangeInputs}
                                value={data.title}
                                maxLength={80}
                            />
                        </div>

                        <div
                            className="contInAddFile"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '15px',
                            }}
                        >
                            <p>Archivo *</p>
                            <div className="typeFile">
                                <label>
                                    <input
                                        required
                                        type="radio"
                                        name="selfile"
                                        value="Archivo"
                                        onChange={(e) => setTypeFile(e.target.value)}
                                        checked={typeFile === 'Archivo'}
                                    />
                                    Archivo
                                </label>
                                <label>
                                    <input
                                        required
                                        type="radio"
                                        name="selfile"
                                        value="URL"
                                        onChange={(e) => setTypeFile(e.target.value)}
                                        checked={typeFile === 'URL'}
                                    />
                                    URL
                                </label>
                            </div>
                            {typeFile === 'Archivo' ? (
                                <label className="file_label">
                                    <span className="first_part">Subir Archivo</span>
                                    <input
                                        className="input_design"
                                        type="file"
                                        id="file"
                                        aria-label="File browser example"
                                        onChange={(e: any) =>
                                            changeImage(e.target.files[0])
                                        }
                                    />
                                    {imageName !== '' ? (
                                        <span className="second_part"> {imageName} </span>
                                    ) : (
                                        <span className="second_part">
                                            No se ha seleccionado ningun archivo
                                        </span>
                                    )}
                                </label>
                            ) : typeFile === 'URL' ? (
                                <>
                                    <div className="contInAddFile">
                                        <p>Nombre del archivo *</p>
                                        <input
                                            type="text"
                                            onChange={(e) => setNameFile(e.target.value)}
                                            value={namefile}
                                            maxLength={80}
                                        />
                                    </div>
                                    <div className="contInAddFile">
                                        <p>Url archivo *</p>
                                        <input
                                            type="text"
                                            onChange={(e) => setUrl(e.target.value)}
                                            value={url}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className="contInAddFile">
                            <p>Descripción</p>
                            <textarea
                                name="description"
                                maxLength={400}
                                onChange={onChangeInputs}
                                value={data.description}
                            />
                            <p
                                style={{
                                    textAlign: 'right',
                                    color: '#a29f9f',
                                    fontWeight: 600,
                                }}
                            >
                                {varchar} caracteres
                            </p>
                        </div>
                        {typeModalFile === 'module' ? null : (
                            <div className="contInAddFile">
                                <div className="half_content_file">
                                    <span className="text_switch">Hacer público </span>

                                    <div
                                        className={`switch ${
                                            changewitch ? 'active' : ''
                                        }`}
                                        onClick={changeValueSwitch}
                                    >
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {changewitch ? (
                            <div className="contInAddFile">
                                <p>Precio</p>
                                <input
                                    type="text"
                                    placeholder="Ingrese el precio"
                                    name="price"
                                    onChange={(e) => onChangeInputs(e, true)}
                                    value={data.price}
                                    maxLength={5}
                                />
                            </div>
                        ) : null}
                        {uploadProgressMaterial > 0 && (
                            <div className="progress-container">
                                <p>{uploadProgressMaterial} %</p>
                                <progress value={uploadProgressMaterial} max="100" />
                            </div>
                        )}
                    </div>
                    <div className="optsBtnAdd">
                        <button onClick={hadleCancelCreate} type="button">
                            Cancelar
                        </button>
                        <button type="submit" disabled={loading}>
                            {loading ? <SpinnerModal /> : 'Guardar'}
                        </button>
                        {/* {
                            loading ? <div className="containerSpinner"> <SpinnerModal /> </div> : <button type="submit" >Guardar</button>
                        } */}
                    </div>
                </form>
            </div>
        </div>
    )
}
