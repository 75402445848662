import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import { MATERIAL_URL } from '../../config/utils'
import {
    APIGetMaterialByCourseResponse,
    APIGetMaterialsByEntityResponse,
    EntityIdParam,
    MaterialByCourse,
} from '../../interfaces'
import { RootState } from '../store'

interface GetMaterialsByCourseParams extends EntityIdParam {
    course_id: string
}

interface GetMaterialsByEntityParams extends EntityIdParam {
    date?: boolean
    docs?: number
    filter_by_title?: string
    page?: number
}

export const materialsApi = createApi({
    reducerPath: 'materialsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${MATERIAL_URL}v5.0.1/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token
            if (token) headers.set('authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['MaterialsByCourse', 'MaterialsByEntity'],
    endpoints: (builder) => ({
        getMaterialsByCourse: builder.query<
            { materials: MaterialByCourse[] },
            GetMaterialsByCourseParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getMaterial_byCourse',
                    ...params,
                },
            }),
            providesTags: ['MaterialsByCourse'],
            transformResponse: (response: APIGetMaterialByCourseResponse) => ({
                materials: response.materials
                    ? response.materials.map((material) => material.material)
                    : [],
            }),
        }),
        getMaterialsByEntity: builder.query<
            APIGetMaterialsByEntityResponse,
            GetMaterialsByEntityParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getMaterial_byEntity',
                    ...params,
                },
            }),
            providesTags: ['MaterialsByEntity'],
        }),
    }),
})

export const { useGetMaterialsByCourseQuery, useGetMaterialsByEntityQuery } = materialsApi
