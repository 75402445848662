import './noData.sass'

interface NoDataProps {
    title: string
    description?: string
}

export const NoData = ({ title, description }: NoDataProps) => {
    return (
        <div className="main_noData">
            <img src="/images/robot/RobotType1.svg" alt="robot playtec" />
            <div>
                <b>{title}</b>
            </div>
            {description && (
                <div>
                    <b>{description}</b>
                </div>
            )}
        </div>
    )
}
