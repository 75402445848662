import { axiosCourses } from './../../config/axios'

export const getCoursesUserb2b = async (token: string, entity_id: any) => {
    return axiosCourses({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getCourses',
            entity_id: entity_id,
        },
    })
}

export const createCoursesUserb2b = async (obj: any, token: string) => {
    return axiosCourses({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const updateCoursesUserb2b = async (obj: any, token: string) => {
    return axiosCourses({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const deleteCourseUserb2b = async (id: string, token: string) => {
    return axiosCourses({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'deleteCourse',
            id_course: id,
        },
    })
}
