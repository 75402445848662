import { useState } from 'react'
import { NoData } from '../../components/general/nodata/NoData'

import ProgressNS from '../../components/general/progress/ProgressNS'
import ContainerModal from '../../components/modals/ContainerModal'
import { AddFile } from '../../components/modals/continental/admin/files'
import {
    AddEditModule,
    DeleteModule,
} from '../../components/modals/continental/admin/modules'
import { AddEditTemary } from '../../components/modals/continental/admin/temary'
import { useAppSelector } from '../../hooks/useReduxHooks'
import { Course, ModalOptions } from '../../interfaces'
import {
    useGetCoursesChildrenQuery,
    useGetItemsQuery,
    useGetMaterialsByCourseQuery,
} from '../../store/api'
import { selectAuth, selectCourse } from '../../store/slices'
import ItemModuleCourse2 from './ItemModuleCourse2'

interface ModuleCourses2Props {
    setShowView: React.Dispatch<React.SetStateAction<'module' | 'evaluation' | 'none'>>
    course: Course
}

const ModuleCourses2 = ({ course, setShowView }: ModuleCourses2Props) => {
    const { entity } = useAppSelector(selectAuth)
    const { currentCategory } = useAppSelector(selectCourse)
    const [currentModule, setCurrentModule] = useState<Course>()
    const [showAddEditModule, setShowAddEditModule] = useState<ModalOptions>('hide')
    const [showAddEditTemary, setShowAddEditTemary] = useState<ModalOptions>('hide')
    const [showDeleteModule, setShowDeleteModule] = useState<boolean>(false)

    // const [skip, setSkip] = useState(true)
    const [showAddFile, setShowAddFile] = useState(false)

    const { data, isLoading: isLoadingModules } = useGetCoursesChildrenQuery({
        entity_id: entity,
        parent_course_id: course._id,
    })

    const { data: itemsData, isLoading: isLoadingItems } = useGetItemsQuery(
        {
            entity,
            course: currentModule?._id!,
        },
        {
            skip: currentModule?._id !== undefined ? false : true,
        },
    )

    const {
        data: dataMaterialsByCourse,
        // isLoading: isLoadingMaterialByCourse
    } = useGetMaterialsByCourseQuery(
        {
            entity_id: entity,
            course_id: currentModule?._id!,
        },
        {
            skip: currentModule?._id !== undefined ? false : true,
        },
    )

    const handleDownload = (url: string) => () => {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('target', '_blank') //or any other extension
        document.body.appendChild(link)
        link.click()
    }

    return (
        <div className="content_ModuleCourse">
            <div className="first_row_moduleCourse">
                {/* /Cursos/{data.name} */}
                <span>
                    <i
                        className="fas fa-chevron-left"
                        onClick={() => setShowView('none')}
                    />
                </span>
                <span style={{ color: '#CDD4DB' }}>{currentCategory?.name}</span>
                <span>/</span>
                <span>{course.name}</span>
            </div>
            <div className="second_row_moduleCourse">
                <div className="left_content">
                    {course.preview && course.preview.includes('https://') ? (
                        <img src={course.preview} alt="ImagItem" />
                    ) : (
                        <div>
                            <i
                                className="profileDef fas fa-user-circle"
                                style={{ fontSize: '150px' }}
                            ></i>
                        </div>
                    )}
                </div>
                <div className="right_content">
                    <p>{currentCategory?.name}</p>
                    <p>{course.name}</p>
                    <p>{course.description}.</p>
                </div>
            </div>
            <div className="third_row_moduleCourse">
                <div className="first_column">
                    <p>Módulos de Curso</p>

                    <div
                        className="button_column"
                        onClick={() => setShowAddEditModule('add')}
                    >
                        <i className="fas fa-plus"></i> Agregar
                    </div>

                    <div className="module_content">
                        <p>General</p>
                        {isLoadingModules ? (
                            <ProgressNS />
                        ) : (
                            data?.courses?.map((res) => (
                                <ItemModuleCourse2
                                    key={res._id}
                                    module={res}
                                    setCurrentModule={setCurrentModule}
                                    setShowAddEditModule={setShowAddEditModule}
                                    setShowDeleteModule={setShowDeleteModule}
                                />
                            ))
                        )}
                    </div>
                </div>
                <div className="second_column">
                    <div className="content_text">
                        {data?.courses.length === 0 ? (
                            <NoData title="No hay módulos" />
                        ) : currentModule ? (
                            <>
                                <p>
                                    Título: <span> {currentModule.name} </span>
                                </p>
                                <p>
                                    Precio:
                                    <span>
                                        {!currentModule.price.course_price
                                            ? 'Gratis'
                                            : currentModule.price.course_price}
                                    </span>
                                </p>
                            </>
                        ) : (
                            <NoData title="Selecciona un módulo" />
                        )}
                    </div>

                    <div className="content_materials">
                        {currentModule && (
                            <>
                                <h5> Temario </h5>
                                <div style={{ marginTop: '10px' }}>
                                    {isLoadingItems && <ProgressNS />}
                                    {itemsData?.items ? (
                                        itemsData?.items.map((res: any, key: number) => (
                                            <div className="card_type_file" key={key}>
                                                <li>{res.title} </li>
                                            </div>
                                        ))
                                    ) : (
                                        <div>No hay nada en temario</div>
                                    )}
                                </div>

                                <div
                                    className="button_container"
                                    style={{ marginTop: '25px' }}
                                >
                                    <div
                                        className="button_add_material"
                                        onClick={() => setShowAddEditTemary('add')}
                                    >
                                        Agregar temario
                                        <i className="fas fa-plus" />
                                    </div>
                                </div>

                                <h5> Materiales </h5>

                                <div className="row_container">
                                    {dataMaterialsByCourse?.materials &&
                                    dataMaterialsByCourse.materials.length > 0 ? (
                                        dataMaterialsByCourse.materials.map(
                                            ({ _id, type, name_file, url }) => (
                                                <div
                                                    className="card_type_file"
                                                    onClick={handleDownload(url)}
                                                    key={_id}
                                                >
                                                    {type ===
                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                                    type === 'application/msword' ? (
                                                        <img
                                                            src={
                                                                '/images/materials/doc.png'
                                                            }
                                                            alt="type_image"
                                                        />
                                                    ) : type ===
                                                      'application/vnd.openxmlformats-officedocument.presentationml.presentation' ? (
                                                        <img
                                                            src={
                                                                '/images/materials/ppt.png'
                                                            }
                                                            alt="type_image"
                                                        />
                                                    ) : type === 'application/pdf' ? (
                                                        <img
                                                            src={
                                                                '/images/materials/pdf.png'
                                                            }
                                                            alt="type_image"
                                                        />
                                                    ) : type === 'video/mp4' ? (
                                                        <img
                                                            src={
                                                                '/images/materials/mp4.png'
                                                            }
                                                            alt="type_image"
                                                        />
                                                    ) : type === 'url' ? (
                                                        <img
                                                            src={
                                                                '/images/materials/url.svg'
                                                            }
                                                            alt="type_image"
                                                            width="52px"
                                                            height="70px"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={
                                                                '/images/materials/img.png'
                                                            }
                                                            alt="type_image"
                                                        />
                                                    )}

                                                    <p> {name_file} </p>
                                                </div>
                                            ),
                                        )
                                    ) : (
                                        <div>No hay materiales</div>
                                    )}
                                </div>

                                <div
                                    className="button_container"
                                    style={{ marginTop: '25px' }}
                                >
                                    <div
                                        className="button_add_material"
                                        onClick={() => setShowAddFile(true)}
                                    >
                                        Subir material de clase
                                        <i className="fas fa-plus" />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <ContainerModal showModal={showAddEditModule !== 'hide'}>
                    <AddEditModule
                        module={currentModule!}
                        setModule={setCurrentModule}
                        showModal={showAddEditModule}
                        setShowModal={setShowAddEditModule}
                        course={course}
                    />
                </ContainerModal>
                <ContainerModal showModal={showDeleteModule}>
                    <DeleteModule
                        moduleId={currentModule?._id!}
                        setShowModal={setShowDeleteModule}
                        setModule={setCurrentModule}
                    />
                </ContainerModal>
                <ContainerModal showModal={showAddEditTemary !== 'hide'}>
                    <AddEditTemary
                        items={itemsData?.items || []}
                        course={course}
                        showModal={showAddEditTemary}
                        setShowModal={setShowAddEditTemary}
                        currentModule={currentModule!}
                    />
                </ContainerModal>
                <ContainerModal showModal={showAddFile}>
                    <AddFile
                        setShowModalCreate={setShowAddFile}
                        dataCourse={course}
                        typeModalFile={'module'}
                    />
                </ContainerModal>
            </div>
        </div>
    )
}

export default ModuleCourses2
