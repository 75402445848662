import React from 'react'
import { useState } from 'react'
import DetailEntollment from './DetailEntollment'

const CardUser = ({ data, setDataTable1, setShowModal, setpagination }: any) => {
    const [detail, setDetail] = useState(false)

    const handleDetailEnrollment = () => {
        setDetail(!detail)
    }

    return (
        <>
            <div className="info_user">
                <div className="data_info">
                    <div className="data_first_part">
                        {data.photo && data.photo.includes('https://') ? (
                            <img src={data.photo} className="img_photo" alt="phot" />
                        ) : (
                            <div className="img_photo">
                                <i className="profileDef fas fa-user-circle"></i>
                            </div>
                        )}
                        <div className="data_info_name">
                            <p> {data.name + ' ' + data.lastname} </p>
                            <p> {data.email} </p>
                        </div>
                    </div>

                    <div className="assing_name">
                        {detail ? (
                            <div
                                className="button_center"
                                onClick={handleDetailEnrollment}
                            >
                                Cancelar
                            </div>
                        ) : (
                            <div
                                className="button_center"
                                onClick={handleDetailEnrollment}
                            >
                                Matricular
                            </div>
                        )}
                    </div>
                </div>

                {detail ? (
                    <DetailEntollment
                        data={data}
                        setDataTable1={setDataTable1}
                        setShowModal={setShowModal}
                        setpagination={setpagination}
                    />
                ) : null}
            </div>
        </>
    )
}

export default CardUser
