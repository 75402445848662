import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { UserAuth } from '../../interfaces'
import type { RootState } from '../store'

interface AuthState {
    token: string | null
    user: UserAuth | null
    isAuthenticated: boolean
    entity: string
}

const initialState: AuthState = {
    token: null,
    user: null,
    isAuthenticated: false,
    entity: '',
}

// create auth slice
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload
        },
        setUser: (state, action: PayloadAction<UserAuth>) => {
            state.user = action.payload
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        setEntity: (state, action: PayloadAction<string>) => {
            state.entity = action.payload
        },
        // login: (state, action: PayloadAction<string>) => {
        //     state.token = action.payload
        // },
        logout: (state) => {
            state.token = null
            state.user = null
            state.isAuthenticated = false
        },
    },
})

export const { setAuthenticated, setUser, setToken, setEntity, logout } =
    authSlice.actions

export const selectAuth = (state: RootState) => state.auth
