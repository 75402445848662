import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { toast } from 'react-toastify'

import { USER_URL_V5 } from '../../config/utils'
import type {
    APIGetUsersInEntityResponse,
    CustomUser,
    EntityIdParam,
    Pagination,
    // User,
} from '../../interfaces'
import type { RootState } from '../store'

interface GetUsersByEntityParams {
    entity_id: string
}

interface GetStudentsParams extends EntityIdParam {
    page: number
    query?: {
        field?: string
        search?: string
    }
    docs?: number
    // sortNameNumber?: number
}

interface DeleteUserByEntityParams extends EntityIdParam {
    user_id: string
}

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${USER_URL_V5}v5.0.1/api`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token
            if (token) headers.set('authorization', `Bearer ${token}`)
            return headers
        },
    }),
    tagTypes: ['Teachers', 'UsersByEntity', 'Students'],
    endpoints: (builder) => ({
        getTeachersByEntity: builder.query<
            APIGetUsersInEntityResponse,
            GetUsersByEntityParams
        >({
            query: ({ entity_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getUsersByEntity',
                    entity_id,
                    roles: ['docente'],
                    docs: 100,
                },
            }),
            providesTags: (result) =>
                result?.users
                    ? [
                          ...result.users.map(({ _id }) => ({
                              type: 'Teachers' as const,
                              id: _id,
                          })),
                          { type: 'Teachers', id: 'LIST' },
                      ]
                    : [{ type: 'Teachers', id: 'LIST' }],
            // onQueryStarted: (params, { dispatch }) => {
            //     console.log('query started', params)
            // }
        }),
        getUsersByEntity: builder.query<
            { users: CustomUser[]; pagination: Pagination },
            GetStudentsParams
        >({
            query: (params) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'getUsersByEntity',
                    sortNameNumber: 1,
                    ...params,
                },
            }),
            transformResponse: (response: APIGetUsersInEntityResponse) => ({
                // ...response,
                users: response.users.map((u) => ({
                    ...u.user,
                    roles: u.roles.map((r) => ({ _id: r.rol._id, rol: r.rol.rol })),
                })),
                pagination: response.pagination,
            }),
            providesTags: (result) =>
                result?.users
                    ? [
                          ...result.users.map(({ _id }) => ({
                              type: 'UsersByEntity' as const,
                              id: _id,
                          })),
                          { type: 'UsersByEntity', id: 'LIST' },
                      ]
                    : [{ type: 'UsersByEntity', id: 'LIST' }],
        }),
        addEditUserByEntity: builder.mutation<{}, FormData>({
            query: (body) => ({
                url: '',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'UsersByEntity', id: 'LIST' }],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Operación realizada con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Ocurrió un error al realizar la operación')
                }
            },
        }),
        deleteUserByEntity: builder.mutation<{}, DeleteUserByEntityParams>({
            query: ({ entity_id, user_id }) => ({
                url: '',
                method: 'POST',
                body: {
                    fname: 'deleteUserByEntity',
                    entity_id,
                    user_id,
                },
            }),
            invalidatesTags: (_, __, { user_id }) => [
                { type: 'UsersByEntity', id: user_id },
            ],
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success('Usuario eliminado con éxito')
                } catch (error) {
                    console.log(error)
                    toast.error('Error al eliminar usuario')
                }
            },
        }),
    }),
})

export const {
    useGetTeachersByEntityQuery,
    useGetUsersByEntityQuery,
    useAddEditUserByEntityMutation,
    useDeleteUserByEntityMutation,
} = usersApi
