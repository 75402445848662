import { typesScheduledCourses } from '../types/typeScheduledCourses'
import { axiosCourses } from './../../config/axios'

export const getScheduledCourses = async (dispatch: any, data: any, token: string) => {
    const { entity_id, docs, page, filter_by_name, sort, query, isEmpty } = data
    return axiosCourses({
        url: '/v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getScheduleds',
            entity_id,
            type_of_scheduled: 'main',
            docs,
            page,
            filter_by_name,
            sort,
            query: {
                field: 'name',
                search: query,
            },
        },
    })
        .then(({ data }) => {
            // console.log(data);
            // console.log(data.scheduleds)
            const array: any[] = []
            data.scheduleds.map((dat: any) => {
                // console.log(dat)
                let arrayteachersids: any[] = []
                dat.teachers.map((teacher: any) =>
                    arrayteachersids.push({
                        label: teacher.name + ' ' + teacher.lastname,
                        value: teacher._id,
                    }),
                )
                let arrayteachersidsolds: any[] = []
                dat.teachers.map((teacher: any) => arrayteachersidsolds.push(teacher._id))
                const course: any = {
                    name: dat.name,
                    id: dat._id,
                    category_id: dat.category?._id,
                    course_id: dat.course?._id,
                    campus_id: dat.campus?._id,
                    description: dat.description,
                    start_date: dat.start_date,
                    end_date: dat.end_date,
                    category_name: dat.category?.name,
                    course_name: dat.course?.name,
                    campus_name: dat.campus?.name,
                    coverpage: dat.coverpage,
                    price: dat.course?.price,
                    teacher_ids: arrayteachersids,
                    teacher_ids_old: arrayteachersidsolds,
                    publish: dat.publish,
                }
                return array.push(course)
            })

            isEmpty
                ? dispatch({
                      type: typesScheduledCourses.getScheduledCourses,
                      payload: {
                          array,
                          pagination: data.pagination,
                      },
                  })
                : dispatch({
                      type: typesScheduledCourses.addMoreScheduledCourses,
                      payload: {
                          array,
                          pagination: data.pagination,
                      },
                  })
        })
        .catch((e) => {
            console.log(e)
            console.log(e.response)
        })
}
