import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { useDeleteCourseMutation } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface DeleteCourseProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    courseId: string
}

export const DeleteCourse = ({ setShowModal, courseId }: DeleteCourseProps) => {
    const { entity } = useAppSelector(selectAuth)

    const [deleteCourse, { isLoading }] = useDeleteCourseMutation()

    const handleDeleteCourse = () => {
        deleteCourse({
            course_id: courseId,
            entity_id: entity,
        }).then(() => setShowModal(false))
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal(false)} />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar el Curso?</p>
                <div className="containerSpinner_center">
                    <button onClick={() => setShowModal(false)} disabled={isLoading}>
                        Cancelar
                    </button>
                    {isLoading ? (
                        <div className="containerSpinner">
                            <SpinnerModal />
                        </div>
                    ) : (
                        <button className="saveButton" onClick={handleDeleteCourse}>
                            Aceptar
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
