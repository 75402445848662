import { useCallback, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import Select from 'react-select'

import { useAppSelector } from '../../../hooks/useReduxHooks'
import { ModalOptions, Scheduled } from '../../../interfaces'
import { todayString } from '../../../utils'
import {
    useAddEditScheduledMutation,
    useGetCategoriesQuery,
    useGetCoursesQuery,
    useGetSedesQuery,
    useGetTeachersByEntityQuery,
} from '../../../store/api'
import { selectAuth } from '../../../store/slices'
import SpinnerModal from '../../general/spinner/SpinnerModal'
import { toast } from 'react-toastify'

const customStyles = {
    menuList: (provided: any, state: any) => ({
        ...provided,
        maxHeight: state.selectProps.maxHeight,
    }),
}

type OptionType = {
    value: string
    label: string
}

interface FormScheduled {
    id: string
    name: string
    category_id: OptionType | undefined
    course_id: OptionType | undefined
    campus_id: OptionType | undefined
    description: string
    start_date: string
    end_date: string
    coverpage: string
    teacher_ids: OptionType[] | undefined
    teacher_ids_old: string[]
    publish: boolean
}

type AddEditScheduledProps = {
    showModal: ModalOptions
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
    scheduled?: Scheduled
    setScheduled: React.Dispatch<React.SetStateAction<Scheduled | undefined>>
}

export const AddEditScheduled = ({
    showModal,
    setShowModal,
    scheduled,
    setScheduled,
}: AddEditScheduledProps) => {
    // console.log({ scheduled })
    const { entity } = useAppSelector(selectAuth)
    const [file, setFile] = useState<File>()

    const { data: categories, isLoading: isLoadingCategories } = useGetCategoriesQuery(
        { entity_id: entity },
        {
            selectFromResult: ({ data, isLoading }) => ({
                data: data?.categories.map((category) => ({
                    value: category._id,
                    label: category.name,
                })),
                isLoading,
            }),
        },
    )

    const { data: courses, isLoading: isLoadingCourses } = useGetCoursesQuery(
        { entity_id: entity },
        {
            selectFromResult: ({ data, isLoading }) => ({
                data: data?.courses.map((course) => ({
                    value: course._id,
                    label: course.name,
                    category_id: course.category._id,
                })),
                isLoading,
            }),
        },
    )

    const { data: sedes, isLoading: isLoadingSedes } = useGetSedesQuery(
        {
            entity,
            page: 1,
            docs: 100,
        },
        {
            selectFromResult: ({ data, isLoading }) => ({
                data: data?.campus.map((c) => ({
                    value: c._id,
                    label: c.name,
                })),
                isLoading,
            }),
        },
    )

    const { data: teachers, isLoading: isLoadingTeachers } = useGetTeachersByEntityQuery(
        {
            entity_id: entity,
        },
        {
            selectFromResult: ({ data, isLoading }) => {
                const users =
                    data?.users.map(({ user }) => ({
                        value: user._id,
                        label: user.name,
                    })) || []
                const rolId = data?.roles[0]._id || ''

                return {
                    data: { users, rolId },
                    isLoading,
                }
            },
        },
    )

    const [addEditScheduled, { isLoading }] = useAddEditScheduledMutation()

    const { handleSubmit, register, control, setValue, watch, getValues } =
        useForm<FormScheduled>({
            defaultValues: {
                id: showModal === 'edit' ? scheduled?._id || '' : '',
                name: showModal === 'edit' ? scheduled?.name || '' : '',
                coverpage: showModal === 'edit' ? scheduled?.coverpage || '' : '',
                description: showModal === 'edit' ? scheduled?.description || '' : '',
                start_date: showModal === 'edit' ? scheduled?.start_date || '' : '',
                end_date: showModal === 'edit' ? scheduled?.end_date || '' : '',
                publish: showModal === 'edit' ? scheduled?.publish || false : false,
                category_id:
                    showModal === 'edit'
                        ? {
                              value: scheduled?.category?._id,
                              label: scheduled?.category?.name,
                          }
                        : undefined,
                course_id:
                    showModal === 'edit'
                        ? {
                              value: scheduled?.course?._id,
                              label: scheduled?.course?.name,
                          }
                        : undefined,
                campus_id:
                    showModal === 'edit'
                        ? {
                              value: scheduled?.campus?._id,
                              label: scheduled?.campus?.name,
                          }
                        : undefined,
                teacher_ids:
                    showModal === 'edit'
                        ? scheduled?.teachers.map((t) => ({
                              value: t._id,
                              label: t.name,
                          }))
                        : undefined,
                teacher_ids_old:
                    showModal === 'edit'
                        ? scheduled?.teachers.map((t) => t._id) || []
                        : [],
            },
        })

    const onSubmit: SubmitHandler<FormScheduled> = (data) => {
        console.log({ data })
        console.log(file)

        if (data.category_id && data.course_id && data.campus_id) {
            let formData = new FormData()
            if (data.coverpage && file) {
                formData.append('coverpage', file)
            }
            if (showModal === 'edit') {
                formData.append('fname', 'updateScheduled')
                formData.append('scheduled_id', data.id)
                formData.append('teacher_ids_old', JSON.stringify(data.teacher_ids_old))
            } else {
                formData.append('fname', 'createScheduled')
            }
            formData.append('rol', teachers.rolId)
            formData.append('name', data.name)
            formData.append('description', data.description)
            formData.append('start_date', data.start_date.toString())
            formData.append('end_date', data.end_date.toString())
            formData.append('publish', data.publish.toString())
            formData.append('course_id', data.course_id.value)
            formData.append('campus_id', data.campus_id.value)
            formData.append(
                'teacher_ids',
                JSON.stringify(data.teacher_ids?.map((t) => t.value)),
            )
            formData.append('entity_id', entity)
            formData.append('type_of_scheduled', 'main')
            console.log(Object.fromEntries(formData))
            addEditScheduled(formData)
                .unwrap()
                .then((payload) => {
                    console.log(payload)
                    setScheduled({
                        ...payload.scheduled,
                        start_date: getValues('start_date'),
                        end_date: getValues('end_date'),
                        teachers: [...payload.teachers],
                    })
                    showModal === 'edit'
                        ? toast.success('Módulo actualizado con éxito')
                        : toast.success('Curso programado correctamente')
                    setShowModal('hide')
                })
                .catch((error) => {
                    console.log(error)
                    showModal === 'edit'
                        ? toast.error('Error al actualizar el módulo')
                        : toast.error('Error al crear el módulo')
                })
        } else {
            return console.log('no category, course or campus')
        }
    }

    console.log('AddEditScheduled')

    const closeModal = useCallback(() => {
        setShowModal('hide')
    }, [setShowModal])

    return (
        <div className="main_ModalCoursesScheduled">
            <div className="headModalCoursesScheduled">
                <h3>{showModal === 'edit' ? 'Editar ' : 'Programar '}Curso</h3>
                <i className="fas fa-times-circle" onClick={closeModal} />
            </div>
            <div className="bodyModalCoursesScheduled">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="container_input_content_MUC center">
                        <input
                            id="fichero"
                            type="file"
                            {...register('coverpage', {
                                onChange: (e) => {
                                    // file.current(e.target.files[0])
                                    setFile(e.target.files[0])
                                    setValue(
                                        'coverpage',
                                        URL.createObjectURL(e.target.files[0]),
                                    )
                                },
                            })}
                        />
                        <label htmlFor="fichero" className="circle">
                            {watch('coverpage').length === 0 ? (
                                <i className="fas fa-camera" />
                            ) : (
                                <img
                                    src={watch('coverpage')}
                                    alt={scheduled?.name}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null
                                        currentTarget.src =
                                            'https://via.placeholder.com/30'
                                    }}
                                    className="circle"
                                />
                            )}
                        </label>
                    </div>
                    <div className="addUser_content_left">
                        <div className="container_input_content_MUC">
                            <label>Categoría: *</label>
                            <Controller
                                name="category_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, ...rest } }) => (
                                    <Select
                                        styles={customStyles}
                                        maxHeight={250}
                                        options={categories}
                                        className="selectbox"
                                        placeholder={'Selecione categoría...'}
                                        value={watch('category_id')}
                                        isLoading={isLoadingCategories}
                                        loadingMessage={() => 'Cargando...'}
                                        noOptionsMessage={() =>
                                            'No hay categorías disponibles'
                                        }
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="container_input_content_MUC">
                            <label>Curso: *</label>
                            <Controller
                                name="course_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, ...rest } }) => (
                                    <Select
                                        styles={customStyles}
                                        maxHeight={200}
                                        options={courses?.filter(
                                            (c) =>
                                                c.category_id ===
                                                watch('category_id')?.value,
                                        )}
                                        className="selectbox"
                                        placeholder="Selecione Curso..."
                                        value={watch('course_id')}
                                        isLoading={isLoadingCourses}
                                        loadingMessage={() => 'Cargando...'}
                                        noOptionsMessage={() =>
                                            'Seleccione una categoría'
                                        }
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="container_input_content_MUC">
                            <label>Sede:</label>
                            <Controller
                                name="campus_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, ...rest } }) => (
                                    <Select
                                        styles={customStyles}
                                        maxHeight={150}
                                        options={sedes}
                                        className="selectbox"
                                        placeholder={'Selecione Sede...'}
                                        value={watch('campus_id')}
                                        isLoading={isLoadingSedes}
                                        loadingMessage={() => 'Cargando...'}
                                        noOptionsMessage={() =>
                                            'No hay sedes disponibles'
                                        }
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="container_input_content_MUC">
                            <label>Asignar Docente:</label>
                            <Controller
                                name="teacher_ids"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, ...rest } }) => (
                                    <Select
                                        isMulti
                                        styles={customStyles}
                                        maxHeight={100}
                                        options={teachers.users}
                                        className="selectbox"
                                        placeholder={'Selecione docente...'}
                                        value={watch('teacher_ids')}
                                        isLoading={isLoadingTeachers}
                                        loadingMessage={() => 'Cargando...'}
                                        noOptionsMessage={() =>
                                            'No hay docentes disponibles'
                                        }
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="addUser_content_right">
                        <div className="container_input_content_MUC">
                            <label>Nombre: *</label>
                            <input
                                required
                                type="text"
                                className="input_design_content_MUC"
                                {...register('name')}
                            />
                        </div>
                        <div className="container_input_content_MUC">
                            <label>Descripción: </label>
                            <textarea
                                style={{ padding: '6px 15px' }}
                                className="input_design_content_MUC"
                                maxLength={400}
                                {...register('description')}
                            />
                            <p
                                style={{
                                    textAlign: 'right',
                                    color: '#a29f9f',
                                    fontWeight: 600,
                                    marginTop: '2px',
                                }}
                            >
                                {/* {varchar} */}
                                {/* {watch('description').length} /400 caracteres */}
                            </p>
                        </div>
                        <div className="content_inputs_flex">
                            <div className="container_input_content_MUC">
                                <label>Fecha de inicio: *</label>
                                <input
                                    required
                                    type="date"
                                    className="input_design_content_MUC"
                                    {...register('start_date')}
                                    min={
                                        showModal === 'edit'
                                            ? scheduled?.start_date.toString() ||
                                              todayString
                                            : todayString
                                    }
                                />
                            </div>
                            <div className="container_input_content_MUC">
                                <label>Fecha de fin: *</label>
                                <input
                                    required
                                    type="date"
                                    className="input_design_content_MUC"
                                    {...register('end_date')}
                                    min={watch('start_date').toString()}
                                />
                            </div>
                        </div>
                        <div
                            className="container_input_content_MUC"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '15px 0',
                            }}
                        >
                            <input
                                type="checkbox"
                                style={{
                                    width: '40px',
                                    height: '20px',
                                    margin: '0',
                                }}
                                className="input_design_content_MUC"
                                {...register('publish')}
                            />
                            <label htmlFor="publicCourse" style={{ margin: '0' }}>
                                PUBLICAR CURSO
                            </label>
                        </div>
                        <div className="buttons_container">
                            <button
                                disabled={isLoading}
                                type="button"
                                className="cancelButton"
                                onClick={closeModal}
                            >
                                <span>CANCELAR</span>
                            </button>

                            {isLoading ? (
                                <div
                                    className="containerSpinner"
                                    style={{
                                        backgroundColor: 'var(--color-secondary)',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        width: '100px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <SpinnerModal />
                                </div>
                            ) : (
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="saveButton"
                                >
                                    GUARDAR
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
