import { memo, useCallback, useMemo } from 'react'
import DataTable, {
    TableColumn,
    TableStyles,
    PaginationOptions,
} from 'react-data-table-component'
import type { ExpandableRowsComponent } from 'react-data-table-component/dist/src/DataTable/types'

import { Pagination } from '../../interfaces'
import ProgressNS from '../general/progress/ProgressNS'
import { NoData } from '../general/nodata/NoData'

interface RowItem {
    _id: string
}

interface MyTableProps<T extends RowItem> {
    columns: TableColumn<T>[]
    data: T[]
    isLoading: boolean
    activeItem?: T
    setActiveItem?: (item: T | undefined) => void
    expandableRowsComponent?: ExpandableRowsComponent<T> | undefined
    pagination?: Pagination
    setPagination?: (pagination: Pagination) => void
    refetch: () => void
    showPagination?: boolean
    // rowOptions?: React.ReactElement
}

export const MyTable = <T extends RowItem>({
    columns,
    data,
    // rowOptions,
    setActiveItem,
    isLoading,
    activeItem,
    pagination,
    setPagination,
    expandableRowsComponent,
    showPagination,
}: MyTableProps<T>) => {
    console.log('my table render')
    const customStyles: TableStyles = useMemo(() => {
        return {
            headCells: {
                style: {
                    fontSize: '1rem',
                },
            },
            cells: {
                style: {
                    fontSize: '1rem',
                    cursor: 'pointer',
                },
            },
            // tableWrapper: {
            //     style: {
            //         maxWidth: '100%',
            //         border: '2px solid red',
            //     },
            // },
            // responsiveWrapper: {
            //     style: {
            //         // border: '4px solid blue',
            //         display: 'grid',
            //     },
            // },
        }
    }, [])

    const paginationComponentOptions: PaginationOptions = useMemo(() => {
        return {
            rowsPerPageText: 'Filas por página',
            rangeSeparatorText: 'de',
            // selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        }
    }, [])

    const onChangeRowsPerPage = (rowPerPage: number) =>
        setPagination && setPagination({ ...pagination, itemCount: rowPerPage })

    const onChangePage = useCallback(
        (page: number) => {
            // console.log({ pagination })
            // console.log({ page })
            setPagination &&
                setPagination({
                    ...pagination,
                    actualPage: page,
                })
        },
        [pagination, setPagination],
    )

    // const expandableRowsComponent = useCallback(
    //     () => <div className="multiedu__table--row">{rowOptions && rowOptions}</div>,
    //     [rowOptions],
    // )
    return (
        <div style={{ display: 'grid' }}>
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                expandableRows={expandableRowsComponent !== undefined ? true : false}
                // expandableRowsHideExpander
                expandOnRowClicked={expandableRowsComponent !== undefined ? true : false}
                expandableRowsComponent={expandableRowsComponent}
                onRowExpandToggled={(rowStatus, row) => {
                    if (setActiveItem) {
                        !rowStatus && row._id === activeItem?._id
                            ? setActiveItem(undefined)
                            : setActiveItem(row)
                    }
                }}
                onRowClicked={(row) => {
                    setActiveItem && setActiveItem(row)
                }}
                expandableRowExpanded={(row) => row?._id === activeItem?._id}
                highlightOnHover
                progressPending={isLoading}
                progressComponent={<ProgressNS />}
                pagination={showPagination}
                paginationServer
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage={pagination?.itemCount}
                paginationRowsPerPageOptions={[10, 20]}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                paginationTotalRows={pagination?.total}
                responsive
                noDataComponent={<NoData title="No hay datos" />}
            />
        </div>
    )
}

export default memo(MyTable) as typeof MyTable
