import { createContext, useContext, useEffect, useReducer } from 'react'
import { IState } from './AparienciaInterface'
import { aparienciaReducer } from './AparienciaReducer'

import { AuthContext } from '../../context/auth/AuthProvider'
import { useAppDispatch } from '../../hooks/useReduxHooks'
import { setEntity } from '../../store/slices'

const init = () => {
    return JSON.parse(localStorage.getItem('theme')!) || { theme: {} }
}

const initialState: IState = {
    theme: {
        colorSidebar: {},
        imgLogo: '',
        imgLogoPreview: '',
    },
    currentIdEntity: '',
    roles: {},
}

export const AparienciaContext = createContext<IState | any>(initialState)

export const AparienciaProvider = (props: any) => {
    const [state, dispatchImg] = useReducer(aparienciaReducer, initialState, init)
    const dispatchRedux = useAppDispatch()
    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (state.currentIdEntity) dispatchRedux(setEntity(state.currentIdEntity))

        localStorage.setItem('theme', JSON.stringify(state))
        user.logged &&
            document.documentElement.style.setProperty(
                '--color-primary',
                state.theme.colorSidebar.bg_sidebar,
            )
        user.logged &&
            document.documentElement.style.setProperty(
                '--color-secondary',
                state.theme.colorSidebar.bg_buttons,
            )
        user.logged &&
            document.documentElement.style.setProperty(
                '--color-font',
                state.theme.colorSidebar.font_color,
            )
    }, [state, user, dispatchRedux])

    return (
        <AparienciaContext.Provider value={{ state, dispatchImg }}>
            {props.children}
        </AparienciaContext.Provider>
    )
}
