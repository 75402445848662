import React from 'react'
import { useEffect, useState } from 'react'
import { axiosCourses, axiosStudent, axiosUserV5 } from '../../../config/axios'
import ContainerModalAllPage from './../../../components/modals/ContainerModalAllPage'
import GenerateCertificate from './../../../components/modals/continental/counter/detailUser/GenerateCertificate'
import ContainerModal from './../../../components/modals/ContainerModal'
import SendEmailModal from '../../../components/modals/continental/counter/detailUser/SendEmailModal'
import { useContext } from 'react'
import { AparienciaContext } from '../../../context/aparienciaContext/AparienciaProvider'
import ProgressNS from './../../../components/general/progress/ProgressNS'

const DetailStudent = ({ detailStudent, setviewTable }: any) => {
    const token = JSON.parse(localStorage.getItem('user')!).token

    const { state: stateApariencia } = useContext(AparienciaContext)

    const [user, setUser] = useState<any>('')

    const [modules, setModules] = useState<any>('')

    const [certificateModal, setCertificateModal] = useState<any>(false)

    const [sendEmail, setSendEmail] = useState<any>(false)

    const [certificate, setCertificate] = useState<any>('')

    const [loading, setloading] = useState(true)

    const [contentView, setContentView] = useState('programa')

    useEffect(() => {
        // console.log('ID USUARIO DE TABLA', detailStudent.detail._id)

        if (detailStudent.detail) {
            setloading(true)

            axiosUserV5({
                url: 'v5.0.1/api',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                data: {
                    fname: 'getUser',
                    user_id: detailStudent.detail._id,
                },
            })
                .then(({ data }) => {
                    // console.log(data)

                    setUser(data.user)

                    setloading(false)
                })
                .catch((e) => {
                    console.log(e.response)
                    setloading(false)
                })
        }
    }, [detailStudent.detail._id, token, detailStudent.detail])

    useEffect(() => {
        axiosStudent({
            url: '/v5.0.1/api',

            headers: {
                authorization: `Bearer ${token}`,
            },

            data: {
                fname: 'getCourseModules_byUser',
                scheduled_id: detailStudent.detail.scheduled,
                course_id: detailStudent.detail.course,
                user_id: detailStudent.detail._id,
            },
        })
            .then(({ data }) => {
                // console.log(data)
                setModules(data.modules)
            })
            .catch((e) => {
                console.log(e)
                console.log(e.response)
            })
    }, [
        detailStudent.detail.course,
        detailStudent.detail.scheduled,
        token,
        detailStudent.detail._id,
    ])

    useEffect(() => {
        axiosCourses({
            url: '/v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getCertificateByUser',
                entity: stateApariencia.currentIdEntity,
                scheduled: detailStudent.detail.scheduled,
                user: detailStudent.detail._id,
            },
        })
            .then(({ data }) => {
                // console.log('CertificateUser', data);
                setCertificate(data.certificate)
            })
            .catch((e) => {
                console.log(e)
                console.log(e.response)
            })
    }, [
        token,
        detailStudent.detail.scheduled,
        detailStudent.detail._id,
        stateApariencia.currentIdEntity,
    ])

    const downloadCertificate = (url: any) => {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('target', '_blank') //or any other extension
        document.body.appendChild(link)
        link.click()
    }

    return (
        <div className="detail_student_container">
            {loading ? (
                <ProgressNS />
            ) : (
                <>
                    <div className="back_button">
                        <div
                            style={{
                                color: `var(--color-secondary)`,
                                cursor: 'pointer',
                                width: 'max-content',
                            }}
                            onClick={() => setviewTable(true)}
                        >
                            <i className="fas fa-chevron-left"></i> Volver
                        </div>
                        <h4>
                            Diplomado, <span>{user.name + ' ' + user.lastname}</span>
                        </h4>
                    </div>

                    <div className="info_student_content">
                        <div className="image_student">
                            {user.photo && user.photo.includes('https://') ? (
                                <img
                                    src={user.photo}
                                    alt="ImagenDef"
                                    className="imgDeforView"
                                />
                            ) : (
                                <div className="imgDeforView">
                                    <i className="fas fa-user-circle" />
                                </div>
                            )}
                        </div>

                        <div className="data_patagraph_content">
                            <p>{user.name + user.lastname}</p>
                            <p>{user.email}</p>
                            <p>{user.phone}</p>
                            <p>{user.dni}</p>
                            <p>{user.address}</p>
                        </div>
                    </div>

                    <div className="detail_part_student">
                        <h4>Detalle de estudiante</h4>

                        <div className="option_select">
                            <div className="list_opt_slc">
                                <div
                                    className={
                                        contentView === 'programa'
                                            ? 'item active'
                                            : 'item'
                                    }
                                    onClick={() => setContentView('programa')}
                                >
                                    Programa
                                </div>
                                <div
                                    className={
                                        contentView === 'certificado'
                                            ? 'item active'
                                            : 'item'
                                    }
                                    onClick={() => setContentView('certificado')}
                                >
                                    Certificado
                                </div>
                            </div>

                            <div className="content_option_select">
                                {contentView === 'programa' ? (
                                    <div className="content_modules">
                                        <h4>Listado de Modulos</h4>

                                        <p>
                                            Para dar acceso a los módulos pendientes puede
                                            realizarlo al matrucular a alumno en
                                            programas, otra forma es indicar al estudiante
                                            que lo puede hacer desde la plataforma
                                        </p>

                                        <div className="modules_container">
                                            <ul className="list_modules">
                                                {modules.length > 0 ? (
                                                    modules.map((res: any) =>
                                                        res.buyed === true &&
                                                        res.status === 'enabled' ? (
                                                            <li
                                                                className="item_module"
                                                                key={res._id}
                                                            >
                                                                {' '}
                                                                <i className="fas fa-unlock"></i>{' '}
                                                                <p>{res.name}</p>{' '}
                                                            </li>
                                                        ) : (
                                                            <li
                                                                className="item_module"
                                                                style={{
                                                                    color: '#ADADAD;',
                                                                }}
                                                                key={res._id}
                                                            >
                                                                {' '}
                                                                <i className="fas fa-lock">
                                                                    {' '}
                                                                </i>{' '}
                                                                <p>{res.name}</p>{' '}
                                                            </li>
                                                        ),
                                                    )
                                                ) : (
                                                    <li style={{ color: '#ff0000' }}>
                                                        No hay modulos programados para
                                                        este curso ...{' '}
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="content_certificate">
                                        <h4>Generar certificado</h4>

                                        <p>
                                            Una vez finalizada la barra de progreso del
                                            programa puede generar el certificado.
                                        </p>

                                        <div className="progressbar_container">
                                            <ul className="progressbar">
                                                <li className="active">Modulo 1</li>
                                                <li>Modulo 2</li>
                                                <li>Modulo 3</li>
                                                <li>Modulo 4</li>
                                            </ul>
                                        </div>

                                        {certificate.hasCertificate === true ? (
                                            <div className="previewCertificateContainer">
                                                <p>
                                                    Enviar certificación al correo
                                                    electrónico del alumno
                                                </p>
                                                <div
                                                    className="image_certificate_container"
                                                    onClick={() =>
                                                        downloadCertificate(
                                                            certificate.url,
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src="/images/certificateActions/certificate.png"
                                                        alt=""
                                                    />
                                                    <div className="overlay">
                                                        {' '}
                                                        <i className="fas fa-cloud-download-alt"></i>{' '}
                                                    </div>
                                                </div>
                                                <div className="button_container_certificate">
                                                    <button
                                                        onClick={() => setSendEmail(true)}
                                                    >
                                                        Enviar
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="button_container">
                                                <button
                                                    onClick={() =>
                                                        setCertificateModal(true)
                                                    }
                                                >
                                                    {' '}
                                                    Generar Certificado{' '}
                                                </button>
                                            </div>
                                        )}

                                        <ContainerModalAllPage
                                            showModal={certificateModal}
                                        >
                                            <GenerateCertificate
                                                setShowModalCertificateView={
                                                    setCertificateModal
                                                }
                                                setSendEmail={setSendEmail}
                                                user_data={user}
                                                setCertificate={setCertificate}
                                                courseScheduled={
                                                    detailStudent.detail.scheduled
                                                }
                                            />
                                        </ContainerModalAllPage>
                                        <ContainerModal showModal={sendEmail}>
                                            <SendEmailModal
                                                email={user.email}
                                                setSendEmail={setSendEmail}
                                                courseScheduled={
                                                    detailStudent.detail.scheduled
                                                }
                                                user_data={user}
                                            />
                                        </ContainerModal>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default DetailStudent
