import { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
    getEntityAndTheme,
    getRoles,
} from '../../context/aparienciaContext/AparienciaAction'
// import { deleteEntity } from '../../context/entityContext/EntityAction';
// import { typesEntity } from '../../context/types/typesEntity';
import ContainerModal from '../modals/ContainerModal'
import { EditEntityModal } from '../modals/entity/EditEntity'
import { DeleteEntityModal } from '../modals/entity/DeleteEntity'
import { typeUi } from './../../context/types/typesui'
import { AparienciaContext } from './../../context/aparienciaContext/AparienciaProvider'
// import Notification, { type } from './../general/toast/Toast';
import { AuthContext } from './../../context/auth/AuthProvider'
import { types } from '../../context/types/types'

interface DataCardEntity {
    data: any
    userGeneral?: boolean
}

const CardEntity = ({ data, userGeneral }: DataCardEntity) => {
    const [option, setOption] = useState<boolean>(false)

    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

    const [showEditEntity, setShowEditEntity] = useState<boolean>(false)

    const [idEntity, setIdEntity] = useState<boolean>(false)

    const [detailEntity, setDetailEntity] = useState('')

    const { dispatchImg } = useContext(AparienciaContext)
    const { dispatch } = useContext(AuthContext)

    const { user } = useContext(AuthContext)

    const token = JSON.parse(localStorage.getItem('user')!).token

    const handleDelete = (id: any) => {
        setShowConfirmDelete(true)
        setIdEntity(id)
    }

    const handleEdit = (data: any) => {
        setShowEditEntity(true)
        setDetailEntity(data)
    }

    const history = useHistory()

    const goEntity = () => {
        getEntityAndTheme(token, data._id)
            .then(({ data }) => {
                // console.log(data)

                const theme = data

                // dispatchImg({
                //     type: typeUi.getTheme,
                //     payload: {
                //         colorSidebar: data.entity.customize.colors,
                //         imgLogo: data.entity.customize.logo
                //     }
                // })

                // dispatchImg({
                //     type: typeUi.getId,
                //     payload: data.entity._id
                // })

                // console.log("Entidad",data.entity._id )
                // console.log("User ID", user.idUser)

                getRoles(token, data.entity._id, user.idUser)
                    .then(({ data }) => {
                        console.log(data)

                        dispatch({
                            type: types.getRoles,
                            payload: data.roles,
                        })

                        if (data.roles['coordinador']) {
                            dispatch({
                                type: types.changeRoles,
                                payload: 'coordinador',
                            })

                            history.push('/dashboard/coordinador/courses')

                            dispatchImg({
                                type: typeUi.getTheme,
                                payload: {
                                    colorSidebar: theme.entity.customize.colors,
                                    imgLogo: theme.entity.customize.logo,
                                },
                            })

                            dispatchImg({
                                type: typeUi.getId,
                                payload: theme.entity._id,
                            })
                        }

                        if (data.roles['counter']) {
                            dispatch({
                                type: types.changeRoles,
                                payload: 'counter',
                            })

                            history.push('/dashboard/accountant/alumnos')

                            dispatchImg({
                                type: typeUi.getTheme,
                                payload: {
                                    colorSidebar: theme.entity.customize.colors,
                                    imgLogo: theme.entity.customize.logo,
                                },
                            })

                            dispatchImg({
                                type: typeUi.getId,
                                payload: theme.entity._id,
                            })
                        }

                        if (data.roles['admin b2b']) {
                            dispatch({
                                type: types.changeRoles,
                                payload: 'admin b2b',
                            })

                            // history.push('/dashboard/admin/home')
                            history.push('dashboard/admin/courses')

                            dispatchImg({
                                type: typeUi.getTheme,
                                payload: {
                                    colorSidebar: theme.entity.customize.colors,
                                    imgLogo: theme.entity.customize.logo,
                                },
                            })

                            dispatchImg({
                                type: typeUi.getId,
                                payload: theme.entity._id,
                            })
                        }

                        // console.log(!data.roles['admin b2b'])

                        if (
                            !data.roles['admin b2b'] &&
                            !data.roles['counter'] &&
                            !data.roles['coordinador']
                        ) {
                            history.push('/dashboard/user/entity')
                            // Notification(type.danger, "No tienes acceso a esta entidad")
                            toast.error('No tienes acceso a esta entidad')
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            })
            .catch((e) => {
                console.log(e.response)
            })
    }

    return (
        <div className="card_entity_container">
            <div
                className="card_entity_content"
                onClick={() => {
                    userGeneral && goEntity()
                }}
            >
                <div
                    className="img_background"
                    style={{ backgroundImage: `url(${data.customize.portrait})` }}
                ></div>
                <div className="overlay_cardImage"></div>
                <div className="infoCardEntity">
                    <div className="header_infoCardEntity">
                        <div className="left_content_infoCardEntity">
                            <div className="containerImageEntity">
                                <img src={data.customize.logo} alt="logo"></img>
                            </div>
                            <span>{data.entity_name}</span>
                        </div>
                        {userGeneral ? null : (
                            <div
                                className="right_content_infoCardEntity"
                                onClick={() => setOption(!option)}
                            >
                                <i className="fas fa-ellipsis-v"></i>
                            </div>
                        )}
                        {/* <div className="right_content_infoCardEntity">
                            <i className="fas fa-ellipsis-v" onClick={() => setOption(!option)} ></i>
                        </div> */}
                        {option && (
                            <div
                                className="contOptSelOpt"
                                onMouseLeave={() => setOption(false)}
                            >
                                <div className="optSelectOption">
                                    <div className="onlyOpt">
                                        <Link
                                            to={`/superadmin/dashboard/permissions/${data._id}`}
                                        >
                                            Gestionar permisos
                                        </Link>
                                    </div>

                                    <div
                                        className="onlyOpt"
                                        onClick={() => {
                                            handleEdit({ ...data, opt: 2 })
                                            setOption(!option)
                                        }}
                                    >
                                        <div>Asignar Administrador</div>
                                    </div>
                                    <div
                                        className="onlyOpt"
                                        onClick={() => {
                                            handleDelete(data._id)
                                            setOption(!option)
                                        }}
                                    >
                                        <div>Eliminar</div>
                                    </div>
                                    <div
                                        className="onlyOpt"
                                        onClick={() => {
                                            handleEdit(data)
                                            setOption(!option)
                                        }}
                                    >
                                        <div>Editar</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="description_infoCardEntity">
                        <p>{data.description}</p>
                    </div>
                </div>
            </div>
            <ContainerModal showModal={showConfirmDelete}>
                <DeleteEntityModal
                    idEntity={idEntity}
                    setShowConfirmDelete={setShowConfirmDelete}
                />
            </ContainerModal>

            <ContainerModal showModal={showEditEntity}>
                <EditEntityModal
                    detailEntity={detailEntity}
                    setShowEditEntity={setShowEditEntity}
                />
            </ContainerModal>
        </div>
    )
}

export default CardEntity
