import React, { useContext, useEffect, useState } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import ProgressNS from '../components/general/progress/ProgressNS'
import { verifyTokenExpiration } from '../context/auth/AuthAction'
import { AuthContext } from '../context/auth/AuthProvider'
import { types } from '../context/types/types'

export const PrivateRoute = ({
    isAuthenticated,
    component: Component,
    rol,
    ...rest
}: any) => {
    const [loader, setLoader] = useState<boolean>(true)
    const { dispatch } = useContext(AuthContext)
    const history = useHistory()

    useEffect(() => {
        // console.log('gaaaa')
        const verify = async () => {
            const logged = localStorage.getItem('user') ? true : false
            if (logged) {
                const token = JSON.parse(localStorage.getItem('user')!).token
                const expired = await verifyTokenExpiration(token)
                setLoader(false)
                if (expired) {
                    dispatch({
                        type: types.logout,
                    })
                }
            } else {
                history.push('/login')
            }
        }
        verify()
    }, [dispatch, history])

    if (loader)
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <ProgressNS />
            </div>
        )

    return (
        <Route
            {...rest}
            component={(props: any) =>
                isAuthenticated && rol ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        ></Route>
    )
}
