const CommingSoon = () => {
    return (
        <div className="main_Status scroll">
            <div className="contError notice">
                <img src="/images/ViewsAlert/CommingSoon.svg" alt="Notice page" />
                <div className="contMessg">
                    <div>"¡Proximamente!"</div>
                    {/* <div>{screenText}</div> */}
                </div>
            </div>
        </div>
    )
}

export default CommingSoon
