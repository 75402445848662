const CardAccess = ({ data, children }: any): JSX.Element => {
    const { title, name, info }: any = data

    return (
        <div className="containerAccess">
            <img src="/images/login/LoginMultiedu.svg" alt="multipachalogo" />
            <div className="contAccess">
                <div className="titleAccess">
                    <div>{title}</div>
                    {name && <div>{name}</div>}
                </div>
                {info && (
                    <div className="infoAccess">
                        <div className="infTit">{info}</div>
                        <div className="infHelp">
                            <span>¿Necesitas ayuda?</span>
                        </div>
                    </div>
                )}
                {children}
            </div>
        </div>
    )
}

export default CardAccess
