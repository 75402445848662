import { axiosAuth, axiosEntities } from './../../config/axios'

export const getEntitySuperAdmin = async (token: string) => {
    return axiosEntities({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getEntities',
            service: 'users',
        },
    })
}

export const userIsInEntities = async (token: string, idUser: string) => {
    return axiosEntities({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'userIsInEntitiesAdmin',
            service: 'users',
            user_id: idUser,
        },
    })
}

export const createEntitySuperAdmin = async (obj: any, token: string) => {
    return axiosEntities({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const updateEntitySuperAdmin = async (obj: any, token: string) => {
    return axiosEntities({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const deleteEntity = async (idEntity: any, token: string) => {
    return axiosEntities({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            service: 'users',
            fname: 'deleteEntity',
            entity_id: idEntity,
        },
    })
}

export const getPermisos = async (token: string, entity_id: string) => {
    return axiosAuth({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getManagement',
            service: 'users',
            entity_id: entity_id,
        },
    })
}

export const updatePermisos = async (token: string, obj: any) => {
    return axiosAuth({
        url: 'api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

// export const getUniqueEntidad = async (token: string, entityId: string) => {

//     return axiosAuth({
//         url: 'api',
//         headers: {
//             authorization: `Bearer ${token}`,
//         },
//         data: {
//             fname: 'getEntity',
//             service: 'users',
//             entity_id: entityId

//         }
//     })
// }
