import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { NoData } from '../../../../components/general/nodata/NoData'

import ProgressNS from '../../../../components/general/progress/ProgressNS'
import ContainerModal from '../../../../components/modals/ContainerModal'
import { AddEditScheduledChild } from '../../../../components/modals/scheduleds'
import { useAppSelector } from '../../../../hooks/useReduxHooks'
import type { ModalOptions, Scheduled } from '../../../../interfaces'
import {
    useGetCoursesChildrenQuery,
    useGetScheduledsChildrenQuery,
} from '../../../../store/api'
import { selectAuth, selectCourse } from '../../../../store/slices'
import './modulepage.sass'

const lf = new Intl.ListFormat('es')

interface CustomModule {
    id: string
    name: string
    price: number
}

const ModulePage2 = () => {
    const { entity } = useAppSelector(selectAuth)
    const { mainScheduled } = useAppSelector(selectCourse)
    const { _idCourse } = useParams<{ _idCourse: string }>()
    const [minDateStart, setMinDateStart] = useState('')
    const [minDateEnd, setMinDateEnd] = useState('')
    const [maxDate, setMaxDate] = useState('')
    const [disabledProgram, setdisabledProgram] = useState(true)
    const [isNew, setIsNew] = useState<boolean>(true)
    const [showModalAddEditScheduledChild, setShowModalAddEditScheduledChild] =
        useState<ModalOptions>('hide')
    const [showScheduledInfo, setShowScheduledInfo] = useState<boolean>(false)

    const [activeModule, setActiveModule] = useState<CustomModule>()
    const [activeScheduled, setActiveScheduled] = useState<Scheduled>()

    const {
        data: scheduleds,
        // isLoading: loadingScheduleds
    } = useGetScheduledsChildrenQuery({
        entity_id: entity,
        parent_scheduled: _idCourse,
    })

    const { data: modules, isLoading: isLoadingModules } = useGetCoursesChildrenQuery(
        {
            entity_id: entity,
            parent_course_id: mainScheduled?.course._id!,
        },
        {
            selectFromResult: ({ data, isLoading }) => ({
                data: data?.courses.map((c) => ({
                    id: c._id,
                    name: c.name,
                    price: c.price.course_price,
                    // start_date: c.,
                    // end_date: c.end_date,
                })),
                isLoading,
            }),
        },
    )

    function dayCorrect(date: string, type: string) {
        let fecha = new Date(date)
        // console.log(date);
        // console.log(fecha);
        if (type === 'minus') {
            fecha.setDate(fecha.getDate())
        } else {
            fecha.setDate(fecha.getDate() + 2)
        }
        let day = fecha.getDate().toString().padStart(2, '0')
        let month = (fecha.getMonth() + 1).toString().padStart(2, '0')
        let year = fecha.getFullYear().toString().padStart(2, '0')
        let FechaCorrect = `${year}-${month}-${day}`
        // console.log(FechaCorrect);
        return FechaCorrect
    }
    function orderdate(date?: string) {
        if (date) {
            const format = date.split('-')
            const dateCorrect = format[2] + '-' + format[1] + '-' + format[0]
            return dateCorrect
        }
        return ''
    }

    const clearModal = () => {
        setActiveScheduled(undefined)
        setMinDateEnd('')
        setMinDateStart('')
        setMaxDate('')
    }

    const handleSelectModule = (module: CustomModule, key: number) => {
        // console.log({ module })
        clearModal()
        setShowScheduledInfo(true)
        setActiveModule(module)
        let scheduledExists = scheduleds?.scheduleds.find(
            (data) => data.course._id === module.id,
        )
        console.log({ scheduledExists })
        if (!!scheduledExists) {
            setIsNew(false)
            setActiveScheduled(scheduledExists)
            if (modules && modules.length > key + 1) {
                let next = scheduleds?.scheduleds.find(
                    (data: any) => data.course._id === modules[key + 1].id,
                )
                if (!!next) {
                    setMaxDate(dayCorrect(next.start_date.toString(), 'minus'))
                } else {
                    setMaxDate(mainScheduled?.end_date.toString() || '')
                    // console.log('no tiene módulo siquiente')
                }
            } else {
                setMaxDate(mainScheduled?.end_date.toString() || '')
            }
        } else {
            setActiveScheduled(undefined)
            setIsNew(true)
            setMaxDate(mainScheduled?.end_date.toString() || '')
        }
        if (key === 0) {
            setMinDateStart(mainScheduled?.start_date.toString() || '')
            setMinDateEnd(mainScheduled?.start_date.toString() || '')
            setdisabledProgram(false)
        } else {
            let prev =
                modules &&
                scheduleds?.scheduleds.find(
                    (data) => data.course._id === modules[key - 1].id,
                )
            if (!!prev) {
                setMinDateStart(dayCorrect(prev.end_date.toString(), 'add'))
                setMinDateEnd(dayCorrect(prev.end_date.toString(), 'add'))
                setdisabledProgram(false)
            } else {
                setdisabledProgram(true)
            }
        }
    }

    return (
        <div className="container_UsuariosPage">
            <div className="headUserPag">
                <h3
                    onClick={() => {
                        console.log(mainScheduled)
                        console.log({ activeScheduled })
                    }}
                >
                    {mainScheduled?.name}
                </h3>
            </div>
            <div
                className="bodyUserPag Modules scroll"
                style={{ margin: '0', gridTemplateRows: 'max-content auto' }}
            >
                <div className="content_data_course">
                    <div className="left_data">
                        <img
                            style={{
                                objectFit: 'contain',
                            }}
                            src={mainScheduled?.coverpage}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = 'https://via.placeholder.com/30'
                            }}
                            alt=""
                            className="img_course"
                        />
                        <div className="name_Category">
                            <div className="name">{mainScheduled?.name}</div>
                            <div className="description">
                                {mainScheduled?.description}
                            </div>
                        </div>
                    </div>
                    <div className="desmodData">
                        <div className="item">
                            <span>
                                <b>Docentes:</b>
                            </span>
                            <span>
                                {mainScheduled?.teachers &&
                                    lf.format(
                                        mainScheduled.teachers.map(
                                            (teacher) =>
                                                teacher.name + ' ' + teacher.lastname,
                                        ),
                                    )}
                            </span>
                        </div>
                        <div className="item">
                            <span>
                                <b>Fecha de Inicio:</b>
                            </span>
                            <span>
                                {mainScheduled?.start_date &&
                                    orderdate(mainScheduled.start_date.toString())}
                            </span>
                        </div>
                        <div className="item">
                            <span>
                                <b>Fecha de Fin:</b>
                            </span>
                            <span>
                                {mainScheduled?.end_date &&
                                    orderdate(mainScheduled.end_date.toString())}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="content_modules">
                    {isLoadingModules ? (
                        <ProgressNS />
                    ) : (
                        <>
                            <div className="list_modules" style={{ width: '298px' }}>
                                <div className="title">Módulos del curso</div>
                                <ul className="listEvaluations scroll">
                                    {modules?.map(({ id, name, price }, key) => (
                                        <li
                                            className={`itemaForum pointer
                                                ${
                                                    id === activeModule?.id
                                                        ? 'select '
                                                        : ''
                                                }
                                            `}
                                            key={id}
                                            onClick={() =>
                                                handleSelectModule(
                                                    { id, name, price },
                                                    key,
                                                )
                                            }
                                        >
                                            <div className="icon">
                                                <i className="fas fa-folder-open" />
                                            </div>
                                            <div className="detailsItem">
                                                <div>{name}</div>
                                                <div></div>
                                            </div>
                                            <i className="fas fa-chevron-right" />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="content_data_module">
                                {showScheduledInfo ? (
                                    <div className="info_module">
                                        <div className="left_info_module">
                                            <div className="info_1">
                                                Título:
                                                <span>
                                                    {activeScheduled?.name
                                                        ? activeScheduled.name
                                                        : activeModule?.name || ''}
                                                </span>
                                            </div>
                                            <div className="info_2">
                                                <div className="info_1">
                                                    Fecha de inicio:
                                                    <span>
                                                        {activeScheduled?.start_date
                                                            ? orderdate(
                                                                  activeScheduled.start_date.toString(),
                                                              )
                                                            : 'no definido aun'}
                                                    </span>
                                                </div>
                                                <div className="info_1">
                                                    fecha de fin:
                                                    <span>
                                                        {activeScheduled?.end_date
                                                            ? orderdate(
                                                                  activeScheduled.end_date.toString(),
                                                              )
                                                            : 'no definido aun'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="info_1">
                                                Precio:
                                                <span>S/ {activeModule?.price}</span>
                                            </div>
                                        </div>
                                        <div className="right_info_module">
                                            {isNew ? (
                                                <button
                                                    disabled={disabledProgram}
                                                    style={
                                                        disabledProgram
                                                            ? {
                                                                  background: 'gray',
                                                                  cursor: 'no-drop',
                                                              }
                                                            : {}
                                                    }
                                                    className="btn_program"
                                                    onClick={() =>
                                                        setShowModalAddEditScheduledChild(
                                                            'add',
                                                        )
                                                    }
                                                >
                                                    PROGRAMAR
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn_program"
                                                    onClick={() =>
                                                        setShowModalAddEditScheduledChild(
                                                            'edit',
                                                        )
                                                    }
                                                >
                                                    EDITAR
                                                </button>
                                            )}
                                            {disabledProgram && (
                                                <span>
                                                    Debes programar el módulo anterior
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <NoData title="Seleccione un módulo" />
                                )}
                                <div className="materials_module"></div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <ContainerModal
                showModal={
                    showModalAddEditScheduledChild === 'add' ||
                    showModalAddEditScheduledChild === 'edit'
                }
            >
                <AddEditScheduledChild
                    maxDate={maxDate}
                    minDateEnd={minDateEnd}
                    minDateStart={minDateStart}
                    showModal={showModalAddEditScheduledChild}
                    setShowModal={setShowModalAddEditScheduledChild}
                    activeScheduled={activeScheduled!}
                    activeModuleId={activeModule?.id!}
                    setMinDateEnd={setMinDateEnd}
                    setActiveScheduled={setActiveScheduled}
                    setIsNew={setIsNew}
                />
            </ContainerModal>
        </div>
    )
}

export default ModulePage2
