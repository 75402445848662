import { useState } from 'react'
import FormUserB2B from './FormUserB2B'

const FormLinkInvitation = ({ onSumbitData, loading, setModal, closeModal }: any) => {
    const [showFormCreateUser, setShowFormCreateUser] = useState(false)

    const showModalCreateUser = () => {
        setShowFormCreateUser(true)
        setModal(true)
    }

    return (
        <div className="forms_container">
            {showFormCreateUser ? (
                <FormUserB2B
                    onSumbitData={onSumbitData}
                    loading={loading}
                    setModal={setModal}
                    closeModal={closeModal}
                />
            ) : (
                <div className="formLinkInvitation_container">
                    <form onSubmit={(e) => onSumbitData(e)}>
                        <div className="container_input">
                            <div> Link de la invitacion </div>
                            <input placeholder="Correo Electronico" name="email" />
                        </div>

                        <div className="text_second_form">
                            {' '}
                            Escriba el correo del destinatario{' '}
                        </div>
                        <div className="container_input_two">
                            <input placeholder="Nombres" name="name" />

                            <button type="submit" disabled={loading}>
                                {' '}
                                Enviar{' '}
                            </button>
                        </div>
                    </form>

                    <hr></hr>

                    <div className="button_add_user" onClick={showModalCreateUser}>
                        <button type="button"> Asignar Manualmente </button>
                    </div>

                    <div
                        className="button_cancel"
                        onClick={() => {
                            closeModal()
                            setModal(false)
                        }}
                    >
                        Cancelar
                    </div>
                </div>
            )}
        </div>
    )
}

export default FormLinkInvitation
