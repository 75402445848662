import React, { useCallback, useEffect, useState } from 'react'
import { axiosEvaluations } from '../../../../../config/axios'

const ListQuestionsShow = ({
    token,
    id,
    typeOption,
    setTypeOption,
    setQuestions,
}: any) => {
    const [state, setstate] = useState<any[]>([])
    const [option, setoption] = useState<boolean>(false)
    const [data, setdata] = useState<any>({})

    const showItemEvaluation = useCallback(() => {
        Boolean(id) &&
            axiosEvaluations({
                url: 'v5.0.1/api',
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                data: {
                    fname: 'getEvaluation',
                    evaluation_id: id,
                },
            })
                .then(({ data }) => {
                    console.log(data)
                    setdata(data)
                    setstate([...data.questions])

                    let array: any[] = []
                    for (let { question, alternatives } of data.questions) {
                        array.push({ ...question, alternatives, questionbank: true })
                    }
                    setQuestions([...array])
                })
                .catch((err) => {
                    console.log(err.response)
                })
    }, [setQuestions, token, id])

    useEffect(() => {
        showItemEvaluation()
    }, [showItemEvaluation])

    // const deleteEvaluation = () => {
    // }

    // const showEditEvaluation = () => {
    // }
    return (
        <div className="optModify showEvaluation">
            <div className="contFormum">
                <div className="headerContentEvaluation">
                    <i className="icon fas fa-file-alt"></i>
                    <div className="title">{data.evaluation?.title}</div>
                    <div className="description">{data.evaluation?.description}</div>
                </div>
                <div
                    className="menu_container_courses"
                    style={{
                        height: '106px',
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '35px',
                    }}
                >
                    <i className="fas fa-ellipsis-v" onClick={() => setoption(!option)} />
                    <div className={`optSelectOption ${option ? 'active' : ''}`}>
                        <div className="onlyOpt" onClick={() => setTypeOption('update')}>
                            Editar
                        </div>
                        <div className="onlyOpt" onClick={() => setTypeOption('delete')}>
                            Eliminar
                        </div>
                    </div>
                </div>
                <span className="separator"></span>
                <div className="bodyContentEvaluation">
                    <div className="questions">
                        <div className="list_question">
                            {state.map((qst: any, key: number) => (
                                <div className="view_question border" key={key}>
                                    <div className="container_question">
                                        <div className="half">
                                            <label>Nivel de Dificultad:</label>
                                            <label>
                                                {qst.question.nivel === 'easy'
                                                    ? 'Fácil'
                                                    : qst.question.nivel === 'medium'
                                                    ? 'Normal'
                                                    : 'Difícil'}
                                            </label>
                                        </div>
                                        <div className="half">
                                            <label>Puntos:</label>
                                            <label>{qst.question.points}</label>
                                        </div>
                                        <div className="half">
                                            <label>Tipo de Pregunta:</label>
                                            <label>
                                                {qst.question.type === 'unique'
                                                    ? 'Opción única'
                                                    : qst.question.type === 'multiple'
                                                    ? 'Selección múltiple'
                                                    : qst.question.type === 'boolean'
                                                    ? 'Verdadero o Falso'
                                                    : qst.question.type === 'short'
                                                    ? 'Pregunta corta'
                                                    : qst.question.type === 'large'
                                                    ? 'Pregunta larga'
                                                    : 'Pregunta con archivos'}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="container_question">
                                        <label>Pregunta:</label>
                                        <label className="question">
                                            {qst.question.title}
                                        </label>
                                    </div>
                                    {qst.question.question ? (
                                        <div className="container_question">
                                            <label>Descripción:</label>
                                            <div
                                                className="question_view"
                                                dangerouslySetInnerHTML={{
                                                    __html: qst.question.question,
                                                }}
                                            ></div>
                                        </div>
                                    ) : null}
                                    <div className="container_question">
                                        <label>Alternativas:</label>
                                        <div className="alternatives_view">
                                            <ul>
                                                {qst.question.type === 'unique' ||
                                                qst.question.type === 'multiple' ||
                                                qst.question.type === 'boolean'
                                                    ? qst.alternatives?.map(
                                                          (alter: any, key: number) => (
                                                              <li key={key}>
                                                                  <i
                                                                      className={
                                                                          alter.correct ===
                                                                          true
                                                                              ? 'fas fa-check-square'
                                                                              : 'far fa-square'
                                                                      }
                                                                  ></i>
                                                                  {alter.value}
                                                              </li>
                                                          ),
                                                      )
                                                    : qst.question.type === 'short'
                                                    ? 'Los alumnos responderán la pregunta de manera consisa (80 palabras).'
                                                    : qst.question.type === 'large'
                                                    ? 'Los alumnos responderán la pregunta de forma libre y explayada.'
                                                    : 'Los alumnos responderán la pregunta adjuntando un archivo'}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* <div className="contBtns"> */}
                {/* <div className="btnOpts" onClick={deleteEvaluation}>ELIMINAR</div> */}
                {/* <div className="btnOpts modify" onClick={()=>setTypeOption("update")}>EDITAR</div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default ListQuestionsShow
