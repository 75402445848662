import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import ContainerModal from '../../../../components/modals/ContainerModal'
import { QuestionAddEdit, QuestionDelete, QuestionDetail } from './modalsQuestion'
import { ModalOptions, Pagination, Question } from '../../../../interfaces'
import { useGetCoursesChildrenQuery, useGetQuestionsQuery } from '../../../../store/api'
import { useAppSelector } from '../../../../hooks/useReduxHooks'
import { selectAuth } from '../../../../store/slices'
import { Button, MyTable, TableHeader } from '../../../../components'
import type { TableColumn } from 'react-data-table-component'

const QuestionAll2 = () => {
    const { entity } = useAppSelector(selectAuth)
    const { _idCourse, _idModules } = useParams<{
        _idCourse: string
        _idModules: string
    }>()

    const [showModalView, setShowModalView] = useState(false)
    const [showAddEditQuestion, setShowAddEditQuestion] = useState<ModalOptions>('hide')
    const [showModalDelete, setshowModalDelete] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState<Question>()
    const [textQuery, setTextQuery] = useState('')
    const [pagination, setPagination] = useState<Pagination>({
        actualPage: 1,
        itemCount: 15,
        total: 0,
    })

    const {
        moduleName,
        // isLoading: isLoadingModules,
        // isFetching: isFetchingModules,
    } = useGetCoursesChildrenQuery(
        {
            entity_id: entity,
            parent_course_id: _idCourse,
        },
        {
            selectFromResult: ({ data, isLoading, isFetching }) => ({
                moduleName:
                    data?.courses.find((course) => course._id === _idModules)?.name || '',
                isLoading,
                isFetching,
            }),
        },
    )

    const {
        data,
        isLoading: isLoadingQuestions,
        isFetching: isFetchingQuestions,
        refetch,
    } = useGetQuestionsQuery(
        {
            entity: entity,
            filter: moduleName,
            page: pagination.actualPage!,
            docs: pagination.itemCount!,
            descendant: false,
            query: {
                field: 'title',
                search: textQuery,
            },
        },
        {
            skip: moduleName === '' ? true : false,
        },
    )

    const columns: TableColumn<Question>[] = useMemo(
        () => [
            {
                name: 'Pregunta',
                selector: (row) => row.title,
                maxWidth: '400px',
            },
            {
                name: 'Tipo',
                selector: (row) => row.type,
            },
            {
                name: 'Dificultad',
                selector: (row) => row.nivel,
            },
        ],
        [],
    )

    const RowOptions = useCallback(
        () => (
            <div
                className="multiedu__table--row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                    padding: '1rem',
                }}
            >
                <Button onClick={() => setShowModalView(true)} text="Mostrar pregunta" />
                <Button
                    onClick={() => setShowAddEditQuestion('edit')}
                    text="Actualizar pregunta"
                />
                <Button
                    onClick={() => setshowModalDelete(true)}
                    text="Eliminar pregunta"
                />
            </div>
        ),
        [],
    )

    const openAddModal = useCallback(() => {
        setShowAddEditQuestion('add')
        setCurrentQuestion(undefined)
    }, [])

    useEffect(() => {
        if (data) {
            let { itemCount, ...newPagination } = data.pagination
            setPagination((c) => ({
                ...c,
                ...newPagination,
            }))
        }
    }, [data])

    return (
        <>
            <div className="main_UsuariosB2bPage">
                <div className="headUsuariosB2b">
                    <h3>Banco de Preguntas del curso: {moduleName}</h3>
                    <Link
                        to={
                            '/dashboard/coordinator/evaluations/' +
                            _idCourse +
                            '/' +
                            _idModules
                        }
                        className="btn_Page_Evaluations"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            columnGap: '10px',
                        }}
                    >
                        <i className="fas fa-reply" /> REGRESAR
                    </Link>
                </div>
                <div className="bodyUsuariosB2b scroll">
                    <div className="cardEntity_container">
                        <TableHeader
                            title="Listado de Preguntas"
                            refetch={refetch}
                            handleShowAddModal={openAddModal}
                            setTextQuery={setTextQuery}
                        />
                        <MyTable
                            columns={columns}
                            data={data?.questions || []}
                            isLoading={isLoadingQuestions || isFetchingQuestions}
                            activeItem={currentQuestion}
                            setActiveItem={setCurrentQuestion}
                            pagination={pagination}
                            setPagination={setPagination}
                            refetch={refetch}
                            expandableRowsComponent={RowOptions}
                            showPagination
                        />
                    </div>
                </div>
            </div>
            <ContainerModal showModal={showAddEditQuestion !== 'hide'}>
                <QuestionAddEdit
                    showModal={showAddEditQuestion}
                    setShowModal={setShowAddEditQuestion}
                    currentQuestion={currentQuestion!}
                    setCurrentQuestion={setCurrentQuestion}
                    moduleName={moduleName}
                />
            </ContainerModal>
            <ContainerModal showModal={showModalDelete}>
                <QuestionDelete
                    setShowModal={setshowModalDelete}
                    questionId={currentQuestion?._id!}
                />
            </ContainerModal>
            <ContainerModal showModal={showModalView}>
                <QuestionDetail
                    setShowModal={setShowModalView}
                    currentQuestion={currentQuestion!}
                />
            </ContainerModal>
        </>
    )
}

export default QuestionAll2
