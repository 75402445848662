import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import ListQuestions from './../evaluationXCourse/listQuestions/ListQuestions'
import ContainerModal from './../../../../components/modals/ContainerModal'
import QuestionsSelect from './questionSelect/QuestionsSelect'
import { axiosEvaluations } from '../../../../config/axios'
import SpinnerModal from '../../../../components/general/spinner/SpinnerModal'
import { convertMinutesToHours, handleOnInput } from '../../../../utils'
import { Button2 } from '../../../../components'
import { NoData } from '../../../../components/general/nodata/NoData'
// import Notification, { type } from '../../../../components/general/toast/Toast';

const CreateEvaluationsPage = ({
    _idModules,
    _idCourse,
    typeOption,
    setTypeOption,
    curso_name,
    token,
    entity,
    dataEvaluations,
    datasettingE,
    dataquestions,
    nivelsquestionE,
    peticionGet,
}: any) => {
    const [loader, setLoader] = useState<boolean>(false)

    const [questionsArray, setQuestionsArray] = useState<any>([])
    // const [questionsSelect, setQuestionsSelect] = useState<any>([])
    const [showSetting, setShowSetting] = useState<boolean>(false)
    const [warning] = useState<boolean>(false)
    const [arrayIds, setarrayIds] = useState<any[]>([])
    const [showSelectQuestions, setShowSelectQuestions] = useState<boolean>(false)
    const [questionsArrayBank, setQuestionsArrayBank] = useState<any>([])
    const [time, setTime] = useState<string>('')
    const [data, setdata] = useState<any>({
        _id: '',
        title: '',
        attempts: 1,
        duration_minutes: 0,
        duration_hours: 0,
        duration_time: 0,
        points: 0,
    })
    // const [datasetting, setdatasetting] = useState<any>({
    //     random_order: false,
    //     show_max_quantity: false,
    //     show_next: false,
    //     block_return: false,
    //     one_by_one: false,
    //     random_by_student: false,
    // })
    const [nivelsquestion, setnivelsquestion] = useState<any>({
        easy: 0,
        medium: 0,
        hard: 0,
    })
    const { title, attempts, points, duration_time } = data
    // const { easy, medium, hard } = nivelsquestion
    // const {
    //     random_order,
    //     show_max_quantity,
    //     show_next,
    //     block_return,
    //     one_by_one,
    //     random_by_student,
    // } = datasetting

    const peticionGetQuestions = useCallback(
        (name_course: string) => {
            axiosEvaluations({
                url: 'v5.0.1/api',
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                data: {
                    fname: 'getQuestions',
                    course_name: name_course,
                    entity: entity,
                    docs: 100,
                    page: 1,
                    filter_by_name: '',
                    sort: '-createdAt',
                    query: {
                        field: 'title',
                        search: '',
                    },
                },
            })
                .then(({ data }) => {
                    // console.log(data);
                    setQuestionsArray(data.questions)
                })
                .catch((err) => {
                    console.log(err.response)
                })
        },
        [token, entity],
    )

    const deleteQuestion = (value: number) => {
        const clonedata = questionsArrayBank
        const newdata: any = []
        // setQuestionsArrayBank([])
        clonedata.map((data: any, key: number) => {
            if (key !== value) newdata.push(data)
            return null
        })
        setQuestionsArrayBank([])
        // console.log(newdata)
        setQuestionsArrayBank(newdata)
        // console.log(questionsArrayBank)
    }

    useEffect(() => {
        peticionGetQuestions(curso_name)
        typeOption === 'update' && setdata(dataEvaluations)
        // setdatasetting(datasettingE)
        setQuestionsArrayBank([...dataquestions])
        setnivelsquestion(nivelsquestionE)
    }, [
        curso_name,
        dataquestions,
        dataEvaluations,
        // datasettingE,
        nivelsquestionE,
        peticionGetQuestions,
        typeOption,
    ])

    const WarningCheck = () => {
        // if (typeOption === 'update') return
        // if (title === '' || attempts !== 0) {
        //     setShowSetting(true)
        // }
        console.log('warnign check')
        if (title === '' || +attempts === 0 || !Boolean(duration_time)) {
            toast.warning('Completar todos los campos')
            return setShowSetting(true)
        }
        // return alert('save')
        peticionPost()
    }

    const peticionPost = () => {
        // setLoader(true)
        // e.preventDefault()
        // console.log(datasetting)
        // console.log(questionsArrayBank);
        // console.log(data._id);
        let total = 0
        questionsArrayBank.forEach(
            (dat: any) => dat.points && total < 21 && (total += +dat.points),
        )
        if (total < 20) return toast.warn(`El total de puntos debe ser  20`)
        if (total > 20) return toast.error(`El máximo de puntos es 20`)

        setLoader(true)

        let idsArray: any[] = []
        let questionArray: any[] = []
        questionsArrayBank.map((dat: any, index: number) => {
            if (dat.questionbank) {
                return idsArray.push({ id: dat._id, index, points: +dat.points || 0 })
            } else {
                return questionArray.push({ ...dat, index, points: +dat.points })
            }
        })
        axiosEvaluations({
            url: 'v5.0.1/api',
            method: 'POST',
            headers: {
                authorization: `Bearer ${token}`,
            },

            data: {
                fname:
                    typeOption === 'update'
                        ? 'updateEvaluationManual'
                        : 'createEvaluationManual',
                evaluation: typeOption === 'update' ? data._id : '',
                main_course: _idCourse,
                course_id: _idModules,
                entity: entity,
                points: points,
                arrayIds: idsArray,
                arrayQuestions: questionArray,
                duration_time,
                title: title,
                attempts: attempts,
                // options: datasetting,
                nivel: nivelsquestion,
            },
        })
            .then(({ data }) => {
                console.log(data)
                peticionGet('create')
                setTypeOption('show')
                if (typeOption === 'update') {
                    // Notification(type.success, "La evaluación ha sido actualizada")
                    toast.success('La evaluación ha sido actualizada')
                } else {
                    // Notification(type.success, "La evaluación ha sido creada")
                    toast.success('La evaluación ha sido creada')
                }
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                console.log(err.response)
                // Notification(type.danger, "Ha ocurrido un error")
                toast.warning('Ha ocurrido un error')
                setLoader(false)
            })
    }

    const newQuestion = () => {
        const data: any = {
            alternatives: [],
            entity: entity,
            keywords: [curso_name],
            nivel: 'easy',
            points: 0,
            question: '',
            questionbank: false,
            title: '',
            type: 'unique',
            _id: '00000000000000',
        }
        setQuestionsArrayBank([...questionsArrayBank, data])
    }
    const onChangeInputs = (e: any) => {
        setdata({
            ...data,
            [e.target.name]: e.target.value,
        })
    }

    useEffect(() => {
        // console.log('converted time')
        let convertedTime: string = convertMinutesToHours(duration_time)
        console.log({ convertedTime })
        setTime(convertedTime)
    }, [duration_time])

    // const onChangeSetting = (name: string) => {
    //     setdatasetting({
    //         ...datasetting,
    //         [name]: !datasetting[name],
    //     })
    // }

    // const onChangeNivels = (e: any) => {
    //     setnivelsquestion({
    //         ...nivelsquestion,
    //         [e.target.name]: Number(e.target.value),
    //     })
    // }

    return (
        <div>
            <div className="contFormum">
                <div className="headerContentEvaluation">
                    <i className="icon fas fa-file-alt"></i>
                    <div className="title">
                        {title || 'Nombre de Evaluación no definido'}
                    </div>
                    {/* <div className="description">
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                    </div> */}
                </div>
                <div className="contBtns">
                    {/* {
                        typeOption === "update" &&
                        <div className="btnOpts">ELIMINAR</div>
                    } */}

                    <div className="newBtn">
                        <button
                            type="submit"
                            className="btnMody"
                            onClick={WarningCheck}
                            disabled={loader}
                        >
                            {loader ? <SpinnerModal /> : 'Guardar'}
                        </button>
                    </div>
                    <div className="cogbtn">
                        {warning ? <i className="alerta fas fa-exclamation"></i> : null}
                        <i className="fas fa-cog" onClick={() => setShowSetting(true)} />
                    </div>
                </div>
                <span className="separator"></span>
                <div className={`bodyContentEvaluation ${loader ? 'disabled' : ''}`}>
                    {questionsArrayBank.length === 0 ? (
                        <NoData title="Agregue nuevas preguntas" />
                    ) : (
                        <div className="questions">
                            <ListQuestions
                                questions={questionsArrayBank}
                                id={data._id}
                                setQuestions={setQuestionsArrayBank}
                                setTypeOption={setTypeOption}
                                deleteQuestion={deleteQuestion}
                                typeOption={typeOption}
                            />
                        </div>
                    )}
                    <div className="optionsAdd">
                        <div className="btn100" onClick={newQuestion}>
                            <span>
                                <i className="fas fa-plus" />
                            </span>
                            <span>Añadir nueva pregunta</span>
                        </div>
                        <div
                            className="btn100"
                            onClick={() => setShowSelectQuestions(true)}
                        >
                            <span>
                                <i className="fas fa-search" />
                            </span>
                            <span>Encontrar preguntas</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`contMoreOpts ${showSetting ? 'active' : ''}`}>
                <div className="titmoreops">
                    <span>Configuración</span>
                    <span onClick={() => setShowSetting(false)}>X</span>
                </div>
                <div className="bodymoreops">
                    <div className="container_options">
                        <label>Nombre de Evaluación: *</label>
                        <input
                            required
                            className="input_question"
                            type="text"
                            name="title"
                            value={title}
                            onChange={onChangeInputs}
                            disabled={loader}
                        />
                    </div>
                    <div className="container_options">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <label>Duración ({time} h)</label>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '.5rem',
                                }}
                            >
                                <input
                                    className="input_question"
                                    name="duration_time"
                                    value={duration_time}
                                    type="number"
                                    min="5"
                                    step="5"
                                    max="550"
                                    onChange={onChangeInputs}
                                    onInput={(e) => handleOnInput(e, 551, 3)}
                                />
                                <span>min</span>
                            </div>
                        </div>
                        {/* <div className="select time">
                            <label>Duración ( hs : min ): *</label>
                            <input
                                required
                                max="10"
                                min="0"
                                className="input_question"
                                type="number"
                                name="duration_hours"
                                value={duration_hours}
                                onChange={onChangeInputs}
                                disabled={loader}
                            />
                            <span>:</span>
                            <input
                                required
                                max="59"
                                min="0"
                                className="input_question"
                                type="number"
                                name="duration_minutes"
                                value={duration_minutes}
                                onChange={onChangeInputs}
                                disabled={loader}
                            />
                        </div> */}
                        <div className="select">
                            <label>N° de intentos:*</label>
                            <input
                                max="5"
                                min="1"
                                required
                                className="input_question"
                                type="number"
                                name="attempts"
                                value={attempts}
                                onChange={onChangeInputs}
                                disabled={loader}
                            />
                        </div>
                    </div>
                    {/* <div className="container_options">
                        <label>Opciones:</label>
                        <div className={`content_setting ${loader ? 'disabled' : ''}`}>
                            <div
                                className="setting"
                                onClick={() => onChangeSetting('show_max_quantity')}
                            >
                                <i
                                    className={
                                        show_max_quantity
                                            ? 'fas fa-check-square'
                                            : 'far fa-square'
                                    }
                                ></i>
                                <label htmlFor="">Mostrar Cantidad Maxima</label>
                            </div>
                            <div
                                className="setting"
                                onClick={() => onChangeSetting('show_next')}
                            >
                                <i
                                    className={
                                        show_next
                                            ? 'fas fa-check-square'
                                            : 'far fa-square'
                                    }
                                ></i>
                                <label htmlFor="">Mostrar Siguiente Pregunta</label>
                            </div>
                            <div
                                className="setting"
                                onClick={() => onChangeSetting('block_return')}
                            >
                                <i
                                    className={
                                        block_return
                                            ? 'fas fa-check-square'
                                            : 'far fa-square'
                                    }
                                ></i>
                                <label htmlFor="">Bloquear el Retroceder</label>
                            </div>
                            <div
                                className="setting"
                                onClick={() => onChangeSetting('random_order')}
                            >
                                <i
                                    className={
                                        random_order
                                            ? 'fas fa-check-square'
                                            : 'far fa-square'
                                    }
                                ></i>
                                <label htmlFor="">Orden Aleatorio</label>
                            </div>
                            <div
                                className="setting"
                                onClick={() => onChangeSetting('one_by_one')}
                            >
                                <i
                                    className={
                                        one_by_one
                                            ? 'fas fa-check-square'
                                            : 'far fa-square'
                                    }
                                ></i>
                                <label htmlFor="">Enviar Pregunta una por una</label>
                            </div>
                            <div
                                className="setting"
                                onClick={() => onChangeSetting('random_by_student')}
                            >
                                <i
                                    className={
                                        random_by_student
                                            ? 'fas fa-check-square'
                                            : 'far fa-square'
                                    }
                                ></i>
                                <label htmlFor="">Preguntas Aleatorias por Alumno</label>
                            </div>
                        </div>
                    </div>
                    {random_by_student ? (
                        <div className="container_options">
                            <label>Total de Preguntas</label>
                            <br />
                            <div className="option_quantity">
                                <label className="label_quantity">
                                    Preguntas Faciles:
                                </label>
                                <input
                                    className="input_question"
                                    type="number"
                                    name="easy"
                                    value={easy}
                                    onChange={onChangeNivels}
                                />
                                <label>de {15}</label>
                            </div>
                            <div className="option_quantity">
                                <label className="label_quantity">
                                    Preguntas Normales:
                                </label>
                                <input
                                    className="input_question"
                                    type="number"
                                    name="medium"
                                    value={medium}
                                    onChange={onChangeNivels}
                                />
                                <label>de {15}</label>
                            </div>
                            <div className="option_quantity">
                                <label className="label_quantity">
                                    Preguntas dificiles:
                                </label>
                                <input
                                    className="input_question"
                                    type="number"
                                    name="hard"
                                    value={hard}
                                    onChange={onChangeNivels}
                                />
                                <label>de {15}</label>
                            </div>
                        </div>
                    ) : null} */}
                </div>
                <div className="btnsmoreOps">
                    {/* <button type="submit" disabled={loader}>
                        {loader ? <SpinnerModal /> : 'Guardar'}
                    </button> */}
                    {/* <button type="submit" disabled={true}><SpinnerModal /></button> */}
                    <Button2
                        text="Cancelar"
                        typeButton="outline"
                        onClick={() => setShowSetting(false)}
                    />
                    <Button2 text="Aceptar" onClick={() => setShowSetting(false)} />
                </div>
            </div>
            <ContainerModal showModal={showSelectQuestions}>
                <div className="main_ModalCoursesScheduled">
                    <div className="headModalCoursesScheduled">
                        <h3>Seleccione las Preguntas que Desea Agregar</h3>
                        <i
                            className="fas fa-times-circle"
                            onClick={() => setShowSelectQuestions(false)}
                        />
                    </div>
                    <div className="bodyModalCoursesScheduled">
                        <QuestionsSelect
                            setshowSelectQuestion={setShowSelectQuestions}
                            arrayIds={arrayIds}
                            setarrayIds={setarrayIds}
                            data={questionsArray}
                            arraySelect={questionsArrayBank}
                            setarraySelect={setQuestionsArrayBank}
                        />
                    </div>
                </div>
            </ContainerModal>
        </div>
    )
}

export default CreateEvaluationsPage
