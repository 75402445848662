import React, { createContext, useReducer } from 'react'
// import { AuthContext } from '../auth/AuthProvider';
import { IState } from './UserCounterInterface'
import { userCounterReducer } from './UserCounterReducer'
// import { useHistory } from 'react-router-dom';
// import { getStudentCounter } from './UserCounterAction';
// import { AparienciaContext } from './../aparienciaContext/AparienciaProvider';
// import { useState } from 'react';

const initialState: IState = {
    loading: false,
    userCounter: [],
    coursesUserCounter: [],
}

export const UserCounterContext = createContext<IState | any>(initialState)

export const UserCounterProvider = (props: any) => {
    // const history = useHistory()

    // const { user } = useContext(AuthContext)

    // const { state: stateApariencia } = useContext(AparienciaContext)

    // const [pagination, setpagination] = useState('')

    // const getUserCounter = useCallback((dispatch) => {

    //     const payload = {
    //         token: user.token,
    //         entity_id: stateApariencia.currentIdEntity,
    //         dispatch: dispatch,
    //         page: 1,
    //         query: '',
    //         docs: 15,
    //         descendant: false,
    //         setpagination: setpagination,
    //         pagination: pagination,
    //     }

    //     getStudentCounter(payload)

    // }, [user.token, stateApariencia.currentIdEntity, setpagination, pagination])

    // useEffect(() => {

    //     if (props.isAuthenticated !== false) {

    //         getUserCounter(dispatch)

    //     } else {
    //         // history.goBack()
    //         history.push('./login')
    //         // <Redirect to="/login"/>

    //     }

    // }, [props.isAuthenticated, history, getUserCounter])

    const [state, dispatch] = useReducer<any>(userCounterReducer, initialState)

    return (
        <UserCounterContext.Provider value={{ state, dispatch }}>
            {props.children}
        </UserCounterContext.Provider>
    )
}
