import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { Course, Item, ModalOptions } from '../../../../../interfaces'
import { useAddEditItemMutation, useDeleteItemMutation } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerCertificate from '../../../../general/spinner/SpinnerCertificate'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface FormTemary {
    title: string
}

interface AddEditTemaryProps {
    showModal: ModalOptions
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
    course: Course
    currentModule: Course
    items: Item[]
}

export const AddEditTemary = ({
    setShowModal,
    course,
    currentModule,
    items,
}: AddEditTemaryProps) => {
    const { entity } = useAppSelector(selectAuth)
    const { register, handleSubmit, resetField } = useForm<FormTemary>({
        defaultValues: {
            title: '',
        },
    })

    const [addEditItem, { isLoading }] = useAddEditItemMutation()

    const onSubmit: SubmitHandler<FormTemary> = (data) => {
        addEditItem({
            fname: 'createItem',
            entity,
            course: currentModule._id,
            main_course: course._id,
            title: data.title,
        })
            .then(() => resetField('title'))
            .catch(console.log)
    }

    return (
        <div className="main_AddCategory">
            <div className="heaCategory">
                <h3>Agregar Temario</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal('hide')} />
            </div>
            <div className="bodyCategory">
                <form className="contFormEnt" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contFormEnt">
                        <div className="contInAddEnt">
                            <p>Título del temario</p>
                            <div className="title_label">
                                <input
                                    className="first_part"
                                    type="text"
                                    placeholder="Ingresar titulo del temario"
                                    required
                                    {...register('title')}
                                />
                                {isLoading ? (
                                    <div className="containerSpinner">
                                        <SpinnerModal />
                                    </div>
                                ) : (
                                    <button
                                        type="submit"
                                        className="second_part"
                                        disabled={isLoading}
                                    >
                                        Agregar
                                    </button>
                                )}
                            </div>
                        </div>
                        {items.length > 0 &&
                            items.map((item) => (
                                <TemaryItem
                                    key={item._id}
                                    item={item}
                                    mainCourseId={course._id}
                                    currentModuleId={currentModule._id}
                                />
                            ))}
                    </div>

                    <div className="optsBtnAddItems" style={{ textAlign: 'right' }}>
                        <button
                            onClick={() => setShowModal('hide')}
                            style={{
                                border: '1px solid var(--color-secondary)',
                                color: 'var(--color-secondary)',
                                backgroundColor: 'inherit',
                                height: '35px',
                                width: '100px',
                            }}
                            disabled={isLoading}
                        >
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

interface TemaryItemProps {
    item: Item
    mainCourseId: string
    currentModuleId: string
}

export const TemaryItem = ({ item, mainCourseId, currentModuleId }: TemaryItemProps) => {
    const { entity } = useAppSelector(selectAuth)
    const [addEditItem, { isLoading }] = useAddEditItemMutation()
    const [deleteItem] = useDeleteItemMutation()

    const { register, handleSubmit } = useForm<FormTemary>({
        defaultValues: {
            title: item.title,
        },
    })

    const [disabled, setDisabled] = useState<boolean>(true)

    const handleDelete = () => {
        deleteItem({
            entity_id: entity,
            item: item._id,
        })
            .then(console.log)
            .catch(console.log)
    }

    const onSubmit: SubmitHandler<FormTemary> = (data) => {
        addEditItem({
            fname: 'updateItem',
            entity,
            item: item._id,
            course: currentModuleId,
            main_course: mainCourseId,
            title: data.title,
        })
            .then(() => setDisabled(true))
            .catch(console.log)
    }

    return (
        <div className="container_title">
            <div className="content_content">
                <div className="content_title">
                    <input
                        className="text_title"
                        style={{
                            background: 'white',
                            height: '33px',
                            border: 'none',
                            width: '300px',
                        }}
                        {...register('title')}
                        disabled={disabled}
                    />
                </div>
                <div className="options">
                    {isLoading ? (
                        <SpinnerCertificate />
                    ) : disabled ? (
                        <i
                            className="fas fa-pen"
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                            onClick={() => setDisabled(!disabled)}
                        />
                    ) : (
                        <i
                            className="fas fa-save"
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                            onClick={handleSubmit(onSubmit)}
                        />
                    )}
                    <i
                        className="far fa-trash-alt"
                        onClick={handleDelete}
                        style={{ marginRight: '10px', cursor: 'pointer' }}
                    />
                </div>
            </div>
        </div>
    )
}
