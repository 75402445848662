import { useState, useContext } from 'react'
import { toast } from 'react-toastify'

import ContainerModal from './../../../ContainerModal'
import { axiosStudent } from '../../../../../config/axios'
import RejectVoucher from '../../admin/rejectVouchers/RejectVoucher'
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import SpinnerCertificate from '../../../../general/spinner/SpinnerCertificate'
// import Notification, { type } from '../../../../general/toast/Toast';

const DetailVoucher = ({
    setShowModal,
    order,
    totalPrice,
    imageVoucher,
    setVouchers,
    setpagination,
    history,
}: any) => {
    const [rejectModal, setRejectModal] = useState(false)
    const token = JSON.parse(localStorage.getItem('user')!).token
    const { state: stateApariencia } = useContext(AparienciaContext)
    const [loading, setloading] = useState(false)

    console.log(order)

    const downloadCertificate = (url: any) => {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('target', '_blank') //or any other extension
        document.body.appendChild(link)
        link.click()
    }

    const acceptOrder = () => {
        setloading(true)

        axiosStudent({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'activateOrders',
                order_id: order._id,
            },
        })
            .then((data) => {
                // console.log(data)
                if (data.status === 200) {
                    axiosStudent({
                        url: 'v5.0.1/api',
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                        data: {
                            fname: 'getOrders',
                            entity_id: stateApariencia.currentIdEntity,
                            page: 1,
                            docs: 15,
                            filter_by_email: '',
                            sort: '-created_at',
                        },
                    })
                        .then(({ data }) => {
                            setloading(false)

                            let array: any[] = []
                            data.orders.map((dat: any) =>
                                dat
                                    ? array.push({
                                          _id: dat._id,
                                          name: dat.user.name,
                                          lastname: dat.user.lastname,
                                          photo:
                                              dat.user.photo &&
                                              dat.user.photo.includes('https://')
                                                  ? dat.user.photo
                                                  : 'https://livewellwithstacy.com/wp-content/uploads/2018/04/avtar.png',
                                          email: dat.user.email,
                                          state: dat.pending,
                                          total_price: dat.total_price,
                                          created_at: dat.created_at,
                                      })
                                    : null,
                            )
                            setVouchers(array)

                            setpagination(data.pagination)

                            setShowModal(false)

                            // Notification(type.success, "La orden ha sido aceptada")
                            toast.success('La orden ha sido aceptada')
                        })
                        .catch((e) => {
                            console.log(e.response)

                            // Notification(type.danger, "Ocurrio un error y la orden no ha sido aceptada")
                            toast.error('Ocurrio un error y la orden no ha sido aceptada')

                            setloading(false)
                        })
                }
            })
            .catch((e) => {
                console.log(e.response)

                setloading(false)
            })
    }

    return (
        <div className="main_searchStudent">
            <div className="headSearchStudent">
                <i className="fas fa-times" onClick={() => setShowModal(false)} />
            </div>
            <div className="bodySearchStudent scroll">
                <>
                    <div className="contentHeader_voucher">
                        <div className="content_info_head">
                            <h2>
                                {' '}
                                Detalle,{' '}
                                <span>
                                    {' '}
                                    {order.user.name} {order.user.lastname}{' '}
                                </span>{' '}
                            </h2>

                            <div
                                className={
                                    loading
                                        ? `container_options_ar load`
                                        : 'container_options_ar'
                                }
                            >
                                {loading ? (
                                    <SpinnerCertificate />
                                ) : history ? (
                                    order.state === 'success' ? (
                                        <div className="content_state_order">
                                            <div className="accept">
                                                <i className="fas fa-check"></i> Aceptado
                                            </div>
                                        </div>
                                    ) : order.state === 'rejected' ? (
                                        <div className="content_state_order">
                                            <div className="reject">
                                                <i className="fas fa-times"></i> Rechazado
                                            </div>
                                        </div>
                                    ) : order.state === 'canceled' ? (
                                        <div className="content_state_order">
                                            <div className="canceled">
                                                <i className="fas fa-times"></i> Cancelado
                                            </div>
                                        </div>
                                    ) : null
                                ) : (
                                    <>
                                        <div
                                            className="accept"
                                            onClick={() => acceptOrder()}
                                        >
                                            <i className="fas fa-check"></i> Aceptar
                                        </div>
                                        <div
                                            className="reject"
                                            onClick={() => setRejectModal(true)}
                                        >
                                            <i className="fas fa-times"></i> Rechazar
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="containerBody">
                        <div className="content_detailVoucher_body">
                            <div className="container_image_voucher">
                                {order.user.photo &&
                                order.user.photo.includes('https://') ? (
                                    <img
                                        className="imgPhoto"
                                        src={order.user.photo}
                                        alt="ImagItem"
                                    />
                                ) : (
                                    <div className="imgPhoto">
                                        <i className="profileDef fas fa-user-circle"></i>
                                    </div>
                                )}
                            </div>
                            <div className="info_data_voucher">
                                <p>
                                    {' '}
                                    {order.user.name} {order.user.lastname}{' '}
                                </p>
                                <p> {order.user.email} </p>
                                <p> {order.user.phone} </p>
                                <p> DNI {order.user.dni} </p>
                                <p> {order.user.address} </p>
                            </div>
                        </div>

                        <div className="content_coursesModules_body">
                            <h3>Programas adquiridos </h3>

                            {order.products.length > 0
                                ? order.products.map((res: any, key: number) => (
                                      <div className="courses_info_container" key={key}>
                                          <div className="container_data_course">
                                              <div className="image_container">
                                                  <img
                                                      src={
                                                          res.preview !== ''
                                                              ? res.preview
                                                              : 'http://atrilco.com/wp-content/uploads/2017/11/ef3-placeholder-image.jpg'
                                                      }
                                                      alt="image_course"
                                                  />
                                              </div>
                                              <div className="paragraph_container">
                                                  <p> {res.category} </p>
                                                  <p> {res.course_name} </p>
                                              </div>
                                          </div>
                                          <div className="container_price_course">
                                              <p>
                                                  {' '}
                                                  Precio del curso:{' '}
                                                  <span> S/ {res.course_price} </span>
                                              </p>
                                              <p>
                                                  {' '}
                                                  Precio de matrícula:{' '}
                                                  <span>
                                                      {' '}
                                                      S/ {res.inscripcion_price}{' '}
                                                  </span>
                                              </p>
                                              <p>
                                                  {' '}
                                                  Total precio:{' '}
                                                  <span> S/ {res.total_price} </span>{' '}
                                              </p>
                                          </div>
                                      </div>
                                  ))
                                : null}

                            <div className="modules_info_container">
                                {/* <ul className="list_modules">
                                    <li className="item_module">
                                        <span>Módulo 1. Física del ultrasonido, knobología y Ecografía del 1er trimestre y cromosomopatías.</span>
                                        <span> (S/. 320 00)  </span>
                                    </li>
                                    <li className="item_module">
                                        <span>Módulo 1. Física del ultrasonido, knobología y Ecografía del 1er trimestre y cromosomopatías.</span>
                                        <span> (S/. 320 00)  </span>
                                    </li>
                                    <li className="item_module">
                                        <span>Módulo 1. Física del ultrasonido, knobología y Ecografía del 1er trimestre y cromosomopatías.</span>
                                        <span> (S/. 320 00)  </span>
                                    </li>
                                </ul> */}
                                <div className="totalPrice">
                                    <span>Total </span>
                                    <span>S/ {totalPrice}</span>
                                </div>
                            </div>

                            <div className="voucher_preview_container">
                                <h5>Voucher de deposito </h5>

                                <div className="voucher_container_images">
                                    {imageVoucher.length > 0
                                        ? imageVoucher.map((res: any) => (
                                              <div className="container_preview_voucher">
                                                  <img
                                                      src={res.image_url}
                                                      alt="image_voucher"
                                                  />
                                                  <span
                                                      onClick={() =>
                                                          downloadCertificate(
                                                              res.image_url,
                                                          )
                                                      }
                                                  >
                                                      Comprobante{' '}
                                                      <i className="fas fa-download"></i>
                                                  </span>
                                              </div>
                                          ))
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContainerModal showModal={rejectModal}>
                        <RejectVoucher
                            setRejectModal={setRejectModal}
                            order={order}
                            setVouchers={setVouchers}
                            setShowModal={setShowModal}
                            setpagination={setpagination}
                        />
                        {/* <DeleteUser data={detailsCourse}  setShowModalDelete={setShowModalDelete} /> */}
                    </ContainerModal>
                </>
            </div>
        </div>
    )
}

export default DetailVoucher
