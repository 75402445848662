import axios from 'axios'

import { axiosMaterial } from '../../../config/axios'
import { typesRepository } from './../../types/typesRepository'

export const getFiles = async (params: any) => {
    const {
        token,
        entity_id,
        dispatch,
        setLoader,
        setError,
        page,
        docs,
        setpagination,
        filter_by_title,
        descendant,
    } = params

    return axiosMaterial({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'getMaterial_byEntity',
            entity_id: entity_id,
            page: page,
            docs: docs,
            filter_by_title: filter_by_title,
            date: descendant,
        },
    })
        .then(({ data }) => {
            // console.log(data)

            setpagination(data.pagination)

            let array: any[] = []
            data.materials.map((dat: any) =>
                dat
                    ? array.push({
                          _id: dat._id,
                          name_file: dat.name_file,
                          title: dat.title,
                          entity_name: dat.entity.entity_name,
                          category_name: dat.category?.name,
                          category: dat.category,
                          description: dat.description,
                          date: dat.date,
                          state: dat.state,
                          price: dat.price ? dat.price : 'Gratis',
                          url: dat.url,
                          created_by: dat.created_by.name + ' ' + dat.created_by.lastname,
                      })
                    : null,
            )

            // console.log(array)

            dispatch({
                type: typesRepository.getRepository,
                payload: array,
            })

            if (setLoader) {
                setLoader(true)
            }
        })
        .catch((e) => {
            console.log(e)
            console.log(e.response)
            if (setLoader && setError) {
                setLoader(true)
                setError(500)
            }
        })
}

export const getFilesByModule = async (obj: any, token: string) => {
    return axiosMaterial({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const createFiles = async (obj: any, token: string, dispatch: any) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    dispatch({
        type: typesRepository.setCancelToken,
        payload: source,
    })
    return axiosMaterial({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
        cancelToken: source.token,
        onUploadProgress: (data) => {
            console.log('onUploadProgress')
            let percentage: number = Math.round((100 * data.loaded) / data.total)
            console.log(percentage)
            dispatch({
                type: typesRepository.uploadProgressMaterial,
                payload: percentage,
            })
        },
    })
}

export const editFiles = async (obj: any, token: string, dispatch: any) => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    dispatch({
        type: typesRepository.setCancelToken,
        payload: source,
    })
    return axiosMaterial({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
        cancelToken: source.token,
        onUploadProgress: (data) => {
            console.log('onUploadProgress')
            let percentage: number = Math.round((100 * data.loaded) / data.total)
            console.log(percentage)
            dispatch({
                type: typesRepository.uploadProgressMaterial,
                payload: percentage,
            })
        },
    })
}

export const deleteFiles = async (id: string, entity_id: string, token: string) => {
    return axiosMaterial({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'deleteMaterial',
            material_id: id,
            entity_id: entity_id,
        },
    })
}
