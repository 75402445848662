export const typesScheduledCourses = {
    setSelectScheduled: '[sheduled courses] setSelectScheduled',
    getScheduledCourses: '[sheduled courses] getScheduledCourses',
    addMoreScheduledCourses: '[sheduled courses] addMoreScheduledCourses',
    currentScheduledCourses: '[sheduled courses] currentScheduledCourses',
    createScheduledCourses: '[sheduled courses] createScheduledCourses',
    editScheduledCourses: '[sheduled courses] editScheduledCourses',
    deletegetScheduledCourses: '[sheduled courses] deletegetScheduledCourses',
    countStudentScheduledCourses: '[sheduled courses] countStudentScheduledCourses',
}
