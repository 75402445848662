import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import { useContext } from 'react'

import { PublicRoute } from './PublicRoute'
import { PrivateRoute } from './PrivateRoute'
import LoginPage from './../pages/auth/LoginPage'
import { AuthContext } from './../context/auth/AuthProvider'
import DashboardRoutes from './DashboardRoutes'
// import Userb2bRoutes from './privateRoutes/Userb2bRoutes';
// import CoordinatorRoutes from './privateRoutes/CoordinatorRoutes';
// import AccountantRoutes from './privateRoutes/AccountantRoutes';
// import { CoursesUserb2bProvider } from './../context/coursesUserb2b/CoursesUserb2bProvider';
// import { UserCounterProvider } from "../context/userCounter/UserCounterProvider";
// import { SheduledCoursesProvider } from "../context/sheduledCourses/ScheduledCoursesProvider";
import AdminRoutes from './privateRoutes/AdminRoutes'
import RecoverPasswordPage from '../pages/auth/RecoverPassword'
import ResetPasswordPage from './../pages/auth/ResetPassword'
import { useAppSelector } from '../hooks/useReduxHooks'
import { selectAuth } from '../store/slices'

function App() {
    const { user } = useContext(AuthContext)

    const { isAuthenticated } = useAppSelector(selectAuth)

    return (
        <Router>
            <Switch>
                <PublicRoute
                    // isAuthenticated={user.logged}
                    isAuthenticated={isAuthenticated}
                    exact
                    rol={user.rol}
                    path="/login"
                    component={LoginPage}
                />

                <PublicRoute
                    // isAuthenticated={user.logged}
                    isAuthenticated={isAuthenticated}
                    exact
                    rol={user.rol}
                    path="/auth/recover-password"
                    component={RecoverPasswordPage}
                />

                <PublicRoute
                    // isAuthenticated={user.logged}
                    isAuthenticated={isAuthenticated}
                    exact
                    path="/auth/recover-password/:token"
                    component={ResetPasswordPage}
                />

                <PrivateRoute
                    // isAuthenticated={user.logged}
                    isAuthenticated={isAuthenticated}
                    path="/superadmin"
                    rol={user.rol === 'superadmin' ? true : false}
                    component={DashboardRoutes}
                />

                <PrivateRoute
                    // isAuthenticated={user.logged}
                    isAuthenticated={isAuthenticated}
                    path="/dashboard"
                    rol={
                        user.rol === 'admin_entity' || user.rol === 'user' ? true : false
                    }
                    component={AdminRoutes}
                />

                <Redirect exact path={`/*`} to={`/login`} />
            </Switch>
        </Router>
    )
}

export default App
