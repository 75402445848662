import { useContext } from 'react'
import { toast } from 'react-toastify'

import { deleteStudentCounter } from '../../../../../context/userCounter/UserCounterAction'
import { typesCounter } from './../../../../../context/types/typesCounter'
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import { UserCounterContext } from '../../../../../context/userCounter/UserCounterProvider'

import './userCounter.sass'
// import Notification, { type } from '../../../../general/toast/Toast';

export const DeleteUser = ({ setShowModalDelete, data }: any): JSX.Element => {
    const { dispatch } = useContext(UserCounterContext)
    const token = JSON.parse(localStorage.getItem('user')!).token
    const { state: stateApariencia } = useContext(AparienciaContext)
    const { id } = data

    const deleteSubmit = () => {
        deleteStudentCounter(token, id, stateApariencia.currentIdEntity)
            .then((res) => {
                // console.log(res);
                dispatch({
                    type: typesCounter.deleteUserCounter,
                    payload: id,
                })
                setShowModalDelete(false)
                // Notification(type.success, `El usuario se ha eliminado correctamente.`)
                toast.success('El usuario se ha eliminado correctamente.')
            })
            .catch((e) => {
                console.log(e.response)
                // Notification(type.danger, `Ha ocurrido un error al intentar eliminar usuario.`)
                toast.error('Ha ocurrido un error al intentar eliminar usuario.')
            })
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalDelete(false)}
                />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar el alumno?</p>
                <div>
                    <button onClick={() => setShowModalDelete(false)}>Cancelar</button>
                    <button className="saveButton" onClick={deleteSubmit}>
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    )
}
