export const todayString: string = new Date()
    .toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        // hour: '2-digit',
        // minute: '2-digit',
    })
    .split('/')
    .reverse()
    .join('-')
    .toString()
