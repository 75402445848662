import axios from 'axios'
import {
    AUTH_URL,
    USER_URL,
    COURSE_URL,
    MATERIAL_URL,
    PROGRAMS_URL,
    ENTITIES_URL,
    USER_URL_V5,
    STUDENT_URL,
    EVALUATIONS_URL,
} from './utils'

export const axiosAuth = axios.create({
    method: 'POST',
    baseURL: `${AUTH_URL}/`,
})

export const axiosUser = axios.create({
    method: 'POST',
    baseURL: `${USER_URL}/`,
})

export const axiosUserV5 = axios.create({
    method: 'POST',
    baseURL: `${USER_URL_V5}/`,
})

export const axiosCourses = axios.create({
    method: 'POST',
    baseURL: `${COURSE_URL}/`,
})

export const axiosMaterial = axios.create({
    method: 'POST',
    baseURL: `${MATERIAL_URL}/`,
})

export const axiosPrograms = axios.create({
    method: 'POST',
    baseURL: `${PROGRAMS_URL}/`,
})

export const axiosEntities = axios.create({
    method: 'POST',
    baseURL: `${ENTITIES_URL}/`,
})

export const axiosStudent = axios.create({
    method: 'POST',
    baseURL: `${STUDENT_URL}/`,
})

export const axiosEvaluations = axios.create({
    method: 'POST',
    baseURL: `${EVALUATIONS_URL}/`,
})
