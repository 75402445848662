import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

type Props = {
    showModal: boolean
}
const ContainerModal: React.FC<Props> = ({ showModal, children }) => {
    useEffect(() => {
        let bodyStyle = document.body
        showModal
            ? (bodyStyle.style.overflowY = 'hidden')
            : bodyStyle.removeAttribute('style')
    }, [showModal])

    if (!showModal) return null

    return createPortal(
        <div id="root-portal">
            <div className="contentModal">{children}</div>
        </div>,
        document.body,
    )
}

export default ContainerModal
