import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

import { axiosEntities, axiosUserV5 } from '../../../config/axios'
import { updateEntitySuperAdmin } from '../../../context/entityContext/EntityAction'
import { EntityContext } from '../../../context/entityContext/EntityProvider'
import { typesEntity } from '../../../context/types/typesEntity'
import { createUsers } from '../../../context/superAdminUser/UserActions'
import SpinnerModal from '../../general/spinner/SpinnerModal'
// import Notification, { type } from '../../general/toast/Toast'

export interface IResponseGetRoles {
    message: string
    roles: Role[]
}

export interface Role {
    rol: string
    _id: string
}

export const EditEntityModal = ({ setShowEditEntity, detailEntity }: any) => {
    const token = JSON.parse(localStorage.getItem('user')!).token

    const { admin, description, entity_name, entity_type, _id, customize, domain } =
        detailEntity

    const [showOpt, setShowOpt] = useState<number>(
        detailEntity.opt ? detailEntity.opt : 1,
    )
    const [userAdmin, setUserAdmin] = useState([])
    const [preview, setPreview] = useState('')
    const [previewPortrait, setPreviewPortrait] = useState('')

    const [imgLogo, setImgLogo] = useState<any>('')
    const [imgPort, setImgPort] = useState<any>('')

    const [loading, setLoading] = useState<boolean>(false)
    const [loadAdmin, setloadAdmin] = useState(false)
    const [loadEntity, setloadEntity] = useState(false)

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [roles, setRoles] = useState<Role[]>([])

    const { dispatch } = useContext(EntityContext)

    const admin_old = admin

    const [dataEntity, setDataEntity] = useState({
        entity_name: entity_name,
        entity_type: entity_type,
        description: description,
        bg_sidebar: '#CC337E',
        bg_buttons: '#CC337E',
        font_color: '#000000',
        admin: admin,
        domain: domain,
    })

    const [dataAdmin, setDataAdmin] = useState<any>({
        id: '',
        username: '',
        password: '',
        name: '',
        lastname: '',
        dni: '',
        email: '',
        phone: '',
        birthdate: '',
        domain: '',
        rol: 'admin_entity',
        roles: [],
        address: '',
    })

    const [dataValidate, setdataValidate] = useState<any>({
        entity_name: dataEntity.entity_name,
        description: dataEntity.description,
        domain: dataEntity.domain,
        name: dataAdmin.name,
        lastname: dataAdmin.lastname,
        email: dataAdmin.email,
        address: dataAdmin.address,
        dni: dataAdmin.dni,
        phone: dataAdmin.phone,
    })

    const getRoles = useCallback(async () => {
        console.log('get roles')
        await axiosEntities({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getRolesByEntity',
                entity_id: _id,
                onlyAdmin: true,
            },
        }).then(({ data }: AxiosResponse<IResponseGetRoles>) => {
            console.log(data)
            setRoles(data.roles)
        })
    }, [token, _id])

    const changeDataAdmin = (e: any, number?: boolean): void => {
        const { name, value } = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setDataAdmin({
                    ...dataAdmin,
                    [name]: value,
                })
            }
        } else {
            setDataAdmin({
                ...dataAdmin,
                [name]: value,
            })
        }
    }

    const changeDataEntity = (e: any, number?: boolean): void => {
        const { name, value } = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setDataEntity({
                    ...dataEntity,
                    [name]: value,
                })
            }
        } else {
            setDataEntity({
                ...dataEntity,
                [name]: value,
            })
        }
    }

    // const getEntityType = (e : any) => {
    //     const { value } = e.target;
    //     let valor = value;
    //     if (value === "") {
    //     valor = "";
    //     }
    //     setDataEntity({
    //         ...dataEntity,
    //         entity_type: valor,
    //     });
    // };

    const getUserAdmin = (e: any) => {
        const { value } = e.target
        let valor = value
        if (value === '') {
            valor = ''
        }

        setDataEntity({
            ...dataEntity,
            admin: valor,
        })

        axiosUserV5({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getUser',
                user_id: valor,
                service: 'users',
            },
        })
            .then(({ data }) => {
                // console.log(data);

                setdataValidate({
                    entity_name: dataEntity.entity_name,
                    name: data.user.name,
                    lastname: data.user.lastname,
                    email: data.user.email,
                    address: data.user.address,
                    dni: data.user.dni,
                    phone: data.user.phone,
                })
            })
            .catch((e) => {
                console.log(e.response)
            })
    }

    const changeImageLogo = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e
                setImgLogo(imgPhoto)
                setPreview(URL.createObjectURL(e))
                setLoading(!loading)
            }
        }
    }

    const changeImagePortrait = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgPhoto: object = e
                setImgPort(imgPhoto)
                setPreviewPortrait(URL.createObjectURL(e))
                setLoading(!loading)
            }
        }
    }

    const sendEntityData = (e: any) => {
        // console.log(_id)
        e.preventDefault()

        // console.log(dataEntity.admin)

        setloadEntity(true)

        // console.log('Esta es data entidad', dataEntity)
        // console.log('Este es el logo de la entidad', imgLogo);
        // console.log('Este es la imagen de portada', imgPort);

        const datan: FormData = new FormData()
        datan.append('fname', 'updateEntity')
        datan.append('service', 'users')
        datan.append('entity_name', dataEntity.entity_name)

        if (imgLogo !== '') {
            datan.append('logo', imgLogo)
        }

        if (imgPort !== '') {
            datan.append('portrait', imgPort)
        }

        datan.append('entity_type', dataEntity.entity_type)
        datan.append('domain', dataEntity.domain)
        datan.append('description', dataEntity.description)
        datan.append('bg_sidebar', dataEntity.bg_sidebar)
        datan.append('bg_buttons', dataEntity.bg_buttons)
        datan.append('font_color', dataEntity.font_color)
        datan.append('admin_id', dataEntity.admin)
        datan.append('admin_old', admin_old)
        datan.append('entity_id', _id)

        console.log(Object.fromEntries(datan))

        updateEntitySuperAdmin(datan, token)
            .then(({ data }) => {
                dispatch({
                    type: typesEntity.updateEntity,
                    payload: data.entity,
                })

                // console.log(data);

                setShowEditEntity(false)

                // Notification(type.success, "La entidad ha sido actualizada")
                toast.success('La entidad ha sido actualizada')

                setloadEntity(false)
            })
            .catch((e) => {
                console.log(e.response)

                // Notification(type.danger, "Ha ocurrido un error")
                toast.error('Ha ocurrido un error')

                setloadEntity(false)
            })
    }

    const getUsersAdmin = useCallback(() => {
        axiosUserV5({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getAdminEntity',
                rol: 'admin_entity',
                // service: "users",
            },
        })
            .then(({ data }) => {
                // console.log(data)

                // const admins = data.users
                setUserAdmin(data.users)
            })
            .catch((e) => {
                console.log(e.response)
            })
    }, [token])

    useEffect(() => {
        getUsersAdmin()
    }, [getUsersAdmin])

    useEffect(() => {
        roles.length === 0 && getRoles()
    }, [roles.length, getRoles])

    const sendDataAdmin = (e: any) => {
        e.preventDefault()
        // console.log('Formulario Admin')
        // console.log(dataAdmin)

        const data = {
            fname: 'createUser',
            name: dataAdmin.name,
            lastname: dataAdmin.lastname,
            dni: dataAdmin.dni,
            email: dataAdmin.email,
            phone: dataAdmin.phone,
            birthdate: dataAdmin.birthdate,
            address: dataAdmin.address,
            rol: dataAdmin.rol,
            // roles: JSON.stringify(dataAdmin.roles),
            roles: JSON.stringify([roles.find((r) => r.rol === 'admin')?._id || '']),
            username: dataAdmin.username,
            password: dataAdmin.password,
            entity: _id,
        }

        // console.log('data crud entity', data)
        setloadAdmin(true)

        createUsers(data, token)
            .then(({ data }) => {
                setloadAdmin(false)

                // console.log(data);

                setDataEntity({
                    ...dataEntity,
                    admin: data.user._id,
                })

                setdataValidate({
                    entity_name: dataEntity.entity_name,
                    name: data.user.name,
                    lastname: data.user.lastname,
                    email: data.user.email,
                    address: data.user.address,
                    dni: data.user.dni,
                    phone: data.user.phone,
                })

                getUsersAdmin()

                // Notification(type.success, 'El usuario ha sido creado correctamente')
                toast.success('El usuario ha sido creado correctamente')

                setDataAdmin({
                    id: '',
                    username: '',
                    password: '',
                    name: '',
                    lastname: '',
                    dni: '',
                    email: '',
                    phone: '',
                    birthdate: '',
                    domain: '',
                    address: '',
                    rol: 'admin_entity',
                    roles: [],
                })

                setShowOpt(3)
            })
            .catch((e) => {
                console.log(e.response)

                if (e.response.status === 400 || e.response.status === 500) {
                    // Notification(type.danger, e.response.data.message)
                    toast.error(e.response.data.message)

                    setDataAdmin({
                        id: '',
                        username: '',
                        password: '',
                        name: '',
                        lastname: '',
                        dni: '',
                        email: '',
                        phone: '',
                        birthdate: '',
                        domain: '',
                        address: '',
                        rol: 'admin_entity',
                        roles: [],
                    })

                    setloadAdmin(false)
                }
            })
    }

    useEffect(() => {
        axiosUserV5({
            url: 'v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'getUser',
                user_id: admin,
                service: 'users',
            },
        })
            .then(({ data }) => {
                // console.log(data);

                setdataValidate({
                    entity_name: dataEntity.entity_name,
                    name: data.user.name,
                    lastname: data.user.lastname,
                    email: data.user.email,
                    address: data.user.address,
                    dni: data.user.dni,
                    phone: data.user.phone,
                })
            })
            .catch((e) => {
                console.log(e.response)
            })
    }, [admin, token, dataEntity.entity_name])

    return (
        <div className="main_AddEntity">
            <div className="heaEntity">
                <h3>Editar entidad</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowEditEntity(false)}
                />
            </div>
            <div className="bodyEntity">
                <div className="barNav">
                    <div className="optNavEdit">
                        <span className={`${showOpt === 1 ? 'select' : ''}`}>Paso 1</span>
                        <span
                            className={`${
                                showOpt === 2 || showOpt === 4 ? 'select' : ''
                            }`}
                        >
                            Paso 2
                        </span>
                        <span className={`${showOpt === 3 ? 'select' : ''}`}>Paso 3</span>
                    </div>
                </div>
                <form
                    className="contFormEnt"
                    onSubmit={(e) =>
                        showOpt === 3
                            ? sendEntityData(e)
                            : showOpt === 4 && sendDataAdmin(e)
                    }
                >
                    <div className="contFormEnt scrollBody">
                        {showOpt === 1 ? (
                            <>
                                <div className="contInAddEnt">
                                    <p className="title">
                                        <b>Configuración de la entidad</b>
                                    </p>
                                </div>
                                <div className="contInAddEnt image">
                                    <label htmlFor="imgPort" className="imgPort">
                                        {previewPortrait ? (
                                            <img
                                                src={previewPortrait}
                                                alt="portada"
                                                className="imgPort"
                                            ></img>
                                        ) : customize.portrait !== '' ? (
                                            <img
                                                src={customize.portrait}
                                                alt="portada"
                                                className="imgPort"
                                            ></img>
                                        ) : (
                                            <div className="imgPort"></div>
                                        )}
                                    </label>

                                    <input
                                        id="imgPort"
                                        name="imgPort"
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept=".png, .jpg, .jpeg"
                                        onChange={(e: any) =>
                                            changeImagePortrait(e.target.files[0])
                                        }
                                    />

                                    <label
                                        className={
                                            customize.logo !== ''
                                                ? `imgPerf active`
                                                : ` imgPerf`
                                        }
                                        htmlFor="imgLogo"
                                    >
                                        <div>
                                            <i className="fas fa-camera" />
                                            <div>Perfil</div>
                                        </div>
                                        <div>
                                            {preview ? (
                                                <img
                                                    src={preview}
                                                    alt="perfilPhoto"
                                                    className="imgPhotoEntity"
                                                />
                                            ) : (
                                                <img
                                                    src={customize.logo}
                                                    alt="perfilPhoto"
                                                    className="imgPhotoEntity"
                                                />
                                            )}
                                        </div>
                                    </label>

                                    <input
                                        id="imgLogo"
                                        name="imgLogo"
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept=".png, .jpg, .jpeg"
                                        onChange={(e: any) =>
                                            changeImageLogo(e.target.files[0])
                                        }
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Nombre de la entidad</p>
                                    <input
                                        type="text"
                                        onChange={(e) => changeDataEntity(e)}
                                        name="entity_name"
                                        value={dataEntity.entity_name}
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Descripción</p>
                                    <textarea
                                        onChange={(e) => changeDataEntity(e)}
                                        name="description"
                                        value={dataEntity.description}
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Dominio entidad</p>
                                    <input
                                        type="text"
                                        onChange={(e) => changeDataEntity(e)}
                                        name="domain"
                                        value={dataEntity.domain}
                                    />
                                </div>
                            </>
                        ) : showOpt === 2 ? (
                            <>
                                <div className="contInAddEnt">
                                    <p className="title">
                                        <b>Configuración de administrador principal</b>
                                    </p>
                                </div>
                                <div className="contInAddEnt">
                                    <p>Establecer con administrador existente</p>
                                    <select
                                        className="half"
                                        onChange={(e) => getUserAdmin(e)}
                                    >
                                        <option value={dataValidate.id}>
                                            {dataValidate.name}
                                        </option>
                                        {userAdmin.length > 0 &&
                                            userAdmin.map((user: any, key: number) => (
                                                <option value={user._id} key={key}>
                                                    {' '}
                                                    {user.name}{' '}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="sepContAddEnt">
                                    <div className="contInAddEnt">
                                        <p>Crear uno nuevo</p>
                                        <div
                                            onClick={() => setShowOpt(4)}
                                            className="button_ent"
                                        >
                                            Agregar admin
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : showOpt === 3 ? (
                            <>
                                <div className="contInAddEnt">
                                    <p>Nombre de la entidad </p>
                                    <input
                                        type="text"
                                        value={dataValidate.entity_name}
                                        disabled
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Administrador</p>
                                    <input
                                        type="text"
                                        value={dataValidate.name}
                                        disabled
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Correo electrónico</p>
                                    <input
                                        type="text"
                                        value={dataValidate.email}
                                        disabled
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Dirección</p>
                                    <input
                                        type="text"
                                        value={dataValidate.address}
                                        disabled
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Nro de DNI/Pasaporte/Carnet de extranjería</p>
                                    <input
                                        type="text"
                                        maxLength={8}
                                        value={dataValidate.dni}
                                        disabled
                                    />
                                </div>
                                <div className="contInAddEnt">
                                    <p>Celular</p>
                                    <input
                                        type="text"
                                        maxLength={9}
                                        value={dataValidate.phone}
                                        disabled
                                    />
                                </div>
                            </>
                        ) : (
                            showOpt === 4 && (
                                <>
                                    <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <p>Nombres</p>
                                            <input
                                                type="text"
                                                name="name"
                                                value={dataAdmin.name}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                        <div className="contInAddEnt">
                                            <p>Apellidos</p>
                                            <input
                                                type="text"
                                                name="lastname"
                                                value={dataAdmin.lastname}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="contInAddEnt">
                                        <p>Correo electrónico</p>
                                        <input
                                            type="email"
                                            name="email"
                                            value={dataAdmin.email}
                                            onChange={(e) => changeDataAdmin(e)}
                                        />
                                    </div>
                                    <div className="contInAddEnt">
                                        <p>Dirección</p>
                                        <input
                                            type="text"
                                            name="address"
                                            value={dataAdmin.address}
                                            onChange={(e) => changeDataAdmin(e)}
                                        />
                                    </div>
                                    <div className="contInAddEnt">
                                        <p>Nro de DNI/Pasaporte/Carnet de extranjería</p>
                                        <input
                                            type="text"
                                            name="dni"
                                            maxLength={8}
                                            value={dataAdmin.dni}
                                            onChange={(e) => changeDataAdmin(e, true)}
                                        />
                                    </div>
                                    <div className="contInAddEnt">
                                        <p>Celular</p>
                                        <input
                                            type="text"
                                            name="phone"
                                            maxLength={9}
                                            value={dataAdmin.phone}
                                            onChange={(e) => changeDataAdmin(e, true)}
                                        />
                                    </div>

                                    <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <p>Usuario</p>
                                            <input
                                                type="text"
                                                name="username"
                                                value={dataAdmin.username}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                        <div className="contInAddEnt">
                                            <p>Contraseña</p>
                                            <input
                                                type={`${
                                                    showPassword ? 'text' : 'password'
                                                }`}
                                                name="password"
                                                value={dataAdmin.password}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                            {!showPassword ? (
                                                <i
                                                    className="icon_password far fa-eye-slash"
                                                    onClick={() => setShowPassword(true)}
                                                ></i>
                                            ) : (
                                                <i
                                                    className="icon_password fas fa-eye"
                                                    onClick={() => setShowPassword(false)}
                                                ></i>
                                            )}
                                        </div>
                                    </div>

                                    <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <p>Fecha de nacimiento</p>
                                            <input
                                                type="date"
                                                name="birthdate"
                                                value={dataAdmin.birthdate}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="sepContAddEnt">
                                        <div className="contInAddEnt">
                                            <input
                                                type="hidden"
                                                name="rol"
                                                value={dataAdmin.rol}
                                                onChange={(e) => changeDataAdmin(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </div>
                    <div className="optsBtnAdd">
                        {showOpt === 1 ? (
                            <>
                                <div
                                    className="otherButtons"
                                    onClick={() => setShowEditEntity(false)}
                                >
                                    Cancelar
                                </div>
                                <div
                                    className="otherButtons"
                                    onClick={() => setShowOpt(2)}
                                >
                                    {' '}
                                    Siguiente{' '}
                                </div>
                            </>
                        ) : showOpt === 2 ? (
                            <>
                                <div
                                    className="otherButtons"
                                    onClick={() => setShowOpt(1)}
                                >
                                    Atras
                                </div>
                                <div
                                    className="otherButtons"
                                    onClick={() => setShowOpt(3)}
                                >
                                    {' '}
                                    Siguiente{' '}
                                </div>
                            </>
                        ) : showOpt === 3 ? (
                            <>
                                {' '}
                                <div
                                    className="otherButtons"
                                    onClick={() => setShowOpt(2)}
                                >
                                    Volver
                                </div>
                                {loadEntity ? (
                                    <div className="containerSpinner">
                                        {' '}
                                        <SpinnerModal />{' '}
                                    </div>
                                ) : (
                                    <button type="submit"> Finalizar </button>
                                )}
                            </>
                        ) : (
                            showOpt === 4 && (
                                <>
                                    <div
                                        className="otherButtons"
                                        onClick={() => setShowOpt(2)}
                                    >
                                        {' '}
                                        Volver
                                    </div>
                                    {loadAdmin ? (
                                        <div className="containerSpinner">
                                            {' '}
                                            <SpinnerModal />{' '}
                                        </div>
                                    ) : (
                                        <button type="submit"> Agregar </button>
                                    )}
                                </>
                            )
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
