import { useCallback } from 'react'

import { useDeleteScheduledMutation } from '../../../store/api'
import SpinnerModal from '../../general/spinner/SpinnerModal'

type DeleteScheduledProps = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    scheduledId: string
}

export const DeleteScheduled = ({ setShowModal, scheduledId }: DeleteScheduledProps) => {
    const [deleteScheduled, { isLoading }] = useDeleteScheduledMutation()

    const handleDeleteScheduled = () => {
        deleteScheduled({ scheduled_id: scheduledId })
            .then(() => setShowModal(false))
            .catch(console.log)
    }

    const closeModal = useCallback(() => {
        setShowModal(false)
    }, [setShowModal])

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i
                    className="fas fa-times-circle"
                    style={isLoading ? { pointerEvents: 'none' } : {}}
                    onClick={closeModal}
                />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar el Curso Programado?</p>
                <div>
                    <button disabled={isLoading} onClick={closeModal}>
                        Cancelar
                    </button>
                    {isLoading ? (
                        <div
                            className="containerSpinner"
                            style={{
                                backgroundColor: 'var(--color-secondary)',
                                color: '#fff',
                                borderRadius: '10px',
                                width: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <SpinnerModal />
                        </div>
                    ) : (
                        <button
                            disabled={isLoading}
                            className="saveButton"
                            onClick={handleDeleteScheduled}
                        >
                            Aceptar
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
