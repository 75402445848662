import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { AuthContext } from './../../context/auth/AuthProvider'
import { AparienciaContext } from '../../context/aparienciaContext/AparienciaProvider'
import { updateUserb2b } from './../../context/userb2b/Userb2bAction'
import { types } from './../../context/types/types'
import SpinnerModal from '../../components/general/spinner/SpinnerModal'
// import Notification, { type } from '../../components/general/toast/Toast';

const ProfilePage = () => {
    const [loader, setLoader] = useState<boolean>(false)

    const { user, dispatch } = useContext(AuthContext)
    const [imgCourse, setImgCourse] = useState<any>(user.user.photo)
    const [, setLoading] = useState<boolean>(false)
    // console.log(loading)

    const { state: stateApariencia } = useContext(AparienciaContext)
    const token = JSON.parse(localStorage.getItem('user')!).token

    const [preview, setPreview] = useState<string>('')

    const [userInfo, setUserInfo] = useState({
        name: user.user.name,
        lastname: user.user.lastname,
        dni: user.user.dni,
        phone: user.user.phone,
        address: user.user.address,
        rol: ['admin b2b', 'admin b2c'],
        type: user.user.documentType,
    })

    const changeData = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        number?: boolean,
    ): void => {
        const { name, value }: any = e.target
        if (number) {
            const re = /^[0-9.\b]+$/
            if (value === '' || re.test(value)) {
                setUserInfo({
                    ...userInfo,
                    [name]: value,
                })
            }
        } else {
            setUserInfo({
                ...userInfo,
                [name]: value,
            })
        }
    }

    // const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    //     const { name, value } = e.target
    //     setUserInfo({
    //         ...userInfo,
    //         [name]: value
    //     })
    // }

    const changeImage = (e: any) => {
        if (e !== undefined) {
            const typeFile = e.type
            if (
                typeFile === 'image/png' ||
                typeFile === 'image/jpg' ||
                typeFile === 'image/jpeg'
            ) {
                const imgCourse = e
                setImgCourse(imgCourse)
                setPreview(URL.createObjectURL(e))
            }
        } else {
            setImgCourse('')
        }
    }

    const handleUpdateUser = async (e: any) => {
        e.preventDefault()

        setLoader(true)

        // console.log(token)

        const datan: FormData = new FormData()
        datan.append('fname', 'updateUser')
        datan.append('user', user.user._id)
        datan.append('name', userInfo.name)
        datan.append('lastname', userInfo.lastname)
        datan.append('phone', userInfo.phone)
        datan.append('address', userInfo.address)
        datan.append('entity_id', stateApariencia.currentIdEntity)
        datan.append('documentType', userInfo.type)
        datan.append('dni', userInfo.dni)
        // datan.append("roles", JSON.stringify(userInfo.rol))
        if (imgCourse) {
            datan.append('photo', imgCourse)
        }

        setLoading(true)

        await updateUserb2b(datan, token)
            .then((data) => {
                // console.log(data)
                setLoader(false)

                dispatch({
                    type: types.editUser,
                    payload: data.data.user,
                })

                // Notification(type.success, "Se actualizó el usuario correctamente.")
                toast.success('Se actualizó el usuario correctamente.')

                // axiosUserV5({
                //     url: 'v5.0.1/api',
                //     headers: {
                //         authorization: `Bearer ${token}`,
                //     },
                //     data: {
                //         fname: "getUser",
                //         user_id: user.user._id
                //     },
                // }).then(({ data }) => {

                //     console.log(data)

                // }).catch((e) => {

                //     console.log(e.response)

                // })
            })

            .catch((err: any) => {
                const error = err.response.data.message

                console.log(e)

                console.log(e.response)
                setLoader(false)

                // Notification(type.danger, error ? error : "Hubo un error, no se actualizó el usuario.")
                toast.error(error ? error : 'Hubo un error, no se actualizó el usuario.')
            })

        setLoading(false)
    }

    return (
        <div className="main_settings">
            <form onSubmit={handleUpdateUser}>
                <div className="containerForm">
                    <div className="profileImageChoose">
                        {preview ? (
                            <img
                                style={{ objectFit: 'contain' }}
                                src={preview}
                                alt="ImagenDef"
                                className="imgDeforView"
                            />
                        ) : user.user.photo && user.user.photo.includes('https://') ? (
                            <img
                                src={user.user.photo}
                                className="img_photo"
                                alt="phot"
                                style={{ objectFit: 'contain' }}
                            />
                        ) : (
                            <div className="img_photo">
                                <i
                                    className="profileDef fas fa-user-circle"
                                    style={{ fontSize: '120px', color: '#B3B3B3' }}
                                ></i>
                            </div>
                        )}
                        {/* {user.user.photo && user.user.photo.includes('https://') ? <img src={user.user.photo} className="img_photo" alt="phot" /> : <div className="img_photo"><i className="profileDef fas fa-user-circle"></i></div>} */}

                        <label htmlFor="file" className="button_designs">
                            Subir Imagen
                            <input
                                className="img_input"
                                type="file"
                                id="file"
                                onChange={(e: any) => changeImage(e.target.files[0])}
                            />
                        </label>
                    </div>

                    <label>
                        <span>Nombre</span>
                        <input
                            style={{ textTransform: 'capitalize' }}
                            type="text"
                            name="name"
                            value={userInfo.name}
                            onChange={changeData}
                        />
                    </label>

                    <label>
                        <span>Apellido</span>
                        <input
                            style={{ textTransform: 'capitalize' }}
                            type="text"
                            name="lastname"
                            value={userInfo.lastname}
                            onChange={changeData}
                        />
                    </label>

                    <label>
                        <span>Correo</span>
                        <input
                            disabled
                            type="text"
                            name="phone"
                            value={user.user.email}
                            onChange={(e: any) => changeData(e, true)}
                        />
                    </label>

                    <label>
                        <span>Telefono</span>
                        <input
                            type="text"
                            name="phone"
                            value={userInfo.phone}
                            onChange={(e: any) => changeData(e, true)}
                        />
                    </label>

                    <label>
                        <span>Tipo de Documento</span>
                        <select
                            className=""
                            name="type"
                            value={userInfo.type}
                            onChange={changeData}
                            required
                        >
                            <option value="" style={{ display: 'none' }}>
                                Seleccione una opción...
                            </option>
                            <option value="dni">DNI</option>
                            <option value="pasaporte">Pasaporte</option>
                            <option value="carné">Carné de Extranjería</option>
                        </select>
                    </label>

                    <label>
                        <span>Número de Documento</span>
                        <input
                            type="text"
                            name="dni"
                            value={userInfo.dni}
                            onChange={(e: any) => changeData(e, true)}
                        />
                    </label>

                    <label>
                        <span>Dirección</span>
                        <input
                            type="text"
                            name="address"
                            value={userInfo.address}
                            onChange={(e: any) => changeData(e)}
                        />
                    </label>

                    <div className="buttonContainerProfile">
                        <button
                            className="buttonDesignProfile"
                            type="submit"
                            disabled={loader}
                        >
                            {loader ? <SpinnerModal /> : 'Guardar Cambios'}
                        </button>
                        {/* <button disabled className="buttonDesignProfile" type="submit" ><SpinnerModal /></button> */}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ProfilePage
