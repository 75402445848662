import { useEffect, useMemo, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Editor } from '@tinymce/tinymce-react'
import { toast } from 'react-toastify'

import {
    TypeUnique,
    TypeMultiple,
    TypeBoolean,
    TypeShort,
    TypeLarge,
    TypeFile,
} from '../typeQuestions'
import { TINY_API_KEY } from '../../../../../config/utils'
import type {
    Alternative,
    ModalOptions,
    NivelQuestion,
    Question,
    TypeQuestion,
} from '../../../../../interfaces'
import './styleModalQuestion.sass'
import { useAddEditQuestionMutation } from '../../../../../store/api'
import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { selectAuth } from '../../../../../store/slices'

interface QuestionAddEditProps {
    currentQuestion?: Question
    moduleName: string
    showModal: ModalOptions
    setCurrentQuestion: React.Dispatch<React.SetStateAction<Question | undefined>>
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
}

export interface FormQuestion {
    question_id?: string
    title: string
    question: string
    nivel: NivelQuestion
    type: TypeQuestion
    alternatives: Alternative[]
    keywords: string[]
}
let renderCount = 0

export const QuestionAddEdit = ({
    currentQuestion,
    setCurrentQuestion,
    moduleName,
    showModal,
    setShowModal,
}: QuestionAddEditProps) => {
    renderCount++
    console.log({ renderCount })
    // console.log({ currentQuestion })
    const { entity } = useAppSelector(selectAuth)
    // total of type questions change
    const [totalChanges, setTotalChanges] = useState<number>(0)
    const { register, handleSubmit, control, watch, setValue, getValues } =
        useForm<FormQuestion>({
            defaultValues: {
                question_id: showModal === 'edit' ? currentQuestion?._id : '',
                title: showModal === 'edit' ? currentQuestion?.title : '',
                question: showModal === 'edit' ? currentQuestion?.question : '',
                nivel: showModal === 'edit' ? currentQuestion?.nivel : 'easy',
                type: showModal === 'edit' ? currentQuestion?.type : 'unique',
                alternatives: showModal === 'edit' ? currentQuestion?.alternatives : [],
                keywords: showModal === 'edit' ? currentQuestion?.keywords : [moduleName],
            },
        })
    const [addEditQuestion, { isLoading }] = useAddEditQuestionMutation()

    const type = watch('type')

    const editorRef = useRef<any>(null)

    const typesQuestion = useMemo(
        () => [
            {
                value: 'unique',
                label: 'Opción única',
            },
            {
                value: 'multiple',
                label: 'Selescción múltiple',
            },
            {
                value: 'boolean',
                label: 'Verdadero o Falso',
            },
            {
                value: 'short',
                label: 'Pregunta corta',
            },

            {
                value: 'large',
                label: 'Pregunta larga',
            },
            {
                value: 'file',
                label: 'Pregunta con archivos',
            },
        ],
        [],
    )

    const validateAlternatives = (typeQuestion: TypeQuestion) => {
        let correctAlternatives = false
        let emptyAlternatives = false
        let alts = getValues('alternatives')
        let totalAlts = alts.length
        toast.dismiss() // clean all previous toasts
        if (totalAlts === 0) {
            toast.warn('No hay alternativas')
            return true
        }
        alts.forEach((alternative) => {
            if (alternative.correct) correctAlternatives = true
            if (!alternative.value.trim().length) emptyAlternatives = true
        })
        if (!correctAlternatives) {
            // if some alternatives is not correct show warning
            switch (typeQuestion) {
                case 'unique':
                    if (totalAlts === 1)
                        toast.warn('Se debe añadir al menos una alternativa más')
                    toast.warn('Por favor, indique la alternativa correcta.')
                    return true
                case 'multiple':
                    if (totalAlts === 1)
                        toast.warn('Se debe añadir al menos una alternativa más')
                    toast.warn('Por favor, indique al menos una alternativa correcta.')
                    return true
                case 'boolean':
                    toast.warn('Por favor, indique si el enunciado es verdadero o falso.')
                    return true
                default:
                    return true
            }
        }
        if (emptyAlternatives) {
            // if some alternatives are empty
            toast.warn('Hay una o más alternativas en blanco. Complétela o elimínela.')
            return true
        }
        return false
    }

    const renderType = useMemo(() => {
        switch (type) {
            case 'unique':
                return (
                    <TypeUnique
                        control={control}
                        register={register}
                        isLoading={isLoading}
                    />
                )
            case 'multiple':
                return (
                    <TypeMultiple
                        control={control}
                        register={register}
                        isLoading={isLoading}
                    />
                )
            case 'boolean':
                return <TypeBoolean control={control} isLoading={isLoading} />
            case 'short':
                return <TypeShort />
            case 'large':
                return <TypeLarge />
            case 'file':
                return <TypeFile />
            default:
                return null
        }
    }, [type, control, register, isLoading])

    const onSubmit: SubmitHandler<FormQuestion> = (data) => {
        if (validateAlternatives(data.type)) return

        // console.log({ data })
        // console.log(data.alternatives)
        if (editorRef.current) {
            data.question = editorRef.current.getContent()
            // console.log(editorRef.current.getContent())
            addEditQuestion({
                fname: showModal === 'edit' ? 'updateQuestion' : 'createQuestion',
                entity,
                ...data,
            })
                .then(() => {
                    setShowModal('hide')
                    setCurrentQuestion(undefined)
                })
                .catch(console.log)
        }
        console.log({ data })
    }

    useEffect(() => {
        switch (type) {
            case 'boolean':
                if (showModal === 'edit' && totalChanges === 0) {
                    setValue('alternatives', [...currentQuestion?.alternatives!])
                } else {
                    setValue('alternatives', [
                        { _id: '1', value: 'Verdadero', correct: false },
                        { _id: '2', value: 'Falso', correct: false },
                    ])
                }
                break
            case 'short':
                setValue('alternatives', [
                    { _id: '1', value: 'Short Question', correct: true },
                ])
                break

            case 'large':
                setValue('alternatives', [
                    { _id: '1', value: 'Large Question', correct: true },
                ])
                break

            case 'file':
                setValue('alternatives', [
                    { _id: '1', value: 'File Question', correct: true },
                ])
                break
            default:
                if (showModal === 'edit' && totalChanges === 0) {
                    setValue('alternatives', [...currentQuestion?.alternatives!])
                } else {
                    setValue('alternatives', [])
                }
                break
        }
    }, [type, setValue, showModal, currentQuestion?.alternatives, totalChanges])

    return (
        <div className="main_ModalCoursesScheduled">
            <div className="headModalCoursesScheduled">
                <h3>{showModal === 'edit' ? 'Editar' : 'Crear'} Pregunta</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal('hide')} />
            </div>
            <div className="bodyModalCoursesScheduled scroll">
                <form onSubmit={handleSubmit(onSubmit)} className="form_question">
                    <div className="container_options">
                        <div className="select">
                            <label>Nivel de Dificultad:</label>
                            <select {...register('nivel')} disabled={isLoading}>
                                <option value="easy">Facil</option>
                                <option value="medium">Normal</option>
                                <option value="hard">Dificil</option>
                            </select>
                        </div>
                        <div className="select">
                            <label>Tipo de Pregunta:</label>
                            <select
                                {...register('type', {
                                    onChange: (
                                        e: React.ChangeEvent<HTMLSelectElement>,
                                    ) => {
                                        setValue('type', e.target.value as TypeQuestion)
                                        setTotalChanges(totalChanges + 1)
                                    },
                                })}
                                disabled={isLoading}
                            >
                                {typesQuestion.map((tq) => (
                                    <option value={tq.value} key={tq.value}>
                                        {tq.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="container_options">
                        <label>Titulo:</label>
                        <input
                            className="input_question"
                            type="text"
                            disabled={isLoading}
                            {...register('title')}
                        />
                    </div>
                    <div className="container_options">
                        <label>Pregunta:</label>
                        <Editor
                            disabled={isLoading}
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            initialValue={
                                showModal === 'edit'
                                    ? currentQuestion?.question
                                    : undefined
                            }
                            init={{
                                language: 'es',
                                directionality: 'ltr',
                                height: 300,
                                width: '100%',
                                menubar: true,
                                branding: false,
                                plugins: [
                                    'advlist autolink lists link image',
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste wordcount',
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic | ' +
                                    'alignleft aligncenter alignright |' +
                                    'bullist numlist outdent indent | help',
                            }}
                            apiKey={TINY_API_KEY}
                        />
                    </div>
                    <div className="container_options">
                        <label>Alternativas:</label>
                        {renderType}
                    </div>
                    <div className="container_options button_options">
                        <button type="submit" disabled={isLoading}>
                            {showModal === 'edit' ? 'Actualizar' : 'Guardar'} pregunta
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
