import { axiosPrograms } from '../../../config/axios'
// import { typesRepository } from './../../types/typesRepository';
// import { axiosPrograms } from './../../../config/axios';
import { typesPrograms } from './../../types/typesPrograms'

export const getCategories = async (
    token: string,
    obj: any,
    dispatch: any,
    where?: any,
) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
        .then(({ data }) => {
            // console.log(data)

            dispatch({
                type: typesPrograms.getCategories,
                payload: data.categories,
            })

            dispatch({
                type: typesPrograms.loadingCategories,
                payload: true,
            })

            // if (where !== 'materialFile') {

            //     if (data.categories.length) {
            //         dispatch({
            //             type: typesPrograms.currentCategory,
            //             payload: data.categories[0]
            //         })

            //     }
            // }
        })
        .catch((e) => {
            console.log(e.response)
        })
}

export const getCourses = async (params: any) => {
    const { token, obj, dispatch, setpagination } = params

    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
        .then(({ data }) => {
            setpagination(data.pagination)

            let array: any[] = []

            data.courses.map((res: any) =>
                res
                    ? array.push({
                          _id: res._id,
                          type_of_course: res.type_of_course,
                          alias: res.alias,
                          name: res.name,
                          preview: res.preview,
                          description: res.description,
                          price_certificate: res.price.certificate,
                          price_course_price: res.price.course_price,
                          price_inscription: res.price.inscription,
                          entity_id: res.entity._id,
                          entity_name: res.entity.entity_name,
                          target_audiences: res.target_audiences
                              ? res.target_audiences
                              : '',
                          category: res.category,
                          category_id: res.category._id,
                          category_name: res.category.name,
                          tags: res.tags,
                      })
                    : null,
            )

            dispatch({
                type: typesPrograms.getPrograms,
                payload: array,
            })
        })
        .catch((e) => {
            console.log(e)
            console.log(e.response)
        })
}

export const getModulesByCourse = async (token: string, obj: any, dispatch: any) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
        .then(({ data }) => {
            // console.log('entra para hacer el get, esta e la funcion', data)

            console.log('traes modulos?', data)

            let array: any[] = []

            data.courses.map((res: any) =>
                res
                    ? res.alias === 'modulo' &&
                      array.push({
                          _id: res._id,
                          type_of_course: res.type_of_course,
                          alias: res.alias,
                          name: res.name,
                          preview: res.preview,
                          description: res.description,
                          price: res.price,
                          entity: res.entity,
                          category: res.category,
                      })
                    : null,
            )

            // console.log('este array porsiacaso', array)

            dispatch({
                type: typesPrograms.getModules,
                payload: array,
            })

            dispatch({
                type: typesPrograms.loadingModules,
                payload: true,
            })

            return array
        })
        .catch((e) => {
            console.log(e.response)
        })
}

export const getItems = async (token: string, obj: any) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const createCategories = async (obj: any, token: string) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const createCourses = async (obj: any, token: string) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const editCategory = async (obj: any, token: string) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const editPrograms = async (obj: any, token: string) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: obj,
    })
}

export const deleteCategory = async (id: string, token: string, idEntity: string) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'deleteCategory',
            category_id: id,
            entity_id: idEntity,
        },
    })
}

export const deleteCourses = async (id: string, token: string, idEntity: string) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'deleteCourse',
            course_id: id,
            entity_id: idEntity,
        },
    })
}

export const deleteItems = async (id: string, token: string, idEntity: string) => {
    return axiosPrograms({
        url: 'v5.0.1/api',
        headers: {
            authorization: `Bearer ${token}`,
        },
        data: {
            fname: 'deleteItem',
            item: id,
            entity_id: idEntity,
        },
    })
}
