import React from 'react'
import CommingSoon from '../../components/global/comming/CommingSoon'
// import  from ''
const HomePage = () => {
    return (
        <div>
            <CommingSoon></CommingSoon>
        </div>
    )
}

export default HomePage
