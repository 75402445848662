import { useForm, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import type { ModalOptions } from '../../../../../interfaces'
import { useAddEditCategoryMutation } from '../../../../../store/api'
import { selectAuth, selectCourse } from '../../../../../store/slices'

import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface FormCategory {
    name: string
    description: string
}

interface AddEditCategoryProps {
    showModal: ModalOptions
    setShowModal: React.Dispatch<React.SetStateAction<ModalOptions>>
    // currentCategory: Category
}

export const AddEditCategory = ({
    showModal,
    setShowModal,
}: // currentCategory,
AddEditCategoryProps) => {
    const { entity } = useAppSelector(selectAuth)
    const { currentCategory } = useAppSelector(selectCourse)

    const { register, handleSubmit } = useForm<FormCategory>({
        defaultValues: {
            name: showModal === 'edit' ? currentCategory?.name : '',
            description: showModal === 'edit' ? currentCategory?.description : '',
        },
    })

    const [addEditCategory, { isLoading }] = useAddEditCategoryMutation()

    const onSubmit: SubmitHandler<FormCategory> = (data) => {
        console.log({ data })
        if (data.name.trim() === '' || data.description.trim() === '') {
            return toast.warning('No puede crear una categoría con espacios en blanco')
        }
        addEditCategory({
            entity_id: entity,
            fname: showModal === 'edit' ? 'updateCategory' : 'createCategory',
            category_id: showModal === 'edit' ? currentCategory?._id : '',
            name: data.name,
            description: data.description,
        })
            .then(() => setShowModal('hide'))
            .catch((error) => console.log(error))
    }

    return (
        <div className="main_AddCategory">
            <div className="heaCategory">
                <h3>{showModal === 'edit' ? 'Editar' : 'Crear'} Categoría</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal('hide')} />
            </div>
            <div className="bodyCategory">
                <form className="contFormEnt" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contFormEnt">
                        <div className="contInAddEnt">
                            <p>Nombre de la categoría *</p>
                            <input
                                type="text"
                                required
                                maxLength={50}
                                disabled={isLoading}
                                {...register('name')}
                            />
                        </div>

                        <div className="contInAddEnt">
                            <p>Descripción *</p>
                            <textarea
                                maxLength={400}
                                required
                                disabled={isLoading}
                                {...register('description')}
                            />
                            <p
                                style={{
                                    textAlign: 'right',
                                    color: '#a29f9f',
                                    fontWeight: 600,
                                }}
                            >
                                {' '}
                                {/* {varchar} caracteres */}
                            </p>
                            {/* <span>400 carácteres</span> */}
                        </div>
                    </div>

                    <div className="optsBtnAdd">
                        <button type="button" onClick={() => setShowModal('hide')}>
                            Cancelar
                        </button>
                        {isLoading ? (
                            <div className="containerSpinner">
                                <SpinnerModal />
                            </div>
                        ) : (
                            <button type="submit" disabled={isLoading}>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
