import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import { Course } from '../../../../../interfaces'
import { useDeleteCourseChildrenMutation } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

interface DeleteModuleProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    setModule: React.Dispatch<React.SetStateAction<Course | undefined>>
    moduleId: string
}

export const DeleteModule = ({
    setShowModal,
    moduleId,
    setModule,
}: DeleteModuleProps) => {
    const { entity } = useAppSelector(selectAuth)
    const [deleteCourseChildren, { isLoading }] = useDeleteCourseChildrenMutation()

    const handleDeleteModule = () => {
        deleteCourseChildren({
            entity_id: entity,
            course_id: moduleId,
        })
            .then(() => {
                setShowModal(false)
                setModule(undefined)
            })
            .catch(console.log)
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i className="fas fa-times-circle" onClick={() => setShowModal(false)} />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar esta módulo?</p>
                {
                    <div className="containerSpinner_center">
                        <button
                            type="button"
                            onClick={() => setShowModal(false)}
                            disabled={isLoading}
                        >
                            Cancelar
                        </button>
                        {isLoading ? (
                            <div className="containerSpinner">
                                <SpinnerModal />
                            </div>
                        ) : (
                            <button
                                className="saveButton"
                                onClick={handleDeleteModule}
                                disabled={isLoading}
                            >
                                Aceptar
                            </button>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}
