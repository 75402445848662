import { useState } from 'react'
import { toast } from 'react-toastify'

// import { axiosEvaluations } from '../../../../../../config/axios'
import QuestionAdd from '../../../questions/modalsQuestion/QuestionAdd'
// import Notification, {type} from '../../../../../../components/general/toast/Toast';

export const EditDragQuestionModal = ({
    setShowModalEdit,
    data,
    editModal,
    setQuestions,
    idEvaluation,
    setTypeOption,
}: any) => {
    const token = JSON.parse(localStorage.getItem('user')!).token

    console.log(data)

    return (
        <div className="main_ModalCoursesScheduled">
            <div className="headModalCoursesScheduled">
                <h3>Editar Pregunta</h3>{' '}
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalEdit(false)}
                />
            </div>

            <div className="bodyModalCoursesScheduled">
                <QuestionAdd
                    dataedit={data}
                    token={token}
                    nameCourse={data.keywords[0]}
                    edit={editModal}
                    closeModal={() => {
                        setShowModalEdit(false)
                    }}
                    setQuestions={setQuestions}
                    idEvaluation={idEvaluation}
                    setTypeOption={setTypeOption}
                />
            </div>
        </div>
    )
}

export const DeleteDragQuestionModal = ({
    setShowModalDelete,
    data,
    handleDelete,
}: // setQuestions,
// idEvaluation,
any) => {
    const [disabled, setdisabled] = useState(false)

    // const token = JSON.parse(localStorage.getItem('user')!).token

    console.log(data)

    const deleteDragQuestion = () => {
        console.log(data._id)
        setdisabled(true)

        // axiosEvaluations({
        //     url: 'v5.0.1/api',
        //     method: 'POST',
        //     headers: {
        //         authorization: `Bearer ${token}`,
        //     },
        //     data: {
        //         fname: 'deleteQuestionByEvaluation',
        //         question: data.questionEvalId,
        //     },
        // })
        //     .then(({ data }) => {
        //         console.log(data)

        //         axiosEvaluations({
        //             url: 'v5.0.1/api',
        //             method: 'POST',
        //             headers: {
        //                 authorization: `Bearer ${token}`,
        //             },
        //             data: {
        //                 fname: 'getEvaluation',
        //                 evaluation_id: idEvaluation,
        //             },
        //         })
        //             .then(({ data }) => {
        //                 let array: any[] = []

        //                 for (let { question, alternatives } of data.questions) {
        //                     array.push({ ...question, alternatives, questionbank: true })
        //                 }

        //                 setQuestions([...array])

        //                 // Notification(type.success, "Pregunta eliminada de la evaluación")
        //                 toast.success('Pregunta eliminada de la evaluación')

        //                 setShowModalDelete(false)
        //             })
        //             .catch((err) => {
        //                 console.log(err.response)
        //             })
        //     })
        //     .catch((err) => {
        //         console.log(err.response)
        //     })
        handleDelete()
        toast.success('Pregunta eliminada de la evaluación')

        setShowModalDelete(false)
    }

    return (
        <div className="main_Delete">
            <div className="headModal">
                <h3>Aviso</h3>
                <i
                    className="fas fa-times-circle"
                    style={disabled ? { pointerEvents: 'none' } : {}}
                    onClick={() => setShowModalDelete(false)}
                />
            </div>
            <div className="bodyModals">
                <p>¿Está seguro de eliminar esta pregunta?</p>
                <div>
                    <button disabled={disabled} onClick={() => setShowModalDelete(false)}>
                        Cancelar
                    </button>
                    <button
                        disabled={disabled}
                        className="saveButton"
                        onClick={deleteDragQuestion}
                    >
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    )
}
