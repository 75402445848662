import { IAction, IState } from './EntityInterface'
import { typesEntity } from './../types/typesEntity'

export const entityReducer = (state: IState, action: IAction): any => {
    switch (action.type) {
        case typesEntity.getEntity:
            return {
                ...state,
                entities: action.payload,
            }
        case typesEntity.createEntity:
            return {
                ...state,
                entities: [...state.entities, action.payload],
            }
        case typesEntity.deleteEntity:
            return {
                ...state,
                entities: state.entities.filter(
                    (data: any) => data._id !== action.payload,
                ),
            }

        case typesEntity.updateEntity:
            return {
                ...state,
                entities: state.entities.map((data: any) => {
                    if (data._id === action.payload._id) {
                        return action.payload
                    } else {
                        return data
                    }
                }),
            }

        default:
            return state
    }
}
