import { memo, useCallback, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'

import { Button, Input } from '../../components'

interface SearchInput {
    searchText: string
}

type TableHeaderProps = {
    title: string
    refetch: () => void
    // handleShowAddModal: React.Dispatch<React.SetStateAction<'add' | 'edit' | 'hide'>>
    handleShowAddModal?: () => void
    setTextQuery?: (textQuery: string) => void
    // resetPagination: () => void
    showFilter?: boolean
    resetTable?: string
}

export const TableHeader = ({
    title,
    refetch,
    handleShowAddModal,
    setTextQuery,
    showFilter = true,
    resetTable,
}: TableHeaderProps) => {
    console.log('table header')
    const { handleSubmit, register, reset } = useForm<SearchInput>()

    const handleCleanSearch = useCallback(() => {
        console.log('clean search')
        reset()
        setTextQuery && setTextQuery('')
    }, [reset, setTextQuery])

    const onSubmit: SubmitHandler<SearchInput> = ({ searchText }) => {
        setTextQuery && setTextQuery(searchText)
    }

    // const openModal = useCallback(() => handleShowAddModal('add'), [handleShowAddModal])

    useEffect(() => {
        handleCleanSearch()
    }, [resetTable, handleCleanSearch])

    return (
        <>
            <div className="multiedu__table--head">
                {handleShowAddModal && (
                    <Button onClick={handleShowAddModal} icon="plus" />
                )}
                <Button onClick={refetch} icon="sync-alt" />
            </div>
            <div className="multiedu__table--header">
                <div className="multiedu__table--title">{title && <p>{title}</p>}</div>
                {showFilter && (
                    <form
                        className="multiedu__table--filter"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Input label="searchText" register={register} />
                        <Button icon="search" type="submit" />
                        <Button icon="times" type="button" onClick={handleCleanSearch} />
                    </form>
                )}
            </div>
        </>
    )
}

export default memo(TableHeader)
