import { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Link, useParams } from 'react-router-dom'

import { AuthContext } from './../../../../context/auth/AuthProvider'
import { AparienciaContext } from './../../../../context/aparienciaContext/AparienciaProvider'
import { ProgramsContext } from './../../../../context/userb2b/programs/ProgramsProvider'
import { getModulesByCourse } from './../../../../context/userb2b/programs/ProgramsAction'
import CreateEvaluationsPage from '../createEvaluation/CreateEvaluationsPage'
import ListQuestionsShow from './listQuestions/ListQuestionsShow'
import ContainerModal from './../../../../components/modals/ContainerModal'
import { axiosEvaluations } from '../../../../config/axios'
import './evaluationpage.sass'
// import Notification, { type } from '../../../../components/general/toast/Toast';

// type ChangeEvent = React.ChangeEvent<HTMLInputElement>
// type target = {
//     name: string
//     value: string
// }

const EvaluationXCourse = () => {
    const [disabled, setdisabled] = useState<boolean>(false)
    const { state, dispatch } = useContext(ProgramsContext)
    const { user } = useContext(AuthContext)
    const [typeOption, setTypeOption] = useState<string>('')
    const { _idModules, _idCourse }: any = useParams()
    const { state: stateApariencia } = useContext(AparienciaContext)
    const [search, setsearch] = useState('')
    const [dataSearch, setdataSearch] = useState<any[]>([])

    const [data, setdata] = useState<any>({})
    const [datasetting, setdatasetting] = useState<any>({
        random_order: false,
        show_max_quantity: false,
        show_next: false,
        block_return: false,
        one_by_one: false,
        random_by_student: false,
    })
    const [nivelsquestion, setnivelsquestion] = useState<any>({
        easy: 0,
        medium: 0,
        hard: 0,
    })
    const [curso_name, setcurso_name] = useState<string>('')

    const [questions, setQuestions] = useState<any>([])

    const [listEvaluation, setListEvaluation] = useState<any>([])

    const deleteEvaluation = () => {
        setdisabled(true)
        var idEvaluacion = data._id
        axiosEvaluations({
            url: 'v5.0.1/api',
            method: 'POST',
            headers: {
                authorization: `Bearer ${user.token}`,
            },

            data: {
                fname: 'deleteEvaluation',
                evaluation_id: idEvaluacion,
                entity: stateApariencia.currentIdEntity,
            },
        })
            .then(({ data }) => {
                // console.log(data);
                console.log('dataSearch', dataSearch)
                console.log('listEvaluation', listEvaluation)
                let newlistEvaluation: any[] = dataSearch.filter(
                    (dataF: any) => dataF._id !== idEvaluacion,
                )
                console.log('newListEvaluation', newlistEvaluation)
                setListEvaluation([...newlistEvaluation])
                setdataSearch(newlistEvaluation)
                setTypeOption('none')
                // Notification(type.success, "La evaluación ha sido eliminada")
                toast.success('La evaluación ha sido eliminada')
            })
            .catch((err) => {
                console.log(err.response)
                // Notification(type.danger, "Ha ocurrido un error")
                toast.error('Ha ocurrido un error')
            })
        setdisabled(false)
    }
    const newEvaluation = (): void => {
        setdata({})
        setQuestions([])
        setdatasetting({
            random_order: false,
            show_max_quantity: false,
            show_next: false,
            block_return: false,
            one_by_one: false,
            random_by_student: false,
        })
        setnivelsquestion({
            easy: 0,
            medium: 0,
            hard: 0,
        })
        setTypeOption('create')
    }

    const showItemEvaluation = (data: any) => {
        console.log(data)
        setTypeOption('show')
        // console.log(data);

        setdata({
            ...data,
        })
        setdatasetting({
            ...data.options,
        })
    }

    useEffect(() => {
        const data = {
            fname: 'getCourses',
            entity_id: stateApariencia.currentIdEntity,
            parent_course_id: _idCourse,
        }
        getModulesByCourse(user.token, data, dispatch)
        // console.log(state);
    }, [_idCourse, stateApariencia.currentIdEntity, user.token, dispatch])

    const peticionGet = useCallback(
        (value?: string) => {
            console.log('courseId', _idModules)
            console.log('crrenyEntiti', stateApariencia.currentIdEntity)

            axiosEvaluations({
                url: 'v5.0.1/api',
                method: 'POST',
                headers: {
                    authorization: `Bearer ${user.token}`,
                },
                data: {
                    fname: 'getEvaluations',
                    course_id: _idModules,
                    entity: stateApariencia.currentIdEntity,
                    docs: 100,
                    page: 1,
                    filterBy: 'coordinador',
                    // filter_by_name : '',
                    sort: '-createdAt',
                    query: {
                        field: 'title',
                        search: '',
                    },
                },
            })
                .then(({ data }) => {
                    console.log(data)
                    setListEvaluation(data.evaluations)
                    setdataSearch(data.evaluations)
                    if (value) {
                        // console.log('que se acctualice')
                        showItemEvaluation(data.evaluations[data.evaluations.length - 1])
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                })
        },
        [_idModules, stateApariencia.currentIdEntity, user.token],
    )

    useEffect(() => {
        peticionGet()
    }, [peticionGet])

    useEffect(() => {
        let array: any[] = state.modules
        if (array.length > 0) {
            let data_curso = array.find((dat: any) => dat._id === _idModules)
            setcurso_name(data_curso.name)
        }
    }, [_idModules, state.modules])

    const filterElement = (e: any) => {
        const valor = e.target.value.toLowerCase()

        setsearch(e.target.value)

        var search = listEvaluation.filter((item: any) =>
            item.title?.toLowerCase().includes(valor) ? item : null,
        )
        setdataSearch(search)
    }
    return (
        <div className="containerEvaluations">
            <Link
                className="btnBankQuestions"
                to={
                    '/dashboard/coordinator/evaluations/' +
                    _idCourse +
                    '/' +
                    _idModules +
                    '/question_all'
                }
            >
                Banco de Preguntas
            </Link>
            <div className="containerLeftEvaluations">
                <div className="headerLeftEvaluations">
                    <div className="iconBoxs">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="titlePage">
                        Evaluaciones
                        {/* <i className="fas fa-filter"></i>
                        <i className="fas fa-search"></i> */}
                    </div>
                </div>
                <div className="search100">
                    <input
                        type="text"
                        placeholder="Buscar..."
                        value={search}
                        onChange={filterElement}
                    />
                    <i className="fas fa-search" />
                </div>
                <div className="btn100" onClick={newEvaluation}>
                    <span>
                        <i className="fas fa-plus" />
                    </span>
                    <span>Añadir nueva Evaluación</span>
                    <span>
                        <i className="fas fa-clipboard-check" />
                    </span>
                </div>
                <div className="listEvaluations">
                    {dataSearch.map((evaluation: any, key: number) =>
                        evaluation
                            ? !evaluation.published && (
                                  <div
                                      className={`itemaForum pointer ${
                                          evaluation._id === data?._id ? 'select ' : ''
                                      }`}
                                      key={key}
                                      onClick={() => showItemEvaluation(evaluation)}
                                  >
                                      <div className="detailsItem">
                                          <div title={evaluation.title}>
                                              {evaluation.title}
                                          </div>
                                          <div></div>
                                      </div>
                                      <i className="fas fa-chevron-right" />
                                  </div>
                              )
                            : null,
                    )}
                </div>
            </div>
            <div className="containerRightEvaluations">
                {typeOption === 'create' || typeOption === 'update' ? (
                    <>
                        <CreateEvaluationsPage
                            _idModules={_idModules}
                            _idCourse={_idCourse}
                            curso_name={curso_name}
                            token={user.token}
                            entity={stateApariencia.currentIdEntity}
                            dataEvaluations={data}
                            datasettingE={datasetting}
                            dataquestions={questions}
                            nivelsquestionE={nivelsquestion}
                            typeOption={typeOption}
                            setTypeOption={setTypeOption}
                            peticionGet={peticionGet}
                        />
                    </>
                ) : typeOption === 'show' || typeOption === 'delete' ? (
                    <>
                        <ListQuestionsShow
                            token={user.token}
                            id={data._id}
                            typeOption={typeOption}
                            setTypeOption={setTypeOption}
                            setQuestions={setQuestions}
                        />
                    </>
                ) : (
                    <div className="noOptsSelect">
                        <img src="/images/robot/RobotType1.svg" alt="robot playtec" />
                        <div>
                            <b>No ha seleccionado una Evaluación</b>
                        </div>
                        <div>
                            <b>Por favor seleccione una para empezar</b>
                        </div>
                    </div>
                )}
                <ContainerModal showModal={typeOption === 'delete'}>
                    <div className="main_Delete">
                        <div className="headModal">
                            <h3>Aviso</h3>
                            <i
                                className="fas fa-times-circle"
                                style={disabled ? { pointerEvents: 'none' } : {}}
                                onClick={() => setTypeOption('show')}
                            />
                        </div>
                        <div className="bodyModals">
                            <p>¿Desea Eliminar La Evaluación?</p>
                            <div>
                                <button
                                    disabled={disabled}
                                    onClick={() => setTypeOption('show')}
                                >
                                    Cancelar
                                </button>
                                <button
                                    disabled={disabled}
                                    className="saveButton"
                                    onClick={deleteEvaluation}
                                >
                                    Aceptar
                                </button>
                            </div>
                        </div>
                    </div>
                </ContainerModal>
            </div>
        </div>
    )
}

export default EvaluationXCourse
