import React, { createContext, useReducer } from 'react'
import { IState } from './ProgramsInterface'
import { programsReducer } from './ProgramsReducer'

const initialState: IState = {
    loading: false,
    loadingCategories: false,
    categories: [],
    currentCategory: null,
    courses: [],
    loadingModules: false,
    modules: [],
    currentModule: null,
    filesByModule: [],
}

export const ProgramsContext = createContext<IState | any>(initialState)

export const ProgramsProvider = (props: any) => {
    const [state, dispatch] = useReducer<any>(programsReducer, initialState)

    return (
        <ProgramsContext.Provider value={{ state, dispatch }}>
            {props.children}
        </ProgramsContext.Provider>
    )
}
