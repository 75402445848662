import { IAction } from './UserInterface'

import { IState } from './UserInterface'
import { types } from './../types/types'

export const userReducer = (state: IState, action: IAction): any => {
    switch (action.type) {
        case types.getUser:
            return {
                ...state,
                allUsers: action.payload,
            }

        // allUsers = [
        //     [
        //         {}
        //     ],
        //     [
        //         {}
        //     ],
        //     [],
        //     {}
        // ]

        // allUser = [
        //     {},
        //     {},
        // ]

        case types.createUser:
            return {
                ...state,
                allUsers: action.payload,
            }

        default:
            return state
    }
}
