import { useState, useEffect } from 'react'

import ContainerModal from '../../components/modals/ContainerModal'
import ProgressNS from '../../components/general/progress/ProgressNS'
import { NoData } from '../../components/general/nodata/NoData'
import CertificateView from './CertificateView'
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHooks'
import {
    clearCurrentCategory,
    selectAuth,
    selectCourse,
    setCurrentCategory,
} from '../../store/slices'
import { useGetCategoriesQuery } from '../../store/api'
import type { Category, ModalOptions } from '../../interfaces'
import { ListCoursesPage2 } from './ListCoursesPage2'
import { AddEditCategory } from '../../components/modals/continental/admin/categories'

const CoursesB2BPage2 = () => {
    const { entity } = useAppSelector(selectAuth)
    const { currentCategory } = useAppSelector(selectCourse)
    const dispatch = useAppDispatch()
    const [search, setSearch] = useState<string>('')
    const [dataSearch, setDataSearch] = useState<Category[]>([])
    // const [currentCategory, setCurrentCategory] = useState<Category>()
    // const [showModule, setShowModule] = useState<boolean>(false)
    const [showView, setShowView] = useState<'module' | 'evaluation' | 'none'>('none')

    const [showModalAddEditCategory, setShowModalAddEditCategory] =
        useState<ModalOptions>('hide')

    // const [showViewEvaluation, setShowViewEvaluation] = useState<boolean>(false)

    const [currentCourseId, setCurrentCourseId] = useState<string>('')

    const [showViewCertificate, setShowViewCertificate] = useState<boolean>(false)

    const { categories, isLoading } = useGetCategoriesQuery(
        {
            entity_id: entity,
        },
        {
            selectFromResult: ({ data, isLoading }) => ({
                categories: data?.categories,
                isLoading,
            }),
        },
    )

    const handleCurrentCategory = (category: Category) => {
        dispatch(setCurrentCategory(category))
        // setCurrentCategory(category)
    }

    const filterElement = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.value.toLowerCase()

        setSearch(e.target.value)

        const search = categories?.filter((item) =>
            item.name.toLowerCase().includes(valor),
        )

        // console.log({ search })

        if (search) setDataSearch(search)
    }

    useEffect(() => {
        if (categories && categories.length > 0) {
            console.log({ categories })
            setDataSearch([...categories])
        }
    }, [categories])

    useEffect(() => {
        return () => {
            dispatch(clearCurrentCategory())
        }
    }, [dispatch])

    // console.log({ currentCategory })

    return (
        <>
            <div className="courses_container_page scroll">
                <h3
                    style={{
                        color: 'var(--color-secondary)',
                        fontSize: '28px',
                        marginTop: '0',
                        margin: '0',
                    }}
                >
                    Programas
                </h3>
                {showViewCertificate ? (
                    <CertificateView
                        currentCourseId={currentCourseId}
                        setShowViewCertificate={setShowViewCertificate}
                    />
                ) : (
                    <div className="course_content_page">
                        <div className="left_categories_container">
                            <div className="search_box_categories">
                                <div className="text_search_categories">
                                    <input
                                        type="text"
                                        placeholder="Buscar"
                                        value={search}
                                        onChange={filterElement}
                                    />
                                </div>
                                {/* <div className="icons_search_categories">
                                <i className="fas fa-filter"></i>
                                <i className="fas fa-search"></i>
                            </div> */}
                            </div>

                            <div
                                className="button_add_categories"
                                onClick={() => setShowModalAddEditCategory('add')}
                            >
                                <div className="text_search_categories">
                                    <i className="fas fa-plus"></i>
                                </div>
                                Añadir nueva categoría
                                <div className="icons_search_categories">
                                    <i className="fas fa-clipboard-check"></i>
                                </div>
                            </div>

                            <div className="container_categories_text scroll">
                                {isLoading ? (
                                    <ProgressNS />
                                ) : dataSearch.length > 0 ? (
                                    dataSearch.map((category) => {
                                        const total = category?.totalCourses
                                            ? category.totalCourses
                                            : '0'
                                        return (
                                            <div
                                                className={
                                                    currentCategory?._id === category._id
                                                        ? `item_course active`
                                                        : ' item_course '
                                                }
                                                key={category._id}
                                                onClick={() =>
                                                    handleCurrentCategory(category)
                                                }
                                            >
                                                <div className="info_item">
                                                    <p> {category.name} </p>
                                                    <p> {total} cursos</p>
                                                </div>
                                                <div className="arrow_item">
                                                    <i className="fas fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <NoData title="No hay categorías con ese nombre" />
                                )}
                            </div>
                        </div>
                        {currentCategory ? (
                            <div
                                className={
                                    // !showViewEvaluation && !showModule
                                    showView === 'none'
                                        ? 'rigth_courses_container'
                                        : 'newClass'
                                }
                            >
                                <ListCoursesPage2
                                    // showViewCertificate={showViewCertificate}
                                    setCurrentCourseId={setCurrentCourseId}
                                    setShowViewCertificate={setShowViewCertificate}
                                    showView={showView}
                                    setShowView={setShowView}
                                    setShowModalAddEditCategory={
                                        setShowModalAddEditCategory
                                    }
                                    // currentCategory={currentCategory}
                                    // setShowModule={setShowModule}
                                    // showModule={showModule}
                                    // setShowEvaluation={setShowViewEvaluation}
                                    // showEvaluation={showViewEvaluation}
                                    // setShowViewCertificate={setShowViewCertificate}
                                    // setdetailCourseCertificate={
                                    //     setdetailCourseCertificate
                                    // }
                                />
                            </div>
                        ) : (
                            <NoData title="Seleccione una categoría" />
                        )}
                    </div>
                )}
            </div>
            <ContainerModal showModal={showModalAddEditCategory !== 'hide'}>
                <AddEditCategory
                    showModal={showModalAddEditCategory}
                    setShowModal={setShowModalAddEditCategory}
                    // currentCategory={currentCategory!}
                />
            </ContainerModal>
        </>
    )
}

export default CoursesB2BPage2
