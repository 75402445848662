import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import ProgressNS from '../../../components/general/progress/ProgressNS'
import {
    useGetCategoriesQuery,
    useGetCoursesByCategoryQuery,
    useGetCoursesChildrenQuery,
} from '../../../store/api'
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHooks'
import { selectAuth, selectCourse, setCurrentCategory } from '../../../store/slices'
import type { Category, Course } from '../../../interfaces'
import { NoData } from '../../../components/general/nodata/NoData'
import './evaluationsPage.sass'

const EvaluationsPage2 = () => {
    const { entity } = useAppSelector(selectAuth)
    const { currentCategory } = useAppSelector(selectCourse)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [currentCourse, setCurrentCourse] = useState<Course>()
    const [search, setSearch] = useState<string>('')
    const [dataCategories, setDataCategories] = useState<Category[]>([])

    const {
        categories,
        // isLoading: isLoadingCategories
    } = useGetCategoriesQuery(
        { entity_id: entity },
        {
            selectFromResult: ({ data, isLoading }) => ({
                categories: data?.categories || [],
                isLoading,
            }),
        },
    )

    const {
        courses,
        isLoading: isLoadingCourses,
        isFetching: isFetchingCourses,
    } = useGetCoursesByCategoryQuery(
        {
            entity_id: entity,
            category_id: currentCategory?._id!,
            // page: pagination.actualPage!,
            docs: 900,
        },
        {
            skip: currentCategory ? false : true,
            selectFromResult: ({ data, isLoading, isFetching }) => ({
                courses:
                    data?.courses.filter((course) => course.alias === 'programa') || [],
                isLoading,
                isFetching,
            }),
        },
    )

    const {
        modules,
        isLoading: isLoadingModules,
        isFetching: isFetchingModules,
    } = useGetCoursesChildrenQuery(
        {
            entity_id: entity,
            parent_course_id: currentCourse?._id || '',
        },
        {
            skip: currentCourse ? false : true,
            selectFromResult: ({ data, isLoading, isFetching }) => ({
                modules: data?.courses || [],
                isLoading,
                isFetching,
            }),
        },
    )

    const handleCurrentCategory = (category: Category) => {
        dispatch(setCurrentCategory(category))
        setCurrentCourse(undefined)
    }

    const goEvaluations = (id: string) => {
        history.push(
            '/dashboard/coordinator/evaluations/' + currentCourse?._id + '/' + id,
        )
    }

    const filterElement = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valor = e.target.value.toLowerCase()

        setSearch(e.target.value)

        const search = categories?.filter((item) =>
            item.name.toLowerCase().includes(valor),
        )

        if (search) setDataCategories(search)
    }

    useEffect(() => {
        if (categories.length > 0) {
            setDataCategories(categories)
        }
    }, [categories])

    return (
        <div className="courses_container_page Evaluations scroll">
            <div className="headUserPag">
                <h3>Evaluaciones por cursos principales</h3>
            </div>
            <div className="course_content_page">
                <div className="left_categories_container scroll">
                    <div className="search_box_categories">
                        <div className="text_search_categories">
                            <input
                                type="text"
                                placeholder="Buscar"
                                value={search}
                                onChange={filterElement}
                            />
                        </div>
                        {/* <div className="icons_search_categories">
                                <i className="fas fa-filter"></i>
                                <i className="fas fa-search"></i>
                            </div> */}
                    </div>
                    <div className="listEvaluations category scroll">
                        {dataCategories.map((category) => (
                            <div
                                key={category._id}
                                className={`itemaForum pointer ${
                                    currentCategory?._id === category._id
                                        ? 'select pointernone'
                                        : ''
                                }`}
                                onClick={() => handleCurrentCategory(category)}
                            >
                                <div className="detailsItem">
                                    <div title={category.name}>{category.name}</div>
                                    <div>{category?.totalCourses || '0'} Cursos</div>
                                </div>
                                <i className="fas fa-chevron-right"></i>
                            </div>
                        ))}
                    </div>
                </div>
                {isLoadingCourses || isFetchingCourses ? (
                    <ProgressNS />
                ) : currentCategory ? (
                    <div className="rigth_courses_container">
                        <div className="title_right_courses">
                            <InfoSection
                                title={currentCategory?.name || ''}
                                description={`TOTAL DE CURSOS: ${
                                    currentCategory?.totalCourses || ''
                                }`}
                            />
                        </div>

                        <div className="evaluation_body">
                            <div className="left_content">
                                <div className="listEvaluations courses">
                                    {courses.length > 0 ? (
                                        courses.map((course) => (
                                            <div
                                                key={course._id}
                                                className={`itemaForum pointer ${
                                                    currentCourse?._id === course._id
                                                        ? 'select pointernone'
                                                        : ''
                                                }`}
                                                onClick={() => setCurrentCourse(course)}
                                            >
                                                <div className="detailsItem">
                                                    <div
                                                        data-tip={course.name}
                                                        data-delay-show="300"
                                                    >
                                                        {course.name}
                                                    </div>
                                                    <div>{course.description}</div>
                                                </div>
                                                <i className="fas fa-chevron-right" />
                                            </div>
                                        ))
                                    ) : (
                                        <NoData title="No hay cursos" />
                                    )}
                                </div>
                            </div>
                            {currentCourse ? (
                                <div className="rigth_content">
                                    <div className="title_right_courses">
                                        <InfoSection
                                            title={currentCourse?.name || ''}
                                            description={currentCourse?.description || ''}
                                        />
                                    </div>
                                    {isLoadingModules || isFetchingModules ? (
                                        <ProgressNS />
                                    ) : (
                                        <div className="evaluation_body with_max">
                                            <div className="left_content">
                                                <div className="listEvaluations modules scroll">
                                                    {modules.length ? (
                                                        modules.map((course) => (
                                                            <div
                                                                key={course._id}
                                                                className="itemaForum colum2"
                                                            >
                                                                <div className="detailsItem">
                                                                    <div
                                                                        title={
                                                                            course.name
                                                                        }
                                                                    >
                                                                        {course.name}
                                                                    </div>
                                                                    <div></div>
                                                                </div>
                                                                <div
                                                                    className="btn"
                                                                    onClick={() =>
                                                                        goEvaluations(
                                                                            course._id,
                                                                        )
                                                                    }
                                                                >
                                                                    ver Evaluaciones
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <NoData title="No hay módulos" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <NoData title="Seleccione un curso" />
                            )}
                        </div>
                    </div>
                ) : (
                    <NoData title="Seleccione una categoría" />
                )}
            </div>
        </div>
    )
}

interface InfoSectionProps {
    title: string
    description: string
}

const InfoSection = ({ title, description }: InfoSectionProps) => (
    <div className="text_container_courses">
        <span> {title} </span>
        <div className="total_courses_text">{description}</div>
    </div>
)

export default EvaluationsPage2
