import React, { createContext, useReducer } from 'react'
import { IState } from './UserInterface'
import { userReducer } from './UserReducer'

const initialState: IState = {
    loading: false,
    allUsers: {
        incomplete: [],
        inProcess: [],
        completed: [],
    },
}

export const UserContext = createContext<IState | any>(initialState)

export const UserProvider = (props: any) => {
    const [state, dispatch] = useReducer<any>(userReducer, initialState)

    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {props.children}
        </UserContext.Provider>
    )
}
