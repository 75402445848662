import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { SubmitHandler, useForm } from 'react-hook-form'

import dataCountry from '../../../../../pages/userB2B/countries.json'
import { useAppSelector } from '../../../../../hooks/useReduxHooks'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'
import { CreateSedeParams, useAddSedeMutation } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import { Campus } from '../../../../../interfaces'

type AddSedeProps = {
    setShowModal: React.Dispatch<React.SetStateAction<'add' | 'edit' | 'hide'>>
    showModal: 'add' | 'edit' | 'hide'
    currentSede?: Campus
}

export const AddEditSede = ({ showModal, setShowModal, currentSede }: AddSedeProps) => {
    const { entity } = useAppSelector(selectAuth)
    const [addSede, { isLoading }] = useAddSedeMutation()

    const { register, handleSubmit, watch } = useForm<Omit<Campus, '_id'>>({
        defaultValues: {
            name: showModal === 'edit' ? currentSede?.name || '' : '',
            country:
                showModal === 'edit' && currentSede?.country
                    ? currentSede.country.charAt(0).toUpperCase() +
                          currentSede.country.slice(1) || ''
                    : '',
            department:
                showModal === 'edit' && currentSede?.department
                    ? currentSede.department.charAt(0).toUpperCase() +
                          currentSede.department.slice(1) || ''
                    : '',
            address: showModal === 'edit' ? currentSede?.address || '' : '',
        },
    })

    const onSubmit: SubmitHandler<Omit<CreateSedeParams, '_id'>> = (data) => {
        // console.log(data)
        if (data.name.trim() === '' || data.address.trim() === '') {
            return toast.warning('No puede crear sedes con espacios en blanco')
        }
        addSede({ ...data, entity: entity! })
            .then(() => setShowModal('hide'))
            .catch(console.log)
    }

    const closeAddModal = useCallback(() => {
        setShowModal('hide')
    }, [setShowModal])

    return (
        <div className="main_AddCategory">
            <div className="heaCategory">
                <h3>{showModal === 'add' ? 'Agregar' : 'Editar'} Sede</h3>
                <i className="fas fa-times-circle" onClick={closeAddModal} />
            </div>
            <div className="bodyCategory">
                <form className="contFormEnt" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contFormEnt">
                        <div className="contInAddEnt">
                            <p>Nombre</p>
                            <input
                                type="text"
                                placeholder="Ingrese nombre"
                                maxLength={50}
                                required
                                disabled={isLoading}
                                {...register('name')}
                            />
                        </div>

                        <div className="contInAddEnt">
                            <p>País</p>
                            <select
                                className="design_input"
                                disabled={isLoading}
                                required
                                {...register('country')}
                            >
                                <option value=""> Seleccione una país ... </option>
                                {dataCountry.countries.map(({ country }) => (
                                    <option value={country} key={country}>
                                        {country}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="contInAddEnt">
                            <p>Departamento</p>
                            <select
                                className="design_input"
                                disabled={isLoading}
                                required
                                {...register('department')}
                            >
                                <option value="">
                                    Seleccione un departamento de {watch('country')}...
                                </option>
                                {dataCountry.countries
                                    .filter((res) => res.country === watch('country'))
                                    .map(({ states }) =>
                                        states.map((res, key) => (
                                            <option value={res} key={key}>
                                                {res}
                                            </option>
                                        )),
                                    )}
                            </select>
                        </div>

                        <div className="contInAddEnt">
                            <p>Dirección</p>
                            <input
                                type="text"
                                placeholder="Ingrese dirección"
                                required
                                disabled={isLoading}
                                {...register('address')}
                            />
                        </div>
                    </div>

                    <div className="optsBtnAdd">
                        <button type="button" onClick={closeAddModal}>
                            Cancelar
                        </button>
                        {isLoading ? (
                            <div className="containerSpinner">
                                <SpinnerModal />
                            </div>
                        ) : (
                            <button type="submit" disabled={isLoading}>
                                Guardar
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
