import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import { typesRepository } from '../../../../../context/types/typesRepository'
import { editFiles } from '../../../../../context/userb2b/repository/RepositoryAction'
import { RepositoryContext } from '../../../../../context/userb2b/repository/RepositoryProvider'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHooks'
import { MaterialByEntity } from '../../../../../interfaces'
import { materialsApi, useGetCategoriesQuery } from '../../../../../store/api'
import { selectAuth } from '../../../../../store/slices'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'

const isEmpty = (obj: Object): boolean => Object.keys(obj).length === 0

interface EditFileProps {
    setShowModalEdit: (value: boolean) => void
    material: MaterialByEntity
    setCurrentMaterial: (value: any) => void
}

export const EditFile = ({
    setShowModalEdit,
    material,
    setCurrentMaterial,
}: EditFileProps) => {
    // console.log(detailFile)

    const {
        title,
        price,
        state,
        description,
        name_file,
        category,
        _id,
        url: newurl,
    } = material

    // console.log(category)
    const [typeFile, setTypeFile] = useState<string>('URL')
    const [url, setUrl] = useState<string>(newurl)
    const [namefile, setNameFile] = useState<string>(name_file)

    const [changeSwitch, setChangeSwitch] = useState(state === 'public' ? true : false)
    const [imageSelect, setImageSelect] = useState<any>({})
    const [imageName, setImageName] = useState<any>(name_file)
    const [loading, setLoading] = useState<any>(false)
    const { state: stateApariencia } = useContext(AparienciaContext)
    // const { state: stateProgram } = useContext(ProgramsContext)

    const { entity } = useAppSelector(selectAuth)
    const dispatchRedux = useAppDispatch()
    const { data: dataCategories } = useGetCategoriesQuery({ entity_id: entity })

    // const [entityName, setEntityName] = useState<any>('');
    const newEntityName: string = JSON.parse(localStorage.getItem('user')!).dataEntity
        .entity_name

    const {
        state: { uploadProgressMaterial, cancelToken },
        dispatch,
    } = useContext(RepositoryContext)

    const [data, setData] = useState({
        title: title,
        category: category?._id!,
        description: description,
        price: price === 'Gratis' ? 0 : price,
        state: state,
    })

    // console.log(detailFile);

    const onChangeInputs = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        number?: boolean,
    ): void => {
        const { name, value } = e.target
        if (number) {
            const re = /^[0-9\b]+$/
            if (value === '' || re.test(value)) {
                setData({
                    ...data,
                    [name]: value,
                })
            }
        } else {
            setData({
                ...data,
                [name]: value,
            })
        }
    }

    const changeValueSwitch = () => {
        setChangeSwitch(!changeSwitch)

        if (data.state === 'private') {
            setData({
                ...data,
                state: 'public',
            })
        } else {
            setData({
                ...data,
                state: 'private',
            })
        }
    }

    const changeImage = (e: any) => {
        // console.log(e);

        if (e !== undefined) {
            const imgPhoto: object = e
            // console.log(imgPhoto)
            setImageSelect(imgPhoto)
            setImageName(e.name)
        } else {
            setImageSelect('')
            setImageName('')
        }
    }

    const sendEditFile = (e: any) => {
        // console.log(data.category)
        setLoading(true)

        const token = JSON.parse(localStorage.getItem('user')!).token

        e.preventDefault()

        if (data.description.trim() === '' || data.title.trim() === '') {
            setLoading(false)
            // return Notification(type.warning, "No puede editar un material con espacios en blanco");
            return toast.warning('No puede editar un material con espacios en blanco')
        }
        if (typeFile === 'URL') {
            if (url.trim() === '' || namefile.trim() === '') {
                setLoading(false)
                // return Notification(type.warning, "No puede crear un archivo con espacios en blanco");
                return toast.warning('No puede crear un archivo con espacios en blanco')
            }
        }

        const datan: FormData = new FormData()

        datan.append('fname', 'editMaterial')

        // if (imageSelect !== {}) {
        //     // datan.append("files", imageSelect)
        //     datan.append("name_file", 'nueva url editado')
        //     datan.append("url", 'aaaaaaaaaaaaaa')
        // }

        if (typeFile === 'Archivo') {
            if (imageSelect !== {}) {
                datan.append('files', imageSelect)
            }
        } else if ('URL') {
            datan.append('name_file', namefile)
            datan.append('url', url)
        }

        datan.append('category', data.category!)

        if (data.price !== null && data.price !== 'Gratis') {
            datan.append('price', data.price.toString())
        }

        datan.append('state', data.state)
        datan.append('description', data.description)
        datan.append('title', data.title)
        datan.append('material_id', _id)
        datan.append('entity', stateApariencia.currentIdEntity)

        editFiles(datan, token, dispatch)
            .then((res) => {
                // console.log(res)

                dispatch({
                    type: typesRepository.clearUploadProgressMaterial,
                })

                setShowModalEdit(false)

                // Notification(type.success, "El archivo ha sido editado correctamente")
                toast.success('El archivo ha sido editado correctamente')

                dispatchRedux(materialsApi.util.invalidateTags(['MaterialsByEntity']))

                setCurrentMaterial(undefined)

                // getFiles(token, stateApariencia.currentIdEntity, dispatch)

                // const payload = {
                //     token: token,
                //     entity_id: stateApariencia.currentIdEntity,
                //     dispatch: dispatch,
                //     page: 1,
                //     query: '',
                //     docs: 15,
                //     descendant: false,
                //     setpagination: setpagination,
                // }
                // getFiles(payload)

                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                console.log(e.response)
                const error = null
                // Notification(type.danger, error ? error : "Hubo un error, no se actualizó el archivo")
                toast.error(error ? error : 'Hubo un error, no se actualizó el archivo')

                setLoading(false)
            })
    }

    const handleCancelEdit = () => {
        setShowModalEdit(false)
        dispatch({
            type: typesRepository.clearUploadProgressMaterial,
        })
        if (!isEmpty(cancelToken)) cancelToken.cancel('Operation canceled by the user.')
    }

    // const token = JSON.parse(localStorage.getItem('user')!).token

    // useEffect(() => {
    //     const dataBack = {
    //         fname: 'getCategories',
    //         entity_id: stateApariencia.currentIdEntity,
    //     }

    //     getCategories(token, dataBack, dispatchProgram)
    // }, [dispatchProgram, stateApariencia.currentIdEntity, token])

    return (
        <div className="main_AddFiles">
            <div className="headFiles">
                <h3>Editar material de clases</h3>
                <i
                    className="fas fa-times-circle"
                    onClick={() => setShowModalEdit(false)}
                />
            </div>
            <div className="bodyFiles">
                <form className="contFormFile" onSubmit={sendEditFile}>
                    <div className="sepScroll scroll">
                        <div className="contInAddFile">
                            <p className="text_option_disabled">Entidad</p>
                            <input
                                type="text"
                                name="title"
                                value={newEntityName}
                                style={{ textTransform: 'capitalize' }}
                                disabled
                            />
                            {/* <select name="select" className="design_input" disabled style={{ textTransform: 'capitalize' }}>
                                <option value="" selected>{entityName}</option>
                            </select> */}
                        </div>

                        <div className="contInAddFile">
                            <p>Título</p>
                            <input
                                type="text"
                                placeholder="Ingrese el título"
                                value={data.title}
                                name="title"
                                onChange={onChangeInputs}
                                maxLength={80}
                            />
                        </div>

                        <div className="contInAddFile">
                            <p>Categorías</p>
                            <select
                                name="category"
                                value={data.category}
                                className="design_input"
                                onChange={(e: any) => onChangeInputs(e)}
                                required
                            >
                                {/* {
                                    category ?
                                        <option value={category?._id}  > {category?.name}</option>
                                        : <option value=""  style={{ display: 'none' }}> Seleccione una nueva categoría ...</option>
                                } */}
                                <option value="" style={{ display: 'none' }}>
                                    Seleccione una nueva categoría ...
                                </option>
                                {/* {stateProgram.categories.length &&
                                    stateProgram.categories.map((res: any) => (
                                        <option key={res._id} value={res._id}>
                                            {res.name}
                                        </option>
                                    ))} */}
                                {dataCategories &&
                                    dataCategories.categories.length &&
                                    dataCategories.categories.map(({ _id, name }) => (
                                        <option key={_id} value={_id}>
                                            {name}
                                        </option>
                                    ))}
                            </select>
                        </div>

                        <div
                            className="contInAddFile"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '15px',
                            }}
                        >
                            <p>Archivo</p>
                            <div className="typeFile">
                                <label>
                                    <input
                                        required
                                        type="radio"
                                        name="selfile"
                                        value="Archivo"
                                        onChange={(e) => setTypeFile(e.target.value)}
                                        checked={typeFile === 'Archivo'}
                                    />
                                    Archivo
                                </label>
                                <label>
                                    <input
                                        required
                                        type="radio"
                                        name="selfile"
                                        value="URL"
                                        onChange={(e) => setTypeFile(e.target.value)}
                                        checked={typeFile === 'URL'}
                                    />
                                    URL
                                </label>
                            </div>
                            {typeFile === 'Archivo' ? (
                                <label className="file_label">
                                    <span className="first_part">Subir Archivo</span>
                                    <input
                                        className="input_design"
                                        type="file"
                                        id="file"
                                        aria-label="File browser example"
                                        onChange={(e: any) =>
                                            changeImage(e.target.files[0])
                                        }
                                    />
                                    {imageName !== '' ? (
                                        <span className="second_part"> {imageName} </span>
                                    ) : (
                                        <span className="second_part">
                                            No se ha seleccionado ningun archivo{' '}
                                        </span>
                                    )}
                                </label>
                            ) : typeFile === 'URL' ? (
                                <>
                                    <div className="contInAddFile">
                                        <p>Nombre del archivo *</p>
                                        <input
                                            type="text"
                                            onChange={(e) => setNameFile(e.target.value)}
                                            value={namefile}
                                        />
                                    </div>
                                    <div className="contInAddFile">
                                        <p>Url archivo *</p>
                                        <input
                                            type="text"
                                            onChange={(e) => setUrl(e.target.value)}
                                            value={url}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <div className="contInAddFile">
                            <p>Descripción</p>
                            <textarea
                                name="description"
                                value={data.description}
                                onChange={onChangeInputs}
                                required
                            />
                        </div>

                        <div className="contInAddFile">
                            <div className="half_content_file">
                                <span className="text_switch">Hacer público </span>

                                <div
                                    className={`switch ${changeSwitch ? 'active' : ''}`}
                                    onClick={changeValueSwitch}
                                >
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>

                        {changeSwitch ? (
                            <div className="contInAddFile">
                                <p>Precio</p>
                                <input
                                    type="text"
                                    placeholder="Ingrese el precio"
                                    value={data.price?.toString()}
                                    name="price"
                                    onChange={(e) => onChangeInputs(e, true)}
                                    maxLength={5}
                                />
                            </div>
                        ) : null}

                        {uploadProgressMaterial > 0 && (
                            <div className="progress-container">
                                <p>{uploadProgressMaterial} %</p>
                                <progress value={uploadProgressMaterial} max="100" />
                            </div>
                        )}
                    </div>

                    <div className="optsBtnAdd">
                        <button type="button" onClick={handleCancelEdit}>
                            Cancelar
                        </button>
                        <button type="submit" disabled={loading}>
                            {loading ? <SpinnerModal /> : 'Guardar'}
                        </button>
                        {/* {

                            loading ? <div className="containerSpinner"> <SpinnerModal /> </div> : <button type="submit" >Guardar</button>
                        } */}
                    </div>
                </form>
            </div>
        </div>
    )
}
