import { useState, useContext } from 'react'
import { toast } from 'react-toastify'

import { axiosStudent } from '../../../../../config/axios'
import { AparienciaContext } from '../../../../../context/aparienciaContext/AparienciaProvider'
import SpinnerModal from '../../../../general/spinner/SpinnerModal'
// import Notification, { type } from './../../../../general/toast/Toast';

const SendEmailModal = ({ setSendEmail, email, courseScheduled, user_data }: any) => {
    const { state: stateApariencia } = useContext(AparienciaContext)

    const [loading, setloading] = useState<any>(false)

    const token = JSON.parse(localStorage.getItem('user')!).token

    const [data, setData] = useState({
        email: email,
    })

    const onChangeInputs = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    }

    const handleSendEmail = (e: any) => {
        e.preventDefault()

        setloading(true)

        axiosStudent({
            url: '/v5.0.1/api',
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                fname: 'sendCertificate',
                entity: stateApariencia.currentIdEntity,
                student: user_data._id,
                scheduled: courseScheduled,
                email: data.email,
            },
        })
            .then(({ data: dat }) => {
                // console.log(dat)

                setloading(false)

                // Notification(type.success, `El certificado se ha enviado al correo ${data.email}`)
                toast.success(`El certificado se ha enviado al correo ${data.email}`)

                setSendEmail(false)
            })
            .catch((e) => {
                console.log(e.response)

                setloading(false)

                // Notification(type.danger, "No se ha podido enviar el certificado, inténtalo de nuevo")
                toast.error('No se ha podido enviar el certificado, inténtalo de nuevo')
            })
    }

    return (
        <div className="main_AddCategory">
            <div className="heaCategory">
                <h3>Enviar certificado</h3>
                <i className="fas fa-times-circle" onClick={() => setSendEmail(false)} />
            </div>
            <div className="bodyCategory">
                <form className="contFormEnt" onSubmit={handleSendEmail}>
                    <div className="contFormEnt">
                        <div className="contInAddEnt">
                            <p>Correo destinario:</p>
                            <input
                                type="text"
                                placeholder="Ingrese correo electonico..."
                                name="email"
                                value={data.email}
                                onChange={onChangeInputs}
                                disabled={loading}
                            />
                        </div>
                    </div>

                    <div className="optsBtnAdd">
                        {
                            <>
                                <button type="button" onClick={() => setSendEmail(false)}>
                                    {' '}
                                    Cancelar
                                </button>
                                {loading ? (
                                    <div className="containerSpinner">
                                        {' '}
                                        <SpinnerModal />{' '}
                                    </div>
                                ) : (
                                    <button type="submit" disabled={loading}>
                                        {' '}
                                        Enviar{' '}
                                    </button>
                                )}
                            </>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SendEmailModal
