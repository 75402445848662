import { typesRepository } from './../../types/typesRepository'
import { IState, IAction } from './RepositoryInterface'

export const repositoryReducer = (state: IState, action: IAction): any => {
    switch (action.type) {
        case typesRepository.getRepository:
            return {
                ...state,
                files: action.payload,
            }

        // allUsers = [
        //     [
        //         {}
        //     ],
        //     [
        //         {}
        //     ],
        //     [],
        //     {}
        // ]

        // allUser = [
        //     {},
        //     {},
        // ]

        case typesRepository.createRepository:
            return {
                ...state,
                files: [...state.files, action.payload],
            }

        case typesRepository.deleteRepository:
            return {
                ...state,
                files: state.files.filter((data: any) => data._id !== action.payload),
            }

        case typesRepository.uploadProgressMaterial:
            return {
                ...state,
                uploadProgressMaterial: action.payload,
            }

        case typesRepository.clearUploadProgressMaterial:
            return {
                ...state,
                uploadProgressMaterial: 0,
            }

        case typesRepository.setCancelToken:
            return {
                ...state,
                cancelToken: action.payload,
            }

        default:
            return state
    }
}
