import { IAction, IState } from './AparienciaInterface'
import { typeUi } from './../types/typesui'

export const aparienciaReducer = (state: IState, action: IAction): any => {
    switch (action.type) {
        case typeUi.getTheme:
            return {
                ...state,
                theme: action.payload,
            }

        case typeUi.getId:
            return {
                ...state,
                currentIdEntity: action.payload,
            }

        case typeUi.changeImg:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    imgLogo: action.payload,
                },
            }
        case typeUi.setImgPreview:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    imgLogoPreview: action.payload,
                },
            }

        case typeUi.setImgReload:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    imgLogoPreview: null,
                },
            }

        case typeUi.removeTheme:
            return {
                theme: {},
            }
        default:
            return state
    }
}
