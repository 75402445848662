import { useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import TypeUnique from '../../questions/types/TypeUnique'
import TypeMultiple from '../../questions/types/TypeMultitple'
import TypeBoolean from '../../questions/types/TypeBoolean'
import TypeShort from '../../questions/types/TypeShort'
import TypeLarge from '../../questions/types/TypeLarge'
import TypeFile from '../../questions/types/TypeFile'
import { TINY_API_KEY } from '../../../../../config/utils'

const NewQuestion = ({ data, updateData, index, deleteQuestion }: any) => {
    const [questiondata, setQuestionData]: any = useState({
        title: '',
        nivel: 'easy',
        type: 'unique',
        data_unique: [],
        data_multi: [],
        data_boolean: [
            { _id: '1', value: 'Verdadero', correct: false },
            { _id: '2', value: 'Falso', correct: false },
        ],
        points: 0,
    })

    // const [message] = useState('')

    // const [question, setquestion] = useState('')

    const onChangeInputs = (e: any) => {
        setQuestionData({
            ...questiondata,
            [e.target.name]: e.target.value,
        })
        updateData({ ...data, [e.target.name]: e.target.value }, index)
    }

    const onChangeOption = (e: any) => {
        // console.log(e.target.value);

        setQuestionData({
            ...questiondata,
            type: e.target.value,
            data_unique: [],
            data_multi: [],
            data_boolean: [
                { _id: '1', value: 'Verdadero', correct: false },
                { _id: '2', value: 'Falso', correct: false },
            ],
        })
        updateData({ ...data, type: e.target.value, alternatives: [] }, index)
    }

    const renderTypeQuestion = () => {
        // console.log('type', type)
        switch (type) {
            case 'unique':
                return (
                    <TypeUnique
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                        updateData={updateData}
                        data={data}
                        index={index}
                    />
                )
            case 'multiple':
                return (
                    <TypeMultiple
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                        updateData={updateData}
                        data={data}
                        index={index}
                    />
                )
            case 'boolean':
                return (
                    <TypeBoolean
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                        updateData={updateData}
                        data={data}
                        index={index}
                    />
                )
            case 'short':
                return (
                    <TypeShort
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                )
            case 'large':
                return (
                    <TypeLarge
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                )
            default:
                return (
                    <TypeFile
                        setQuestionData={setQuestionData}
                        questiondata={questiondata}
                    />
                )
        }
    }

    const { nivel, title, type, points } = questiondata

    useEffect(() => {
        const { alternatives, ...resp } = data

        switch (type) {
            case 'unique':
                setQuestionData({
                    ...resp,
                    data_multi: [],
                    data_boolean: [
                        { _id: '1', value: 'Verdadero', correct: false },
                        { _id: '2', value: 'Falso', correct: false },
                    ],
                    data_unique: [...alternatives],
                })
                break
            case 'multiple':
                setQuestionData({
                    ...resp,
                    data_unique: [],
                    data_boolean: [
                        { _id: '1', value: 'Verdadero', correct: false },
                        { _id: '2', value: 'Falso', correct: false },
                    ],
                    data_multi: [...alternatives],
                })
                break
            case 'boolean':
                setQuestionData({
                    ...resp,
                    data_unique: [],
                    data_multi: [],
                    data_boolean:
                        alternatives.length !== 0
                            ? [...alternatives]
                            : [
                                  { _id: '1', value: 'Verdadero', correct: false },
                                  { _id: '2', value: 'Falso', correct: false },
                              ],
                })
                break
            default:
                setQuestionData({
                    ...resp,
                    data_unique: [],
                    data_multi: [],
                    data_boolean: [
                        { _id: '1', value: 'Verdadero', correct: false },
                        { _id: '2', value: 'Falso', correct: false },
                    ],
                })
                break
        }

        // if (type === 'unique') {
        //     console.log('unique==', alternatives)
        //     setQuestionData({
        //         ...resp,
        //         data_multi: [],
        //         data_boolean: [
        //             { _id: '1', value: 'Verdadero', correct: false },
        //             { _id: '2', value: 'Falso', correct: false }
        //         ],
        //         data_unique: [
        //             ...alternatives
        //         ]
        //     })
        // } else if (type === 'multiple') {
        //     setQuestionData({
        //         ...resp,
        //         data_unique: [],
        //         data_boolean: [
        //             { _id: '1', value: 'Verdadero', correct: false },
        //             { _id: '2', value: 'Falso', correct: false }
        //         ],
        //         data_multi: [
        //             ...alternatives
        //         ]
        //     })
        // } else if (type === 'boolean') {
        //     setQuestionData({
        //         ...resp,
        //         data_unique: [],
        //         data_multi: [],
        //         data_boolean: alternatives.length !== 0
        //             ? [...alternatives]
        //             : [
        //                 { _id: '1', value: 'Verdadero', correct: false },
        //                 { _id: '2', value: 'Falso', correct: false }
        //             ]
        //     })
        // } else {
        //     setQuestionData({
        //         ...resp,
        //         data_unique: [],
        //         data_multi: [],
        //         data_boolean: [
        //             { _id: '1', value: 'Verdadero', correct: false },
        //             { _id: '2', value: 'Falso', correct: false }
        //         ],
        //     })
        // }
    }, [type, data])
    return (
        <>
            <div className="form_question" style={{ position: 'relative' }}>
                <i
                    className="fas fa-times-circle"
                    style={{
                        position: 'absolute',
                        right: -5,
                        top: 0,
                        cursor: 'pointer',
                        fontSize: '25px',
                    }}
                    onClick={() => deleteQuestion(index)}
                />
                <div className="container_options">
                    <div className="select">
                        <label>Tipo de Pregunta:</label>
                        <select value={type} onChange={onChangeOption}>
                            <option value="unique"> Opción única </option>
                            <option value="multiple"> Selección múltiple </option>
                            <option value="boolean"> Verdadero o Falso </option>
                            <option value="short"> Pregunta corta </option>
                            <option value="large"> Pregunta larga </option>
                            <option value="file"> Pregunta con archivos </option>
                        </select>
                    </div>
                    <div className="select">
                        <label>Nivel de Dificultad:</label>
                        <select name="nivel" value={nivel} onChange={onChangeInputs}>
                            <option value="easy">Facil</option>
                            <option value="medium">Normal</option>
                            <option value="hard">Dificil</option>
                        </select>
                    </div>
                    <div className="select">
                        <label>Puntaje:</label>
                        <input
                            required
                            className="input_question"
                            type="number"
                            name="points"
                            value={points}
                            onChange={onChangeInputs}
                        />
                    </div>
                </div>
                <div className="container_options title">
                    <label>Pregunta:</label>
                    <input
                        required
                        className="input_question"
                        type="text"
                        name="title"
                        value={title}
                        onChange={onChangeInputs}
                    />
                </div>
                <div className="container_options">
                    <label>Descripción (opcional):</label>
                    <Editor
                        apiKey={TINY_API_KEY}
                        init={{
                            language: 'es',
                            directionality: 'ltr',
                            height: 180,
                            width: '100%',
                            menubar: true,
                            branding: false,
                            plugins: [
                                'advlist autolink lists link image',
                                'charmap print preview anchor help',
                                'searchreplace visualblocks code',
                                'insertdatetime media table paste wordcount',
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic |' +
                                'alignleft aligncenter alignright |' +
                                'bullist numlist outdent indent | help',
                        }}
                        value={data.question}
                        onEditorChange={(content: string, editor: any) => {
                            // setquestion(content);
                            updateData({ ...data, question: content }, index)
                        }}
                    />
                </div>
                <div className="container_options">
                    <label>Alternativas:</label>
                    {/* {
                        data.type === 'unique' ? <TypeUnique setQuestionData={setQuestionData} questiondata={questiondata} updateData={updateData} data={data} index={index} /> :
                        data.type === 'multiple' ? <TypeMultiple setQuestionData={setQuestionData} questiondata={questiondata} updateData={updateData} data={data} index={index} /> :
                        data.type === 'boolean' ? <TypeBoolean setQuestionData={setQuestionData} questiondata={questiondata} updateData={updateData} data={data} index={index} /> :
                        data.type === 'short' ? <TypeShort setQuestionData={setQuestionData} questiondata={questiondata} /> :
                        data.type === 'large' ? <TypeLarge setQuestionData={setQuestionData} questiondata={questiondata} /> :
                                            <TypeFile setQuestionData={setQuestionData} questiondata={questiondata} />
                    } */}
                    {renderTypeQuestion()}
                </div>
                {/* <h4 className="menssage_modal_form">{message}</h4> */}
            </div>
        </>
    )
}

export default NewQuestion
